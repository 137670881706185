import io from "socket.io-client";
import {sortBy as _sortBy} from "lodash";
import {getDeviceInfo} from "@/utils/device";
import {getFormattedDate} from "./date";
import {getByteLength, getFormattedTelNumber} from "./string";
import epsonPrinter from "../store/modules/epsonPrinter";
import {getTsConfReceiptInfos, getBillPrintContent} from "./BillPrintContentManager";

// class NoUsePrinterError extends Error {}
// class NotConnectedPrinterError extends Error {}

/**
 * @typedef ConfigGetTsConfReceiptInfo 설정
 * @property {boolean | null | undefined} useBillPrinter
 * @property {boolean | null | undefined} useKitchenPrinter
 */
/**
 * 빌프린터로 테스트 프린트
 * @param type bill,kitchen
 * @returns {Promise<void>}
 */
async function testWebSdkPrint(type){
  const {
    billPrinterIpAddress,
    billPrinterIpPort,
    billPrinterDeviceId,
    kitchenBillPrinterIpAddress,
    kitchenBillPrinterIpPort,
    kitchenBillPrinterDeviceId,
  } = getDeviceInfo();
  setPosId(issueID);
  let imageFileWindows = "C:\\BIXOLON\\Web Print SDK\\logo.bmp";
  printBitmapFile(imageFileWindows, -2, 1, false);
  printText(`${type} 프린터 테스트\n이미지경로: C:\\BIXOLON\\Web Print SDK\\logo.bmp\n`, 0, 0, false, false, false, 0, 0);
  cutPaper(1);
  if(type=="bill") {
    let serverURL = `wss://${billPrinterIpAddress}:${billPrinterIpPort}/WebPrintSDK/`;
    await requestBixolonPrint(serverURL, billPrinterDeviceId, getPosData(),this);
  }else{
    let serverURL = `wss://${kitchenBillPrinterIpAddress}:${kitchenBillPrinterIpPort}/WebPrintSDK/`;
    await requestBixolonPrint(serverURL, kitchenBillPrinterDeviceId, getPosData(),this);
  }



  issueID++;
}
/**
 * 빌프린터로 tsConfReceiptMstList 조회한 컨텐츠 포멧으로 프린트
 * @param receiptCode
 * @param useFlag
 * @param data
 * @param {ConfigGetTsConfReceiptInfo} config
 * @returns {Promise<void>}
 */
async function getTsConfReceiptInfo(receiptCode, useFlag, data, config, printCount = 1) {
  const {tsConfReceiptMstList} = await getTsConfReceiptInfos(receiptCode, useFlag);
  // 컨텐츠 없으면 출력하지 않음
  if (!tsConfReceiptMstList?.length) {
    this.errorToast(
        this.$t("billPrinter.validationMessage.billPrintInfoNotExist")
    );
    return;
  }

  let {
    useBillPrinter,
    useKitchenPrinter,
  } = config;

  const {
    isBillPrinterUse,
    billPrinterCommunication,
    billPrinterIpAddress,
    billPrinterIpPort,
    billPrinterDeviceId,
    isKitchenBillPrinterUse,
    kitchenBillPrinterCommunication,
    kitchenBillPrinterIpAddress,
    kitchenBillPrinterIpPort,
    kitchenBillPrinterDeviceId,
  } = getDeviceInfo();

  if(!useBillPrinter && !useKitchenPrinter && receiptCode === "CADDIE_ARGMT") {
    this.errorToast(
        this.$t("billPrinter.validationMessage.deviceBillPrintSet")
    );
    return;
  } else if (!useBillPrinter && !useKitchenPrinter && receiptCode === "KITCHEN_STATEMENT"){
    this.errorToast(
        this.$t("billPrinter.validationMessage.kitchenPrinterSetting")
    );
    return;
  }

  const epsonBillPrinter = epsonPrinter.state.billPrinter;
  const epsonKitchenPrinter = epsonPrinter.state.kitchenPrinter;

  const dataset = (Array.isArray(data) ? data : [data]);
  const serialPortPrinterContent = dataset
      .reduce((prev, item) => prev + getBillPrintContent.call(this, tsConfReceiptMstList, item), "");

  let printerFailedCount = 0;

  if (!serialPortPrinterContent?.trim?.()) {
    this.errorToast("출력할 데이터가 없습니다");
    return;
  }

  let serverURL = "";

  // 빌프린터로 출력
  if (useBillPrinter) {
    if (!isBillPrinterUse || (billPrinterCommunication === "IP" && !epsonBillPrinter)) {
      this.errorToast(
          this.$t("billPrinter.validationMessage.deviceBillPrintSet")
      );
      printerFailedCount++;
    } else {
      try {
        switch (billPrinterCommunication) {
          case "PORT":
            await writeBillPrint("5555", {
              content: serialPortPrinterContent
            });
            break;
          case "IP":
            await Promise.all(dataset.map((item) =>
                printBillContentsAsEpson(epsonBillPrinter, tsConfReceiptMstList, item, 1)
            ));
            break;
          case "WEBSDK":
            serverURL = `wss://${billPrinterIpAddress}:${billPrinterIpPort}/WebPrintSDK/`;
            setPosId(issueID);
            dataset.map((item) =>
                printBillContentsAsBixolon(tsConfReceiptMstList,item, 1));
            if(!await requestBixolonPrint(serverURL, billPrinterDeviceId, getPosData(),this)){
              printerFailedCount++;
            }
            issueID++;
            break;
        }
      } catch (e) {
        printerFailedCount++;
        console.log(e);
      }

    }
  }
  // 주방프린터로 출력
  if (useKitchenPrinter) {
    if (!isKitchenBillPrinterUse || (kitchenBillPrinterCommunication === "IP" && !epsonKitchenPrinter)) {
      this.errorToast(
          this.$t("billPrinter.validationMessage.kitchenDeviceBillPrintSet")
      );
      printerFailedCount++;
    } else {
      try {
        switch (kitchenBillPrinterCommunication) {
          case "PORT":
            await kitchenWriteBillPrint("5555", {
              content: serialPortPrinterContent,
            });
            break;
          case "IP":
            for(let i = 0 ;  i < printCount ; i++){
              await Promise.all(dataset.map((item) =>
                 printBillContentsAsEpson(epsonKitchenPrinter, tsConfReceiptMstList, item, 1)
             ));

            }
            break;
          case "WEBSDK" :
            serverURL = `wss://${kitchenBillPrinterIpAddress}:${kitchenBillPrinterIpPort}/WebPrintSDK/`;
            setPosId(issueID);
            dataset.map((item) =>
                printBillContentsAsBixolon(tsConfReceiptMstList, item, printCount));
            if(!await requestBixolonPrint(serverURL, kitchenBillPrinterDeviceId, getPosData(),this)){
              printerFailedCount++;
            }
            issueID++;
        }

      } catch (e) {
        printerFailedCount++;
        console.log(e);
      }

    }
  }

  if (printerFailedCount === 0) {
    this.infoToast("출력하였습니다");
  }
}


function getPortList(portNum) {
  return new Promise((resolve, reject) => {
    const socket = io(`http://localhost:${portNum}?type=portlist`, {
      reconnection: false,
    });

    socket.on("connect_error", (error) => {
      console.error(error);
      reject(
        "Serial 통신 daemon 연결이 실패하였습니다.\nPort 설정을 확인하시거나\ndaemon을 띄운 후 다시 시도해 주십시오."
      );
    });

    socket.on("disconnect", () => {
      console.log("Serial 통신 daemon과의 연결 끊김");
    });

    socket.on("portListFailed", () => {
      console.error("portListFailed event!");
      reject("포트 정보를 가져올 수 없습니다");
      socket.close();
    });

    socket.on("portList", (portList) => {
      resolve(portList);
      socket.close();
    });

    socket.emit("requestPortList");
  });
}

function writeBillPrint(portNum, writeContent) {
  return new Promise((resolve, reject) => {
    const diviceObj = getDeviceInfo();

    writeContent.port = diviceObj.billPrinterComPort;
    writeContent.bandRate = diviceObj.billPrinterBitRate;

    const socket = io(`http://localhost:${portNum}?type=billprinter`, {
      reconnection: false,
    });

    socket.on("connect_error", (error) => {
      console.log(error);
      reject(
        "Serial 통신 daemon 연결이 실패하였습니다.\nPort 설정을 확인하시거나\ndaemon을 띄운 후 다시 시도해 주십시오."
      );
    });

    socket.on("disconnect", () => {
      console.log("Serial 통신 daemon과의 연결 끊김");
    });

    socket.on("billprintEnded", (res) => {
      if (res.state) {
        resolve();
      } else {
        reject(res.errorMessage);
      }
      socket.disconnect();
    });

    socket.emit("billprint", writeContent);
  });
}

function kitchenWriteBillPrint(portNum, writeContent) {
  return new Promise((resolve, reject) => {
    const diviceObj = getDeviceInfo();

    writeContent.port = diviceObj.kitchenBillPrinterComPort;
    writeContent.bandRate = diviceObj.kitchenBillPrinterBitRate;

    const socket = io(`http://localhost:${portNum}?type=billprinter`, {
      reconnection: false,
    });

    socket.on("connect_error", (error) => {
      console.log(error);
      reject(
        "Serial 통신 daemon 연결이 실패하였습니다.\nPort 설정을 확인하시거나\ndaemon을 띄운 후 다시 시도해 주십시오."
      );
    });

    socket.on("disconnect", () => {
      console.log("Serial 통신 daemon과의 연결 끊김");
    });

    socket.on("billprintEnded", (res) => {
      if (res.state) {
        resolve();
      } else {
        reject(res.errorMessage);
      }
      socket.disconnect();
    });


    // setTimeout(()=>{
      socket.emit("billprint", writeContent);
    // },1000);
  });
}
/**
 * @typedef TsConfReceiptMst 환경설정) 영수증 정보
 * @property {number} receiptId 영수증ID
 * @property {string} receiptCode 영수증코드
 * @property {boolean} logoFlag 로고여부
 * @property {"LEFT"|"CENTER"|"RIGHT"} sortCode 정렬코드
 * @property {boolean} lineFlag 라인여부
 * @property {number} lineWidth 라인가로
 * @property {"LEFT"|"CENTER"|"RIGHT"|null|undefined} lineLocation 라인여백위치
 * @property {boolean} linePaddingFlag 라인여백여부
 * @property {boolean} loopFlag LOOP여부
 * @property {string|null|undefined} loopName LOOP명
 * @property {boolean} unPrtFlag 미출력여부
 * @property {string|null|undefined} unPrtVariable 미출력변수
 * @property {boolean} cutFlag 영수증 절단 여부
 * @property {number} sortNo 정렬번호
 * @property {boolean} useFlag 사용여부
 * @property {string|null|undefined} insertDt 등록자
 * @property {number} insertId 등록일시
 * @property {string|null|undefined} updateDt 수정자
 * @property {number|null|undefined} updateId 수정일시
 * @property {TsConfReceiptDtl[]} tsConfReceiptDtlList
 */
/**
 * @typedef TsConfReceiptDtl 환경설정) 영수증 정보 상세
 * @property {number} dtlId 상세ID
 * @property {number} receiptId 영수증ID
 * @property {boolean} variableFlag 변수여부
 * @property {string|null|undefined} variableName 변수명
 * @property {"DATE"|"NUMBER"|"TEL"|null|undefined} variableType 변수타입
 * @property {string|null|undefined} variableFormat 변수포맷
 * @property {string|null|undefined} contents 내용
 * @property {number} contentsWidth 내용가로
 * @property {"LEFT"|"CENTER"|"RIGHT"|null|undefined} paddingLocation 여백위치
 * @property {boolean} lineFeedFlag 라인줄바꿈여부
 * @property {"DOUBLE"|"NORMAL"|"SIZE6"|"SIZE5"|"SIZE4"|"SIZE3"|"SIZE2"|"SIZE1"|"DOUBLE3"|"DOUBLE2"|"DOUBLE"|null|undefined} fontSize 폰트사이즈
 * @property {number} sortNo 정렬번호
 * @property {boolean} useFlag 사용여부
 * @property {number} insertId 등록자
 * @property {string} insertDt 등록일시
 * @property {number} updateId 수정자
 * @property {string|null|undefined} updateDt 수정일시
 */
/**
 * @typedef PrintDataInfo
 * @property {string} visitDate 내장일자
 * @property {string} visitName 내장객명
 * @property {StoreAmt[]|null|undefined} storeAmtList
 * @property {number} totPayAmt 합계금액
 * @property {string} bizName 사업자명
 * @property {string} bizNo 사업자번호
 * @property {string} rprsntvName 대표자명
 * @property {string} telNo 전화번호
 * @property {string} faxNo 팩스번호
 */
/**
 * @typedef StoreAmt
 * @property {string} storeName 매출명
 * @property {number} payAmt 매출금액
 */
export const PRINTER_FULL_WIDTH = 42;

/**
 * @param {EpsonPrinterInstance|null|undefined} printer
 * @param {TsConfReceiptMst[]|null|undefined} tsConfReceiptMstList
 * @param {PrintDataInfo|null|undefined} printDataInfo
 * @param {number|null|undefined} printCount
 */
export function printBillContentsAsEpson(
    printer,
    tsConfReceiptMstList,
    printDataInfo,
    printCount,
) {
  if (!printer) {
    throw new Error("프린터 설정 필요.");
  }
  if (!printDataInfo) {
    throw new Error("PrintDataInfo 필수.");
  }
  if (!!printCount && isNaN(parseInt(printCount))) {
    throw new Error("printCount 는 number 타입이어야 합니다.");
  }

  // console.log(tsConfReceiptMstList);
  // if (!tsConfReceiptMstList?.length) {
  //   return;
  // }

  const _printCunt = printCount || 1;
  if (_printCunt <= 0) {
    return;
  }

  printer.addTextLang('ko');

  // 프린트 매수만큼 반복
  for (let i = 0; i < _printCunt; i++) {
    _sortBy(tsConfReceiptMstList, "sortBy").forEach(({
                                                       logoFlag,
                                                       cutFlag,
                                                       sortCode,
                                                       lineFlag,
                                                       lineWidth,
                                                       lineLocation,
                                                       linePaddingFlag,
                                                       unPrtFlag,
                                                       unPrtVariable,
                                                       loopFlag,
                                                       loopName,
                                                       tsConfReceiptDtlList,
                                                     }) => {
      const _lineWidth =
          lineWidth === 0 ?
              PRINTER_FULL_WIDTH :
              lineWidth;

      // const _lineWidth =
      //     lineWidth === 0 ?
      //캐디배치쪽은 표준이 42 로 되어있음
      // PRINTER_FULL_WIDTH : (
      //     PRINTER_FULL_WIDTH < lineWidth ? PRINTER_FULL_WIDTH : lineWidth
      // );


      const isUnPrintFlag =
          unPrtFlag && (
              printDataInfo[unPrtVariable] === null ||
              printDataInfo[unPrtVariable] === undefined ||
              printDataInfo[unPrtVariable].length === 0
          );

      const isSkipDtl = !tsConfReceiptDtlList.length;

      if (cutFlag) {
        printer.addCut(printer.CUT_FEED);
        return;
      }

      switch (sortCode) {
        case "LEFT":
          printer.addTextAlign(printer.ALIGN_LEFT);
          break;
        case "RIGHT":
          printer.addTextAlign(printer.ALIGN_RIGHT);
          break;
        case "CENTER":
        default:
          printer.addTextAlign(printer.ALIGN_CENTER);
      }


      if (logoFlag) {
        // 로고 출력
        let logoSize = 32;
        printer.addLogo(logoSize, logoSize);
        return;
      }

      if (linePaddingFlag) {
        // 한줄 공백 출력
        if (!isUnPrintFlag) {
          printer.addFeedLine(1);
        }
        return;
      }

      if (isSkipDtl && !isUnPrintFlag) {
        // printer.addText('  ');
      }

      if (lineFlag) {
        const _lineLocation =
            lineLocation === "LEFT" ?
                "RIGHT" : (
                    lineLocation === "RIGHT" ? "LEFT" : "CENTER"
                );
        // 점선 라인 출력
        if (!isUnPrintFlag) {
          printDtlItemAsEpson(printer,
              (new Array(_lineWidth).fill("-")).join(""),
              "",
              PRINTER_FULL_WIDTH, _lineLocation);
        }
      } else if (!isUnPrintFlag && !isSkipDtl) {
        const blankWidth = PRINTER_FULL_WIDTH - tsConfReceiptDtlList.reduce((prev, tsConfReceiptDtl) => prev + tsConfReceiptDtl.contentsWidth, 0);
        const _blankWidth =
            tsConfReceiptDtlList.reduce((prev, tsConfReceiptDtl) => prev || tsConfReceiptDtl.lineFeedFlag, false) ?
                0 : (blankWidth < 0 ? 0 : blankWidth);

        const [fBlank, tBlank] = getBetweenSpaces(_blankWidth);

        if (loopFlag) {
          /**
           * @type {StoreAmt[]} loopData
           */
          let loopData = printDataInfo[loopName];

          if (loopData?.length) {

            loopData.forEach((loopDataItem) => {
              _sortBy(tsConfReceiptDtlList, ["sortNo"]).forEach((tsConfReceiptDtl, index) => {
                // tsConfReceiptDtl === billPrintInfoList[i].tsConfReceiptDtlList[a]
                const {
                  contentsWidth,
                  contents,
                  variableFlag,
                  variableType,
                  variableFormat,
                  variableName,
                  fontSize,
                  paddingLocation,
                  lineFeedFlag,
                } = tsConfReceiptDtl;

                if (index === 0) {
                  printer.addText(fBlank);
                }

                let text = !variableFlag ? "" : getTextFromPrintDataInfo(
                    loopDataItem,
                    variableType,
                    variableFormat,
                    variableName,
                );

                if (contents) {
                  text = `${text}${contents}`;
                }

                const _contentsWidth =
                    contentsWidth === 0 ?
                        getByteLength(text) : (
                            _lineWidth < contentsWidth ? _lineWidth : contentsWidth
                        );
                printDtlItemAsEpson(printer, text, fontSize, _contentsWidth, paddingLocation);

                if (lineFeedFlag) {
                  printer.addText('\n');
                }

                if (index === tsConfReceiptDtlList.length - 1) {
                  printer.addText(tBlank);
                  printer.addText('\n');
                }
              });
            });
          }
        } else {
          _sortBy(tsConfReceiptDtlList, ["sortNo"]).forEach((tsConfReceiptDtl, index) => {
            // tsConfReceiptDtl === billPrintInfoList[i].tsConfReceiptDtlList[a]
            if (index === 0) {
              // printer.addText(fBlank);
            }
            const {
              contentsWidth,
              contents,
              variableFlag,
              variableType,
              variableFormat,
              variableName,
              fontSize,
              paddingLocation,
              lineFeedFlag,
            } = tsConfReceiptDtl;

            let text = !variableFlag ? "" : getTextFromPrintDataInfo(
                printDataInfo,
                variableType,
                variableFormat,
                variableName,
            );

            if (contents) {
              text = `${text}${contents}`;
            }

            const _contentsWidth =
                contentsWidth === 0 ?
                    getByteLength(text) : (
                        _lineWidth < contentsWidth ? _lineWidth : contentsWidth
                    );

            printDtlItemAsEpson(printer, text, fontSize, _contentsWidth, paddingLocation);

            if (lineFeedFlag) {
              printer.addFeedLine(1);
            }
            if (index === tsConfReceiptDtlList.length - 1) {
              // printer.addText(tBlank);
            }
          });

        }
      }

      if (isSkipDtl && !isUnPrintFlag) {
        // printer.addText('  ');
      }

      if (!loopFlag && !isUnPrintFlag) {
        printer.addText('\n');
      }
    });


    for (let z = 0; z < 2; z++) {
      printer.addText("\n");
    }

    printer.addCut(printer.CUT_FEED);
    printer.send();
  }
}

function printBillContentsAsBixolon(
    tsConfReceiptMstList,
    printDataInfo,
    printCount,
) {
  if (!tsConfReceiptMstList?.length) {
    return;
  }

  const _printCunt = printCount || 1;
  if (_printCunt <= 0) {
    return;
  }
  // 프린트 매수만큼 반복
  for (let i = 0; i < _printCunt; i++) {
    _sortBy(tsConfReceiptMstList, "sortBy").forEach(({
                                                       logoFlag,
                                                       cutFlag,
                                                       sortCode,
                                                       lineFlag,
                                                       lineWidth,
                                                       lineLocation,
                                                       linePaddingFlag,
                                                       unPrtFlag,
                                                       unPrtVariable,
                                                       loopFlag,
                                                       loopName,
                                                       tsConfReceiptDtlList,
                                                     }) => {
      const _lineWidth = lineWidth === 0 ? PRINTER_FULL_WIDTH : lineWidth;

      const isUnPrintFlag =
          unPrtFlag && (
              printDataInfo[unPrtVariable] === null ||
              printDataInfo[unPrtVariable] === undefined ||
              printDataInfo[unPrtVariable].length === 0
          );

      const isSkipDtl = !tsConfReceiptDtlList.length;

      if (cutFlag) {
        cutPaper(1);
        return;
      }
      let sort = 1;
      switch (sortCode) {
        case "LEFT":
          sort = 0;
          break;
        case "RIGHT":
          sort = 2;
          break;
        case "CENTER":
          sort = 1;
          break;
      }

      if (logoFlag) {
        // 로고 출력
        let imageFileWindows = "C:\\BIXOLON\\Web Print SDK\\logo.bmp";
        printBitmapFile(imageFileWindows, -2, 1, false);
        return;
      }

      if (linePaddingFlag) {
        // 한줄 공백 출력
        if (!isUnPrintFlag) {
          printText("\n", 0, 0, false, false, false, 0, sort);
        }
        return;
      }
      if (lineFlag) {
        const _lineLocation = lineLocation === "LEFT" ? "RIGHT" : (lineLocation === "RIGHT" ? "LEFT" : "CENTER");
        // 점선 라인 출력
        if (!isUnPrintFlag) {
          printDtlItemAsBixolon((new Array(_lineWidth).fill("-")).join(""), "", PRINTER_FULL_WIDTH, _lineLocation);
        }
      } else if (!isUnPrintFlag && !isSkipDtl) {
        const blankWidth = PRINTER_FULL_WIDTH - tsConfReceiptDtlList.reduce((prev, tsConfReceiptDtl) => prev + tsConfReceiptDtl.contentsWidth, sort);
        const _blankWidth =
            tsConfReceiptDtlList.reduce((prev, tsConfReceiptDtl) => prev || tsConfReceiptDtl.lineFeedFlag, false) ?
                0 : (blankWidth < 0 ? 0 : blankWidth);

        const [fBlank, tBlank] = getBetweenSpaces(_blankWidth);

        if (loopFlag) {
          /**
           * @type {StoreAmt[]} loopData
           */
          let loopData = printDataInfo[loopName];

          if (loopData?.length) {

            loopData.forEach((loopDataItem) => {
              _sortBy(tsConfReceiptDtlList, ["sortNo"]).forEach((tsConfReceiptDtl, index) => {
                // tsConfReceiptDtl === billPrintInfoList[i].tsConfReceiptDtlList[a]
                const {
                  contentsWidth,
                  contents,
                  variableFlag,
                  variableType,
                  variableFormat,
                  variableName,
                  fontSize,
                  paddingLocation,
                  lineFeedFlag,
                } = tsConfReceiptDtl;

                if (index === 0) {
                  // printer.addText(fBlank);
                  printText(fBlank, 0, 0, false, false, false, 0, sort);
                }

                let text = !variableFlag ? "" : getTextFromPrintDataInfo(
                    loopDataItem,
                    variableType,
                    variableFormat,
                    variableName,
                );

                if (contents) {
                  text = `${text}${contents}`;
                }

                const _contentsWidth = contentsWidth === 0 ? getByteLength(text) : (_lineWidth < contentsWidth ? _lineWidth : contentsWidth);
                printDtlItemAsBixolon(text, fontSize, _contentsWidth, paddingLocation);

                if (lineFeedFlag) {
                  printText("\n", 0, 0, false, false, false, 0, sort);
                }

                if (index === tsConfReceiptDtlList.length - 1) {
                  // printer.addText(tBlank);
                  // printer.addText('\n');
                  printText(tBlank, 0, 0, false, false, false, 0, sort);
                  printText("\n", 0, 0, false, false, false, 0, sort);
                }
              });
            });
          }
        } else {
          _sortBy(tsConfReceiptDtlList, ["sortNo"]).forEach((tsConfReceiptDtl, index) => {
            // tsConfReceiptDtl === billPrintInfoList[i].tsConfReceiptDtlList[a]
            if (index === 0) {
              // printer.addText(fBlank);
            }
            const {
              contentsWidth,
              contents,
              variableFlag,
              variableType,
              variableFormat,
              variableName,
              fontSize,
              paddingLocation,
              lineFeedFlag,
            } = tsConfReceiptDtl;

            let text = !variableFlag ? "" : getTextFromPrintDataInfo(
                printDataInfo,
                variableType,
                variableFormat,
                variableName,
            );

            if (contents) {
              text = `${text}${contents}`;
            }

            const _contentsWidth = contentsWidth === 0 ? getByteLength(text) : (_lineWidth < contentsWidth ? _lineWidth : contentsWidth);

            printDtlItemAsBixolon(text, fontSize, _contentsWidth, paddingLocation);

            if (lineFeedFlag) {
              // printer.addFeedLine(1);
              printText("\n", 0, 0, false, false, false, 0, sort);
            }
          });

        }
      }

      if (!loopFlag && !isUnPrintFlag) {
        printText("\n", 0, 0, false, false, false, 0, sort);
      }
    });
    for (let z = 0; z < 2; z++) {
      printText("\n", 0, 0, false, false, false, 0, 0);
    }
    cutPaper(1);
  }
}

/**
 *
 * @param {PrintDataInfo|null|undefined} printDataInfo
 * @param {"DATE"|"NUMBER"|"TEL"|null|undefined} variableType 변수타입
 * @param {string|null|undefined} variableFormat 변수포맷
 * @param {string|null|undefined} variableName 변수명
 * @return {string} text
 */
function getTextFromPrintDataInfo(
    printDataInfo,
    variableType,
    variableFormat,
    variableName,
) {
  const data = printDataInfo[variableName];
  switch (variableType) {
    case "DATE":
      return getFormattedDate(data, variableFormat) || "";
    case "NUMBER":
      return new Intl.NumberFormat().format(data);
    case "TEL":
      return getFormattedTelNumber(data, variableFormat) || "";
    default:
      return [null, undefined].includes(data) ? "" : `${data}`;
  }
}

/**
 *
 * @param {EpsonPrinterInstance|null|undefined} printer
 * @param {string} text
 * @param {"DOUBLE"|"NORMAL"|"SIZE6"|"SIZE5"|"SIZE4"|"SIZE3"|"SIZE2"|"SIZE1"|"DOUBLE3"|"DOUBLE2"|"DOUBLE"|null|undefined}fontSize
 * @param {number} width
 * @param {"LEFT"|"CENTER"|"RIGHT"|null|undefined} paddingLocation
 */
function printDtlItemAsEpson(printer, text, fontSize, width, paddingLocation) {
  let widthSize = 1;
  let heightSize = 1;
  switch (fontSize) {
    case "SIZE1":
      widthSize = 2;
      heightSize = 2;
      break;
    case "SIZE2":
      widthSize = 3;
      heightSize = 3;
      break;
    case "SIZE3":
      widthSize = 4;
      heightSize = 4;
      break;
    case "SIZE4":
      widthSize = 4;
      heightSize = 5;
      break;
    case "SIZE5":
      widthSize = 5;
      heightSize = 6;
      break;
    case "SIZE6":
      widthSize = 6;
      heightSize = 7;
      break;
    case "DOUBLE":
      widthSize = 1;
      heightSize = 2;
      break;
    case "DOUBLE2":
      widthSize = 1;
      heightSize = 2;
      break;
    case "DOUBLE3":
      widthSize = 1;
      heightSize = 2;
      break;
    default:
      widthSize = 1;
      heightSize = 1;
      break;
  }

  const byteLength = getByteLength(text);
  const _width = width < byteLength ? byteLength : width;

  // if (width < byteLength) {
  //     printer.addText(text.substr(0, width));
  //     return;
  // }

  const emptySpace = getSpace(_width, text, widthSize);
  const pivot = Math.ceil(emptySpace.length / 2);
  const frontEmptySpace = emptySpace.substr(0, pivot);
  const tailEmptySpace = emptySpace.substr(pivot);


  switch (paddingLocation) {
    case "LEFT":
      printer.addTextSize(widthSize,heightSize);
      printer.addText(emptySpace);
      printer.addTextSize(widthSize, heightSize);
      printer.addText(text);
      break;
    case "CENTER":
      printer.addTextSize(1, 1);
      printer.addText(frontEmptySpace);
      printer.addTextSize(widthSize, heightSize);
      printer.addText(text);
      printer.addTextSize(1, 1);
      printer.addText(tailEmptySpace);
      break;
    case "RIGHT":
    default:
      printer.addTextSize(widthSize, heightSize);
      printer.addText(text);
      printer.addTextSize(widthSize, heightSize);
      printer.addText(emptySpace);
      break;
  }
}

function printDtlItemAsBixolon( text, fontSize, width, paddingLocation) {
  let widthSize = 0;
  let heightSize = 0;
  switch (fontSize) {
    case "SIZE1":
      widthSize = 1;
      heightSize = 1;
      break;
    case "SIZE2":
      widthSize = 2;
      heightSize = 2;
      break;
    case "SIZE3":
      widthSize = 3;
      heightSize = 3;
      break;
    case "SIZE4":
      widthSize = 3;
      heightSize = 4;
      break;
    case "SIZE5":
      widthSize = 4;
      heightSize = 5;
      break;
    case "SIZE6":
      widthSize = 5;
      heightSize = 7;
      break;
    case "DOUBLE":
      widthSize = 0;
      heightSize = 1;
      break;
    case "DOUBLE2":
      widthSize = 0;
      heightSize = 1;
      break;
    case "DOUBLE3":
      widthSize = 0;
      heightSize = 1;
      break;
    default:
      widthSize = 0;
      heightSize = 0;
      break;
  }

  const byteLength = getByteLength(text);
   const _width = width < byteLength ? byteLength : width;
  // if( width > 7 && width < (byteLength*(widthSize+1)) ) {
  //   text = subStrByte(text, (width / (widthSize + 1))-4);
  // }

  const emptySpace = getSpace(_width, text, widthSize+1);
  const pivot = Math.ceil(emptySpace.length / 2);
  const frontEmptySpace = emptySpace.substr(0, pivot);
  const tailEmptySpace = emptySpace.substr(pivot);


  switch (paddingLocation) {
    case "LEFT":
      printText(emptySpace,widthSize,heightSize,false, false, false, 0, 0);
      printText(text,widthSize,heightSize,false, false, false, 0, 2);
      break;
    case "CENTER":
      printText(frontEmptySpace,widthSize,heightSize,false, false, false, 0, 0);
      printText(text,widthSize,heightSize,false, false, false, 0, 1);
      printText(tailEmptySpace,widthSize,heightSize,false, false, false, 0, 0);
      break;
    case "RIGHT":
    default:
      printText(text,widthSize,heightSize,false, false, false, 0, 0);
      printText(emptySpace,widthSize,heightSize,false, false, false, 0, 0);
      break;
  }
}

function getSpace(width, text, widthSize= 1) {
  width = width/widthSize;
  let space = "";
  const byteLength = getByteLength(text);
  if (width < byteLength) {
    return space;
  }

  for (let i = 0; i < width - (getByteLength(text)); i++) {
    space += ' ';
  }

  return space;
}

//region 빅솔론 관련 webSocket
let webBixolonSocket =  {};
async function requestBixolonPrint(serverURL, strPrinterName, strSubmit,objThis) {
  //console.log(strPrinterName,strSubmit);
  return sendWebBixolonSocket(serverURL , strPrinterName, strSubmit, objThis);
}
function sendWebBixolonSocket(serverURL,strPrinterName,strSubmit,objThis) {
  if(!webBixolonSocket[strPrinterName]){
    webBixolonSocket[strPrinterName] = new WebSocket(serverURL+strPrinterName);
  }
  webBixolonSocket[strPrinterName].onopen = () => {
    // console.log(strPrinterName);
  };
  webBixolonSocket[strPrinterName].onerror = args => {
    objThis.errorToast( "BIXOLON Web Print SDK 설정 및 프린터 설정을 확인해 주세요");
  };
  webBixolonSocket[strPrinterName].onmessage = args => {
    // console.log("Ws:onmessage",args);
    webBixolonSocket[strPrinterName].close();
  };
  webBixolonSocket[strPrinterName].onclose = args =>{
    // console.log("Ws:onclose",args);
    closeWebSocket(strPrinterName);
  };

  if(webBixolonSocket[strPrinterName].readyState === 1) {
    webBixolonSocket[strPrinterName].send(strSubmit);
  }else{
    // sendWebBixolonSocket(strPrinterName,strSubmit,objThis);
    webBixolonSocket[strPrinterName].onopen =() =>{
      if (webBixolonSocket[strPrinterName].readyState == 1) {
        webBixolonSocket[strPrinterName].send(strSubmit);
      }
    };
  }
}
function closeWebSocket(strPrinterName) {
  webBixolonSocket[strPrinterName].close();
  webBixolonSocket[strPrinterName] = null;
}
//endregion
//region 빅솔론 관련 function
const pos_data = {id: 0, functions: {}};
let pos_func = {};
let incPosNum = 0;
let issueID = 1;
function getPosData() {
  pos_data.functions = pos_func;
  pos_func = {};
  incPosNum = 0;

  return JSON.stringify(pos_data);
}

function setPosId(setId) {
  pos_data.id = setId;
}

function checkPrinterStatus() {
  const _a = {checkPrinterStatus: []};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function directPrintText(text) {
  const _a = {directPrintText: [text]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function directPrintHex(hexString) {
  const _a = {directPrintHex: [hexString]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function cutPaper(bFeedCut = 0) {
  const _a = {cutPaper: [bFeedCut]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function setInternationalCharset(ics) {
  const _a = {setInternationalCharset: [ics]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function setCharacterset(charset) {
  const _a = {setCharacterset: [charset]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function printText(text, horizontal, vertical, bold, invert, underline, fonttype, alignment) {
  const _a = {printText: [text, horizontal, vertical, bold, invert, underline, fonttype, alignment]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function print1DBarcode(data, symbol, barWidth, height, hriPosition, alignment) {
  const _a = {print1DBarcode: [data, symbol, barWidth, height, hriPosition, alignment]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function printPDF417(data, symbol, alignment, columnNumber, rowNumber, moduleWidth, moduleHeight, eccLevel) {
  const _a = {printPDF417: [data, symbol, alignment, columnNumber, rowNumber, moduleWidth, moduleHeight, eccLevel]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function printQRCode(data, model, alignment, moduleSize, eccLevel) {
  const _a = {printQRCode: [data, model, alignment, moduleSize, eccLevel]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function printGS1Databar(data, symbol, alignment, moduleSize) {
  const _a = {printGS1Databar: [data, symbol, alignment, moduleSize]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function printDataMatrix(data, alignment, moduleSize) {
  var _a = {printDataMatrix:[data, alignment, moduleSize]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function printCompositeBarcode(data, symbol, alignment, moduleSize) {
  var _a = {printCompositeBarcode:[data, symbol, alignment, moduleSize]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function printBitmap(imagedata, width, alignment, dither) {
  var _a = {printBitmap:[imagedata, width, alignment, dither]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function printBitmapFile(filepath, width, alignment, dither) {
  var _a = {printBitmapFile:[filepath, width, alignment, dither]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function printPDFFile(filepath, pageNumber, width, alignment, dither) {
  var _a = {printPDFFile:[filepath, pageNumber, width, alignment, dither]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function pagemodeBegin() {
  var _a = {pagemodeBegin:[]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function pagemodePrintArea(width, height) {
  var _a = {pagemodePrintArea:[width, height]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function pagemodePrintPosition(x, y) {
  var _a = {pagemodePrintPosition:[x, y]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function pagemodePrintDirection(direction) {
  var _a = {pagemodePrintDirection:[direction]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function pagemodeEnd() {
  var _a = {pagemodeEnd:[]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}

function openDrawer(pinNumber) {
  var _a = {openDrawer:[pinNumber]};
  pos_func["func"+incPosNum] = _a;
  incPosNum++;
}
//endregion

export function getBetweenSpaces(emptySpaceLength) {
  const emptySpace = (new Array(emptySpaceLength).fill(" ")).join("");
  const pivot = Math.ceil(emptySpace.length / 2);
  const frontEmptySpace = emptySpace.substr(0, pivot);
  const tailEmptySpace = emptySpace.substr(pivot);

  return [frontEmptySpace, tailEmptySpace];
}

export default {
  getPortList,
  writeBillPrint,
  kitchenWriteBillPrint,
  getTsConfReceiptInfo,
  testWebSdkPrint,
};
