<template>
  <div class="appFooter">
    <div
      :class="[
        'footer-state',
        footerMessageIcon,
      ]"
    >
      {{ footerMessage }}
    </div>
    <div class="footer-code"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'appFooter',
  computed: {
    ...mapGetters(['footerMessage', 'footerMessageIcon']),
  },
};
</script>
