import Vue from "vue";
import gridTemplateDestroyMixin from "../../../mixins/gridTemplateDestroyMixin";

/**
 *
 * @param field
 * @param grid EjsGridWrapper object
 * @returns {*}
 */
export default function gridCheckboxEditTemplate(field, grid) {
  return {
    template: Vue.component("gridCheckboxEditTemplate", {
      mixins: [gridTemplateDestroyMixin],
      template: `
        <div @click="divClicked" :class="[{ 'gridCheckboxEdit': useCommonStyles }, { 'ejs-grid-checkbox': !useCommonStyles }]">
          <div v-if="useCommonStyles" class="gridCheckboxEdit-wrap">
            <input v-model="data[field]" ref="checkbox" type="checkbox" :id="field" :name="field">
            <i></i>
          </div>
          <template v-else>
            <input v-model="data[field]" ref="checkbox" type="checkbox" :id="field" :name="field">
            <i></i>
          </template>
        </div>`,
      data() {
        return {
          checkboxEditTemplateEventObj: grid.checkboxEditTemplateEventObj,
          useCommonStyles: grid.useCommonStyles,
          field: field,
          grid: grid,
          rowIndex: -1,
          data: {
            data: {},
          },
        };
      },
      mounted() {
        this.rowIndex = grid.currentSelectedRowIndex;
        if (
          this.checkboxEditTemplateEventObj &&
          this.checkboxEditTemplateEventObj.isFromClick
        ) {
          this.data[field] = !this.data[field];
          this.emitGridCheckboxChanged(this.data[field]);
        }
        this.checkboxEditTemplateEventObj.isFromClick = false; // 초기화해야 함
      },
      methods: {
        divClicked() {
          this.data[field] = !this.data[field];
          this.$refs.checkbox.focus();
          this.emitGridCheckboxChanged(this.data[field]);
        },
        emitGridCheckboxChanged(changedValue) {
          if (this.grid) {
            const rowData = grid.getBatchCurrentViewRecords()[this.rowIndex];
            this.grid.onGridCheckboxChanged({
              columnName: this.field,
              rowIndex: this.rowIndex,
              rowData: rowData,
              value: changedValue,
            });
          }
        },
      },
    }),
  };
}
