import GolfERPService from "@/service/GolfERPService";

const documents = {
  namespaced: true,
  state: {
    memberRegistration: {
      isExcelDownloading: false,
    },
    webMemberRegistration: {
      isExcelDownloading: false,
    },
    couponMemberSearch: {
      isExcelDownloading: false,
    },
    memberGradeManagement: {
      isExcelDownloading: false,
    },
    memberGradeChanges: {
      isExcelDownloading: false,
    },
    searchReservationConditions: {
      isExcelDownloading: false,
    },
  },
  actions: {
    downloadExcelMemberRegistration({ getters, commit }, { payload }) {
      if (getters.isMemberRegistrationExcelDownloading) {
        throw Error("The tasks already in process.");
      }

      commit("SET_MEMBER_REGISTRATION_IS_EXCEL_DOWNLOADING", true);

      const {
        promise,
        interrupt,
      } = GolfERPService.downloadExcelMemberRegistration(payload);

      this._vm.$EventBus.$emit("taskToast", {
        message: "회원 등록 현황 엑셀 다운로드중...",
        promise,
        interrupt,
        listeners: {
          close: () => {
            commit("SET_MEMBER_REGISTRATION_IS_EXCEL_DOWNLOADING", false);
          },
        },
      });
    },
    downloadExcelWebMemberRegistration({ getters, commit }, { payload }) {
      if (getters.isWebMemberRegistrationExcelDownloading) {
        throw Error("The tasks already in process.");
      }

      commit("SET_WEB_MEMBER_REGISTRATION_IS_EXCEL_DOWNLOADING", true);

      const {
        promise,
        interrupt,
      } = GolfERPService.downloadExcelWebMemberRegistration(payload);

      this._vm.$EventBus.$emit("taskToast", {
        message: "웹회원 등록 현황 엑셀 다운로드중...",
        promise,
        interrupt,
        listeners: {
          close: () => {
            commit("SET_WEB_MEMBER_REGISTRATION_IS_EXCEL_DOWNLOADING", false);
          },
        },
      });
    },
    downloadExcelMembers({ getters, commit }, { payload }) {
      if (getters.isCouponMemberExcelDownloading) {
        throw Error("The tasks already in process.");
      }

      commit("SET_COUPON_MEMBER_IS_EXCEL_DOWNLOADING", true);

      const { promise, interrupt } = GolfERPService.downloadExcelMembers(
        payload
      );

      this._vm.$EventBus.$emit("taskToast", {
        message: "회원조건검색 엑셀 다운로드중...",
        promise,
        interrupt,
        listeners: {
          close: () => {
            commit("SET_COUPON_MEMBER_IS_EXCEL_DOWNLOADING", false);
          },
        },
      });
    },
    downloadExcelMemberGradeManagement({ getters, commit }, { payload }) {
      if (getters.isMemberGradeManagementExcelDownloading) {
        throw Error("The tasks already in process.");
      }

      commit("SET_MEMBER_GRADE_MANAGEMENT_IS_EXCEL_DOWNLOADING", true);

      const {
        promise,
        interrupt,
      } = GolfERPService.downloadExcelMemberGradeManagement(payload);

      this._vm.$EventBus.$emit("taskToast", {
        message: "회원등급관리 엑셀 다운로드중...",
        promise,
        interrupt,
        listeners: {
          close: () => {
            commit("SET_MEMBER_GRADE_MANAGEMENT_IS_EXCEL_DOWNLOADING", false);
          },
        },
      });
    },
    downloadExcelMemberGradeChanges({ getters, commit }, { payload }) {
      if (getters.isMemberGradeChangesExcelDownloading) {
        throw Error("The tasks already in process.");
      }

      commit("SET_MEMBER_GRADE_CHANGES_IS_EXCEL_DOWNLOADING", true);

      const {
        promise,
        interrupt,
      } = GolfERPService.downloadExcelMemberGradeChanges(payload);

      this._vm.$EventBus.$emit("taskToast", {
        message: "회원등급 변경이력 엑셀 다운로드중...",
        promise,
        interrupt,
        listeners: {
          close: () => {
            commit("SET_MEMBER_GRADE_CHANGES_IS_EXCEL_DOWNLOADING", false);
          },
        },
      });
    },
    downloadExcelSearchReservationConditions({ getters, commit }, { payload }) {
      if (getters.isSearchReservationConditionsExcelDownloading) {
        throw Error("The tasks already in process.");
      }

      commit("SET_SEARCH_RESERVATION_CONDITIONS_IS_EXCEL_DOWNLOADING", true);

      const { promise, interrupt } = GolfERPService.downloadExcelSearchReservationConditions(
        payload
      );

      this._vm.$EventBus.$emit("taskToast", {
        message: "예약조건검색 엑셀 다운로드중...",
        promise,
        interrupt,
        listeners: {
          close: () => {
            commit("SET_SEARCH_RESERVATION_CONDITIONS_IS_EXCEL_DOWNLOADING", false);
          },
        },
      });
    },
  },
  mutations: {
    SET_MEMBER_REGISTRATION_IS_EXCEL_DOWNLOADING(state, isExcelDownloading) {
      state.memberRegistration.isExcelDownloading = isExcelDownloading;
    },
    SET_WEB_MEMBER_REGISTRATION_IS_EXCEL_DOWNLOADING(
      state,
      isExcelDownloading
    ) {
      state.webMemberRegistration.isExcelDownloading = isExcelDownloading;
    },
    SET_COUPON_MEMBER_IS_EXCEL_DOWNLOADING(state, isExcelDownloading) {
      state.couponMemberSearch.isExcelDownloading = isExcelDownloading;
    },
    SET_MEMBER_GRADE_MANAGEMENT_IS_EXCEL_DOWNLOADING(
      state,
      isExcelDownloading
    ) {
      state.memberGradeManagement.isExcelDownloading = isExcelDownloading;
    },
    SET_MEMBER_GRADE_CHANGES_IS_EXCEL_DOWNLOADING(state, isExcelDownloading) {
      state.memberGradeChanges.isExcelDownloading = isExcelDownloading;
    },
    SET_SEARCH_RESERVATION_CONDITIONS_IS_EXCEL_DOWNLOADING(state, isExcelDownloading) {
      state.searchReservationConditions.isExcelDownloading = isExcelDownloading;
    }
  },
  getters: {
    isMemberRegistrationExcelDownloading: ({
      memberRegistration: { isExcelDownloading },
    }) => isExcelDownloading,
    isWebMemberRegistrationExcelDownloading: ({
      webMemberRegistration: { isExcelDownloading },
    }) => isExcelDownloading,
    isCouponMemberExcelDownloading: ({
      couponMemberSearch: { isExcelDownloading },
    }) => isExcelDownloading,
    isMemberGradeManagementExcelDownloading: ({
      memberGradeManagement: { isExcelDownloading },
    }) => isExcelDownloading,
    isMemberGradeChangesExcelDownloading: ({
      memberGradeChanges: { isExcelDownloading },
    }) => isExcelDownloading,
    isSearchReservationConditionsExcelDownloading: ({
      searchReservationConditions: { isExcelDownloading },
    }) => isExcelDownloading
  },
};

export default documents;
