import GolfErpAPI from "@/api/v2/GolfErpAPI";
// 로그인시 필요한 pcCode 의 값과
// POS 에서 사용하는 pos,주방 프린터 사용여부 및 주문서 발행매수 는 로컬스토리지에서 관리
const usePosPrinterKey = "Use-Pos-Printer";
const usePosPrinter = JSON.parse(localStorage.getItem(usePosPrinterKey));

export function comparisonDeviceInfo(args) {
    const ob = {};
    Object.keys(args).map((key) => {
        let paramsName;
        switch (key) {
            case "isCardUse":
                paramsName = "vanUseFlag";
                break;
            case "cardDaemonPort":
                paramsName = "vanPort";
                break;
            case "isSerialUse":
                paramsName = "billPrintCommnFlag";
                break;
            case "serialDaemonPort":
                paramsName = "billPrintCommnPort";
                break;
            case "isBillPrinterUse":
                paramsName = "billPrintUseFlag";
                break;
            case "billPrinterCommunication":
                paramsName = "billPrintDiv";
                break;
            case "billPrinterComPort":
                paramsName = "billPrintPort";
                break;
            case "billPrinterBitRate":
                paramsName = "billPrintBit";
                break;
            case "billPrinterIpAddress":
                paramsName = "billPrintIpAddr";
                break;
            case "billPrinterIpPort":
                paramsName = "billPrintIpPort";
                break;
            case "billPrinterDeviceId":
                paramsName = "billPrintDeviceId";
                break;
            case "isKitchenBillPrinterUse":
                paramsName = "kitchenPrintUseFlag";
                break;
            case "kitchenBillPrinterCommunication":
                paramsName = "kitchenPrintDiv";
                break;
            case "kitchenBillPrinterComPort":
                paramsName = "kitchenPrintPort";
                break;
            case "kitchenBillPrinterBitRate":
                paramsName = "kitchenPrintBit";
                break;
            case "kitchenBillPrinterIpAddress":
                paramsName = "kitchenPrintIpAddr";
                break;
            case "kitchenBillPrinterIpPort":
                paramsName = "kitchenPrintIpPort";
                break;
            case "kitchenBillPrinterDeviceId":
                paramsName = "kitchenPrintDeviceId";
                break;
            case "isClientMonitorUse":
                paramsName = "custMntrUseFlag";
                break;
            case "clientMonitorInterval":
                paramsName = "custMntrImageCnvrsTime";
                break;
            case "clientMonitorEffect":
                paramsName = "custMntrImageCnvrsMethod";
                break;
            case "clientMonitorImagePattern":
                paramsName = "custMntrImagePttrnType";
                break;
            default:
                paramsName = key;
                break;
        }
        ob[paramsName] = args[key];
    });
    return ob;
}


const deviceSettingState = {
    namespaced: true,
    state: {
        device: {
            // 기본값
            isCardUse: false,
            posNo: null,
            cardDaemonPort: 30001,
            isSerialUse: false,
            serialDaemonPort: 5555,
            //pos 프린터 출력 설정
            posPrinterOutput: true,
            //주방프린터 출력 설정
            kitchenPrinterOutput: true,
            isBillPrinterUse: false,
            billPrinterComPort: null,
            billPrinterBitRate: null,
            isKitchenBillPrinterUse: false,
            kitchenBillPrinterComPort: null,
            kitchenBillPrinterBitRate: null,
            isClientMonitorUse: false,
            clientMonitorInterval: 10,
            clientMonitorEffect: 'FADE',
            clientMonitorImagePattern: null,
            billPrinterCommunication: "PORT",
            billPrinterIpAddress: null,
            billPrinterIpPort: null,
            billPrinterDeviceId: null,
            kitchenBillPrinterCommunication: "PORT",
            kitchenBillPrinterIpAddress: null,
            kitchenBillPrinterIpPort: null,
            kitchenBillPrinterDeviceId: null,
            isGridNoColumnNotUse: false,
            kitchenPaperNumber: null,
        }
    },
    getters: {
        getDevice: ({ device }) => device,
    },
    mutations: {
        SETTING_DEVICE(state, info) {
            state.device = {
                // 기본값
                posNo: info?.posNo || null,
                isCardUse: info?.vanUseFlag || false,
                cardDaemonPort: info?.vanPort || 30001,
                isSerialUse: info?.billPrintCommnFlag || null,
                serialDaemonPort: info?.billPrintCommnPort || 5555,
                pcUseCode: info?.pcUseCode || localStorage.getItem('pcUseCode') || null,
                isBillPrinterUse: info?.billPrintUseFlag || false,
                billPrinterCommunication: info?.billPrintDiv || "PORT",
                billPrinterComPort: info?.billPrintPort || null,
                billPrinterBitRate: info?.billPrintBit || null,
                billPrinterIpAddress: info?.billPrintIpAddr || null,
                billPrinterIpPort: info?.billPrintIpPort || null,
                billPrinterDeviceId: info?.billPrintDeviceId || null,

                isKitchenBillPrinterUse: info?.kitchenPrintUseFlag || false,
                kitchenBillPrinterCommunication: info?.kitchenPrintDiv || "PORT",
                kitchenBillPrinterComPort: info?.kitchenPrintPort || null,
                kitchenBillPrinterBitRate: info?.kitchenPrintBit || null,
                kitchenBillPrinterIpAddress: info?.kitchenPrintIpAddr || null,
                kitchenBillPrinterIpPort: info?.kitchenPrintIpPort || null,
                kitchenBillPrinterDeviceId: info?.kitchenPrintDeviceId || null,

                //pos 프린터 출력 설정
                posPrinterOutput: usePosPrinter?.posPrinterOutput ?? true, //추후 작업
                //주방프린터 출력 설정
                kitchenPrinterOutput: usePosPrinter?.kitchenPrinterOutput ?? true,    //추후 작업

                isClientMonitorUse: info?.custMntrUseFlag || false,
                clientMonitorInterval: info?.custMntrImageCnvrsTime || 10,
                clientMonitorEffect: info?.custMntrImageCnvrsMethod || 'FADE',
                clientMonitorImagePattern: info?.custMntrImagePttrnType || null,

                isGridNoColumnNotUse: false,
                kitchenPaperNumber: usePosPrinter?.kitchenPaperNumber ?? 1
            };
            if(!usePosPrinter) {
                const saveItem = JSON.stringify({
                    posPrinterOutput:state.device.posPrinterOutput,
                    kitchenPrinterOutput:state.device.kitchenPrinterOutput,
                    kitchenPaperNumber:state.device.kitchenPaperNumber
                });
                localStorage.setItem(usePosPrinterKey,saveItem);
            }
        },
    },
    actions: {
        async saveDeviceInfo({commit}, args) {
            const mappingParams = comparisonDeviceInfo(args);
            await GolfErpAPI.setDeviceInfoAPI(mappingParams);
            commit("SETTING_DEVICE",mappingParams);
        }
    }
};

export default deviceSettingState;