import { mapMutations } from "vuex";

export default {
  watch: {
    $route(to) {
      if (to.name === this.routeName) {
        this.SET_FOOTER_MESSAGE(this.footerMessage);
      }
    },
  },
  data() {
    return {
      routeName: this.$route.name,
      footerMessage: "",
    };
  },
  methods: {
    ...mapMutations(["SET_FOOTER_MESSAGE"]),
    setFooterMessage(message) {
      this.footerMessage = message;
      this.SET_FOOTER_MESSAGE(message);
    },
  },
};
