<template>
  <div class="sideRight-content">
    <div class="sideRight-header">
      <div class="header-left">
        <div class="header-title">회원 VOC 조회</div>
      </div>
      <div class="header-right">
        <ul class="header-button">
          <li :class="isRightExtendActive ? 'reduce' : 'extend'">
            <erp-button button-div="GET" @click.native="onExtendButtonClicked">
              확장/축소
            </erp-button>
          </li>
          <li class="close">
            <erp-button button-div="CLOSE" @click.native="onCloseButtonClicked">
              닫기
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="sideRight-body">
      <div class="window memberView custom-heigth">
        <div class="windowContent custom-heigth">
          <div class="content-wrapper">
            <div class="content-lookup custom-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field memberName">
                    <div class="content">
                      <ul class="row">
                        <li
                            class="item form-group input"
                        >
                          <div class="form">
                            <input-text
                                ref="memberName"
                                placeholder="회원명, 연락처 4자리"
                                v-model="memberSearch"
                                @keydown.enter="open"
                            />
                          </div>
                        </li>
                        <li class="item form-group button">
                          <ul class="button">
                            <li class="search">
                              <erp-button
                                button-div="GET"
                                :disabled="commonMixinIsButtonDisableByAuth('commonMemberResveVisitGet')"
                                @click.native="open"
                              >
                                조회
                              </erp-button>
                            </li>
                          </ul>
                        </li>
                        <li
                            class="item"
                        >
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="lookup-right">
                <div class="lookup-lookup">
                  <erp-button
                    button-div="GET"
                    @click.native="fetch"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body custom-heigth">
              <article class="body-article custom-heigth custom-padding-none">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01 custom-heigth custom-padding-none">
                  <div class="big-flex-box">
                    <div class="no-data" v-if="!arr.length">No records to display</div>
                    <div class="flex-box" v-for="(item,index) in arr" :key="index">
                      <div class="flex-header">
                        <span class="date">{{item.writngDate}}</span>
                        <div class="button-box">
                          <span class="button"
                              v-if="item.vocCode"
                              :style="{ background: labelColor('VOC_CODE',item.vocCode) }"
                          >
                            {{ labelName('VOC_CODE',item.vocCode) }}
                          </span>
                          <span class="button"
                              v-if="item.vocSection"
                              :style="{ background: labelColor('VOC_SECTION',item.vocSection) }"
                        >
                          {{ labelName('VOC_SECTION',item.vocSection) }}
                        </span>
                          <span class="button"
                              v-if="item.vocManagt"
                              :style="{ background: labelColor('VOC_MANAGT',item.vocManagt) }"
                        >
                          {{ labelName('VOC_MANAGT',item.vocManagt) }}
                        </span>
                        </div>
                      </div>
                      <div class="flex-title">
                        <ul>
                          <li>{{courseTime(item.startTime)}}</li>
                          <li>{{getCourse(item.startCourse)}}</li>
                        </ul>
                        <label>작성자:{{item.visitName}}</label>
                      </div>
                      <div class="flex-body">
                        <div class="inquiry">
                          <template
                              v-for="(line, idx) in item.vocContents.split('\n')"
                          >
                            {{line}}
                            <br
                                v-if="idx !== item.vocContents.split('\n').length - 1"
                                :key="`vocContents${index}-line${idx}`"
                            >
                          </template>
                        </div>
                        <div class="answer" v-if="item.managtContents">
                          <label class="title">조치일자 : {{item.managtDate}}</label>
                          <div class="text">{{item.managtContents}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
    <member-select-popup
        v-if="isMemberSelectPopupOpen"
        ref="memberSelectPopup"
        :isModal="true"
        @popupClosed="onReservationCancelPopupClosed"
        @popupConfirmed="postMemberInfo"
    />
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {getTodayDate} from '@/utils/date';
import InputText from '@/components/common/text/InputText';
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import {mapGetters, mapMutations} from 'vuex';
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {
  commonCodesGetComName,
  commonCodesGetColorValue,

} from '@/utils/commonCodes';
import {orderBy as _orderBy} from "lodash";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: 'RightVocView',
  components: {
    InputText,
    memberSelectPopup,
    ErpButton,
  },
  mixins: [ commonMixin, confirmDialogMixin],
  computed: {
    ...mapGetters(["isRightExtendActive"]),
    isPopupOpened() {
      return (
          this.isMemberSelectPopupOpen
      );
    },
  },
  async created() {
    this.bsnDate = await getTodayDate();
  },
  mounted() {
    this.$EventBus.$on("VOC", async (data)=>{
      this.memberSearch = data.memberName;
      this.memberInfo.membershipId = data.id;
      this.memberInfo.memberName = data.memberName;
      await this.fetch();
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("VOC");
  },
  data() {
    return {
      isMemberSelectPopupOpen:false,
      memberSearch:null,
      popupOpenData:{},
      memberInfo:{},
      arr:[]
    };
  },
  methods: {
    ...mapMutations(["SET_IS_RIGHT_MEMBER_POPUP_OPENED"]),
    courseTime(date=null) {
      if(date){
        return `${date.substr(0, 2)}:${date.substr(2, 2)}`;
      }
    },
    getCourse(course) {
      return commonCodesGetComName("COURSE_CODE",course);
    },
    labelColor(code,comCode) {
      return commonCodesGetColorValue(code, comCode);
    },
    labelName(code,comCode) {
      return commonCodesGetComName(code, comCode);
    },
    onExtendButtonClicked() {
      if (this.isRightExtendActive) {
        this.$EventBus.$emit('reduceRightMenu');
      } else {
        this.$EventBus.$emit('extendRightMenu');
      }
    },
    onCloseButtonClicked() {
      this.$EventBus.$emit('closeRightMenu');
    },
    async onMemberFindButtonClicked() {
      await this.reservationInfoPopupOpen();
    },
    async open() {
      this.popupOpenData = {};
     this.isMemberSelectPopupOpen = true;
     if(this.memberSearch.length===4 && Number(this.memberSearch)){
       this.popupOpenData.contactTelIdx = this.memberSearch;
     } else {
       this.popupOpenData.memberNameNo = this.memberSearch;
     }
     this.popupOpenData.nomDataViewFlag = false;
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(this.popupOpenData);
      });
    },
    async fetch() {
      if(!this.memberInfo.memberName){
        this.errorToast("회원을 선택해 주십시오");
        return ;
      }
      const data = (await GolfErpAPI.fetchVocList({
        membershipId:this.memberInfo.membershipId
      }));
      // this.arr =  _orderBy(data.map(item => ({
      //   ...item,
      //   courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", item.startCourse),
      //   vocSectionSortNo: commonCodesGetSortNo("VOC_SECTION", item.vocSection),
      // })), ["writngDate", "startTime", "courseCodeSortNo", "vocSectionSortNo"]);


      this.arr =  _orderBy(data.map(item => ({
        ...item,
      })), ["vocId"]).reverse();
      console.log("data===>",this.arr);
    },
    onReservationCancelPopupClosed() {
      this.isMemberSelectPopupOpen = false;
    },
    async postMemberInfo(arr) {
      this.memberSearch=arr.selectedRowData.memberName;
      this.isMemberSelectPopupOpen = false;
      this.memberInfo = arr.selectedRowData;
      await this.fetch();
    }
  },
};
</script>
<style scoped>
.custom-heigth {
  height: 100% !important;
}
.no-data {
  font-size: 20px;
  font-weight: bold;
  color: #ccc;
  padding: 5px;
  font-family: 'NanumSquare-Regular', Arial, Verdana, sans-serif;
}
.big-flex-box {
  height: 95%;
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  border-bottom: 1px solid #ccc;
}
.flex-box {
  display: flex;
  flex-flow: column nowrap;
  border-bottom: 1px solid #ccc;
}
.flex-header {
  height: 25px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 15px;
}
.flex-header > .button-box {
  display: flex;
  width: 175px;
}
.flex-header > .date {
  flex: 1;
  font-weight: bold;
  justify-content: start;
  padding-left: 5px;
}
.flex-header > .button-box > .button {
  color: white;
  border-radius: 5px;
  flex: 1;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;

}
.flex-title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0 10px 0 10px;
}
.flex-title > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.flex-title > label {
  display: flex;
  align-items: end;
}
.flex-body {
  display: flex;
  flex-flow: column nowrap;
  padding: 0 5px 0 5px;
}
.flex-body > .inquiry {
  background: #ecfaf0;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px 5px 10px 5px;
}
.flex-body > .answer {
  display: flex;
  flex-flow: column nowrap;
}
.flex-body > .answer > .title {
  display: flex;
  justify-content: end;
}
.flex-body > .answer > .text {
  position: relative;
  background: rgba(173,216,230,0.3);
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px 5px 10px 5px;
}
.flex-body > .answer > .text::after {
  content: '';
  left: 15px;
  top: -5px;
  position: absolute;
  transform: rotate(135deg);
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(173,216,230,0.3);;
  border-left: 5px solid rgba(173,216,230,0.3);;
}
.custom-padding-none{
  padding: 0;
}
.custom-lookup {
  margin-bottom: 0 !important;
  border-bottom: 1px solid #ccc !important;
}
</style>