const iconv = require("iconv-lite");

// 편집에 사용되는 상수들
const ls_linefeed = "\x0A";
const ls_init = "\x1B" + "@";
const ls_logo = "\x1C\x70\x01\x30" + ls_linefeed;
const ls_Left = "\x1B" + "a" + "0";
const ls_Center = "\x1B" + "a" + "1";
const ls_Right = "\x1B" + "a" + "2";
const ls_cut = "\x1B\x69";
const ls_size1 = "\x1D" + "!" + "\x11"; // original
const ls_size1_plus_height = "\x1D" + "!" + "\x01"; // original
const ls_size1_plus_width = "\x1D" + "!" + "\x10"; // original
const ls_size1_emp = "\x1B" + "!" + "\x08";
const ls_size2 = "\x1D" + "!" + "\x22";
const ls_size3 = "\x1D" + "!" + "\x33";
const ls_size4 = "\x1D" + "!" + "\x44";
const ls_size5 = "\x1D" + "!" + "\x55";
const ls_size6 = "\x1D" + "!" + "\x66";
const ls_double = "\x1B" + "!" + "\x10"; // double-height, normal, original
const ls_double2 = "\x1B" + "!" + "\x20";
const ls_double3 = "\x1B" + "!" + "\x30";
const ls_normal = "\x1B" + "!" + "\x08" + "\x1B" + "E" + "\x00";

// const ls_smooth_true = "\x1D" + "b" + "\x01"; // 부드럽게 설정
// const ls_smooth_false = "\x1D" + "b" + "\x00"; // 부드럽게 해제
// const ls_bold = "\x1D" + "1" + "\x80";
const width_length = 38; // BillPrinter는 42가 맞다

import { getFormattedDate } from "@/utils/date";
import { getFormattedTelNumber } from "@/utils/string";
import { getTsConfReceiptInfo } from "@/api/common";

function getInit() {
  return ls_init;
}

function getDivideLine(length, align) {
  let divideLine = "";
  let paddingLine = "";
  let lineLength = length === 0 ? width_length : length;
  let paddingLength = width_length - lineLength;

  if (paddingLength < 0) {
    paddingLength = 0;
  }

  for (let divide = 0; divide < lineLength; divide++) {
    divideLine += "-";
    if (divide < paddingLength) {
      paddingLine += " ";
    }
  }

  switch (align) {
    case "RIGHT":
      divideLine =  ls_normal + paddingLine + divideLine;
      break;
    default:
      divideLine = ls_normal + divideLine + paddingLine;
      break;
  }

  return ls_Center + divideLine + ls_linefeed;
}

function getDivideLineView(length, align) {
  let divideLine = "";
  let paddingLine = "";
  let lineLength = length === 0 ? width_length : length;
  let paddingLength = width_length - lineLength;

  if (paddingLength < 0) {
    paddingLength = 0;
  }

  for (let divide = 0; divide < lineLength; divide++) {
    divideLine += "-";
    if (divide < paddingLength) {
      paddingLine += "&nbsp;";
    }
  }

  switch (align) {
    case "RIGHT":
      divideLine = paddingLine + divideLine;
      break;
    default:
      divideLine = divideLine + paddingLine;
      break;
  }

  return divideLine + "<br>";
}

function getLineSpace() {
  return ls_linefeed;
}

function getLineSpaceView() {
  return "<br>";
}

function getCut() {
  const ls_prnbuf =
    ls_linefeed +
    ls_linefeed +
    ls_linefeed +
    ls_linefeed +
    ls_linefeed +
    ls_cut;

  return ls_prnbuf;
}

// function eucKrEncodedLine(align, content) {
//   return (
//     align + iconv.encode(content, "euc-kr").toString("binary") + ls_linefeed
//   );
// }
//
// function getAlignedString(align, propertyName, value) {
//   let propertyNameEucKrEncoded = iconv
//     .encode(propertyName, "euc-kr")
//     .toString("binary");
//   let valueEucKrEncoded = iconv.encode(value, "euc-kr").toString("binary");
//   let centerPadding = "";
//
//   for (
//     let divide = 0;
//     divide <
//     width_length - propertyNameEucKrEncoded.length - valueEucKrEncoded.length;
//     divide++
//   ) {
//     centerPadding += " ";
//   }
//
//   return (
//     align +
//     propertyNameEucKrEncoded +
//     centerPadding +
//     valueEucKrEncoded +
//     ls_linefeed
//   );
// }

function getDivideString(value, widthLength, align, fontSize, widthSize = 1) {
  let eucKrEncoded = iconv.encode(value, "euc-kr").toString("binary");
  let centerPadding = "";

  for (let divide = 0; divide < (widthLength/widthSize) - eucKrEncoded.length ; divide++) {
    centerPadding += " ";
  }

  if (align !== "NOT") {
    if (align === "LEFT") {
      eucKrEncoded = fontSize + centerPadding + fontSize + eucKrEncoded;
    } else {
      eucKrEncoded = fontSize + eucKrEncoded + fontSize + centerPadding;
    }
  }

  return eucKrEncoded;
}

function getDivideStringView(value, widthLength, align, fontSize) {
  let eucKrEncoded = iconv.encode(value, "euc-kr").toString("binary");
  let centerPadding = "";
  let replaceValue = "";

  for (let divide = 0; divide < widthLength - eucKrEncoded.length; divide++) {
    centerPadding += "&nbsp;";
  }

  for (let i = 0; i < value.length; i++) {
    if (value.substr(i, 1) === " ") {
      replaceValue += "&nbsp;";
    } else {
      replaceValue += value.substr(i, 1);
    }
  }

  if (align !== "NOT") {
    if (align === "LEFT") {
      eucKrEncoded = ls_normal + centerPadding + fontSize + replaceValue;
    } else {
      eucKrEncoded = fontSize + replaceValue + ls_normal + centerPadding;
    }
  }

  return eucKrEncoded;
}

export function getBillPrintContent(billPrintInfoList, printDataInfo, printCount = 1) {
  let printContent = getInit();

  if (billPrintInfoList != null && billPrintInfoList.length > 0) {
    for (let a = 0; a < printCount; a++) {
      for (let i = 0; i < billPrintInfoList.length; i++) {
        let sortCode = null;

        switch (billPrintInfoList[i].sortCode) {
          case "CENTER":
            sortCode = ls_Center;
            break;
          case "LEFT":
            sortCode = ls_Left;
            break;
          case "RIGHT":
            sortCode = ls_Right;
            break;
          default:
            sortCode = ls_Center;
            break;
        }

        if (billPrintInfoList[i].logoFlag) {
          // 로고 출력
          printContent += ls_Center + ls_logo;
          continue;
        } else if (billPrintInfoList[i].cutFlag) {
          printContent += getCut();
          continue;
        } else if (billPrintInfoList[i].lineFlag) {
          if (billPrintInfoList[i].unPrtFlag) {
            if (
              printDataInfo[billPrintInfoList[i].unPrtVariable] === null ||
              printDataInfo[billPrintInfoList[i].unPrtVariable] === undefined ||
              printDataInfo[billPrintInfoList[i].unPrtVariable].length === 0
            ) {
              continue;
            }
          }

          // 점선 라인 출력
          printContent += getDivideLine(
            billPrintInfoList[i].lineWidth,
            billPrintInfoList[i].lineLocation
          );
          continue;
        } else if (billPrintInfoList[i].linePaddingFlag) {
          if (billPrintInfoList[i].unPrtFlag) {
            if (
              printDataInfo[billPrintInfoList[i].unPrtVariable] === null ||
              printDataInfo[billPrintInfoList[i].unPrtVariable] === undefined ||
              printDataInfo[billPrintInfoList[i].unPrtVariable].length === 0
            ) {
              continue;
            }
          }

          // 한줄 공백 출력
          printContent += getLineSpace();
          continue;
        } else if (billPrintInfoList[i].loopFlag) {
          if (
            billPrintInfoList[i].tsConfReceiptDtlList === null ||
            billPrintInfoList[i].tsConfReceiptDtlList.length === 0
          ) {
            this.errorToast(
              this.$t("billPrinter.validationMessage.notPrintDtlInfo")
            );
            return;
          }

          if (billPrintInfoList[i].unPrtFlag) {
            if (
              printDataInfo[billPrintInfoList[i].unPrtVariable] === null ||
              printDataInfo[billPrintInfoList[i].unPrtVariable] === undefined ||
              printDataInfo[billPrintInfoList[i].unPrtVariable].length === 0
            ) {
              continue;
            }
          }

          let loopData = printDataInfo[billPrintInfoList[i].loopName];

          if (
            loopData !== null &&
            loopData !== undefined &&
            loopData.length > 0
          ) {
            let contentData = "";
            let paddingLength = 0;
            let fontSize = null;
            let widthSize = 1;

            for (let z = 0; z < loopData.length; z++) {
              let valueData = "";
              for (
                let a = 0;
                a < billPrintInfoList[i].tsConfReceiptDtlList.length;
                a++
              ) {
                if (
                  billPrintInfoList[i].tsConfReceiptDtlList[a].contentsWidth === 0
                ) {
                  paddingLength = process.env.PRINTER_WIDTH;
                } else {
                  paddingLength =
                    billPrintInfoList[i].tsConfReceiptDtlList[a].contentsWidth;
                }

                if (billPrintInfoList[i].tsConfReceiptDtlList[a].variableFlag) {
                  if (
                    billPrintInfoList[i].tsConfReceiptDtlList[a].variableType !==
                    null
                  ) {
                    switch (
                      billPrintInfoList[i].tsConfReceiptDtlList[a].variableType
                      ) {
                      case "DATE":
                        contentData = getFormattedDate(
                          loopData[z][
                            billPrintInfoList[i].tsConfReceiptDtlList[a]
                              .variableName
                            ],
                          billPrintInfoList[i].tsConfReceiptDtlList[a]
                            .variableFormat
                        );
                        break;
                      case "NUMBER":
                        contentData = new Intl.NumberFormat().format(
                          loopData[z][
                            billPrintInfoList[i].tsConfReceiptDtlList[a]
                              .variableName
                            ]
                        );
                        break;
                      case "TEL":
                        contentData = getFormattedTelNumber(
                          loopData[z][
                            billPrintInfoList[i].tsConfReceiptDtlList[a]
                              .variableName
                            ],
                          billPrintInfoList[i].tsConfReceiptDtlList[a]
                            .variableFormat
                        );
                        break;
                      default:
                        contentData = loopData[z][
                          billPrintInfoList[i].tsConfReceiptDtlList[a]
                            .variableName
                          ]
                          ? loopData[z][
                            billPrintInfoList[i].tsConfReceiptDtlList[a]
                              .variableName
                            ]
                          : "";
                        break;
                    }
                  } else {
                    contentData = loopData[z][
                      billPrintInfoList[i].tsConfReceiptDtlList[a].variableName
                      ]
                      ? loopData[z][
                        billPrintInfoList[i].tsConfReceiptDtlList[a]
                          .variableName
                        ]
                      : "";
                  }

                  contentData =
                    contentData +
                    (billPrintInfoList[i].tsConfReceiptDtlList[a].contents ===
                    null
                      ? ""
                      : billPrintInfoList[i].tsConfReceiptDtlList[a].contents);
                } else {
                  contentData =
                    billPrintInfoList[i].tsConfReceiptDtlList[a].contents;
                }

                switch (billPrintInfoList[i].tsConfReceiptDtlList[a].fontSize) {
                  case "SIZE1":
                    fontSize = ls_size1;
                    widthSize = 2;
                    break;
                  case "SIZE1_EMP":
                    fontSize = ls_size1_emp;
                    break;
                  case "SIZE1_PLUS_WIDTH":
                    fontSize = ls_size1_plus_width;
                    break;
                  case "SIZE1_PLUS_HEIGHT":
                    fontSize = ls_size1_plus_height;
                    break;
                  case "SIZE2":
                    fontSize = ls_size2;
                    widthSize = 3;
                    break;
                  case "SIZE3":
                    fontSize = ls_size3;
                    widthSize = 4;
                    break;
                  case "SIZE4":
                    fontSize = ls_size4;
                    widthSize = 4;
                    break;
                  case "SIZE5":
                    fontSize = ls_size5;
                    widthSize = 5;
                    break;
                  case "SIZE6":
                    fontSize = ls_size6;
                    widthSize = 6;
                    break;
                  case "DOUBLE":
                    fontSize = ls_double;
                    widthSize = 1;
                    break;
                  case "DOUBLE2":
                    fontSize = ls_double2;
                    widthSize = 2;
                    break;
                  case "DOUBLE3":
                    fontSize = ls_double3;
                    widthSize = 3;
                    break;
                  default:
                    fontSize = ls_normal;
                    widthSize = 1;
                    break;
                }

                valueData +=
                  getDivideString(
                    contentData,
                    paddingLength,
                    billPrintInfoList[i].tsConfReceiptDtlList[a].paddingLocation,
                    fontSize,
                    widthSize
                  );

                if (billPrintInfoList[i].tsConfReceiptDtlList[a].lineFeedFlag) {
                  valueData += ls_linefeed;
                }
              }

              printContent += sortCode + valueData + ls_linefeed;
            }
          }
        } else {
          if (
            billPrintInfoList[i].tsConfReceiptDtlList === null ||
            billPrintInfoList[i].tsConfReceiptDtlList.length === 0
          ) {
            this.errorToast(
              this.$t("billPrinter.validationMessage.notPrintDtlInfo")
            );
            return;
          }

          if (billPrintInfoList[i].unPrtFlag) {
            if (
              printDataInfo[billPrintInfoList[i].unPrtVariable] === null ||
              printDataInfo[billPrintInfoList[i].unPrtVariable] === undefined ||
              printDataInfo[billPrintInfoList[i].unPrtVariable].length === 0
            ) {
              continue;
            }
          }

          let contentData = "";
          let valueData = "";
          let paddingLength = 0;
          let fontSize = null;
          let widthSize = 1;

          for (
            let a = 0;
            a < billPrintInfoList[i].tsConfReceiptDtlList.length;
            a++
          ) {
            if (
              billPrintInfoList[i].tsConfReceiptDtlList[a].contentsWidth === 0
            ) {
              paddingLength = process.env.PRINTER_WIDTH;
            } else {
              paddingLength =
                billPrintInfoList[i].tsConfReceiptDtlList[a].contentsWidth;
            }

            if (billPrintInfoList[i].tsConfReceiptDtlList[a].variableFlag) {
              if (
                billPrintInfoList[i].tsConfReceiptDtlList[a].variableType !== null
              ) {
                switch (
                  billPrintInfoList[i].tsConfReceiptDtlList[a].variableType
                  ) {
                  case "DATE":
                    contentData = getFormattedDate(
                      printDataInfo[
                        billPrintInfoList[i].tsConfReceiptDtlList[a].variableName
                        ],
                      billPrintInfoList[i].tsConfReceiptDtlList[a].variableFormat
                    );
                    break;
                  case "NUMBER":
                    contentData = new Intl.NumberFormat().format(
                      printDataInfo[
                        billPrintInfoList[i].tsConfReceiptDtlList[a].variableName
                        ]
                    );
                    break;
                  case "TEL":
                    contentData = getFormattedTelNumber(
                      printDataInfo[
                        billPrintInfoList[i].tsConfReceiptDtlList[a].variableName
                        ],
                      billPrintInfoList[i].tsConfReceiptDtlList[a].variableFormat
                    );
                    break;
                  default:
                    contentData = printDataInfo[
                      billPrintInfoList[i].tsConfReceiptDtlList[a].variableName
                      ]
                      ? printDataInfo[
                        billPrintInfoList[i].tsConfReceiptDtlList[a]
                          .variableName
                        ]
                      : "";
                    break;
                }
              } else {
                contentData = printDataInfo[
                  billPrintInfoList[i].tsConfReceiptDtlList[a].variableName
                  ]
                  ? printDataInfo[
                    billPrintInfoList[i].tsConfReceiptDtlList[a].variableName
                    ]
                  : "";
              }

              contentData =
                contentData +
                (billPrintInfoList[i].tsConfReceiptDtlList[a].contents
                  ? billPrintInfoList[i].tsConfReceiptDtlList[a].contents
                  : "");
            } else {
              contentData = billPrintInfoList[i].tsConfReceiptDtlList[a].contents;
            }

            switch (billPrintInfoList[i].tsConfReceiptDtlList[a].fontSize) {
              case "SIZE1":
                fontSize = ls_size1;
                widthSize = 2;
                break;
              case "SIZE1_EMP":
                fontSize = ls_size1_emp;
                break;
              case "SIZE1_PLUS_WIDTH":
                fontSize = ls_size1_plus_width;
                break;
              case "SIZE1_PLUS_HEIGHT":
                fontSize = ls_size1_plus_height;
                break;
              case "SIZE2":
                fontSize = ls_size2;
                widthSize = 3;
                break;
              case "SIZE3":
                fontSize = ls_size3;
                widthSize = 4;
                break;
              case "SIZE4":
                fontSize = ls_size4;
                widthSize = 4;
                break;
              case "SIZE5":
                fontSize = ls_size5;
                widthSize = 5;
                break;
              case "SIZE6":
                fontSize = ls_size6;
                widthSize = 6;
                break;
              case "DOUBLE":
                fontSize = ls_double;
                widthSize = 1;
                break;
              case "DOUBLE2":
                fontSize = ls_double2;
                widthSize = 2;
                break;
              case "DOUBLE3":
                fontSize = ls_double3;
                widthSize = 3;
                break;
              default:
                fontSize = ls_normal;
                widthSize = 1;
                break;
            }

            valueData +=
              getDivideString(
                contentData,
                paddingLength,
                billPrintInfoList[i].tsConfReceiptDtlList[a].paddingLocation,
                fontSize,
                widthSize
              );

            if (billPrintInfoList[i].tsConfReceiptDtlList[a].lineFeedFlag) {
              valueData += ls_linefeed;
            }
          }

          printContent += sortCode + valueData + ls_linefeed;
        }
      }
      printContent += getCut();
    }
  }

  return printContent;
}

export function getBillPrintViewContent(billPrintInfoList, printDataInfo) {
  let printContent = "";

  if (billPrintInfoList != null && billPrintInfoList.length > 0) {
    for (let i = 0; i < billPrintInfoList.length; i++) {
      if (billPrintInfoList[i].logoFlag) {
        // 로고 출력
        // printContent += ls_logo;
        continue;
      } else if (billPrintInfoList[i].cutFlag) {
        printContent += "<br>";
        continue;
      } else if (billPrintInfoList[i].lineFlag) {
        if (billPrintInfoList[i].unPrtFlag) {
          if (
            printDataInfo[billPrintInfoList[i].unPrtVariable] === null ||
            printDataInfo[billPrintInfoList[i].unPrtVariable] === undefined ||
            printDataInfo[billPrintInfoList[i].unPrtVariable].length === 0
          ) {
            continue;
          }
        }

        // 점선 라인 출력
        printContent += getDivideLineView(
          billPrintInfoList[i].lineWidth,
          billPrintInfoList[i].lineLocation
        );
        continue;
      } else if (billPrintInfoList[i].linePaddingFlag) {
        if (billPrintInfoList[i].unPrtFlag) {
          if (
            printDataInfo[billPrintInfoList[i].unPrtVariable] === null ||
            printDataInfo[billPrintInfoList[i].unPrtVariable] === undefined ||
            printDataInfo[billPrintInfoList[i].unPrtVariable].length === 0
          ) {
            continue;
          }
        }

        // 한줄 공백 출력
        printContent += getLineSpaceView();
        continue;
      } else if (billPrintInfoList[i].loopFlag) {
        if (
          billPrintInfoList[i].tsConfReceiptDtlList === null ||
          billPrintInfoList[i].tsConfReceiptDtlList.length === 0
        ) {
          this.errorToast(
            this.$t("billPrinter.validationMessage.notPrintDtlInfo")
          );
          return;
        }

        if (billPrintInfoList[i].unPrtFlag) {
          if (
            printDataInfo[billPrintInfoList[i].unPrtVariable] === null ||
            printDataInfo[billPrintInfoList[i].unPrtVariable] === undefined ||
            printDataInfo[billPrintInfoList[i].unPrtVariable].length === 0
          ) {
            continue;
          }
        }

        let loopData = printDataInfo[billPrintInfoList[i].loopName];

        if (
          loopData !== null &&
          loopData !== undefined &&
          loopData.length > 0
        ) {
          let contentData = "";
          let paddingLength = 0;

          for (let z = 0; z < loopData.length; z++) {
            let valueData = "";
            for (
              let a = 0;
              a < billPrintInfoList[i].tsConfReceiptDtlList.length;
              a++
            ) {
              if (
                billPrintInfoList[i].tsConfReceiptDtlList[a].contentsWidth === 0
              ) {
                paddingLength = process.env.PRINTER_WIDTH;
              } else {
                paddingLength =
                  billPrintInfoList[i].tsConfReceiptDtlList[a].contentsWidth;
              }

              if (billPrintInfoList[i].tsConfReceiptDtlList[a].variableFlag) {
                if (
                  billPrintInfoList[i].tsConfReceiptDtlList[a].variableType !==
                  null
                ) {
                  switch (
                    billPrintInfoList[i].tsConfReceiptDtlList[a].variableType
                  ) {
                    case "DATE":
                      contentData = getFormattedDate(
                        loopData[z][
                          billPrintInfoList[i].tsConfReceiptDtlList[a]
                            .variableName
                        ],
                        billPrintInfoList[i].tsConfReceiptDtlList[a]
                          .variableFormat
                      );
                      break;
                    case "NUMBER":
                      contentData = new Intl.NumberFormat().format(
                        loopData[z][
                          billPrintInfoList[i].tsConfReceiptDtlList[a]
                            .variableName
                        ]
                      );
                      break;
                    case "TEL":
                      contentData = getFormattedTelNumber(
                        loopData[z][
                          billPrintInfoList[i].tsConfReceiptDtlList[a]
                            .variableName
                        ],
                        billPrintInfoList[i].tsConfReceiptDtlList[a]
                          .variableFormat
                      );
                      break;
                    default:
                      contentData =
                        loopData[z][
                          billPrintInfoList[i].tsConfReceiptDtlList[a]
                            .variableName
                        ];
                      break;
                  }
                } else {
                  contentData =
                    loopData[z][
                      billPrintInfoList[i].tsConfReceiptDtlList[a].variableName
                    ];
                }

                contentData =
                  contentData +
                  (billPrintInfoList[i].tsConfReceiptDtlList[a].contents ===
                  null
                    ? ""
                    : billPrintInfoList[i].tsConfReceiptDtlList[a].contents);
              } else {
                contentData =
                  billPrintInfoList[i].tsConfReceiptDtlList[a].contents;
              }

              valueData += getDivideStringView(
                contentData,
                paddingLength,
                billPrintInfoList[i].tsConfReceiptDtlList[a].paddingLocation
              );

              if (billPrintInfoList[i].tsConfReceiptDtlList[a].lineFeedFlag) {
                valueData += "<br>";
              }
            }

            printContent += valueData + "<br>";
          }
        }
      } else {
        if (
          billPrintInfoList[i].tsConfReceiptDtlList === null ||
          billPrintInfoList[i].tsConfReceiptDtlList.length === 0
        ) {
          this.errorToast(
            this.$t("billPrinter.validationMessage.notPrintDtlInfo")
          );
          return;
        }

        if (billPrintInfoList[i].unPrtFlag) {
          if (
            printDataInfo[billPrintInfoList[i].unPrtVariable] === null ||
            printDataInfo[billPrintInfoList[i].unPrtVariable] === undefined ||
            printDataInfo[billPrintInfoList[i].unPrtVariable].length === 0
          ) {
            continue;
          }
        }

        let contentData = "";
        let valueData = "";
        let paddingLength = 0;

        for (
          let a = 0;
          a < billPrintInfoList[i].tsConfReceiptDtlList.length;
          a++
        ) {
          if (
            billPrintInfoList[i].tsConfReceiptDtlList[a].contentsWidth === 0
          ) {
            paddingLength = process.env.PRINTER_WIDTH;
          } else {
            paddingLength =
              billPrintInfoList[i].tsConfReceiptDtlList[a].contentsWidth;
          }

          if (billPrintInfoList[i].tsConfReceiptDtlList[a].variableFlag) {
            if (
              billPrintInfoList[i].tsConfReceiptDtlList[a].variableType !== null
            ) {
              switch (
                billPrintInfoList[i].tsConfReceiptDtlList[a].variableType
              ) {
                case "DATE":
                  contentData = getFormattedDate(
                    printDataInfo[
                      billPrintInfoList[i].tsConfReceiptDtlList[a].variableName
                    ],
                    billPrintInfoList[i].tsConfReceiptDtlList[a].variableFormat
                  );
                  break;
                case "NUMBER":
                  contentData = new Intl.NumberFormat().format(
                    printDataInfo[
                      billPrintInfoList[i].tsConfReceiptDtlList[a].variableName
                    ]
                  );
                  break;
                case "TEL":
                  contentData = getFormattedTelNumber(
                    printDataInfo[
                      billPrintInfoList[i].tsConfReceiptDtlList[a].variableName
                    ],
                    billPrintInfoList[i].tsConfReceiptDtlList[a].variableFormat
                  );
                  break;
                default:
                  contentData =
                    printDataInfo[
                      billPrintInfoList[i].tsConfReceiptDtlList[a].variableName
                    ];
                  break;
              }
            } else {
              contentData =
                printDataInfo[
                  billPrintInfoList[i].tsConfReceiptDtlList[a].variableName
                ];
            }

            contentData =
              contentData +
              (billPrintInfoList[i].tsConfReceiptDtlList[a].contents === null
                ? ""
                : billPrintInfoList[i].tsConfReceiptDtlList[a].contents);
          } else {
            contentData = billPrintInfoList[i].tsConfReceiptDtlList[a].contents;
          }

          valueData += getDivideStringView(
            contentData,
            paddingLength,
            billPrintInfoList[i].tsConfReceiptDtlList[a].paddingLocation
          );

          if (billPrintInfoList[i].tsConfReceiptDtlList[a].lineFeedFlag) {
            valueData += "<br>";
          }
        }

        printContent += valueData + "<br>";
      }
    }
  }

  return printContent;
}

export async function getTsConfReceiptInfos(receiptCode, useFlag) {
  const {
    value: { tsConfReceiptMstList },
  } = await getTsConfReceiptInfo(receiptCode, useFlag);

  return { tsConfReceiptMstList };
}
