export const PATH_BOARD = `/boards`;

export function fetchBoardWithPosts(args) {
  return this.http.get(`${PATH_BOARD}/${args.boardId}/posts/search`, {
    params: {
      offset: args.offset,
      limit: args.limit,
    },
  });
}

export function fetchSearchPostsById(boardId, postId) {
  return this.http.get(`${PATH_BOARD}/${boardId}/posts/search/${postId}`);
}

export function createPost(boardId) {
  return this.http.post(`${PATH_BOARD}/${boardId}/posts`);
}

export function savePost(boardId, data) {
  return this.http.patch(`${PATH_BOARD}/${boardId}/posts`, data);
}

export function saveImage(boardId, postId, file) {
  return this.http.post(`${PATH_BOARD}/${boardId}/posts/${postId}/image`, file);
}

export function deletePost(boardId, postId) {
  return this.http.delete(`${PATH_BOARD}/${boardId}/posts/${postId}`);
}