import qs from "qs";
export const PATH_GROUP_RECEIPT_STATUS = '/group-receipt-status';

export function annualGroupRegistrationStatus(args) {
    return this.http.get(`${PATH_GROUP_RECEIPT_STATUS}`, {
        params: {
            requestTeam:args.requestTeam,
            resveMm:args.resveMm,
            dwCode:args.dwCode,
            grpHopeTime:args.grpHopeTime,
            resveCycle:args.resveCycle,
            grpDiv:args.grpDiv,
            etcRequstContents:args.etcRequstContents,

            requestYear:args.requestYear,
            grpKind:args.grpKind,
            grpRequestStatus:args.grpRequestStatus,
            name:args.name,
        }
    });
}

export function getGroupDetailedInquiry(args) {
    return this.http.get(`${PATH_GROUP_RECEIPT_STATUS}/members`, {
        params: {
            grpRequestId:args
        }
    });
}

export function patchGroupReceptionStatusChange(params,args) {
    return this.http.patch(`${PATH_GROUP_RECEIPT_STATUS}/change-request-status/${params}`,args);
}

export function deleteGroupRegistration(args) {
    return this.http.delete(`${PATH_GROUP_RECEIPT_STATUS}`,{
        params: {
            grpRequestIds : args
        },
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
        },
    });
}


