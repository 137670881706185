export const PATH_AIR_PORT = `/air-port`;

export function fetchAirPortIncheonInterfaceData(args) {
  return this.http.get(`${PATH_AIR_PORT}/incheon/interface-data/${args.bsnDate}`);
}

export function fetchLogCountByBsnDateAndLinkDiv(args) {
  return this.http.get(`${PATH_AIR_PORT}/log-count`, {
    params: {
      bsnDate: args.bsnDate,
      linkDiv: args.linkDiv
    }
  });
}

export function fetchIncheonAirportInterfaceDataMonthCloseList(args) {
  return this.http.get(`${PATH_AIR_PORT}/incheon/interface-data/month-close-list`, {
    params: {
      bsnDateFrom: args.bsnDateFrom,
      bsnDateTo: args.bsnDateTo,
    }
  });
}

export function airportInterfaceLogSave(data) {
  return this.http.post(`${PATH_AIR_PORT}/log-save`, data);
}

export function postAirPortIncheonInterfaceData(data) {
  return this.http.post(`${PATH_AIR_PORT}/incheon/interface-data`, data);
}