<template>
  <div
    class="inputTime"
    :class="[{ focus: active }, { disabled: disabled }]"
    @focusout="handleFocusout"
    @focusin="handleFocusin"
  >
    <input
      ref="input"
      class="input-time"
      autocomplete="off"
      :name="name"
      :style="{textAlign: textAlign}"
      type="text"
      :disabled="disabled"
      v-model="text"
      @keydown.delete="handleKeydownDelete"
      @keydown.48="handleKeydownNumber"
      @keydown.49="handleKeydownNumber"
      @keydown.50="handleKeydownNumber"
      @keydown.51="handleKeydownNumber"
      @keydown.52="handleKeydownNumber"
      @keydown.53="handleKeydownNumber"
      @keydown.54="handleKeydownNumber"
      @keydown.55="handleKeydownNumber"
      @keydown.56="handleKeydownNumber"
      @keydown.57="handleKeydownNumber"
      @keydown.96="handleKeydownNumber"
      @keydown.97="handleKeydownNumber"
      @keydown.98="handleKeydownNumber"
      @keydown.99="handleKeydownNumber"
      @keydown.100="handleKeydownNumber"
      @keydown.101="handleKeydownNumber"
      @keydown.102="handleKeydownNumber"
      @keydown.103="handleKeydownNumber"
      @keydown.104="handleKeydownNumber"
      @keydown.105="handleKeydownNumber"
      @keydown.left="handleKeydownArrow"
      @keydown.right="handleKeydownArrow"
      @keydown="handleKeydown"
      @focusin="handleTextFocusIn"
    />
    <input type="hidden" :id="id" :value="resultValue" />
  </div>
</template>

<style scoped>
.inputTime {box-sizing: border-box;width: 100%;border: 1px solid #e0e0e0;border-radius: 3px;}
.inputTime input[type="text"] {display: block;box-sizing: border-box;width: 100%;padding: 0 6px;margin: 0;border: none;background-color: transparent;color: #666;font-size: 12px;line-height: 21px;font-family: "돋움", Dotum, Arial, Verdana, sans-serif;outline: none;}
.inputTime input[type="text"]::placeholder {color: #bbb;}
.inputTime.focus {border-color: #999;}
.inputTime.disabled {border-color: #e0e0e0;background-color: #f0f0f0;}
.inputTime.disabled input[type="text"] {color: #bbb;cursor: default;}
input.input-time:focus {background-color: rgba(250, 244, 192, 0.2) !important;}
</style>

<script>
import moment from "moment";
import { isTimeFormatString } from "@/utils/string";
import { TIME_FORMAT_hh_mm, TIME_FORMAT_hh_mm_ss } from "@/utils/time";
import { v4 as uuidv4 } from "uuid";

const TIME_FORMATS = [TIME_FORMAT_hh_mm_ss, TIME_FORMAT_hh_mm];

const EMPTY_OF_FORMAT = {
  "HH:mm:ss": "00:00:00",
  "HH:mm": "00:00",
};

function replaceRange(target, string, startIndex, endIndex) {
  return `${target.substr(0, startIndex)}${string}${target.substr(endIndex)}`;
}

export default {
  name: "InputTime",
  props: {
    id: String,
    textAlign: {
      type: String,
      default: "center",
    },
    name: {
      type: String,
      default: "name",
    },
    value: {
      type: String,
      validator(value) {
        return !value || isTimeFormatString(value);
      },
    },
    format: {
      type: String,
      validator(format) {
        return !(TIME_FORMATS.indexOf(format) < 0);
      },
      default: () => TIME_FORMAT_hh_mm,
    },
    disabled: {
      type: [String, Boolean],
      validator(disabled) {
        return `${disabled}` === "true" || `${disabled}` === "false";
      },
      default: () => false,
    },
  },
  created() {
    this.text = this.value || EMPTY_OF_FORMAT[this.format];
  },
  watch: {
    value() {
      this.text = this.value || EMPTY_OF_FORMAT[this.format];
      this.$emit("change", { data: this.value });
    },
    active(active) {
      if (!active) {
        if (this.text === EMPTY_OF_FORMAT[this.format]) {
          this.$emit("input", EMPTY_OF_FORMAT[this.format]);
        } else if (this.text !== this.value) {
          if (moment(this.text, this.format).isValid()) {
            this.$emit("input", this.text);
          } else {
            this.text = this.value || EMPTY_OF_FORMAT[this.format];
          }
        }
      }
    },
    text(newText, oldText) {
      if (
        this.format === TIME_FORMAT_hh_mm &&
        !/([0-9]{2}:[0-9]{2})/gi.test(newText)
      ) {
        this.text = oldText;
        return;
      }

      if (
        this.format === TIME_FORMAT_hh_mm_ss &&
        !/([0-9]{2}:[0-9]{2}:[0-9]{2})/gi.test(newText)
      ) {
        this.text = oldText;
        return;
      }

      if (!moment(newText, this.format).isValid()) {
        // this.text = oldText;
        console.log(newText, "newText");
        console.log(this.format, "this.format");
      }
    },
  },
  data() {
    return {
      active: false,
      text: "",
    };
  },
  computed: {
    _calendarButtonUUID() {
      return uuidv4();
    },

    resultValue() {
      return moment(this.text, this.format).isValid() ? this.text : this.value;
    },
    calendarValueModel: {
      get() {
        const valueMoment = moment(this.value, this.format);
        const textMoment = moment(this.text, this.format);

        if (textMoment.isValid()) {
          return textMoment.toDate();
        } else {
          return valueMoment.toDate();
        }
      },
      set(value) {
        this.text = moment(value).format(this.format);
      },
    },
  },
  methods: {
    handleFocusin() {
      this.active = true;
    },
    handleFocusout(event) {
      if (!event.relatedTarget) {
        this.active = false;

        return;
      }

      if (event.relatedTarget.dataset && event.relatedTarget.dataset.id) {
        switch (event.relatedTarget.dataset.id) {
          case `${this._calendarButtonUUID}`:
            return;
        }
      }

      this.active = false;
    },
    handleKeydownDelete(event) {
      event.preventDefault();

      const {
        keyCode,
        target: { selectionStart, selectionEnd },
      } = event;

      const deleteStartIndex =
        selectionStart === selectionEnd
          ? selectionStart + (keyCode === 8 ? -1 : 0)
          : selectionStart;
      const deleteEndIndex =
        selectionStart === selectionEnd
          ? selectionStart + (keyCode === 8 ? 0 : 1)
          : selectionEnd;

      const subString = event.target.value.substring(
        deleteStartIndex,
        deleteEndIndex
      );
      const maskString = subString.replace(/[0-9]/gi, "0");
      const result = replaceRange(
        event.target.value,
        maskString,
        deleteStartIndex,
        deleteEndIndex
      );

      if (this.value !== result) {
        this.active = true;
        this.text = result;
      }

      this.$nextTick(() => {
        this.$refs["input"].setSelectionRange(
          deleteStartIndex,
          deleteStartIndex
        );
      });
    },
    handleKeydownNumber(event) {
      event.preventDefault();

      const {
        key,
        target: { selectionStart },
      } = event;

      const keyValue = `${key}`;

      const deleteStartIndex = selectionStart;
      const deleteEndIndex = selectionStart + 1;
      const subString = event.target.value.substring(
        deleteStartIndex,
        deleteEndIndex
      );
      const maskString = subString.replace(/[0-9]/gi, keyValue);
      const result = replaceRange(
        event.target.value,
        maskString,
        deleteStartIndex,
        deleteEndIndex
      );

      if (this.value !== result) {
        this.active = true;
        this.text = result;
      }

      let nextCursorIndex = selectionStart + 1;
      if (this.isHyphenIndex(nextCursorIndex)) {
        nextCursorIndex++;
      }

      this.$nextTick(() => {
        this.$refs["input"].setSelectionRange(nextCursorIndex, nextCursorIndex);
      });
    },
    handleKeydownArrow(event) {
      event.preventDefault();

      const {
        key,
        target: { selectionStart, selectionEnd },
      } = event;

      let nextCursorIndex = selectionStart;

      if (key === "ArrowLeft") {
        if (selectionStart === selectionEnd) {
          nextCursorIndex = selectionStart - 1;
        } else {
          nextCursorIndex = selectionStart;
        }

        if (this.isHyphenIndex(nextCursorIndex)) {
          nextCursorIndex--;
        }
      } else if (key === "ArrowRight") {
        if (selectionStart === selectionEnd) {
          nextCursorIndex = selectionEnd + 1;
        } else {
          nextCursorIndex = selectionEnd;
        }

        if (this.isHyphenIndex(nextCursorIndex)) {
          nextCursorIndex++;
        }
      }

      this.$refs["input"].setSelectionRange(nextCursorIndex, nextCursorIndex);
    },
    handleKeydown(event) {
      const { key } = event;

      if (key === "Tab") {
        return;
      }

      event.preventDefault();
    },
    handleTextFocusIn() {
      if (this.text) {
        this.$refs.input.setSelectionRange(0, this.text.length);
      }
    },
    isHyphenIndex(index) {
      return index === 2 || index === 5;
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

