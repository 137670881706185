import qs from "qs";

export const PATH_SALES = `/sales`;

export function createSalesSlip(
  slipNo,
  tableId,
  visitorsCount,
  visitorId,
  salesDate,
  storeCode,
  sales,
  posNo
) {
  return this.http.post(`${PATH_SALES}`, {
    slipNo,
    tableId,
    visitorsCount,
    visitorId,
    salesDate,
    storeCode,
    sales,
    posNo
  });
}

export function fetchSalesSlip(salesId) {
  return this.http.get(`${PATH_SALES}/${salesId}`);
}

export function fetchNextSlipNo(salesDate, storeCode) {
  return this.http.get(`${PATH_SALES}/next-number`, {
    params: {
      salesDate,
      storeCode,
    },
  });
}

export function fetchSalesSlipMemos(salesId) {
  return this.http.get(`${PATH_SALES}/${salesId}/memos`);
}

export function fetchSalesSlipPayments(salesId, gcDiv) {
  return this.http.get(`${PATH_SALES}/${salesId}/payments`, {
    params: {
      gcDiv,
    }
  });
}

export function fetchKitchenOrderStatements(slipIds) {
  return this.http.get(`${PATH_SALES}/kitchen-order-statement`, {
    params: {
      slipIds,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

export function fetchDeleteSlipKitchenOrderStatements(salesId) {
  return this.http.get(`${PATH_SALES}/kitchen-order-statement/delete-slip`, {
    params: {
      salesId,
    },
  });
}

export function fetchKitchenOrderStatementsReissue(salesId) {
  return this.http.get(`${PATH_SALES}/kitchen-order-statement/reissue`, {
    params: {
      salesId,
    },
  });
}

export function fetchSalesDetailsByItem(args) {
  return this.http.get(`${PATH_SALES}/sales-details-by-item`, {
    params: {
      salesDateFrom: args.salesDateFrom,
      salesDateTo: args.salesDateTo,
      storeCodes: args.storeCodes,
      classCodes: args.classCodes,
      productName: args.productName,
      gcDiv: args.gcDiv,
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    }
  });
}

export function updateKitchenPrtSno(salesId, kitchenOrderStatementRecords) {
  return this.http.put(`${PATH_SALES}/kitchen-print-sno/${salesId}`, {
    kitchenOrderStatementRecords,
  });
}

export function createSalesSlipMemo(salesId, memoContents) {
  return this.http.post(`${PATH_SALES}/${salesId}/memos`, {
    memoContents,
  });
}

export function updateSalesSlipStoreTable(
  salesId,
  storeCode,
  salesDate,
  tableId
) {
  return this.http.put(`${PATH_SALES}/${salesId}/table`, {
    storeCode,
    salesDate,
    tableId,
  });
}

export function updateSalesSlipSales(salesId, storeCode, salesDate, sales) {
  return this.http.patch(`${PATH_SALES}/${salesId}/items`, {
    storeCode,
    salesDate,
    sales,
    tabletOrderUseFlag: false,
  });
}

export function updateSalesSlipVisitor(salesId, visitorId, bsnDate) {
  return this.http.put(`${PATH_SALES}/${salesId}/visitor`, {
    visitorId,
    bsnDate,
  });
}

export function updateSalesSlipVisitorsCount(salesId, visitorsCount) {
  return this.http.put(`${PATH_SALES}/${salesId}/visitors-count`, {
    visitorsCount,
  });
}

export function deleteSalesSlip(salesId, deleteReason, bsnDate, storeCode) {
  return this.http.delete(`${PATH_SALES}/${salesId}`, {
    data: {
      deleteReason,
      bsnDate: bsnDate,
      storeCode: storeCode,
    },
  });
}

export function deleteSalesSlipMemo(salesId, memoId) {
  return this.http.delete(`${PATH_SALES}/${salesId}/memos/${memoId}`);
}

export function putSalesSlipByTableAddUp(data) {
  return this.http.put(`${PATH_SALES}/table/slips-add-up`, data, {
      isBackground: true
    });
}

export function patchTableActivation(salesId) {
  return this.http.patch(`${PATH_SALES}/${salesId}/table-activation`);
}