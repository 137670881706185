import layout from "@/views/layout/layout";

const codeManagementRoute = {
  path: "/code-management",
  component: layout,
  redirect: "/code-management/basic-code/workplace-registration",
  children: [
    {
      path: "basic-code/workplace-registration",
      name: "WorkplaceRegistration",
      component: () =>
        import("@/views/code-management/basic-code/WorkplaceRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "basic-code/common-code-registration",
      name: "CommonCodeRegistration",
      component: () =>
        import("@/views/code-management/basic-code/CommonCodeRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "basic-code/discount-code-registration",
      name: "DiscountCodeRegistration",
      component: () =>
        import("@/views/code-management/basic-code/DiscountCodeRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "basic-code/account-registration",
      name: "AccountRegistration",
      component: () =>
        import("@/views/code-management/basic-code/AccountRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "basic-code/locker-number-registration",
      name: "LockerNumberRegistration",
      component: () =>
        import(
          "@/views/code-management/basic-code/locker-number-registration/LockerNumberRegistration"
        ),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "basic-code/receipt-code-registration",
      name: "ReceiptCodeRegistration",
      component: () =>
        import("@/views/code-management/basic-code/ReceiptCodeRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "product-code/product-class-code",
      name: "ProductClassCode",
      component: () =>
        import("@/views/code-management/product-code/ProductClassCode"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "basic-code/agency-code-registration",
      name: "AgencyCodeRegistration",
      component: () =>
          import("@/views/code-management/basic-code/AgencyCodeRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "product-code/product-code-registration",
      name: "ProductCodeRegistration",
      component: () =>
        import("@/views/code-management/product-code/ProductCodeRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "product-code/store-sales-product-registration",
      name: "storeSalesProductRegistration",
      component: () =>
        import(
          "@/views/code-management/product-code/StoreSalesProductRegistration"
        ),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "product-code/store-table-registration",
      name: "storeTableRegistration",
      component: () =>
        import("@/views/code-management/product-code/StoreTableRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "price-code/price-unit",
      name: "PriceUnit",
      component: () => import("@/views/code-management/price-code/PriceUnit"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "price-code/caddie-fee-price-registration",
      name: "CaddieFeePriceRegistration",
      component: () =>
        import("@/views/code-management/price-code/CaddieFeePriceRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "room-code-management/register-room-number",
      name: "RegisterRoomNumber",
      component: () =>
          import("@/views/code-management/room-code-management/RegisterRoomNumber"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "managing-homepage/post-registration",
      name: "PostRegistration",
      component: () =>
          import("@/views/code-management/managing-homepage/PostRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "system-code/system-config",
      name: "SystemConfig",
      component: () =>
        import("@/views/code-management/system-code/SystemConfig"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "price-code/event-price-config",
      name: "EventPriceConfig",
      component: () =>
        import("@/views/code-management/price-code/EventPriceConfig"),
      meta: {
        useUsualStyles: true,
      },
    },
  ],
};

export default codeManagementRoute;
