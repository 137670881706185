import layout from '@/views/layout/layout';

const gameProgressRoute = {
  path: '/game-progress',
  component: layout,
  redirect: '/game-progress/caddie-info/caddieInfo-registration',
  children: [
    {
      path: 'caddieArrangement',
      name: 'CaddieArrangement',
      component: () =>
      import('@/views/game-progress/caddie-arrangement/CaddieArrangement'),
      meta: {
        enableMemo: true,
        currentView: 'GAME',
        useUsualStyles: true,
      },
    },
    {
      path: 'caddieTARegistration',
      name: 'CaddieTARegistration',
      component: () =>
      import('@/views/game-progress/caddie-arrangement/CaddieTARegistration'),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: 'CaddieAttendStatus',
      name: 'CaddieAttendStatus',
      component: () =>
      import('@/views/game-progress/caddie-arrangement/CaddieAttendStatus'),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: 'caddieRoundTotalTable',
      name: 'CaddieRoundTotalTable',
      component: () =>
      import('@/views/game-progress/caddie-arrangement/CaddieRoundTotalTable'),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: 'caddieArrangementStatement',
      name: 'CaddieArrangementStatement',
      component: () =>
      import('@/views/game-progress/caddie-arrangement/CaddieArrangementStatement'),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: 'matchProgressTimetable',
      name: 'MatchProgressTimetable',
      component: () =>
      import('@/views/game-progress/caddie-arrangement/MatchProgressTimetable'),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: 'caddie-info/caddieInfo-registration',
      name: 'caddieInfoRegistration',
      component: () =>
      import('@/views/game-progress/caddie-info/CaddieInfoRegistration'),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: 'caddie-info/caddieInfo-vacation',
      name: 'caddieInfoVacation',
      component: () =>
      import('@/views/game-progress/caddie-info/CaddieInfoVacation'),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: 'CaddieIncomeStatus',
      name: 'CaddieIncomeStatus',
      component: () =>
      import('@/views/game-progress/caddie-arrangement/CaddieIncomeStatus'),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: 'cart-info-registration',
      name: 'CartInfoRegistration',
      component: () =>
      import('@/views/game-progress/cart-management/CartInfoRegistration'),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: 'visit-score-registration',
      name: 'VisitScoreRegistration',
      component: () =>
      import('@/views/game-progress/team/VisitScoreRegistration'),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: 'award-certificate',
      name: 'AwardCertificate',
      component: () =>
      import('@/views/game-progress/team/AwardCertificate'),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: 'submit-tax-data',
      name: 'SubmitTaxData',
      component: () =>
        import('@/views/game-progress/caddie-info/SubmitTaxData'),
      meta: {
        useUsualStyles: true,
      },
    },
  ],
};

export default gameProgressRoute;
