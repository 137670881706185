import layout from "@/views/layout/layout.vue";

const transferOfNameRouter = {
  path: "/transfer-of-name",
  component: layout,
  redirect: "",
  children: [
    {
      path: "name-change-application-confirmation",
      name: "NameChangeApplicationConfirmation",
      component: () =>
        import("@/views/transfer-of-name/NameChangeApplicationConfirmation.vue"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "name-change-status",
      name: "NameChangeStatus",
      component: () =>
        import("@/views/transfer-of-name/NameChangeStatus.vue"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "name-change-report-by-computerized-medium",
      name: "NameChangeReportByComputerizedMedium",
      component: () =>
        import("@/views/transfer-of-name/NameChangeReportByComputerizedMedium.vue"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "membership-change-status",
      name: "MembershipChangeStatus",
      component: () =>
        import("@/views/transfer-of-name/MembershipChangeStatus.vue"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "membership-change-status-details",
      name: "MembershipChangeStatusDetails",
      component: () =>
        import("@/views/transfer-of-name/MembershipChangeStatusDetails.vue"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "membership-changeStatus-individual-corporate",
      name: "MembershipChangeStatusIndividualCorporate",
      component: () =>
        import("@/views/transfer-of-name/MembershipChangeStatusIndividualCorporate.vue"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "member-change-list",
      name: "MemberChangeList",
      component: () =>
        import("@/views/transfer-of-name/MemberChangeList.vue"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "golf-membership-transaction-data",
      name: "GolfMembershipTransactionData",
      component: () =>
        import("@/views/transfer-of-name/GolfMembershipTransactionData.vue"),
      meta: {
        useUsualStyles: true,
      },
    },
    { // 회원권 현황
      path: "membership-history",
      name: "MembershipHistory",
      component: () =>
        import("@/views/transfer-of-name/MembershipHistory.vue"),
      meta: {
        useUsualStyles: true,
      },
    },
    { // 회원권 압류
      path: "membership-seizure",
      name: "MembershipSeizure",
      component: () =>
          import("@/views/transfer-of-name/MembershipSeizure.vue"),
      meta: {
        useUsualStyles: true,
      },
    },
  ],
};

export default transferOfNameRouter;