const epsonPrinter = {
    namespaced: true,
    state: {
        billPrinter: null,
        kitchenPrinter: null,
    },
    mutations: {
        SET_bILL_PRINTER: (state, billPrinter) => {
            state.billPrinter = billPrinter;
        },
        SET_KITCHEN_PRINTER: (state, kitchenPritner) => {
            state.kitchenPrinter = kitchenPritner;
        }
    },
    getters: {
        billPrinter: ({billPrinter}) => billPrinter || null,
        kitchenPrinter: ({kitchenPrinter}) => kitchenPrinter || null
    },
};

export default epsonPrinter;
