export function encodeBase64(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event) => {
      reader = null;
      resolve(event.target.result);
    };
    reader.onerror = reject;
  });
}
