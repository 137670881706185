const layoutLeftIsShow = "Layout-Left-Show";
const layoutRightTabIsShow = "Layout-Right-Tab-Show";
const layoutRightContentShow = "Layout-Right-Content-Show";
const layoutRightWeatherShow = "Layout-Right-Weather-Show";
const layoutRightMemberShow = "Layout-Right-Member-Show";
const layoutRightVocShow = "Layout-Right-Voc-Show";

export function getLayoutLeftIsShow() {
  let retValue = sessionStorage.getItem(layoutLeftIsShow);
  if (!retValue) {
    retValue = "true";
  }
  return JSON.parse(retValue);
}

export function getLayoutRightTabIsShow() {
  let retValue = sessionStorage.getItem(layoutRightTabIsShow);
  if (!retValue) {
    retValue = "true";
  }
  return JSON.parse(retValue);
}

export function getLayoutRightContentIsShow() {
  let retValue = sessionStorage.getItem(layoutRightContentShow);
  if (!retValue) {
    retValue = "false";
  }
  return JSON.parse(retValue);
}

export function getLayoutRightWeatherIsShow() {
  let retValue = sessionStorage.getItem(layoutRightWeatherShow);
  if (!retValue) {
    retValue = "false";
  }
  return JSON.parse(retValue);
}

export function getLayoutRightMemberIsShow() {
  let retValue = sessionStorage.getItem(layoutRightMemberShow);
  if (!retValue) {
    retValue = "false";
  }
  return JSON.parse(retValue);
}

export function getLayoutRightVocIsShow() {
  let retValue = sessionStorage.getItem(layoutRightVocShow);
  if (!retValue) {
    retValue = "false";
  }
  return JSON.parse(retValue);
}


export function setLayoutLeftRightValues(
  isLeftMenuActive,
  isRightTabActive,
  isRightContentActive,
  isRightWeatherActive,
  isRightMemberActive,
  isRightVocActive,
) {
  sessionStorage.setItem(layoutLeftIsShow, isLeftMenuActive);
  sessionStorage.setItem(layoutRightTabIsShow, isRightTabActive);
  sessionStorage.setItem(layoutRightContentShow, isRightContentActive);
  sessionStorage.setItem(layoutRightWeatherShow, isRightWeatherActive);
  sessionStorage.setItem(layoutRightMemberShow, isRightMemberActive);
  sessionStorage.setItem(layoutRightVocShow, isRightVocActive);
}

export function openNewWindow(routeData) {
  const newScreenWindow = window.open(routeData.href, "_blank");
  newScreenWindow.sessionStorage.setItem(layoutLeftIsShow, "false");
  newScreenWindow.sessionStorage.setItem(layoutRightTabIsShow, "true");
  newScreenWindow.sessionStorage.setItem(layoutRightContentShow, "false");
  newScreenWindow.sessionStorage.setItem(layoutRightWeatherShow, "false");
  newScreenWindow.sessionStorage.setItem(layoutRightMemberShow, "false");
  newScreenWindow.sessionStorage.setItem(layoutRightVocShow, "false");
}
