import layout from "@/views/layout/layout";

const memberManagementRoute = {
  path: "/member-management",
  redirect:
    "/member-management/base-info/membership-group-privilege-registration",
  component: layout,
  children: [
    {
      path: "base-info/membership-group-privilege-registration",
      name: "MembershipGroupPrivilegeRegistration",
      component: () =>
        import(
          "@/views/member-management/group-privilege/MembershipGroupPrivilegeRegistration"
        ),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "member-registration",
      name: "memberInfoRegistration",
      component: () => import("@/views/member-management/MemberRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "web-member-registration",
      name: "webMemberInfoRegistration",
      component: () =>
        import("@/views/member-management/WebMemberRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "coupon-member-search",
      name: "CouponMemberSearch",
      component: () => import("@/views/member-management/CouponMemberSearch"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "coupon-registration",
      name: "CouponRegistration",
      component: () => import("@/views/member-management/CouponRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "coupon-pub-status",
      name: "CouponPubStatus",
      component: () => import("@/views/member-management/CouponPubStatus"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "ticket-publish-current-status",
      name: "TicketPublishCurrentStatus",
      component: () => import("@/views/member-management/TicketPublishCurrentStatus"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "deposit-money-registration",
      name: "DepositMoneyRegistration",
      component: () =>
        import("@/views/member-management/DepositMoneyRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "deposit-money-status",
      name: "DepositMoneyStatus",
      component: () => import("@/views/member-management/DepositMoneyStatus"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "member-grade-management",
      name: "MemberGradeManagement",
      component: () =>
        import("@/views/member-management/MemberGradeManagement"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "member-grade-change-history",
      name: "MemberGradeChangeHistory",
      component: () =>
        import("@/views/member-management/MemberGradeChangeHistory"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "mileage-accrual-usage-status",
      name: "mileageAccrualUsageStatus",
      component: () =>
        import("@/views/member-management/MileageAccrualUsageStatus"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "member-visit-status",
      name: "memberVisitStatus",
      component: () =>
        import("@/views/member-management/MemberVisitStatus"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "destruction-of-personal-information-of-dormant",
      name: "DestructionOfPersonalInformationOfDormant",
      component: () =>
        import("@/views/member-management/DestructionOfPersonalInformationOfDormant.vue"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "destruction-of-personal-information-of-dormant-status",
      name: "DestructionOfPersonalInformationOfDormantStatus",
      component: () =>
        import("@/views/member-management/DestructionOfPersonalInformationOfDormantStatus.vue"),
      meta: {
        useUsualStyles: true,
      },
    },
  ],
};

export default memberManagementRoute;
