import axios from "axios";
import store from "@/store";
import { convertJsonEmptyStringValueToNull } from "@/utils/jsonUtil";
import UnauthorizedError from "@/error/UnauthorizedError";
import ForbiddenError from "@/error/ForbiddenError";
import { getCurrentBizCode } from "@/utils/pageKeyUtil";
import { getAccessToken } from "@/utils/session/SessionUtil";
import { compactObjectValues } from "@/utils/ObjectUtil";

const DEFAULT_CONTENT_TYPE = "application/json;charset=UTF-8";

import router from "@/router";

const getMenuId = () => {
  let menuId = router.currentRoute.query.menuId;
  if (!Number(menuId) && menuId) {
    // 메뉴ID가 숫자가 아닌 경우
    // 예) 즐겨찾기 등록된 메뉴
    if (menuId.indexOf("_") >= 0) {
      menuId = menuId.substring(menuId.indexOf("_") + 1);
    }
  }
  if (menuId && Number(menuId)) {
    return Number(menuId);
  }

  const routerName = router.currentRoute.name;
  if (!routerName) {
    return null;
  }

  const menuList = store.getters.menuList;

  const menuIds = menuList.filter(item => item.routerName === routerName && !item.isMenuUserMark);
  if (menuIds.length > 1) {
    return null;
  }

  return menuIds.length > 0 ? (menuIds[0].menuId ? Number(menuIds[0].menuId) : null) : null;
};

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_HOST_API_BASE_V1, // 환경변수에서 가져온 base url
  timeout: 60000, // request timeout,
  headers: {
    "Content-Type": DEFAULT_CONTENT_TYPE,
  },
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    const menuId = getMenuId();
    const bizCode = getCurrentBizCode();
    const accessToken = getAccessToken();
    config.headers["menuId"] = menuId;

    if (accessToken && !!bizCode) {
      config.headers["Authorization"] = `${bizCode} ${accessToken}`;
    }

    if (config.method === "get") {
      if (config.params) {
        config.params = compactObjectValues(
          JSON.parse(JSON.stringify(config.params)),
          true,
          false
        );
      }
    } else if (
      (config.method === "put" || config.method === "post") &&
      config.headers["Content-Type"] !== "multipart/form-data"
    ) {
      // config.data를 deep copy한 후, empty string을 null로 변환함

      let newData;
      if (config.data) {
        newData = JSON.parse(JSON.stringify(config.data));
      }
      convertJsonEmptyStringValueToNull(newData);
      config.data = newData;
    }

    // Do something before request is sent
    if (!config.isBackground) {
      store._vm.$EventBus.$emit("loaderOn");
    }

    return config;
  },
  (error) => {
    // Do something with request error
    store._vm.$EventBus.$emit("loaderOff");
    store._vm.$EventBus.$emit("alert", {
      message: "서버 요청 중 오류가 발생하였습니다",
    });

    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    if (!response.config.isBackground && store.getters.progress === null) {
      // TODO : 프로그레스 돌고 있는 상황에서는 로딩바 제거 안함.
      store._vm.$EventBus.$emit("loaderOff");
    }
    if (response.data.status !== undefined) {
      if (response.data.status.toUpperCase() !== "OK") {
        store._vm.$EventBus.$emit("errToast", response.data.error.message);
        return Promise.reject("error"); // eslint-disable-line
      }
    }
    return response.data;
  },
  async (error) => {
    if (!error.response) {
      store._vm.$EventBus.$emit("loaderOff");

      store._vm.$EventBus.$emit(
        "errToast",
        "서버로부터 응답을 받을 수 없습니다."
      );

      return Promise.reject(error);
    }

    if (!error.config.isBackground) {
      store._vm.$EventBus.$emit("loaderOff");
    }

    const { response } = error;
    if (!response) {
      if (!error.config.isBackground) {
        store._vm.$EventBus.$emit("alert", {
          message: "서버 요청 중 오류가 발생하였습니다",
        });
      }
      return Promise.reject(error);
    }

    if (response.status === 401) {
      const isSessionExpired =
        error.response.data?.status === "SESSION_EXPIRED";

      if (isSessionExpired) {
        store._vm.$EventBus.$emit("sessionExpiredToast");
      }

      store._vm.$EventBus.$emit("logout", {
        isSessionExpired
      });
    } else if (response.status === 403) {
      if (error.response?.data?.status === "BLOCK_ID") {
        store._vm.$EventBus.$emit(
          "errToast",
          error.response?.data?.data ||
            "요청하신 아이디는 현재 사용중지 상태입니다.\n관리자에게 문의 바랍니다."
        );
      } else if (error.response?.data?.status === "BLOCK_ID_DURING_SESSION") {
        store._vm.$EventBus.$emit("alert", {
          title: "차단",
          message:
            error.response?.data?.data ||
            "요청하신 아이디는 현재 사용중지 상태입니다.\n관리자에게 문의 바랍니다.",
        });
      } else if (error.response?.data?.status === "BLOCK_PASSWORD") {
        store._vm.$EventBus.$emit(
          "errToast",
          error.response?.data?.data ||
            "요청하신 아이디는 로그인 오류 횟수를 초과하였습니다.\n관리자에게 문의 바랍니다."
        );
      } else if (
        error.response?.data?.status === "BLOCK_PASSWORD_DURING_SESSION"
      ) {
        store._vm.$EventBus.$emit("alert", {
          title: "차단",
          message:
            error.response?.data?.data ||
            "요청하신 아이디는 로그인 오류 횟수를 초과하였습니다.\n관리자에게 문의 바랍니다.",
        });
      } else {
        store._vm.$EventBus.$emit(
          "errToast",
          error.response?.data?.data
        );
      }
    }

    if (error.response.status === 401) {
      throw new UnauthorizedError(error.response.data);
    } else if (error.response.status === 403) {
      throw new ForbiddenError();
    } else if (error.response.data.status === "CONFIRM") {
      store._vm.$EventBus.$emit("infoToast", error.response.data.data);
    } else if (typeof error.response.data.data === "string") {
      store._vm.$EventBus.$emit("errToast", error.response.data.data);
    }

    return Promise.reject(error);
  }
);

export default service;
