import { stringify as qs_stringify } from "qs";

export const PATH_STORES = `/stores`;

export function fetchStores(usingPos) {
  return this.http.get(PATH_STORES, {
    params: {
      usingPos,
    },
  });
}

export function fetchStoreSalesAmount(storeCode, salesDate) {
  return this.http.get(`${PATH_STORES}/${storeCode}/sales-amount`, {
    params: {
      salesDate,
    },
  });
}

export function fetchStoreProductCategory(storeCode, parentCategoryCode) {
  return this.http.get(`${PATH_STORES}/${storeCode}/product-categories`, {
    params: {
      parentCode: parentCategoryCode,
    },
  });
}

export function fetchStoreProducts(storeCode, categoryCode, salesDate) {
  return this.http.get(`${PATH_STORES}/${storeCode}/products`, {
    params: {
      categoryCode,
      salesDate,
    },
  });
}

export function fetchPosTables(storeCode, salesDate) {
  return this.http.get(`${PATH_STORES}/${storeCode}/pos-tables`, {
    params: {
      salesDate,
    },
  });
}

export function fetchSlips(storeCode, salesDate, gcDiv, filters, orders,visitorName,grpName) {
  return this.http.get(`${PATH_STORES}/${storeCode}/slips`, {
    paramsSerializer(params) {
      return qs_stringify(params, { arrayFormat: "indices", allowDots: true });
    },
    params: {
      salesDate,
      gcDiv,
      filters,
      orders,
      visitorName,
      grpName
    },
  });
}

export function fetchStorePosMemo(args) {
  return this.http.get(`${PATH_STORES}/${args.storeCode}/pos-memo`, {
    params: {
      bsnDate: args.bsnDate
    }
  });
}

export function fetchStoreCoupon(args) {
  return this.http.get(`${PATH_STORES}/coupon`, {
    params: {
      storeCode: args.storeCode,
      chkinId: args.chkinId,
    },
  });
}

export function storeCheckout(storeCode, salesDate, visitorId, salesId) {
  return this.http.post(`${PATH_STORES}/${storeCode}/checkout`, {
    salesDate,
    visitorId,
    salesId,
  });
}

export function registerStoreProducts(storeCode, products) {
  return this.http.post(`${PATH_STORES}/${storeCode}/products`, products);
}

export function removeStoreProducts(storeCode, products) {
  return this.http.delete(`${PATH_STORES}/${storeCode}/products`, {
    data: products,
  });
}

export function productSortNoResetting(storeCode, data) {
  return this.http.put(`${PATH_STORES}/${storeCode}/products-sort-no-resetting`, data);
}