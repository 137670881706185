import Vue from "vue";
import gridTemplateDestroyMixin from "../../../mixins/gridTemplateDestroyMixin";

const formatToReplace = "${value}";

export default function GridAggregationTotalSumTemplate(templateStyle) {
  return {
    template: Vue.component("gridAggregationTotalSumTemplate", {
      mixins: [gridTemplateDestroyMixin],
      template: `<span style="line-height:24px; vertical-align:middle;" :style="{ color : templateStyle && templateStyle.textColor ? templateStyle.textColor : null }"><b>{{getDataSum()}}</b></span>`, // 폰트굵기 bold css지정 필요
      data() {
        return {
          data: {},
          templateStyle: templateStyle,
        };
      },
      methods: {
        getDataSum() {
          if (this.data.Sum !== 0) {
            this.data.Sum = this.data.Sum.toLocaleString();
          }

          return this.templateStyle.stringFormat
            ? this.templateStyle.stringFormat.replace(
                formatToReplace,
                this.data.Sum
              ) || "-"
            : this.data.Sum || "-";
        },
      },
    }),
  };
}
