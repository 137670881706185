<template>
  <aside
      :class="[
        'appSideRight',
        isRightExtendActive ? 'active' : null,
      ]"
      @transitionend="onAppSideRightTransitionend"
  >
    <div class="sideRight-tab">
      <ul class="navi">
        <!-- 활성 : active -->
        <li
            class="memo"
            :class="isRightContentActive === true ? 'active' : ''"
            v-if="$route.meta.enableMemo"
        >
          <erp-button
            button-div="GET"
            tabindex="-1"
            :is-custom-shortcut-button="true"
            :shortcut="{key: 'F9'}"
            shortcut-key="appSideRight.shortcuts.memoButton"
            @click.native="onMemoShortcutButtonClick"
          >
            메모
          </erp-button>
          <div class="item">
            {{ memoButtonShortcutString }}
          </div>
        </li>
        <li
            class="weather"
            :class="isRightWeatherActive === true ? 'active' : ''"
            v-if="weatherInfoUseFlag && !commonMixinIsButtonDisableByMenuAuth('GET_AUTH', 313)"
        >
          <erp-button
            button-div="GET"
            tabindex="-1"
            :disabled="commonMixinIsButtonDisableByMenuAuth('GET_AUTH', 313)"
            @click="onWeatherButtonClick"
          >
            기상정보
          </erp-button>
        </li>
        <li
            class="member"
            :class="isRightMemberActive === true ? 'active' : ''"
            v-if="!commonMixinIsButtonDisableByMenuAuth('GET_AUTH', 317)"
        >
          <erp-button
            button-div="GET"
            :disabled="commonMixinIsButtonDisableByMenuAuth('GET_AUTH', 317)"
            :is-custom-shortcut-button="true"
            :shortcut="{altKey: true, key: 'F2'}"
            shortcut-key="appSideRight.shortcuts.memberViewButton"
            tabindex="-1"
            @click.native="onMemberButtonClick"
          >
            회원검색
          </erp-button>
<!--          <div class="item">-->
<!--            {{ memberViewButtonShortcutString }}-->
<!--          </div>-->
        </li>
        <li
            class="voc"
            :class="isRightVocActive === true ? 'active' : ''"
            v-if="!commonMixinIsButtonDisableByMenuAuth('GET_AUTH', 319)"
        >
          <erp-button
            button-div="GET"
            tabindex="-1"
            :disabled="commonMixinIsButtonDisableByMenuAuth('GET_AUTH', 319)"
            @click.native="onVocButtonClick"
          >
            VOC
          </erp-button>
        </li>
      </ul>
    </div>
    <keep-alive include="rightMemoView">
      <component ref="right-view" :is="currentView"/>
    </keep-alive>
  </aside>
</template>

<style scoped>
.sideRight-tab .navi li {position: relative;}
.sideRight-tab .navi li .item {display: none;position: absolute;top: -2px;left: 31px;padding: 2px 3px 0 2px;border-radius: 3px;background-color: #222;font-size: 11px;line-height: 16px;color: #fff;}
.sideRight-tab .navi li .e-btn:hover + .item {display: block;}
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import rightMemoView from '@/views/layout/components/rightComponents/rightMemoView';
import rightWeatherView from '@/views/layout/components/rightComponents/rightWeatherView';
import rightMemberView from '@/views/layout/components/rightComponents/rightMemberView';
import rightVocView from '@/views/layout/components/rightComponents/rightVocView';
import FButton from '@/components/button/FButton';
import {getMemoButtonShortcut, getMemberViewButtonShortcut, toShortcutString} from '@/utils/KeyboardUtil';
import {mapGetters} from 'vuex';
import ErpButton from "@/components/button/ErpButton";

export default {
  name: 'appSideRight',
  props: {
    isRightContentActive: {
      type: Boolean,
    },
    isRightWeatherActive: {
      type: Boolean,
    },
    isRightMemberActive: {
      type: Boolean,
    },
    isRightVocActive: {
      type: Boolean,
    },
  },
  components: {
    rightMemoView,
    rightWeatherView,
    rightMemberView,
    rightVocView,
    FButton,
    ErpButton,
  },
  created() {
    this.$EventBus.$on('memoShortcutButtonClick', (isAuto) => {
      this.onMemoShortcutButtonClick(null, isAuto);
    });
    this.$EventBus.$on('vocButtonClick', () => {
      this.currentView = 'rightVocView';
      this.$EventBus.$emit('rightContentLayoutOpen',"VOC");
    });
  },
  mounted() {
    const info = sessionStorage.getItem('Standard-Infos');
    const weatherInfoUseFlag = JSON.parse(info)?.weatherInfoUseFlag;
    if (weatherInfoUseFlag) {
      this.weatherInfoUseFlag = weatherInfoUseFlag;
    }
  },
  beforeDestroy() {
    this.$EventBus.$off('memoShortcutButtonClick');
    this.$EventBus.$off('vocButtonClick');
  },
  mixins: [commonMixin],
  data() {
    return {
      currentView: 'rightMemoView',
      weatherInfoUseFlag: false,
    };
  },
  computed: {
    ...mapGetters(["isRightExtendActive"]),
    memoButtonShortcutString() {
      return this.toShortcutString(getMemoButtonShortcut());
    },
    memberViewButtonShortcutString() {
      return this.toShortcutString(getMemberViewButtonShortcut());
    },
  },
  methods: {
    toShortcutString,
    onMemoShortcutButtonClick(args, isAuto = false) {
      const $el = document.querySelector("#_dialog-header");
      if ($el) {
        return;
      }
      if (this.$route.meta.enableMemo) {
        this.currentView = 'rightMemoView';
        this.$emit('memoButtonClick', null, isAuto);
      }
    },
    onAppSideRightTransitionend() {
      if (this.isRightContentActive) {
        this.$refs['right-view'].focusInputMemoContents?.();
      }
    },
    onWeatherButtonClick() {
      this.currentView = 'rightWeatherView';
      this.$emit('weatherButtonClick');
    },
    onMemberButtonClick() {
      this.currentView = 'rightMemberView';
      this.$emit('memberButtonClick');
    },
    onVocButtonClick() {
      this.currentView = 'rightVocView';
      this.$emit('vocButtonClick');
    },
  },
};
</script>
