<template>
  <div class="sideRight-content">
    <div class="sideRight-header">
      <div class="header-left">
        <div class="header-title" style="margin-left:10px">메모</div>
        <div class="header-caption" style="margin-left:10px" v-if="this.memoInfo">
          [{{ resveCourse }} {{ resveTime }}]
        </div>
      </div>
      <div class="header-right">
        <ul class="header-button">
          <li class="refresh">
            <erp-button button-div="GET" @click.native="onClickRefresh" :tabindex="-1">
              새로고침
            </erp-button>
          </li>
          <li :class="delFlag ? 'hide' : 'show'">
            <erp-button button-div="GET" @click.native="delFlag = !delFlag" :tabindex="-1">
              숨기기
            </erp-button>
          </li>
          <li :class="isRightExtendActive ? 'reduce' : 'extend'">
            <erp-button button-div="CLOSE" @click.native="onExtendButtonClicked" :tabindex="-1">
              확장/축소
            </erp-button>
          </li>
          <li class="close">
            <erp-button
              button-div="CLOSE"
              @click.native="onRightMemoCloseBtnClicked"
              :tabindex="-1"
            >
              닫기
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="sideRight-body">
      <div class="sideRight-memo">
        <div class="memo-view" id="memoViewDiv">
          <div v-for="(data, idx) in filteredMemoViews" :key="`memo_${idx}`">
            <div v-if="data.isTimeLine" class="date">
              <div class="item">
                {{ getTimeLineFormat(data.date) }}
              </div>
            </div>
            <div
              v-else-if="isMe(data.sendDept, data.recptnDept, data.insertId)"
              :class="[`message`, `me`, data.delFlag ? 'disabled' : '']"
            >
              <!--<div class="symbol" :style="{backgroundColor: data.sendDeptColor}">
                {{data.sendDeptNameT}}
              </div>-->
              <div class="content">
                <div class="name">
                  <ul>
                    <li :style="{ color: data.recptnDeptColor }">
                      TO.{{ data.recptnDeptName }}
                    </li>
                    <!--<li class="team">
                      {{data.sendDeptName}}
                    </li>-->
                    <li>
                      {{ data.insertName }}
                    </li>
                  </ul>
                </div>
                <div class="box">
                  <div
                    v-show="data.memoNoConfirmCount > 0"
                    class="item"
                    style="width: 30px; margin: 0 0px 0 0; background-color: #fff; color: #f00; text-align: right;"
                  >
                    {{ data.memoNoConfirmCount }}
                  </div>
                  <div
                    class="item"
                    :style="data.memoNoConfirmCount > 0 ? 'max-width: calc(100% - 35px);' : ''"
                  >
                    <!--<div :style="{color: data.recptnDeptColor}">
                      TO.{{data.recptnDeptName}}
                    </div>-->
                    <template
                      v-for="(line, index) in data.memoContents.split('\n')"
                    >
                      {{ line }}
                      <br
                        v-if="
                          index !== data.memoContents.split('\n').length - 1
                        "
                        v-bind:key="`memo_${idx}-linebreak-${index}`"
                      />
                    </template>
                  </div>
                  <i></i>
                </div>
                <div class="time">
                  {{ getMessageTime(data.insertDt) }}
                </div>
                <div class="delete" v-if="!data.delFlag && (userId == data.insertId)">
                  <erp-button button-div="DELETE" @click.native="onMemoDelete(data)">
                    삭제
                  </erp-button>
                </div>
              </div>
            </div>
            <div
              v-else-if="!isMe(data.sendDept, data.recptnDept, data.insertId)"
              :class="[`message`, { disabled: data.delFlag }]"
            >
              <div
                class="symbol"
                :style="{ backgroundColor: data.sendDeptColor }"
              >
                {{ data.sendDeptNameT }}
              </div>
              <div class="content">
                <div class="name">
                  <ul>
                    <!--<li class="team">
                      {{data.sendDeptName}}
                    </li>-->
                    <li>
                      {{ data.insertName }}
                    </li>
                  </ul>
                </div>
                <div class="box">
                  <div class="item">
                    <template
                        v-for="(line, index) in data.memoContents.split('\n')"
                    >
                      {{ line }}
                      <br
                          v-if="
                          index !== data.memoContents.split('\n').length - 1
                        "
                          v-bind:key="`memo_${idx}-linebreak-${index}`"
                      />
                    </template>
                  </div>
                  <i></i>
                </div>
                <div class="time">
                  {{ getMessageTime(data.insertDt) }}
                </div>
                <div class="delete">
                  <erp-button button-div="DELETE"> 삭제 </erp-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="memo-write">
          <div class="content-body">
            <article class="body-article">
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section class="article-section section-01">
                <div class="section-header">
                  <div class="header-left">
                    <div class="header-title">메모 입력</div>
                  </div>
                  <div class="header-right">
                    <ul
                      v-if="currentView !== 'ROOM'"
                      class="header-check"
                    >
                      <li>
                        <label>
                          <input
                            type="radio"
                            v-model="grpFlag"
                            :checked="grpFlag"
                            :value="false"
                            tabindex="-1"
                            name="memo-radio"
                          />
                          <i></i>
                          <div class="label">팀</div>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input
                            type="radio"
                            v-model="grpFlag"
                            :checked="grpFlag"
                            :value="true"
                            :disabled="memoInfo && memoInfo.isGrp === false"
                            tabindex="-1"
                            name="memo-radio"
                          />
                          <i></i>
                          <div class="label">단체</div>
                        </label>
                      </li>
                    </ul>
                    <ul class="header-button">
                      <li class="add">
                        <ejs-dropdownbutton
                          :disabled="
                            commonMixinIsButtonDisableByAuth(
                              'resveReceiptResveReg'
                            ) &&
                            commonMixinIsButtonDisableByAuth(
                              'frontChkinVisitReg'
                            ) &&
                            commonMixinIsButtonDisableByAuth(
                              'gameProgressCaddiArrangeReg'
                            )
                          "
                          ref="userMenuDropdownButton"
                          cssClass="sideRight-memo-add-dropdown"
                          :items="memoAddItems"
                          :select="onMemoAddSelected"
                          :tabindex="-1"
                        >
                          추가
                        </ejs-dropdownbutton>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="section-body">
                  <div class="body-data body">
                    <div class="data-outer">
                      <div class="data-inner">
                        <ul class="data-content">
                          <li class="field reservationName">
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group input">
                                  <div class="form">
                                    <input-textarea
                                      ref="input-memo-contents"
                                      v-model="memoContents"
                                      @keypress="onMemoKeypress"
                                      :tabindex="-1"
                                    />
                                  </div>
                                  <div class="validation">
                                    <div class="e-tip-content"></div>
                                    <div class="e-tip-top"></div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                    <div class="border-left"></div>
                    <div class="border-right"></div>
                    <div class="border-top"></div>
                    <div class="border-bottom"></div>
                  </div>
                </div>
              </section>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { getDayOfWeekCaption } from "@/utils/date";
import { subStrByte } from "@/utils/string";
import { deleteMemo, saveMemo } from "@/api/common";
import { mapActions } from "vuex/dist/vuex.esm.browser";
import {mapGetters, mapState} from 'vuex';
import {
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetStandardInfo
} from '@/utils/commonCodes';
import moment from "moment";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "rightMemoView",
  components: {
    InputTextarea,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  mounted() {},
  data() {
    return {
      delFlag: true,
      grpFlag: false,
      memoContents: "",
      resveCourse: null,
      resveTime: null,
      messageClassList: [
        { comCode: "%", comName: "전체" },
        { comCode: "RESVE", comName: "reservation" },
        { comCode: "FRONT", comName: "front" },
        { comCode: "GAME", comName: "game" },
      ],
    };
  },
  computed: {
    ...mapGetters(["isRightExtendActive", "isRightContentActive", "userId"]),
    ...mapState("memoView", [
      "memoViews",
      "currentView",
      "memoResveId",
      "memoStayId",
      "memoInfo",
    ]),
    filteredMemoViews() {
      return this.delFlag
        ? this.memoViews
          ?.filter(item => !item.delFlag)
        : this.memoViews;
    },
    memoAddItems() {
      const dataList = commonCodesGetCommonCode("MEMO_DEPT", true).map(data => {
        return {
          text: data.comName,
          id: data.comCode
        };
      });
      //if (this.memoInfo && this.memoInfo.isGallery) {
      //  dataList = dataList.filter(data => data.id !== "GAME");
      //}
      return dataList;
    },
  },
  watch: {
    memoInfo() {
      if (this.memoInfo && Object.keys(this.memoInfo).length > 0) {
        this.memoContents = null;
        const courseList = commonCodesGetCommonCode("COURSE_CODE");
        this.resveCourse = this.memoStayId != null ?
          this.memoInfo.arrivalDate :
          (courseList.find((data) => {
            return data.comCode === this.memoInfo.resveCourse;
          })?.comName || "");
        this.resveTime = this.memoStayId != null ?
          subStrByte(this.memoInfo.guestName, 8) :
          moment(this.memoInfo.resveTime, "HHmm").format(
            "HH:mm"
          );
        if (this.memoInfo) {
          this.grpFlag = this.memoInfo.isGrp === true ? true : false;
        }
      }
    },
    memoViews(data) {
      if (data) {
        this.$nextTick(() => {
          const objDiv = document.getElementById("memoViewDiv");
          objDiv.scrollTop = objDiv.scrollHeight;
        });

        if (this.isRightContentActive) {
          this.memoConfirm();
        }
      } else {
        // 메모 자동오픈이 아닌 사용자가 클릭해서 열리는 방식으로 변경.
        // 사용자가 클릭해서 열어놓은 메모뷰는 자동으로 닫히면 안됨.
        // this.$EventBus.$emit("closeRightMenu");
      }
    },
    currentView(data) {
      if (data) {
        this.memoContents = null;
        this.grpFlag = false;
        this.refreshMemoView();
      }
    },
    isRightContentActive(data) {
      if (data) {
        if (this.isRightContentActive) {
          this.memoConfirm();
        }
      }
    }
  },
  methods: {
    ...mapActions("memoView", ["refreshMemoView"]),
    onClickRefresh() {
      this.refreshMemoView();
    },
    async memoConfirm() {
      if (this.filteredMemoViews?.length > 0) {
        const memoIds =
          this.filteredMemoViews
            .filter(item =>
              !item?.isTimeLine && // 타임라인은 제외.
              ["%", this.currentView].includes(item?.recptnDept) && // 수신부서가 공통이거나 현재 부서.
              item?.sendDept !== "ROOM" &&
              (item?.sendDept !== this.currentView ||
                (item?.sendDept === this.currentView && item?.insertId !== this.userId))) // 전달부서가 현재 부서는 제외.(현재 부서에서 공통으로 메모 입력한 경우를 위해)
            .map(item => item.memoId);
        const memoIdsByStay = this.filteredMemoViews
          .filter(item =>
            !item?.isTimeLine && // 타임라인은 제외.
            ["%", this.currentView].includes(item?.recptnDept) && // 수신부서가 공통이거나 현재 부서.
            item?.sendDept === "ROOM" &&
            (item?.sendDept !== this.currentView ||
              (item?.sendDept === this.currentView && item?.insertId !== this.userId))) // 전달부서가 현재 부서는 제외.(현재 부서에서 공통으로 메모 입력한 경우를 위해)
          .map(item => item.memoId);

        const newMemoFlag =
          this.currentView === "ROOM" ?
            await GolfErpAPI.reservationMemoConfirmByStayId({
              stayId: this.memoStayId,
              recptnDept: this.currentView,
              request: {
                memoIds: memoIds,
                memoIdsByStay: memoIdsByStay,
              },
            }) :
            await GolfErpAPI.reservationMemoConfirm({
              resveId: this.memoResveId,
              recptnDept: this.currentView,
              request: {
                memoIds: memoIds,
                memoIdsByStay: memoIdsByStay,
              },
            });

        if (commonCodesGetStandardInfo("memoConfirmFlag")) {
          this.$EventBus.$emit("memoConfirm", {
            newMemoFlag: newMemoFlag,
            resveId: this.memoResveId,
            stayId: this.memoStayId,
            memoViews: this.filteredMemoViews.filter(item => !item?.isTimeLine),
          });
        }
      }
    },
    focusInputMemoContents() {
      this.$refs["input-memo-contents"].focus();
    },
    getTimeLineFormat(d) {
      const date = new Date(d);
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const dt = date.getDate();
      const day = getDayOfWeekCaption(date);
      return `${y}년 ${m}월 ${dt}일 ${day}요일`;
    },
    isMe(sendDept, recptnDept, insertId) {
      if (recptnDept === this.currentView && insertId.toString() !== this.userId.toString()) {
        return false;
      } else if (sendDept === this.currentView) {
        return true;
      }
      return false;
    },
    getMessageType(data) {
      const classType = this.messageClassList.find((d) => d.comCode === data);
      return classType.comName;
    },
    getMessageTime(d) {
      const date = new Date(d);
      let hour = date.getHours();
      const minute =
        date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
      const ampm = hour > 12 ? "오후" : "오전";
      hour = hour > 12 ? hour - 12 : hour;
      return `${ampm} ${hour}:${minute}`;
    },
    async onMemoAddSelected(args) {
      if (!this.memoResveId && !this.memoStayId) {
        this.errorToast("예약 등록 후 사용가능합니다");
        return;
      }
      if (!this.memoContents || this.memoContents.trim() === "") {
        this.errorToast("메모를 입력바랍니다");
        return;
      }
      if ((this.memoResveId || this.memoStayId) && this.currentView) {
        const attrbIdx1 = commonCodesGetCommonCodeAttrbByCodeAndIdx('MEMO_DEPT', args.item.id, 1);
        const attrbIdx2 = commonCodesGetCommonCodeAttrbByCodeAndIdx('MEMO_DEPT', args.item.id, 2);
        if (attrbIdx1 && attrbIdx2) {
          await this.sendMemo(attrbIdx1);
          await this.sendMemo(attrbIdx2);
        } else {
          await this.sendMemo(args.item.id);
        }
      }
    },
    async sendMemo(id) {
      const body = {
        grpFlag: this.grpFlag,
        memoContents: this.memoContents,
        recptnDept: id,
        sendDept: this.currentView,
        resveId: this.memoResveId,
        stayId: this.memoStayId,
      };
      await saveMemo(body)
        .then(() => {
          this.refreshMemoView();
        })
        .catch((err) => {
          console.error("saveMemo.err.===>", err);
          // this.errorToast(err.response.data.data);
        });
    },
    onMemoKeypress(event) {
      if (event.code === "Enter") {
        if (!event.shiftKey && event.ctrlKey && !event.altKey) {
          this.memoContents = `${this.memoContents}\n`;
        } else if(event.shiftKey) {
          return;
        } else {
          event.preventDefault();
          if (!this.memoContents?.trim()) {
            return;
          }

          this.$refs["userMenuDropdownButton"].toggle();
        }
      }
    },
    onExtendButtonClicked() {
      if (this.isRightExtendActive) {
        this.$EventBus.$emit('reduceRightMenu');
      } else {
        this.$EventBus.$emit('extendRightMenu');
      }
    },
    onRightMemoCloseBtnClicked: function () {
      this.$EventBus.$emit("closeRightMenu");
    },
    async onMemoDelete(data) {
      if (await this.confirm("삭제하시겠습니까?")) {
        await deleteMemo(data.memoId, data.resveId, data.stayId || null)
          .then(() => {
            this.refreshMemoView();
          })
          .catch((err) => {
            console.error("deleteMemo.err.===>", err);
            this.errorToast(err.response.data.data);
          });
      }
    },
  },
};
</script>

<style scoped></style>
