import layout from "@/views/layout/layout";

const LostItemManagement = {
  path: "/lost-item-management",
  component: layout,
  redirect: "",
  children: [
    {
      path: "lost-item-registration",
      name: "LostItemRegistration",
      component: () => import("@/views/voice-of-the-customer/lost/LostItemRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
  ],
};

export default LostItemManagement;