const dialog = {
  namespaced: true,
  state: {
    activeDialog: false,
  },
  actions: {
    confirm({ getters }, { title, message }) {
      if (getters.activeDialog) {
        throw Error("Dialog is already displayed.");
      }

      return new Promise((resolve) => {
        this._vm.$EventBus.$emit("confirm", {
          title,
          message,
          callback: (result = false) => {
            resolve(result);
          },
        });
      });
    },
    alert({ getters }, { title, message }) {
      if (getters.activeDialog) {
        throw Error("Dialog is already displayed.");
      }

      return new Promise((resolve) => {
        this._vm.$EventBus.$emit("alert", {
          title,
          message,
          closeCallback: () => {
            resolve();
          },
        });
      });
    },
  },
  mutations: {
    SET_ACTIVE_DIALOG(state, activeDialog) {
      state.activeDialog = activeDialog;
    },
  },
  getters: {
    activeDialog: ({ activeDialog }) => activeDialog,
  },
};

export default dialog;
