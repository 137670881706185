export const PATH_PENALTY = `/penalty`;

export function fetchAdditionalRegistrationCheck(args) {
  return this.http.get(`${PATH_PENALTY}/additional-registration-check`, {
    params: {
      occurDate: args.occurDate,
      bsnCode: args.bsnCode,
      penltyCode: args.penltyCode,
      penltyLmttScope: args.penltyLmttScope,
      membershipId: args.membershipId,
      contactTel: args.contactTel
    }
  });
}