import qs from "qs";

export const PATH_RECEIPTCODE = `/receipt-code`;

export function fetchTsConfReceiptMsts(receiptCodes) {
  return this.http.get(`${PATH_RECEIPTCODE}/msts`, {
    params: {
      receiptCodes: receiptCodes,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

export function fetchTsConfReceiptDtls(receiptId, useFlag) {
  return this.http.get(`${PATH_RECEIPTCODE}/dtls`, {
    params: {
      receiptId: receiptId,
      useFlag: useFlag,
    },
  });
}

export function patchTsConfReceiptMsts(tsConfReceiptMsts) {
  return this.http.patch(`${PATH_RECEIPTCODE}/msts`, tsConfReceiptMsts);
}

export function patchTsConfReceiptDtls(tsConfReceiptDtls) {
  return this.http.patch(`${PATH_RECEIPTCODE}/dtls`, tsConfReceiptDtls);
}
