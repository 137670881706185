import qs from "qs";

export const PATH_SMS = `/sms`;

/**
 * @typedef NewSMSRequest
 * @type Object
 * @param {string} type - 전송 타입
 * @param {boolean} kakaoFlag - 카카오 전송여부
 * @param {string} kakaoKind - 카카오 전송 종류(알림톡[AT], 친구톡[FT])
 * @param {string} kakaoDispatchKey - 카카오 발신 프로필 key
 * @param {string} kakaoTemplateKey - 카카오 템플릿 key
 * @param {string} kind - 전송 종류 * fixme 사용안함 추후 삭제 필요
 * @param {string} div - 전송 구분 (회원 발송 또는 예약 발송)  ("MEMBER" / "RESERVATION")
 * @param {string} key - 전송 key(회원 번호등)
 * @param {boolean} reservedFlag - 예약 전송 여부
 * @param {string} reserveDateTime - 예약 전송 일시
 * @param {string} receiverName - 수신자 명
 * @param {string} receiverNumber - 수신번호
 * @param {string} subject - 제목
 * @param {string} message - 발신 메시지
 * @param {object} replaceDataMap - 발신 메시지 치환 데이터 리스트
 * @param {string} dispatchNumber - 발신 번호
 */

/**
 *
 * @param {NewSMSRequest[]} newSMSRequestList
 */
export function sendSMS(newSMSRequestList) {
  return this.http.post(`${PATH_SMS}`, newSMSRequestList);
}

export function sendSmsByReservationBatch(resveNoList) {
  return this.http.post(`${PATH_SMS}/send/reservation-batch`, resveNoList);
}

export function sendSMSByCouponPubStatus(newSMSRequestList) {
  return this.http.post(`/member_management/coupon/sms`, newSMSRequestList);
}

export function sendSMSByDeepSearchMembers(newSMSRequestList) {
  return this.http.post(`/deep-search-members/send-sms`, newSMSRequestList);
}

export function fetchSmsWords() {
  return this.http.get(`${PATH_SMS}/words`);
}

export function fetchAllSmsWords() {
  return this.http.get(`${PATH_SMS}/words/all`);
}

export function fetchSmsSendInfos(args) {
  return this.http.get(`${PATH_SMS}/sms-send-status`, {
    params: {
      sendDateFrom: args.sendDateFrom,
      sendDateTo: args.sendDateTo,
      rcverInfo: args.rcverInfo,
      messageContents: args.messageContents,
      smsSenderList: args.smsSenderList,
      smsKindList: args.smsKindList,
      sendFailViewFlag: args.sendFailViewFlag,
      smsSendTypeList: args.smsSendTypeList
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

export function fetchSelfCheckInUrl(args) {
  return this.http.get(`${PATH_SMS}/self-check-in-url`, {
    params: {
      resveId: args.resveId,
      name: args.name,
      contactTel: args.contactTel
    }
  });
}

export function fetchSelfCheckOutUrl(args) {
  return this.http.get(`${PATH_SMS}/self-check-out-url`, {
    params: {
      resveId: args.resveId,
      chkinId: args.chkinId,
      visitId: args.visitId,
    }
  });
}
