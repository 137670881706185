export const PATH_NOW = `/now`;
export const PATH_WORK_PLACES = `/work-places`;
export const PATH_BUSINESS_PARTNERS = `/business-partners`;
export const PATH_BUSINESS_PLACES = `/business-places`;
export const PATH_PRODUCT_CLASSES = `/product-classes`;
export const PATH_COMMON = `/common`;
export const PATH_SYSTEM_VERSION = `/version`;
export const PATH_SYSTEM_CONFIG = `/system-config`;
export const PATH_SYSTEM_CONFIG_COURSE_CLASS = `${PATH_SYSTEM_CONFIG}/courseClass`;
export const PATH_FIND_PASSWORD = `/find-password`;

export function fetchNow() {
  return this.http.get(PATH_NOW);
}

export function fetchBusinessPartners(q, id) {
  return this.http.get(PATH_BUSINESS_PARTNERS, {
    params: {
      q,
      id,
    },
  });
}

export function fetchBusinessPlaces() {
  return this.http.get(PATH_BUSINESS_PLACES);
}

export function fetchWorkPlaces() {
  return this.http.get(PATH_WORK_PLACES);
}

export function fetchProductClasses(depth, parentCode) {
  return this.http.get(PATH_PRODUCT_CLASSES, {
    params: {
      depth,
      parentCode,
    },
  });
}

export function fetchPartnerCertification(args) {
  return this.http.get(`${PATH_COMMON}/partner-certification`, {
    params: {
      div: args.div,
      memberName: args.memberName,
      birthDay: args.birthDay,
      businessNumber: args.businessNumber,
    },
  });
}

export function fetchSystemConfigCourseClass() {
  return this.http.get(`${PATH_SYSTEM_CONFIG_COURSE_CLASS}`);
}

export function fetchClientMonitorImages(imagePattern) {
  return this.http.get(`${PATH_COMMON}/client-monitor-images`, {
    params: {
      imagePattern,
    },
  });
}

export function fetchSystemVersion() {
  return this.http.get(`${PATH_SYSTEM_VERSION}`, {
    isBackground: true
  });
}

export function postMenuUserMark(menuId) {
  return this.http.post(`${PATH_COMMON}/menu-user-mask/${menuId}`);
}

export function deleteMenuUserMark(menuId) {
  return this.http.delete(`${PATH_COMMON}/menu-user-mask/${menuId}`);
}

export function putImageUpload(request) {
  return this.http.put(`${PATH_COMMON}/image-upload`, request);
}

export function patchImageUploadSortNo(request) {
  return this.http.patch(`${PATH_COMMON}/image-upload/sort-no`, request);
}

export function deleteImage(imageId, imagePattern) {
  return this.http.delete(`${PATH_COMMON}/image-delete`, {
    params: {
      imageId,
      imagePattern,
    },
  });
}

export function findPassword({email, name}) {
  return this.http.post(`${PATH_FIND_PASSWORD}`, {email, name});
}
