import InputNumber from "@/components/common/InputNumber";
import InputNumberUnusual from "@/components/common/InputNumberUnusual";
import Vue from "vue";
import gridTemplateDestroyMixin from "../../../mixins/gridTemplateDestroyMixin";

export default function gridInputNumberTemplate(
  field,
  options = {
    allowDot: false,
    allowMinus: true,
    align: "right",
    useCommonStyles: true,
  }
) {
  return {
    template: Vue.component("gridInputNumberTemplate", {
      mixins: [gridTemplateDestroyMixin],
      components: { InputNumber, InputNumberUnusual },
      template: options.useCommonStyles
        ? `<input-number
        v-model="data[field]"
        style='background: transparent; width: 100%'
        :id="field"
        :allow-dot="allowDot"
        :allow-minus="allowMinus"
        :name="field"
        :prop-max-length="options.maxLength"
        :prop-max-under-dot-length="options.maxUnderDotLength"
        :prop-used-in-grid="true"
        :align="options.align"
      />`
        : `<input-number-unusual
        v-model="data[field]"
        :id="field"
        :allow-dot="allowDot"
        :allow-minus="allowMinus"
        :name="field"
        :prop-max-length="options.maxLength"
        :prop-max-under-dot-length="options.maxUnderDotLength"
        :align="options.align"
      />`,
      data() {
        return {
          field: field,
          options: options,
          allowDot: options.allowDot || false,
          allowMinus: options.allowMinus || true,
          data: {
            data: {},
          },
        };
      },
    }),
  };
}
