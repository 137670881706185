import layout from "@/views/layout/layout";

const managementRoute = {
  path: "/management",
  component: layout,
  redirect: "",
  children: [
    {
      path: "monthly-reservation-status-by-member-type",
      name: "MonthlyReservationStatusByMemberType",
      component: () => import("@/views/management/MonthlyReservationStatusByMemberType"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "monthly-reservation-status-by-channel",
      name: "MonthlyReservationStatusByChannel",
      component: () => import("@/views/management/MonthlyReservationStatusByChannel"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "monthly-sales-and-unit-price",
      name: "MonthlySalesAndUnitPrice",
      component: () => import("@/views/management/MonthlySalesAndUnitPrice"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "visitor-status-statistics",
      name: "VisitorStatusStatistics",
      component: () => import("@/views/management/VisitorStatusStatistics"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "monthly-sales-status-by-member-type",
      name: "MonthlySalesStatusByMemberType",
      component: () => import("@/views/management/MonthlySalesStatusByMemberType"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "total-monthly-sales",
      name: "TotalMonthlySales",
      component: () => import("@/views/management/TotalMonthlySales"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "monthly-year-over-year-sales",
      name: "MonthlyYearOverYearSales",
      component: () => import("@/views/management/MonthlyYearOverYearSales"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "weekly-meeting-report",
      name: "WeeklyMeetingReport",
      component: () => import("@/views/management/WeeklyMeetingReport"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "sales-plan-std",
      name: "SalesPlanStd",
      component: () => import("@/views/management/SalesPlanStd"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "sales-plan",
      name: "SalesPlan",
      component: () => import("@/views/management/SalesPlan"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "sales-plan-result",
      name: "SalesPlanResult",
      component: () => import("@/views/management/SalesPlanResult"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "monthly-sales-increas-status",
      name: "MonthlySalesIncreasStatus",
      component: () => import("@/views/management/MonthlySalesIncreasStatus"),
      meta: {
        useUsualStyles: true,
      },
    },

    {
      path: "monthly-visitor-status",
      name: "MonthlyVisitorStatus",
      component: () => import("@/views/management/MonthlyVisitorStatus"),
      meta: {
        useUsualStyles: true,
      },
    },
  ],
};

export default managementRoute;