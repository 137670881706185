export const PATH_CALENDER = `/calender`;

export function fetchCalenderInfo(bsnDate, isBackground = false) {
  return this.http.get(`${PATH_CALENDER}/info`, {
    isBackground: isBackground,
    params: {
      bsnDate: bsnDate,
    },
  });
}

export function fetchCalenderInfos(
  bsnDateFrom,
  bsnDateTo,
  isBackground = false
) {
  return this.http.get(`${PATH_CALENDER}/infos`, {
    isBackground: isBackground,
    params: {
      bsnDateFrom: bsnDateFrom,
      bsnDateTo: bsnDateTo,
    },
  });
}

export function fetchReservationCalendar(args) {
  return this.http.get(`${PATH_CALENDER}/reservation-calendar`, {
    params: {
      year: args.year,
      month: args.month,
      div: args.div,
      isAditTeam: args.isAditTeam,
    },
  });
}

export function fetchReservationCalendarWeathers(args) {
  return this.http.get(`${PATH_CALENDER}/reservation-calendar/weathers`, {
    params: {
      year: args.year,
      month: args.month,
    },
  });
}
