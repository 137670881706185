import { Grid } from "@syncfusion/ej2-grids";
import _ from "lodash";
import { numberWithCommas } from "@/utils/number";
import { phoneNumberFormatter } from "@/utils/formatter";

export function gridUtilGetTelColumnAccess(field, data) {
  return phoneNumberFormatter(data[field]);
}

export function gridUtilGetMemberNoColumnAccess(field, data) {
  let memberNo = data[field];
  if (memberNo) {
    memberNo =
      memberNo.substring(0, 2) +
      "-" +
      memberNo.substring(2, 6) +
      "-" +
      memberNo.substring(6);
  } else {
    memberNo = "";
  }
  return memberNo;
}

export function gridUtilIsDuplicatedPkExists(records, pkPropertyName) {
  const groupByObject = records.reduce((accumulator, currentValue) => {
    const pkPropertyValue = currentValue[pkPropertyName];
    if (!Object.prototype.hasOwnProperty.call(accumulator, pkPropertyValue)) {
      accumulator[pkPropertyValue] = 0;
    }
    accumulator[pkPropertyValue]++;

    if (accumulator[pkPropertyValue] > 1) {
      accumulator.isDuplicatedPK = true;
    }
    return accumulator;
  }, {});

  if (groupByObject.isDuplicatedPK) {
    return true;
  } else {
    return false;
  }
}

/**
 * 원본 데이터와 신규 데이터를 비교하여 추가된/변경된/삭제된 데이터를 판별하여 return
 * @param originalDataArray 원본 데이터
 * @param newDataArray 신규 데이터
 * @param pks primary key 컬럼들
 */
export function getAddedChangedDeletedData(
  originalDataArray,
  newDataArray,
  pks
) {
  let returnObject = {
    addedRecords: [],
    changedRecords: [],
    deletedRecords: [],
  };

  // 추가, 수정된 데이터 설정
  newDataArray.forEach((newDataItem) => {
    let findedOriginalDataItem = originalDataArray.find((originalDataItem) => {
      for (let pkIndex = 0; pkIndex < pks.length; pkIndex++) {
        if (newDataItem[pks[pkIndex]] !== originalDataItem[pks[pkIndex]]) {
          return false;
        }
      }
      return true;
    });

    if (!findedOriginalDataItem) {
      returnObject.addedRecords.push(JSON.parse(JSON.stringify(newDataItem)));
    } else if (
      JSON.stringify(findedOriginalDataItem) !== JSON.stringify(newDataItem)
    ) {
      returnObject.changedRecords.push(JSON.parse(JSON.stringify(newDataItem)));
    }
  });

  // 삭제 데이터 설정
  originalDataArray.forEach((originalDataItem) => {
    let findedNewDataItem = newDataArray.find((newDataItem) => {
      for (let pkIndex = 0; pkIndex < pks.length; pkIndex++) {
        if (newDataItem[pks[pkIndex]] !== originalDataItem[pks[pkIndex]]) {
          return false;
        }
      }
      return true;
    });

    if (!findedNewDataItem) {
      returnObject.deletedRecords.push(
        JSON.parse(JSON.stringify(originalDataItem))
      );
    }
  });

  return returnObject;
}

/**
 * batch 모드의 그리드에서, 현재 그리드에 설정되어 있는 데이터 목록을 가져옴 - Deprecated. getBatchCurrentViewRecords 를 대신 사용
 * Ordering 순서에 맞게 가져옴
 * 추가 record는 그리드 최 하단에 붙는다고 가정함
 * @param gridElement 그리드 Object
 * @param gridIdKeyName 그리드 Primary key 이름
 * @returns data array
 */
export function getGridCurrentList(gridElement, gridIdKeyName) {
  const changedObj = gridElement.ej2Instances.editModule.getBatchChanges();
  const addedRecords = changedObj.addedRecords.slice(0);
  const changedRecords = changedObj.changedRecords.slice(0);
  const deletedRecords = changedObj.deletedRecords.slice(0);

  // 현재 그리드의 데이터 원본 가져옴
  let returnDataList = gridElement.getCurrentViewRecords().slice(0);

  // 삭제된 데이터 제외
  if (deletedRecords.length > 0) {
    deletedRecords.forEach((deletedRecord) => {
      let deletedIndex = returnDataList.findIndex(
        (record) => record[gridIdKeyName] === deletedRecord[gridIdKeyName]
      );
      if (deletedIndex !== -1) {
        returnDataList.splice(deletedIndex, 1);
      }
    });
  }

  // 수정된 데이터 대체
  if (changedRecords.length > 0) {
    changedRecords.forEach((changedRecord) => {
      let changedIndex = returnDataList.findIndex(
        (record) => record[gridIdKeyName] === changedRecord[gridIdKeyName]
      );
      if (changedIndex !== -1) {
        returnDataList.splice(changedIndex, 1, changedRecord);
      }
    });
  }

  // 추가된 목록 추가
  returnDataList = returnDataList.concat(addedRecords.slice(0));

  return returnDataList;
}

/**
 * 이 함수는 Batch mode 의 Syncfusion Grid Component 에 대하여 수정된 상태의 데이터를 추출한다.
 * @param gridRef - Vue reference of Syncfusion grid
 * @param identity - identity of records
 * @returns {*[]}
 */
export function getBatchCurrentViewRecords(gridRef, identity) {
  // check gridRef type
  if (!gridRef || !(gridRef.ej2Instances instanceof Grid)) {
    throw new Error("'gridRef' is not grid.");
  }

  // check editable and mode is Batch
  if (!gridRef.editSettings || gridRef.editSettings.mode !== "Batch") {
    throw new Error("'gridRef' edit mode is not 'Batch'.");
  }

  // check valid identity
  if (!identity) {
    throw new Error("'identity' is undefined.");
  } else if (Array.isArray(identity)) {
    if (identity.length === 0) {
      throw new Error("'identity' is empty array.");
    }

    if (0 < identity.filter((col) => typeof col !== "string").length) {
      throw new Error("'identity' must contains only 'string' elements.");
    }
  } else if (typeof identity !== "string") {
    throw new Error(
      `'identity' type must be 'string' or 'Array'. Your type: ${typeof identity}`
    );
  }

  const _identity = Array.isArray(identity) ? identity : [identity];

  const {
    addedRecords,
    changedRecords,
    deletedRecords,
  } = gridRef.ej2Instances.getBatchChanges();
  const originRecords = gridRef.ej2Instances.getCurrentViewRecords();

  // There are no changes
  if (
    (!addedRecords || addedRecords.length === 0) &&
    (!changedRecords || changedRecords.length === 0) &&
    (!deletedRecords || deletedRecords.length === 0)
  ) {
    return JSON.parse(JSON.stringify(originRecords));
  }

  // A(origin - deleted)
  const A =
    deletedRecords.length === 0
      ? originRecords
      : _.differenceWith(originRecords, deletedRecords, equals);

  // B: A, updated as changed
  const B = A.map(
    (a) => changedRecords.find((changedRecord) => equals(changedRecord, a)) || a
  );

  // return (B + addedRecords) or (addedRecords + B)
  return gridRef.editSettings.newRowPosition === "Bottom"
    ? B.concat(addedRecords)
    : addedRecords.concat(B);

  // equals of record
  function equals(r1, r2) {
    return _identity
      .map((col) => r1[col] === r2[col])
      .reduce((accumulator, currentValue) => accumulator && currentValue, true);
  }
}

/**
 * 그리드의 primary key 값을 가지고, 그리드 현재 데이터 중에 조회한 데이터를 return
 * 추가/변경된 데이터를 조회하기 위하여 추가/변경된 데이터로부터 찾고, 없으면 datasource로부터 찾는다 -> getGridCurrentList 를 이용해서 가져온다
 * 그리드가 'Batch' 모드라고 가정한다
 * getGridCurrentList 가 그리드 정렬 순서와 똑같이 출력되므로, 사용되지 않을 가능성이 높음
 *
 * @param gridElement 그리드 Object
 * @param gridId 그리드 Primary key 값
 * @param gridIdKeyName 그리드 Primary key 이름
 * @returns 그리드 현재 데이터 중, 해당 Primary key 값에 해당하는 데이터 Object
 */
export function getCurrentDataByGridId(gridElement, gridId, gridIdKeyName) {
  const dataList = getGridCurrentList(gridElement, gridIdKeyName);
  return dataList.find((record) => record[gridIdKeyName] === gridId);
  /*
  const changedObj = gridElement.ej2Instances.editModule.getBatchChanges()
  let retObject

  // 추가, 수정된 목록에서 찾고 없으면 datasource에서 찾는다
  let addedAndModifiedRecord = changedObj.addedRecords.slice(0).concat(changedObj.changedRecords.slice(0))
  retObject = addedAndModifiedRecord.find((record) => record[gridIdKeyName] === gridId)

  if (retObject === undefined) {
    retObject = gridDataSource.find((record) => record[gridIdKeyName] === gridId)
  }
  return retObject
  */
}

/**
 * 그리드의 primary key 값을 가지고, 그리드 삭제 데이터 중에 조회한 데이터를 return
 * @param gridElement 그리드 Object
 * @param gridId 그리드 Primary key 값
 * @param gridIdKeyName gridIdKeyName 그리드 Primary key 이름
 * @returns element object
 */
export function getDeleteDataByGridId(gridElement, gridId, gridIdKeyName) {
  const changedObj = gridElement.ej2Instances.editModule.getBatchChanges();
  const deletedRecords = changedObj.deletedRecords.slice(0);

  return deletedRecords.find((record) => record[gridIdKeyName] === gridId);
}

/**
 * Grid HTML Element를 통해, 특정 index의 컬럼 값을 가져옴
 * HTML 값을 가져오는 것이므로, combobox나 checkbox 등의 값은 가져올 수 없음
 * 또한 편집중인 cell은 innerHTML이 바뀌므로, 신중하게 가져와야 함
 *
 * @param gridElement 그리드 Object
 * @param rowIndex 그리드 row index
 * @param columnIndex 그리드 column index
 * @param isNumber 숫자 값으면 true
 * @returns 그리드 column 값
 */
export function getColumnValueOfGridElement(
  gridElement,
  rowIndex,
  columnIndex,
  isNumber
) {
  let dataRows = gridElement.getDataRows();
  let columnValue = dataRows[rowIndex].cells[columnIndex].innerHTML;

  if (isNumber) {
    columnValue = parseInt(columnValue);
  }
  return columnValue;
}

/**
 * 그리드 레코드 추가 시, 추가할 레코드의 Grid No 값을 Grid HTML Element를 통해 가져옴
 *
 * @param gridDataRows GridObject.getDataRows() 를 통해 가져온 Grid row html element array
 * @param columnIndex Grid PK column index
 * @returns 추가할 Grid No 값
 */
export function getGridNextNoOfGridElement(gridDataRows, columnIndex) {
  return parseInt(
    gridDataRows[gridDataRows.length - 1].cells[columnIndex].children[0]
      .innerHTML
  );
}

/**
 * 그리드 레코드 추가 시, 추가할 레코드의 Grid PK 값을 Grid HTML Element를 통해 가져옴
 * Grid PK 값이 편집 가능할 경우에는 조심해서 사용해야 함
 *
 * @param gridDataRows GridObject.getDataRows() 를 통해 가져온 Grid row html element array
 * @param columnIndex Grid PK column index
 * @returns 추가할 Grid PK 값
 */
export function getGridNextPKOfGridElement(gridDataRows, columnIndex) {
  return (
    parseInt(
      gridDataRows[gridDataRows.length - 1].cells[columnIndex].innerHTML
    ) + 1
  );
}

/**
 * 그리드 one-click editing 기능 모듈 추가. 그리드의 load 이벤트 핸들러에 추가하면 됨
 *
 * @param gridInstance 그리드 Object ej2instance
 * @param eventParam load 이벤트 파라미터
 */
export function setGridOneClickEdit(gridInstance, eventParam) {
  if (eventParam.target.classList.contains("e-rowcell")) {
    let index = gridInstance
      .getDataRows()
      .findIndex((dataRow) => dataRow === eventParam.target.parentElement);
    let colindex = parseInt(eventParam.target.getAttribute("aria-colindex"));
    let field = gridInstance.getColumns()[colindex].field;
    gridInstance.editModule.editCell(index, field);
  }
}

export function setGridUneditKeydownProcess(
  gridInstance,
  eventParam,
  currentEditingCellIndex
) {
  if (eventParam.key === "F4") {
    let currentSelectedRowCell = gridInstance.getSelectedRowCellIndexes();

    if (currentSelectedRowCell.length === 0 && currentEditingCellIndex !== -1) {
      // TODO : editing 모드인지 확인 필요
      let currentSelectedRow = gridInstance.getSelectedRowIndexes();
      currentSelectedRowCell = [
        {
          rowIndex: currentSelectedRow[0],
          cellIndexes: [currentEditingCellIndex],
        },
      ];
      gridInstance.saveCell();
      gridInstance.selectCells(currentSelectedRowCell);
    }
  }
}

/**
 * 프론트에서 사용하는 요금할인 설정을 위한 함수
 * @param dcInfo 요금할인 Object
 */
export function calculateApplyAmt(dcInfo, dcList) {
  // 요금코드가 없으면, 하나씩 요금코드 계산
  let applyAmt = dcInfo.saleAmt;
  dcList.forEach((dcRuleToSetting, dcIdx) => {
    switch (dcRuleToSetting.dcMethod) {
      case "AMT": // 금액할인
        applyAmt = applyAmt - dcRuleToSetting.dcAmt;
        break;
      case "RATE": // 할인율
        applyAmt = Math.round((applyAmt * (100 - dcRuleToSetting.dcAmt)) / 100);
        break;
      case "PAYMT": // 요금 코드 - applyAmt 가 그대로 적용 요금이 됨
        if (dcInfo?.paymtCode === dcRuleToSetting?.paymtCode) {
          applyAmt = dcInfo?.saleAmt || 0;
        } else {
          applyAmt = dcRuleToSetting.applyAmt;
        }
        break;
    }
    dcRuleToSetting.applyAmt = applyAmt;
    dcRuleToSetting._rid = dcIdx + 1;
  });

  // totAmt는 마지막 dcList의 applyAmt
  if (dcList.length === 0) {
    dcInfo.totAmt = dcInfo.saleAmt;
  } else if (dcList.length > 0) {
    dcInfo.totAmt = dcList[dcList.length - 1].applyAmt;
  }
}

/**
 * 그리드 수정 여부 판단
 * @param gridInstance
 * @returns {boolean}
 */
export function isGridModified(gridInstance) {
  const batchChanges = gridInstance.getBatchChanges();
  if (
    batchChanges.addedRecords.length > 0 ||
    batchChanges.changedRecords.length > 0 ||
    batchChanges.deletedRecords.length > 0
  ) {
    return true;
  }
  return false;
}

function inputNumberWithComma(str) {
  str = String(str);
  const strArr = str.split(".");
  if (strArr.length > 1) {
    //소숫점 형식은 소숫점으로 나눠서 정수 부분만 Comma 입력
    const firstNum = strArr[0].replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    return firstNum + "." + strArr[1];
  } else {
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
  }
}

function inputNumberRemoveComma(str) {
  str = String(str);
  const hasMinus = str.startsWith("-");
  const strArr = str.split(".");
  let result = 0;
  if (strArr.length > 1) {
    //소숫점 형식은 소숫점으로 나눠서 Comma 삭제 후 소수 부분은 자릿수대로 자르기
    const firstNum = strArr[0].replace(/[^\d]+/g, "");
    const secondNum = strArr[1].replace(/[^\d]+/g, "");
    result = firstNum + "." + secondNum;
    result = hasMinus ? "-" + result : result;
    result = Number.parseFloat(result);
  } else {
    result = str.replace(/[^\d]+/g, "");
    result = hasMinus ? "-" + result : result;
    result = Number.parseInt(result);
  }
  if (isNaN(result)) {
    return 0;
  } else {
    return result;
  }
}

export function isSameNumeric(args) {
  if (
    args.columnObject.isNumericType &&
    inputNumberRemoveComma(args.value) ===
      inputNumberRemoveComma(args.previousValue)
  ) {
    return true;
  }
  return false;
}

export function inputNumberValueAccessor(field, data, column) {
  if (column.type === "number") {
    // isNumeric = true 인 컬럼 중에 type을 number로 설정한 경우. TODO : 버그 없으면 이 부분만 남긴다
    const commaRemovedValue = inputNumberRemoveComma(data[field]);
    if (column.inputNumberProperty && column.inputNumberProperty.unit) {
      return (
        inputNumberWithComma(commaRemovedValue) +
        " " +
        column.inputNumberProperty.unit
      );
    } else {
      return data[field] === 0 && !column.allowEditing
        ? "-"
        : inputNumberWithComma(data[field]);
      // return inputNumberWithComma(commaRemovedValue);
    }
  } else {
    // isNumeric = true 인 컬럼 중에 type을 string으로 설정한 경우. 버그 없으면 정리 예정
    data[field] = inputNumberRemoveComma(data[field]);
    if (column.inputNumberProperty && column.inputNumberProperty.unit) {
      return (
        inputNumberWithComma(data[field]) +
        " " +
        column.inputNumberProperty.unit
      );
    } else {
      return data[field] === 0 && !column.allowEditing
        ? "-"
        : inputNumberWithComma(data[field]);
    }
  }
}

export function companyRegistrationNumberAccess(field, data) {
  let registrationNumber = data[field];
  if (registrationNumber.length === 10) {
    return `${registrationNumber.substring(
      0,
      3
    )}-${registrationNumber.substring(3, 5)}-${registrationNumber.substring(
      5,
      10
    )}`;
  } else {
    return registrationNumber;
  }
}

export function reservationCourseGridSectionBodyClassName(
  courseList,
  baseClassName
) {
  let className = null;
  if (courseList) {
    const courseLength = Array.isArray(courseList) ? courseList.length : 0;
    if (courseLength >= 1 && courseLength <= courseList.length) {
      className = baseClassName + courseLength;
    }
  }
  return className;
}

export function getColumnValue(row, col) {
  let data = row[col.field];

  if (col.dataSource) {
    data = col.dataSource?.find(
      (source) => source[col.foreignKeyField] === row[col.field]
    )?.[col.foreignKeyValue];
  } else if (col.valueAccessor) {
    data = col.valueAccessor(col.field, row, col);
  }

  return col["isNumericType"] !== true ? data : numberWithCommas(data);
}
