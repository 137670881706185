import Vue from "vue";
import gridTemplateDestroyMixin from "../../../mixins/gridTemplateDestroyMixin";

const formatToReplace = "${value}";

export default function gridAggregationCaptionTemplate(templateStyle) {
  return {
    template: Vue.component("gridAggregationCaptionTemplate", {
      mixins: [gridTemplateDestroyMixin],
      template: `<span style="line-height:24px; vertical-align:middle;" :style="{ color : templateStyle && templateStyle.textColor ? templateStyle.textColor : null, textAlign : templateStyle && templateStyle.textAlign ? templateStyle.textAlign : null }" v-html="getDataCustom()"></span>`, // 폰트굵기 bold css지정 필요
      data() {
        return {
          data: {},
          templateStyle: templateStyle,
        };
      },
      methods: {
        getDataCustom() {
          let returnValue = this.data?.Custom
            ? this.data.Custom.toLocaleString()
            : "-";

          if (this.templateStyle.stringFormat) {
            returnValue = this.templateStyle.stringFormat.replace(
              formatToReplace,
              returnValue
            );
          }

          // bold 처리
          return `<b>${returnValue}</b>`;
        },
      },
    }),
  };
}
