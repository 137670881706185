import request from "@/utils/request";
const qs = require("qs");

/**
 * @return Promise
 */
export function getBlacklist(params) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/blacklist_management/black_info",
    method: "get",
    params: params,
  });
}

/**
 * @return Promise
 */
export function deleteBlacklist(penltyIds) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/blacklist_management/black_info",
    method: "delete",
    params: {
      penltyId: penltyIds,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

/**
 * @return Promise
 */
export function getBlackBsnCode(resveDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/blacklist_management/black_week",
    method: "get",
    params: {
      resveDate: resveDate,
    },
  });
}

/**
 * @return Promise
 */
export function getBlackEnddate(params) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/blacklist_management/black_endate",
    method: "get",
    params: params,
  });
}

/**
 * @return Promise
 */
export function postBlackinfo(data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/blacklist_management/black_info",
    method: "post",
    data: data,
  });
}

/**
 * @return Promise
 */
export function putBlackinfo(data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/blacklist_management/black_info",
    method: "put",
    data: data,
  });
}
