import { groupBy as _groupBy } from "lodash";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import moment from "moment";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import GolfERPService from "@/service/GolfERPService";

export const MODES = {
  EMPTY: "EMPTY",
  CREATE: "CREATE",
  MODIFY: "MODIFY",
};

export const SLIP_STATUS = {
  SETTLEMENT_COMPLETE_PRE_PAY: "SETTLEMENT_COMPLETE_PRE_PAY",
  SETTLEMENT_COMPLETE_POST_PAY: "SETTLEMENT_COMPLETE_POST_PAY",
  SETTLEMENT_COMPLETE_NA: "SETTLEMENT_COMPLETE_NA",
  SETTLEMENT_INCOMPLETE_GROUP: "SETTLEMENT_INCOMPLETE_GROUP",
  SETTLEMENT_INCOMPLETE_TEAM: "SETTLEMENT_INCOMPLETE_TEAM",
  SETTLEMENT_INCOMPLETE_NA: "SETTLEMENT_INCOMPLETE_NA",
  SETTLEMENT_DELETED_NA: "SETTLEMENT_DELETED_NA",
};

const SLIP_PAYMENT_DIVISION = {
  PRE: "0",
  POST: "1",
  PENDING: "2",
};

function getDividePaymentDivisionOfSlip({ sales }) {
  const comCodesOfDivideDiv = _groupBy(
    commonCodesGetCommonCode("DIVIDE_DIV"),
    "comCode"
  );

  let maxSortNo = 0;
  let maxDividePaymentDivision = "NA";
  sales.forEach(({ dividePaymentDivision = "N" }) => {
    const weight = comCodesOfDivideDiv[dividePaymentDivision]?.sortNo || 0;

    if (maxSortNo < weight) {
      maxSortNo = weight;
      maxDividePaymentDivision = dividePaymentDivision;
    }
  });

  switch (maxDividePaymentDivision) {
    case "G":
      return SLIP_STATUS.SETTLEMENT_INCOMPLETE_GROUP;
    case "T":
      return SLIP_STATUS.SETTLEMENT_INCOMPLETE_TEAM;
    case "N":
    default:
      return SLIP_STATUS.SETTLEMENT_INCOMPLETE_NA;
  }
}

export function getSlipStatus(slip) {
  if (!slip) {
    return SLIP_STATUS.SETTLEMENT_INCOMPLETE_NA;
  }

  const { isDeleted, isPaid, payPreOrFutureDivision, sales } = slip;
  if (isDeleted) {
    return SLIP_STATUS.SETTLEMENT_DELETED_NA;
  }

  if (isPaid) {
    switch (payPreOrFutureDivision) {
      case SLIP_PAYMENT_DIVISION.PRE:
        return SLIP_STATUS.SETTLEMENT_COMPLETE_PRE_PAY;
      case SLIP_PAYMENT_DIVISION.POST:
        return SLIP_STATUS.SETTLEMENT_COMPLETE_POST_PAY;
      default:
        return SLIP_STATUS.SETTLEMENT_COMPLETE_NA;
    }
  } else {
    if (!sales || sales.length === 0) {
      return SLIP_STATUS.SETTLEMENT_INCOMPLETE_NA;
    }

    return getDividePaymentDivisionOfSlip(slip);
  }
}

export function availableToUpdate(slip) {
  const slipStatus = getSlipStatus(slip);

  return (
    slipStatus === SLIP_STATUS.SETTLEMENT_INCOMPLETE_GROUP ||
    slipStatus === SLIP_STATUS.SETTLEMENT_INCOMPLETE_TEAM ||
    slipStatus === SLIP_STATUS.SETTLEMENT_INCOMPLETE_NA
  );
}

const getDefaultState = () => {
  return {
    isLoading: false,
    mode: MODES.EMPTY,
    salesDate: new Date(),
    store: null,
    slip: null,

    visibleConfirmDialog: false,
  };
};

const pos = {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getStoreSalesAmount({ getters, commit }) {
      if (getters.store && getters.salesDate) {
        commit("setStoreSalesAmount", {
          storeSalesAmount: await GolfErpAPI.fetchStoreSalesAmount(
            getters.store.code,
            moment(getters.salesDate).format(DATE_FORMAT_YYYY_MM_DD)
          ),
        });
      }
    },
    async changeSalesDate({ getters, commit }, { salesDate }) {
      commit("setSalesDate", { salesDate });
      if (getters.store) {
        commit("setStoreSalesAmount", {
          storeSalesAmount: await GolfErpAPI.fetchStoreSalesAmount(
            getters.store.code,
            moment(salesDate).format(DATE_FORMAT_YYYY_MM_DD)
          ),
        });
      }
    },
    async changeStore({ getters, commit }, { store }) {
      if (!store.code) {
        store.salesAmount = 0;
      } else {
        store.name = commonCodesGetComName("STORE_CODE", store.code);
        store.salesAmount = await GolfErpAPI.fetchStoreSalesAmount(
          store.code,
          moment(getters.salesDate).format(DATE_FORMAT_YYYY_MM_DD)
        );
      }

      commit("setStore", { store });
    },
    async changeSlipStoreTable({ getters, commit }, { storeTable }) {
      if (getters.mode === MODES.CREATE) {
        commit("setSlipStoreTable", { storeTable });
      } else if (getters.mode === MODES.MODIFY) {
        commit("setSlipStoreTable", {
          storeTable: await GolfERPService.updateSalesSlipStoreTable(
            getters.slip.id,
            getters.store.code,
            moment(getters.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
            storeTable.id
          ),
        });
      }
    },
    async intoCreateSlipMode({ getters, commit }, { storeTable } = {}) {
      const slipNumber = await GolfERPService.fetchNextSlipNo(
        moment(getters.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
        getters.store.code
      );

      const slip = {
        number: slipNumber,
        domesticCustomersCount: 1,
      };

      if (storeTable) {
        slip.storeTableId = storeTable.id;
        slip.storeTableUses = [{ isUse: true, storeTable }];
      }

      commit("setSlip", { slip });
      commit("setMode", { mode: MODES.CREATE });
    },
    async changeSlipDomesticCustomersCount(
      { getters, commit },
      { domesticCustomersCount }
    ) {
      if (getters.mode === MODES.CREATE) {
        commit("setDomesticCustomersCount", { domesticCustomersCount });
      } else if (getters.mode === MODES.MODIFY) {
        await GolfERPService.updateSalesSlipVisitorsCount(
          getters.slip.id,
          domesticCustomersCount
        );
        commit("setDomesticCustomersCount", { domesticCustomersCount });
      }
    },
    async changeVisitor({ getters, commit }, { visitor, bsnDate }) {
      if (getters.mode === MODES.CREATE) {
        commit("setVisitor", { visitor });
      } else if (getters.mode === MODES.MODIFY) {
        await GolfERPService.updateSalesSlipVisitor(
          getters.slip.id,
          visitor.id,
          bsnDate
        );
        commit("setVisitor", { visitor });
      }
    },
    async removeVisitor({ getters, commit }) {
      if (getters.mode === MODES.CREATE) {
        commit("setVisitor", { visitor: null });
      } else if (getters.mode === MODES.MODIFY) {
        await GolfERPService.updateSalesSlipVisitor(
          getters.slip.id,
          0,
          getters.slip.date
        );
        commit("setVisitor", { visitor: null });
      }
    },
  },
  mutations: {
    setSalesDate(state, { salesDate }) {
      state.salesDate = salesDate;
    },
    setStore(state, { store }) {
      state.store = store;
    },
    setStoreSalesAmount(state, { storeSalesAmount }) {
      state.store.salesAmount = storeSalesAmount;
    },
    setSlip(state, { slip }) {
      state.slip = slip;
    },
    setMode(state, { mode }) {
      state.mode = mode;
    },
    enterSlip(state, { slip }) {
      state.slip = slip;
      state.mode = MODES.MODIFY;
    },
    clear(state) {
      state.slip = null;
      state.mode = MODES.EMPTY;
    },
    setSlipStoreTable(state, { storeTable }) {
      if (state.mode === MODES.CREATE) {
        state.slip.storeTableId = storeTable.id;
        state.slip.storeTableUses = [{ isUse: true, storeTable }];
      } else if (state.mode === MODES.MODIFY) {
        state.slip.storeTableId = storeTable.id;
        if (!state.slip.storeTableUses) {
          state.slip.storeTableUses = [{ isUse: true, storeTable }];
        } else {
          state.slip.storeTableUses.forEach((storeTableUse) => {
            storeTableUse.isUse = false;
          });

          state.slip.storeTableUses.push({ isUse: true, storeTable });
        }
      }
    },
    setDomesticCustomersCount(state, { domesticCustomersCount }) {
      state.slip.domesticCustomersCount = domesticCustomersCount;
    },
    setVisitor(state, { visitor }) {
      state.slip.visitorId = visitor?.id || 0;
      state.slip.visitor = visitor;
    },
    setVisibleConfirmDialog(state, { visibleConfirmDialog }) {
      state.visibleConfirmDialog = visibleConfirmDialog;
    },
  },
  getters: {
    isLoading: ({ isLoading }) => isLoading,
    mode: ({ mode }) => mode,
    availableToUpdate: ({ slip }) => availableToUpdate(slip),
    slipStatus: ({ slip }) => getSlipStatus(slip),

    salesDate: ({ salesDate }) => salesDate,
    store: ({ store }) => store,
    storeSalesAmount: ({ store }) => store?.salesAmount || 0,
    slip: ({ slip }) => slip,
    visibleConfirmDialog: ({ visibleConfirmDialog }) => visibleConfirmDialog,
  },
};

export default pos;
