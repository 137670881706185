import { isUndefinedOrNull } from "@/utils/ObjectUtil";

export const SHORTCUT_KEYS = {
  F1: "F1",
  F2: "F2",
  F3: "F3",
  F4: "F4",
  F5: "F5",
  F6: "F6",
  F7: "F7",
  F8: "F8",
  F9: "F9",
  F10: "F10",
  F11: "F11",
  F12: "F12",
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  E: "E",
  F: "F",
  G: "G",
  H: "H",
  I: "I",
  J: "J",
  K: "K",
  L: "L",
  M: "M",
  N: "N",
  O: "O",
  P: "P",
  Q: "Q",
  R: "R",
  S: "S",
  T: "T",
  U: "U",
  V: "V",
  W: "W",
  X: "X",
  Y: "Y",
  Z: "Z",
  "`": "`",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  0: "0",
  "-": "-",
  "=": "=",
  "~": "~",
  "!": "!",
  "@": "@",
  "#": "#",
  $: "$",
  "%": "%",
  "^": "^",
  "&": "&",
  "*": "*",
  "(": "(",
  ")": ")",
  _: "_",
  "+": "+",
  "[": "[",
  "]": "]",
  "\\": "\\",
  ";": ";",
  "'": "'",
  ",": ",",
  ".": ".",
  "/": "/",
  Insert: "Insert",
  ArrowDown: "ArrowDown",
  ArrowUp: "ArrowUp",
  ArrowLeft: "ArrowLeft",
  ArrowRight: "ArrowRight",
  Enter: "Enter",
  Esc: "Escape",
};

export const GLOBAL_SHORTCUT_KEYS = {
  SEARCH: "SEARCH",
  ADD: "ADD",
  DELETE: "DELETE",
  SAVE: "SAVE",
  PRINT: "PRINT",
  MEMO: "MEMO",
  MEMBER_VIEW: "MEMBER_VIEW",
};

export const GLOBAL_SHORTCUTS_MAP = {
  SEARCH: getSearchButtonShortcut(),
  ADD: getAddButtonShortcut(),
  DELETE: getDeleteButtonShortcut(),
  SAVE: getSaveButtonShortcut(),
  PRINT: getPrintButtonShortcut(),
  MEMO: getMemoButtonShortcut(),
  MENU: getGlobalMenuToggleButtonShortcut(),
  MEMBER_VIEW: getMemberViewButtonShortcut(),
};

const registeredShortcutMap = {
  ...GLOBAL_SHORTCUTS_MAP,
};

export function isOverrideApplicationShortcut(ctrlKey, altKey, shiftKey, key) {
  return !!getAllApplicationShortcuts().find((shortcut) =>
    keypressEventEqualsShortcut(
      {
        ctrlKey,
        altKey,
        shiftKey,
        key,
      },
      shortcut
    )
  );
}

export function getAllApplicationShortcuts() {
  return Object.getOwnPropertyNames(registeredShortcutMap).map(
    (key) => registeredShortcutMap[key]
  );
}

export function getShortcut(key) {
  return registeredShortcutMap[key];
}

export function addShortcut(key, shortcut) {
  if (registeredShortcutMap[key]) {
    throw Error(`Shortcut Key '${key}' already registered.`);
  }

  const registeredShortcuts = Object.getOwnPropertyNames(registeredShortcutMap)
    .map((key) => ({
      key,
      shortcut: registeredShortcutMap[key],
    }))
    .filter(
      ({ shortcut: registered }) =>
        shortcut.ctrlKey === registered.ctrlKey &&
        shortcut.altKey === registered.altKey &&
        shortcut.shiftKey === registered.shiftKey &&
        shortcut.key === registered.key
    );

  if (0 < registeredShortcuts.length) {
    console.warn(
      `Shortcut already registered. Key: '${key}', Shortcut: ${toShortcutString(
        shortcut
      )}. Registered Keys: ${registeredShortcuts
        .map(({ key }) => `'${key}'`)
        .join(", ")}`
    );
  }

  registeredShortcutMap[key] = shortcut;
}

export function keypressEventEqualsShortcut(event, shortcut) {
  return (
    (!isUndefinedOrNull(shortcut.ctrlKey)
      ? event.ctrlKey === shortcut.ctrlKey
      : true) &&
    (!isUndefinedOrNull(shortcut.altKey)
      ? event.altKey === shortcut.altKey
      : true) &&
    (!isUndefinedOrNull(shortcut.shiftKey)
      ? event.shiftKey === shortcut.shiftKey
      : true) &&
    event.key.toUpperCase() === shortcut.key.toUpperCase()
  );
}

export function removeShortcut(key) {
  delete registeredShortcutMap[key];
}

export function getSearchButtonShortcut() {
  return {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.F2,
  };
}
export function getAddButtonShortcut() {
  return {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.F3,
  };
}
export function getDeleteButtonShortcut() {
  return {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.F4,
  };
}
export function getSaveButtonShortcut() {
  return {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.F6,
  };
}
export function getPrintButtonShortcut() {
  return {
    ctrlKey: false,
    altKey: true,
    shiftKey: false,
    key: SHORTCUT_KEYS.P,
  };
}
export function getMemoButtonShortcut() {
  return {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.F9,
  };
}
export function getGlobalMenuToggleButtonShortcut() {
  return {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.F1,
  };
}
export function getMemberViewButtonShortcut() {
  return {
    ctrlKey: false,
    altKey: true,
    shiftKey: false,
    key: SHORTCUT_KEYS.F2,
  };
}
export function toShortcutString(shortcut) {
  const { ctrlKey, altKey, shiftKey, key, keyName } = shortcut;

  if (
    (ctrlKey !== true && ctrlKey !== false) ||
    (altKey !== true && altKey !== false) ||
    (shiftKey !== true && shiftKey !== false) ||
    !key
  ) {
    return "";
  }

  let shortcutString = keyName || key;
  if (shiftKey) {
    shortcutString = `Shift + ${shortcutString}`;
  }
  if (altKey) {
    shortcutString = `Alt + ${shortcutString}`;
  }
  if (ctrlKey) {
    shortcutString = `Ctrl + ${shortcutString}`;
  }

  return shortcutString;
}
