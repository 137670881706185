import qs from "qs";
export const PATH_ROOM_CODE_MANAGEMENT_REGISTER_ROOM_NUMBER = `/room/code-management/register-room-number`;
export const PATH_ROOM_STANDARD_INFO_AVAILABLE_ROOMS = `/room/standard-info/available-rooms`;
export const PATH_ROOM_STANDARD_INFO_CALENDER = `/room/standard-info/calender`;
export const PATH_ROOM_STANDARD_INFO_PAYMT = `/room/standard-info/paymt`;

export function getRoomNumber(args) {
    return this.http.get(`${PATH_ROOM_CODE_MANAGEMENT_REGISTER_ROOM_NUMBER}`, {
        params: {
            roomBizDiv:args.roomBizDiv,
            roomType:args.roomType,
            dongDiv:args.roomBuilding,
            useFlag:args.useFlag,
            virtlRoomFlag:args.virtualFlag,
        }
    });
}
export function getRoomDetailNumber(roomId) {
    return this.http.get(`${PATH_ROOM_CODE_MANAGEMENT_REGISTER_ROOM_NUMBER}/${roomId}`);
}
export function patchRoomDetailNumber(data) {
    return this.http.patch(`${PATH_ROOM_CODE_MANAGEMENT_REGISTER_ROOM_NUMBER}/save`,data);
}
export function patchRoomDetailNumberSeveral(data) {
    return this.http.patch(`${PATH_ROOM_CODE_MANAGEMENT_REGISTER_ROOM_NUMBER}/save-several`,data);
}
export function deleteRoomDetailNumber(roomId) {
    return this.http.delete(`${PATH_ROOM_CODE_MANAGEMENT_REGISTER_ROOM_NUMBER}/${roomId}`);
}
export function deleteRoomDetailNumberSeveral(data) {
    return this.http.delete(`${PATH_ROOM_CODE_MANAGEMENT_REGISTER_ROOM_NUMBER}/several`,{
        params: {
            roomIds: data
        },
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
        },
    });
}
export function postRoomNumber(data) {
    return this.http.post(`${PATH_ROOM_CODE_MANAGEMENT_REGISTER_ROOM_NUMBER}/save`,data);
}

export function getAvailableRooms(args) {
    return this.http.get(`${PATH_ROOM_STANDARD_INFO_AVAILABLE_ROOMS}`, {
        params: args,
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
        },
    });
}

export function getAvailableRoomsByRaw(args) {
    return this.http.get(`${PATH_ROOM_STANDARD_INFO_AVAILABLE_ROOMS}/raw`, {
        params: args,
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
        },
    });
}

export function createAvailableRooms(data) {
    return this.http.post(`${PATH_ROOM_STANDARD_INFO_AVAILABLE_ROOMS}`, data);
}

export function patchRoomUsefulSeveral(data) {
    return this.http.patch(`${PATH_ROOM_STANDARD_INFO_AVAILABLE_ROOMS}/several`, data);
}

export function deleteAvailableRooms(data) {
    return this.http.delete(`${PATH_ROOM_STANDARD_INFO_AVAILABLE_ROOMS}`, {
        data: data
    });
}

export function getRoomCalendarList(args) {
    return this.http.get(`${PATH_ROOM_STANDARD_INFO_CALENDER}`, {
        params: {
            bsnYear:args.bsnYear,
            bsnMt:args.bsnMt
        },
    });
}

export function getRoomCalendarByFromDateAndToDate(args, isBackground = false) {
    return this.http.get(`${PATH_ROOM_STANDARD_INFO_CALENDER}/${args.fromDate}/${args.toDate}`, {
        isBackground: isBackground,
    });
}

export function getAddRoomCalendarInfo(args) {
    return this.http.get(`${PATH_ROOM_STANDARD_INFO_CALENDER}/bsnMt`, {
        params: {
            bsnYear:args
        }
    });
}

export function postAddRoomCalendarInfo(args) {
    return this.http.post(`${PATH_ROOM_STANDARD_INFO_CALENDER}/${args.bsnYear}`,args.bsnMts);
}

export function patchRoomCalendarList(data) {
    return this.http.patch(`${PATH_ROOM_STANDARD_INFO_CALENDER}/several`, data);
}

export function deleteRoomCalendar(args) {
    return this.http.delete(`${PATH_ROOM_STANDARD_INFO_CALENDER}`, {
        params: {
            bsnYear: args.bsnYear,
            bsnMt: args.bsnMt
        }
    });
}

export function putRoomCalendarRealtimeSetting(args) {
    return this.http.put(`${PATH_ROOM_STANDARD_INFO_CALENDER}/web-open`, args);
}

export function putRoomCalendarRealtimeSettingInit(args) {
    return this.http.put(`${PATH_ROOM_STANDARD_INFO_CALENDER}/web-open/init`, args);
}

export function getRoomRateBaseSetting(args) {
    return this.http.get(`${PATH_ROOM_STANDARD_INFO_PAYMT}/std`, {
        params: {
            roomType:args.roomType,
            memberGrade:args.memberGrade
        }
    });
}

export function patchRoomRateBaseSetting(args) {
    return this.http.patch(`${PATH_ROOM_STANDARD_INFO_PAYMT}/std/several`, args);
}

export function getRoomRatePeriodSetting(args) {
    return this.http.get(`${PATH_ROOM_STANDARD_INFO_PAYMT}`, {
        params: {
            bsnDateFrom:args.bsnDateFrom,
            bsnDateTo:args.bsnDateTo,
            roomType:args.roomType,
            memberGrade:args.memberGrade
        }
    });
}

export function patchRoomRatePeriodSetting(args) {
    return this.http.patch(`${PATH_ROOM_STANDARD_INFO_PAYMT}/several`, args);
}

