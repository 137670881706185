import layout from "@/views/layout/layout";

const samplePageRoute = {
  path: "/sample",
  component: layout,
  redirect: "/sample/common-components",
  children: [
    {
      path: "common-components",
      component: () => import("@/views/sample/CommonComponentsSampleView"),
      name: "CommonComponentsSample",
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "report",
      component: () => import("@/views/sample/ReportSampleView"),
      name: "ReportSampleView",
      meta: {
        useUsualStyles: true,
      },
    },
  ],
};

export default samplePageRoute;
