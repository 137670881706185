import layout from "@/views/layout/layout";

const authoritiesManagementRoute = {
  path: "/authorities",
  component: layout,
  redirect: "/authorities/users",
  children: [
    {
      path: "users",
      name: "UserManagement",
      component: () => import("@/views/authority-management/UserManagement"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "groups",
      name: "AuthorityGroupsManagement",
      component: () =>
        import("@/views/authority-management/AuthorityGroupsManagement"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "groupUsers",
      name: "AuthorityGroupUsersManagement",
      component: () =>
        import("@/views/authority-management/AuthorityGroupUsersManagement"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "menuInfo",
      name: "MenuManagement",
      component: () =>
        import("@/views/authority-management/MenuManagement"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "menuAuthApi",
      name: "MenuAuthPostApi",
      component: () =>
          import("@/views/authority-management/MenuAuthApi"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "apiInfo",
      name: "ApiInfoAdd",
      component: () =>
          import("@/views/authority-management/ApiInfoAdd"),
      meta: {
        useUsualStyles: true,
      },
    }
  ],
};

export default authoritiesManagementRoute;
