import store from "@/store";
import {commonCodesGetComName} from "@/utils/commonCodes";
import {replaceByCamelCase} from "@/utils/string";

export default {
  data() {
    return {
      isFullscreen: false,
      menuList: store.getters.menuList,
      authList: store.getters.authList,
      menuAuthList: store.getters.menuAuthList,
    };
  },
  computed: {
    commonMixinGetMenuId() {
      return (args) => {
        let menuId = args?.menuId ? args?.menuId : this.$route.query.menuId;
        if (!Number(menuId) && menuId) {
          // 메뉴ID가 숫자가 아닌 경우
          // 예) 즐겨찾기 등록된 메뉴
          if (menuId.indexOf("_") >= 0) {
            menuId = menuId.substring(menuId.indexOf("_") + 1);
          }
        }
        if (menuId && Number(menuId)) {
          return Number(menuId);
        }

        // 새창으로 메뉴 오픈한 경우
        const routerName = this.$route.name;
        if (!routerName) {
          return null;
        }

        const menuIds = this.menuList.filter(item => item.routerName === routerName && !item.isMenuUserMark);
        if (menuIds.length > 1) {
          return null;
        }

        return menuIds.length > 0 ? (menuIds[0].menuId ? Number(menuIds[0].menuId) : null) : null;
      };
    },
    commonMixinIsButtonDisableByMenuAuth() {
      return (menuAuthDiv, menuId) => {
        if (!commonCodesGetComName("MENU_AUTH_DIV", menuAuthDiv)) {
          return true;
        }

        if (!menuId) {
          menuId = this.commonMixinGetMenuId();
          if (!menuId) {
            return true;
          }
        }

        const authDiv = replaceByCamelCase(menuAuthDiv);

        return !this.menuAuthList.filter(item => item.menuId === menuId).map(item => item[authDiv]).includes(true);
      };
    },
    commonMixinIsButtonDisableByAuth() {
      return (name) => {
        let menuAuthDiv = "";
        switch ((name ? name : "").slice(-3).toUpperCase()) {
          case "GET":
            menuAuthDiv = "GET_AUTH";
            break;
          case "REG":
            menuAuthDiv = "SAVE_AUTH";
            break;
          case "DEL":
            menuAuthDiv = "DELETE_AUTH";
            break;
          default:
            break;
        }
        return this.commonMixinIsButtonDisableByMenuAuth(menuAuthDiv, null);
      };
    },
    commonMixinHasCiperTextGet() {
      return !this.commonMixinIsButtonDisableByMenuAuth("GET_AUTH", 299);
    },
  },
  methods: {
    toggleFullScreen() {
      const elem = document.documentElement;

      if (
        document.fullscreen === false ||
        document.webkitIsFullScreen === false ||
        document.mozFullScreen === false
      ) {
        this.isFullscreen = false;
      }

      if (
        document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled
      ) {
        if (!this.isFullscreen) {
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
            this.isFullscreen = true;
            return;
          } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
            this.isFullscreen = true;
            return;
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
            this.isFullscreen = true;
            return;
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
            this.isFullscreen = true;
            return;
          }
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
            this.isFullscreen = false;
            return;
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
            this.isFullscreen = false;
            return;
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
            this.isFullscreen = false;
            return;
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
            this.isFullscreen = false;
            return;
          }
        }
      }
    },
  },
};
