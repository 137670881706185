export const PATH_RESERVATION_MEMO = `/reservation-memo`;
export const PATH_RESERVATION_MEMO_CONFIRM = `/reservation-memo/confirm`;

export function reservationMemoConfirm(request) {
  return this.http.post(`${PATH_RESERVATION_MEMO_CONFIRM}/${request.resveId}/${request.recptnDept}`, request.request);
}

export function reservationMemoConfirmByStayId(request) {
  return this.http.post(`${PATH_RESERVATION_MEMO_CONFIRM}/room/${request.stayId}/${request.recptnDept}`, request.request);
}
