<template>
  <div>
    <ejs-dialog
      ref="clientMonitorPatternImageConfigPopup"
      header="이미지 삽입"
      :width="1574"
      :isModal="true"
      :showCloseIcon="true"
      :allowDragging="true"
      :animationSettings="{ effect: 'None' }"
      @close="closePopup"
    >
      <div class="window clientMonitorPatternImageConfig">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="select-pattern">
              <div class="field">
                이미지 패턴 타입
              </div>
              <div class="content">
                <select
                  v-model="imagePattern"
                  @change="fetch"
                >
                  <option
                    v-for="(item, index) in imagePatternOptions" :key="index"
                    :value="item.comCode"
                  >
                    {{ item.comName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="content-body">
              <div
                class="prev-button"
                @click="moveScroll(true)"
              />
              <div
                ref="imageList"
                id="upload-body"
                :class="['upload-wrapper', fixedImageInfo.start && 'draggable']"
              >
                <div
                  v-if="fixedImageInfo.before"
                  class="before"
                  :style="{
                    width: `${(imageBoxWidth + 18) / 3}px`,
                  }"
                />
                <div
                  v-if="fixedImageInfo.after"
                  class="after"
                  :style="{
                    width: `${(imageBoxWidth + 18) / 3}px`,
                  }"
                />
                <div
                  class="upload-box"
                  v-for="(item, index) in imageList" :key="index"
                >
                  <div class="image">
                    <div
                      v-if="item.imageUrl"
                      class="item"
                      :style="{
                        ...imageBoxCss,
                        backgroundImage: `url(${item.imageUrl})`,
                      }"
                    />
                  </div>
                  <div
                    class="view-button"
                    :class="[fixedImageInfo.start && 'draggable']"
                    :data-id="item.imageId"
                    :data-image-url="item.imageUrl"
                    :data-sort-no="item.sortNo"
                    @mousedown="onMouseDown"
                    @click="onShowImageClicked(index)"
                  />
                  <div class="sort-no-mark">
                    {{ item.sortNo + 1 }}
                  </div>
                  <erp-button button-div="SAVE" class="upload" :use-syncfusion-component-style="false" />
                  <erp-button
                    v-if="index > 0"
                    button-div="GET"
                    :class="[
                      'move prev',
                      index === imageList.length - 1 && 'pull-right',
                    ]"
                    :use-syncfusion-component-style="false"
                    @click="onMoveImageClicked(index, 'prev')"
                  />
                  <erp-button
                    v-if="index < imageList.length - 1"
                    button-div="GET"
                    class="move next"
                    :use-syncfusion-component-style="false"
                    @click="onMoveImageClicked(index, 'next')"
                  />
                  <erp-button
                    button-div="FILE"
                    class="download"
                    :use-syncfusion-component-style="false"
                    @click="onDownloadImageClicked(item)"
                  />
                  <erp-button
                    button-div="DELETE"
                    class="delete"
                    :use-syncfusion-component-style="false"
                    @click="onDeleteProfileImageClicked(item)"
                  />
                  <input
                    type="file"
                    @change="onFileChange($event, item)"
                  />
                </div>
                <div class="upload-box new">
                  <div class="image">
                    <div
                      class="item"
                      :style="imageBoxCss"
                    />
                  </div>
                  <div class="view-button" />
                  <input
                    type="file"
                    @change="onFileChange"
                  />
                </div>
              </div>
              <div
                class="next-button"
                @click="moveScroll(false)"
              />
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closePopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="upload-box fixed">
        <div class="image">
          <div
            v-if="fixedImageInfo.start"
            class="item"
            :style="{
              ...imageBoxCss,
              position: 'fixed',
              left: `${pos.x + 10}px`,
              top: `${pos.y + 10}px`,
              backgroundImage: `url(${fixedImageInfo.imageUrl})`
            }"
          />
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.draggable {cursor: move !important}
</style>

<script>
import {sortBy as _sortBy} from 'lodash';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {commonCodesGetCommonCodeByIdxAttrb} from '@/utils/commonCodes';
import {
  uploadImage,
  updateImageSortNo,
} from '@/utils/formUtil';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import ErpButton from "@/components/button/ErpButton";

const IMAGE_BOX_WIDTH = 460;
const DELAY_MOVE_SCROLL = 300;

export default {
  name: 'ClientMonitorPatternImageConfigPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ErpButton,
  },
  data() {
    return {
      pos: {
        x: 0,
        y: 0,
      },
      imagePattern: null,
      imagePatternOptions: commonCodesGetCommonCodeByIdxAttrb(
        'IMAGE_DIV',
        1,
        'MONTR',
      ),
      imageList: [],
      imageBoxCss: {
        width: `${IMAGE_BOX_WIDTH}px`,
        height: '258px',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
      },
      imageBoxWidth: IMAGE_BOX_WIDTH,
      imageFileUploaderExtensions: '.png, .jpg, .jpeg, .gif, .bmp',
      fixedImageInfo: {
        start: null,
        end: null,
        imageUrl: null,
        orgSortNo: null,
        sortNo: null,
      },
      savedDropImageId: null,
      isMoveScrollVisible: true,
      isImageUploadedAndNotSaved: false,
    };
  },
  methods: {
    async showPopup(imagePattern) {
      this.imagePattern = imagePattern;
      await this.fetch();
    },
    closePopup() {
      this.$emit('popupClosed');
    },
    onShowImageClicked(index) {
      this.$viewerApi({
        options: {
          initialViewIndex: index,
        },
        images: this.imageList.map(item => item.imageUrl),
      });
    },
    async onMoveImageClicked(index, to = 'prev') {
      const self = this.imageList[index];
      const next = this.imageList[index + (to === 'prev' ? -1 : 1)];
      if (self && next) {
        const selfSortNo = self.sortNo;
        const nextSortNo = next.sortNo;
        self.sortNo = nextSortNo;
        next.sortNo = selfSortNo;
        this.imageList = _sortBy(this.imageList, ['sortNo']);
        await updateImageSortNo(self.imageId, nextSortNo);
        await updateImageSortNo(next.imageId, selfSortNo);
      }
    },
    onDownloadImageClicked(item) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", item.imageUrl + "?" + (new Date()).getTime(), true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(this.response);
        const tag = document.createElement("a");
        tag.href = imageUrl;
        tag.download = `sample-image-${item.sortNo}.png`;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };
      xhr.send();
    },
    onMouseDown(event) {
      event.preventDefault();
      document.onmousemove = this.onMouseMove;
      document.onmouseup = this.onMouseUp;
    },
    async onMouseMove(event) {
      event.preventDefault();
      const $el = document.getElementById('upload-body');
      const rect = $el.getBoundingClientRect();
      const distance = (this.imageBoxWidth + 18) / 3;
      if (event.clientX < rect.left + distance) {
        await this.delayMoveScroll(true);
      } else if (event.clientX > rect.right - distance) {
        await this.delayMoveScroll(false);
      } else {
        this.fixedImageInfo.before = false;
        this.fixedImageInfo.after = false;
      }
      const id = Number(event.target.getAttribute('data-id'));
      const imageUrl = event.target.getAttribute('data-image-url');
      const sortNo = Number(event.target.getAttribute('data-sort-no'));
      if (this.fixedImageInfo.start === null) {
        this.fixedImageInfo.start = id;
        this.fixedImageInfo.imageUrl = imageUrl;
        this.fixedImageInfo.orgSortNo = sortNo;
      }
      this.fixedImageInfo.end = id;
      this.fixedImageInfo.sortNo = sortNo;
      this.pos = {
        x: event.clientX,
        y: event.clientY,
      };
    },
    async onMouseUp() {
      if (
        !!this.fixedImageInfo?.start &&
        !!this.fixedImageInfo?.end &&
        this.fixedImageInfo.start !== this.fixedImageInfo.end
      ) {
        const before = this.imageList
          ?.find(item => item.imageId === this.fixedImageInfo.start);
        const after = this.imageList
          ?.find(item => item.imageId === this.fixedImageInfo.end);
        if (before && after) {
          const {
            start,
            end,
            orgSortNo,
            sortNo,
          } = this.fixedImageInfo;
          before.sortNo = sortNo;
          after.sortNo = orgSortNo;
          this.imageList = _sortBy(this.imageList, ['sortNo']);
          await updateImageSortNo(start, sortNo);
          await updateImageSortNo(end, orgSortNo);
        }
      }
      this.fixedImageInfo = {
        start: null,
        end: null,
        imageUrl: null,
        orgSortNo: null,
        sortNo: null,
        before: false,
        after: false,
      };
      this.savedDropImageId = null;
      document.onmouseup = null;
      document.onmousemove = null;
    },
    async onFileChange(args, item = null) {
      const file = args.target.files[0];
      try {
        const newSortNo = this.imageList.length > 0
          ? this.imageList[this.imageList.length - 1].sortNo + 1
          : 0;
        const data = await uploadImage(
          item?.imageId || null,
          this.imagePattern,
          [{
            statusCode: '1',
          }],
          file,
          item ? item.sortNo : newSortNo,
        );
        if (data?.imageId && data?.imageURL) {
          const findItem = item && this.imageList
            ?.find(i => i.imageId === item.imageId);
          if (findItem) {
            findItem.imageId = data?.imageId;
            findItem.imageUrl = data?.imageURL;
          } else {
            this.imageList.push({
              imageId: data?.imageId,
              imageUrl: data?.imageURL,
              sortNo: newSortNo,
            });
          }
          this.isImageUploadedAndNotSaved = true;
          if (!item) {
            await this.moveScroll();
          }
        }
      } catch (e) {
        alert(e.message);
      }
    },
    async onDeleteProfileImageClicked(item) {
      if (item?.imageUrl && item?.imageId) {
        if (!(await this.confirm(this.$t("main.popupMessage.confirmDelete")))) {
          return;
        }
        await this.deleteImageFile(item);
        this.infoToast(this.$t('main.popupMessage.deleted'));
      }
    },
    async deleteImageFile(item) {
      try {
        const findIndex = item && this.imageList
          ?.find(i => i.imageId === item.imageId);
        if (findIndex < 0) {
          return;
        }
        delete this.imageList[findIndex];
        this.isImageUploadedAndNotSaved = false;
        await GolfErpAPI.deleteImage(item.imageId, this.imagePattern);
        await this.fetch();
      } catch (e) {
        console.error('imageDelete.err.===>', e);
      }
    },
    async moveScroll(isPrev = false, isSmooth = true) {
      if (!this.isMoveScrollVisible) {
        return;
      }
      await this.$nextTick();
      const distance = this.imageBoxWidth + 18;
      const imageList = this.$refs.imageList;
      imageList.scrollTo({ left: imageList.scrollLeft + (isPrev ? -distance : distance), behavior: isSmooth ? 'smooth' : 'auto' });
      this.isMoveScrollVisible = false;
      setTimeout(() => this.isMoveScrollVisible = true, DELAY_MOVE_SCROLL);
    },
    delayMoveScroll(isPrev = false) {
      if (
        !this.fixedImageInfo.start ||
        this.savedDropImageId === this.fixedImageInfo.end
      ) {
        return;
      }
      this.fixedImageInfo.before = isPrev === true;
      this.fixedImageInfo.after = isPrev === false;
      this.savedDropImageId = this.fixedImageInfo.end;
      setTimeout(async () => {
        await this.moveScroll(isPrev, false);
        this.delayMoveScroll(isPrev);
      }, DELAY_MOVE_SCROLL);
    },
    async fetch() {
      const data = await GolfErpAPI.fetchClientMonitorImages(this.imagePattern);
      this.imageList = _sortBy(data.map(item => {
        return {
          imageId: item.id,
          imageUrl: item.path,
          sortNo: item.sortNo,
        };
      }), ['sortNo']);
    },
  },
};
</script>
