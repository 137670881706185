import { stringify as qs_stringify } from "qs";

const PATH_USERS = "/users";

export function fetchUsers({ q, bizCode, authGroupId, filters, orders }) {
  return this.http.get(PATH_USERS, {
    paramsSerializer(params) {
      return qs_stringify(params, { arrayFormat: "indices", allowDots: true });
    },
    params: {
      q,
      bizCode,
      authGroupId,
      filters,
      orders,
    },
  });
}

export function fetchUser(id) {
  return this.http.get(`${PATH_USERS}/${id}`);
}

export function createUser({
  name,
  email,
  password,
  remarks,
  phone,
  baseBizCode,
  isBlocked,
  useLimit,
  authGroupIds,
}) {
  return this.http.post(`${PATH_USERS}`, {
    name,
    email,
    password,
    remarks,
    phone,
    baseBizCode,
    isBlocked,
    useLimit,
    authGroupIds,
  });
}

export function patchUser(
  id,
  {
    name,
    email,
    password,
    remarks,
    deptAdminFlag,
    phone,
    baseBizCode,
    isBoardManager,
    isBlocked,
    useLimit,
  }
) {
  return this.http.patch(`${PATH_USERS}/${id}`, {
    name,
    email,
    password,
    remarks,
    deptAdminFlag,
    phone,
    baseBizCode,
    isBoardManager,
    isBlocked,
    useLimit,
  });
}

export function updateKeepDoNotChangePassword(id) {
  return this.http.put(`${PATH_USERS}/${id}/keep-temp-password`);
}

export function deleteUser(id) {
  return this.http.delete(`${PATH_USERS}/${id}`);
}

export function reIssuancePassword(id) {
  return this.http.post(`${PATH_USERS}/${id}/reissuance-password`);
}

export function initPasswordToUser(id) {
  return this.http.patch(`${PATH_USERS}/${id}/init-password`);
}