import request from "@/utils/request";

/**
 * @return Promise
 */
export function getCalendarList(bsnYear, bsnMt, isBackground = false) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/calendar/",
    method: "get",
    isBackground: isBackground,
    params: {
      bsnYear: bsnYear,
      bsnMt: bsnMt,
    },
  });
}

/**
 * @return Promise
 */
export function saveCalendarList(bsnYear, bsnMt, calendarList) {
  return request({
    url: "/reservation_management/standard_info/calendar/",
    method: "put",
    params: {
      bsnYear: bsnYear,
      bsnMt: bsnMt,
    },
    data: calendarList,
  });
}

/**
 * @return Promise
 */
export function getAddCalendarInfo(bsnYear) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/calendar/add",
    params: {
      bsnYear: bsnYear,
    },
    method: "get",
  });
}

/**
 * @return Promise
 */
export function addCalendar(generateInfo) {
  return request({
    url: "/reservation_management/standard_info/calendar/add",
    method: "post",
    data: generateInfo,
  });
}

/**
 * @return Promise
 */
export function deleteCalendar(bsnYear, bsnMt) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/calendar/",
    method: "delete",
    params: {
      bsnYear: bsnYear,
      bsnMt: bsnMt,
    },
  });
}

/**
 * @return Promise
 */
export function getHolidayList(bsnYear) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/calendar/holiday",
    method: "get",
    params: {
      bsnYear: bsnYear,
    },
  });
}

/**
 * @return Promise
 */
export function saveHolidayList(holidayList) {
  return request({
    url: "/reservation_management/standard_info/calendar/holiday",
    method: "put",
    data: holidayList,
  });
}

/**
 * @return Promise
 */
export function copyHolidayList(fromBsnYear, toBsnYear) {
  return request({
    url: "/reservation_management/standard_info/calendar/holiday/copy",
    method: "put",
    params: {
      fromBsnYear: fromBsnYear,
      toBsnYear: toBsnYear,
    },
  });
}

/**
 * @return Promise
 */
export function saveCalendarWeekendSetting(setting) {
  return request({
    url: "/reservation_management/standard_info/calendar/weekend",
    method: "put",
    data: setting,
  });
}

/**
 * @return Promise
 */
export function saveCalendarRealtimeSetting(setting) {
  return request({
    url: "/reservation_management/standard_info/calendar/wait",
    method: "put",
    data: setting,
  });
}

/**
 * @return Promise
 */
export function initCalendarWeekendSetting(setting) {
  return request({
    url: "/reservation_management/standard_info/calendar/weekend/init",
    method: "put",
    data: setting,
  });
}

/**
 * @return Promise
 */
export function initCalendarRealtimeSetting(setting) {
  return request({
    url: "/reservation_management/standard_info/calendar/wait/init",
    method: "put",
    data: setting,
  });
}
