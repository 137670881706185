import request from "@/utils/request";

/**
 * 1/N분할 팝업 List 조회
 * @param paramData
 * @return Promise
 */
export function getDivideListByPut(divideFlag, data) {
  return request({
    url: `/front_management/check-in/divide-list/${divideFlag}`,
    method: "put",
    data: data
  });
}

/**
 * 1/N 분할 저장
 * @param data
 * @return Promise
 */
export function saveDivideList(data) {
  return request({
    url: "/front_management/check-in/divide",
    method: "post",
    data: data,
  });
}

/**
 * 부분합산 조회
 * @return Promise
 */
export function getPartialSumByPut(partialSumFlag, data) {
  return request({
    url: `/front_management/check-in/partial-sum/${partialSumFlag}`,
    method: "put",
    data: data
  });
}

/**
 * 부분합산 저장
 * @param data
 * @return Promise
 */
export function savePartialSum(data) {
  return request({
    url: "/front_management/check-in/partial-sum",
    method: "put",
    data: data,
  });
}

/**
 * 팝업 오픈 초기 정보 조회
 * @param chkinId
 * @param resveId
 * @param resveCmpnFlag
 * @param grpNo
 * @return Promise
 */
export function getCheckinInfo(chkinId, resveId, resveCmpnFlag, grpNo) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/front_management/check-in/info",
    method: "get",
    params: {
      chkinId: chkinId,
      resveId: resveId,
      resveCmpnFlag: resveCmpnFlag,
      grpNo: grpNo,
    },
  });
}

/**
 * 락카반납 / 캐디피 지급 유무 저장
 * @param data
 * @return Promise
 */
export function saveLockerCaddieFee(data) {
  return request({
    url: "/front_management/check-in/visit-sale-list/pay",
    method: "put",
    data: data,
  });
}

/**
 * 체크아웃 적용/해제 저장
 * @param chkoutFlag
 * @param data
 * @return Promise
 */
export function checkOut(chkoutFlag, data) {
  return request({
    url: `/front_management/check-in/check-out/${chkoutFlag}`,
    method: "put",
    data: data,
  });
}

/**
 * 분할 취소
 * @param cancelFlag
 * @param data
 * @return Promise
 */
export function divideCancel(cancelFlag, data) {
  return request({
    url: `/front_management/check-in/divide-cancel/${cancelFlag}`,
    method: "put",
    data: data,
  });
}

/**
 * 내장객 복사 List 조회
 * @param bsnDate
 * @param resveName
 * @return Promise
 */
export function getVisitCopy(bsnDate, resveName) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/front_management/check-in/visit-copy",
    method: "get",
    params: {
      bsnDate: bsnDate,
      resveName: resveName,
    },
  });
}

/**
 * 일반대여 등록의 등록된 데이터 조회
 * @param bsnDate
 * @param visitId
 * @return Promise
 */
export function getProductListSaleInfo(bsnDate, visitId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/front_management/check-in/rent-product-list/sale-info",
    method: "get",
    params: {
      bsnDate: bsnDate,
      visitId: visitId,
    },
  });
}

/**
 * 일반대여 등록의 데이터 저장
 * @param data
 * @return Promise
 */
export function saveProductListSaleInfo(data) {
  return request({
    url: `/front_management/check-in/rent-product-list/sale-info`,
    method: "put",
    data: data,
  });
}

/**
 * 할인코드 검색 팝업의 할인코드 조회
 * @param dcDiv
 * @param paramData
 * @return Promise
 */
export function getDcCodeList(dcDiv, paramData) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/check-in/dc-code-list/${dcDiv}`,
    method: "get",
    params: {
      searchDiv: paramData.searchDiv, // 조회구분
      searchContents: paramData.searchContents, // 조회내용
      useFlag: paramData.useFlag, // 사용가능여부
      benMembershipId: paramData.benMembershipId, // 특전회원권ID
      membershipId: paramData.membershipId, // 회원권ID
      memberNo: paramData.memberNo, // 회원번호
      memberDiv: paramData.memberDiv, // 회원구분
      memberGrade: paramData.memberGrade, // 회원등급
    },
  });
}

/**
 * 회원권 특전 요금코드 조회
 * @return Promise
 * @param params
 */
export function getMemberBenefitPaymtCode(params) {
  return this.http.get(
    `${process.env.VUE_APP_HOST_API_BASE_V1}/front_management/check-in/member-benefit-paymt-code`,
    {
      params
    }
  );
}

/**
 * 새로운 부분합산의 분할 목록(내장객) 조회
 * @param args
 * @return Promise
 */
export function getVisitPays(args) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/front_management/check-in/visit-pays",
    method: "get",
    params: {
      chkinId: args.chkinId,
      grpNo: args.grpNo,
    }
  });
}
/**
 * 내장객현황 변경이력 조회
 * @param args
 * @return Promise
 */
export function getCheckinHistory(params) {
  return this.http.get(
      `${process.env.VUE_APP_HOST_API_BASE_V1}/front_management/check-in/change-history`,
      {
        params
      }
  );
}