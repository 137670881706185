`
<template>
  <!-- TODO <span> since of using syncfusion -->
  <span class="e-input-group e-control-wrapper e-multi-line-input">
    <textarea
      ref="input"
      class="input-textarea"
      v-bind="inputAttributes"
      spellcheck="false"
      autocomplete="off"
      v-on="inputListeners"
      :style="{
         resize: resize ? '' : 'none' ,
         height: height ? `${height}px !important` : null
      }"
    >
      <slot/>
    </textarea>
  </span>
</template>

<script>
export default {
  name: "InputTextarea",
  props: {
    rows: {
      type: Number,
      default: 0,
    },
    resize: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
    }
  },
  data() {
    return {
      previousValue: this.$attrs.value,

      defaultAutoComplete: "off",
      defaultClasses: ["e-input"],
      defaultMaxLength: 400,

      inputListeners: Object.assign({}, this.$listeners, {
        input: (event) => {
          this.$emit("input", event.target.value);
        },
        /**
         * @deprecated since of using syncfusion
         * @returns {string}
         */
        change: (event) => {
          const newValue = this.value;
          this.$emit("change", {
            ...event,
            event,
            value: newValue,
            previousValue: this.previousValue,
          });
          this.previousValue = newValue;
        },
        /**
         * @deprecated since of using syncfusion
         * @returns {string}
         */
        blur: (event) => {
          this.$emit("blur", {
            ...event,
            event,
            value: this.value,
          });
        },
      }),
    };
  },
  computed: {
    /**
     * @deprecated since of using syncfusion
     * @returns {string}
     */
    value() {
      return this.$attrs.value;
    },
    inputAttributes() {
      const classes = Object.assign([], this.defaultClasses);
      if (typeof this.$attrs.class === "string") {
        classes.push(this.$attrs.class);
      } else if (Array.isArray(this.$attrs.class)) {
        classes.concat(classes);
      } else if (typeof this.$attrs.class === "object") {
        classes.push(this.$attrs.class);
      }

      return Object.assign({}, this.$attrs, {
        autocomplete: this.$attrs.autocomplete || this.defaultAutoComplete,
        class: classes,
        type: "text",
        maxlength: this.$attrs.maxlength || this.defaultMaxLength,
        rows: this.rows > 0 ? this.rows : undefined,
      });
    },
  },
  methods: {
    /**
     * @deprecated since of using syncfusion
     */
    focusIn() {
      this.$refs["input"].focus();
    },
    focus() {
      this.$refs["input"].focus();
    },
  },
};
</script>

<style scoped>
textarea.input-textarea:focus {background-color: rgba(250, 244, 192, 0.2) !important;}
</style>
