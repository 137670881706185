const WEB_SOCKET_PORT = 50000;

let webSocket = null;

export default {
  methods: {
    openWebSocket(config, isOpenClientMonitor = false) {
      if (webSocket) {
        return;
      }
      if (isOpenClientMonitor) {
        document.location = 'erp-monitor:open';
      }
      webSocket = new WebSocket(`ws://localhost:${WEB_SOCKET_PORT}`);
      webSocket.onopen = () => {
        const jsonData = {
          type: 'open',
          url: location.protocol + location.host,
          data: {
            interval: config.interval,
            effect: config.effect,
            imageList: config.imageList,
          },
        };
        webSocket.send(JSON.stringify(jsonData));
      };
      webSocket.onerror = args => {
        webSocket.close();
        webSocket = null;
        console.error('Socket encountered error: ', args);
        this.errorToast(this.$t('clientMonitor.popupMessage.notRunningOrError'));
      };
    },
    closeWebSocket() {
      if (!webSocket) {
        return;
      }
      const jsonData = {
        type: 'close',
      };
      webSocket.send(JSON.stringify(jsonData));
      webSocket.close();
      webSocket = null;
      this.infoToast(this.$t('clientMonitor.popupMessage.exitSuccess'));
    },
    sendWebSocket(type, data = null) {
      if (!webSocket) {
        this.errorToast(this.$t('clientMonitor.popupMessage.notRunning'));
        return;
      }
      const jsonData = {
        type,
        data,
      };
      webSocket.send(JSON.stringify(jsonData));
    },
  },
};
