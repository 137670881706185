import qs from 'qs';

export const PATH_CADDIE = `/caddie`;

export function fetchCheckForDuplicateCartNumbers(args) {
  return this.http.get(`${PATH_CADDIE}/arrangement/check-for-duplicate-cart-numbers`, {
    params: {
      bsnDate: args.bsnDate,
      cartNo: args.cartNo,
      argmtId: args.argmtId
    }
  });
}

/**
 * 캐디 라운드 집계표 조회
 */
export function fetchCaddieIncomeAttendStatus(args) {
  return this.http.get(`${PATH_CADDIE}/caddie-attend-income-statements`, {
    params: {
      bsnDateFrom: args.bsnDateFrom,
      bsnDateTo: args.bsnDateTo,
      caddieName: args.caddieName,
      caddieEmployDiv: args.caddieEmployDiv,
      caddieGroupDiv: args.caddieGroupDiv,
      caddieWorkDiv: args.caddieWorkDiv,
      caddieGrade: args.caddieGrade,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, {arrayFormat: 'repeat'});
    },
  });
}

/**
 * 캐디 라운드 집계표 조회
 */
export function fetchCaddieRoundTotalTable(args) {
  return this.http.get(`${PATH_CADDIE}/caddie-round-total-table`, {
    params: {
      bsnDate: args.bsnDate,
      yydwDate: args.yydwDate,
      caddieName: args.caddieName,
      caddieEmployDiv: args.caddieEmployDiv,
      caddieGroupDiv: args.caddieGroupDiv,
      caddieWorkDiv: args.caddieWorkDiv,
      caddieGrade: args.caddieGrade,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, {arrayFormat: 'repeat'});
    },
  });
}

/**
 * 캐디배치 현황 조회
 */
export function fetchCaddieArrangementStatementList(args) {
  return this.http.get(`${PATH_CADDIE}/caddie-arrangement-statements`, {
    params: {
      visitDateFrom: args.visitDateFrom,
      visitDateTo: args.visitDateTo,
      name: args.name
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, {arrayFormat: 'repeat'});
    },
  });
}

export function fetchCaddieTaxSubmissionStatements(args) {
  return this.http.get(`${PATH_CADDIE}/caddie-tax-submission-statements`, {
    params: {
      visitDateFrom: args.visitDateFrom,
      visitDateTo: args.visitDateTo,
    },
  });
}