import moment from "moment";
import {
  DATE_FORMAT_YYYY,
  DATE_FORMAT_YYYY_MM,
  DATE_FORMAT_YYYY_MM_DD,
  validateDate,
  validateMonth,
  validateYear,
} from "@/utils/date";
import {
  TIME_FORMAT_hh_mm,
  TIME_FORMAT_hh_mm_ss,
  validateHours,
  validateMinute,
  validateSeconds,
} from "@/utils/time";

/**
 * YYYY, YYYY-MM, YYYY-MM-DD 포멧의 날짜 형식에 맞는 문자열인지 검사한다.
 * @param string {string}
 * @returns {boolean}
 */
export function isDateFormatString(string) {
  // deny not string
  if (typeof string !== "string") {
    return false;
  }

  // [start to check hyphen]
  const split = string.split("-");

  // allows split.length is 1 or 2 or 3
  if (split.length !== 1 && split.length !== 2 && split.length !== 3) {
    return false;
  }

  // check year, month, date
  if (
    0 < split.length &&
    (split[0].length !== 4 ||
      isNaN(Number(split[0])) ||
      !validateYear(Number(split[0])))
  ) {
    // year
    return false;
  }

  if (
    1 < split.length &&
    (split[1].length !== 2 ||
      isNaN(Number(split[1])) ||
      !validateMonth(Number(split[1])))
  ) {
    // month
    return false;
  }
  if (
    2 < split.length &&
    (split[2].length !== 2 ||
      isNaN(Number(split[2])) ||
      !validateDate(Number(split[2]), Number(split[0]), Number(split[1])))
  ) {
    // date
    return false;
  }

  switch (split.length) {
    case 1:
      return moment(string, DATE_FORMAT_YYYY).isValid();
    case 2:
      return moment(string, DATE_FORMAT_YYYY_MM).isValid();
    case 3:
      return moment(string, DATE_FORMAT_YYYY_MM_DD).isValid();
  }

  return false;
}

/**
 * hh:mm, hh:mm:ss 포멧의 시간 형식에 맞는 문자열인지 검사한다.
 * @param string {string}
 * @returns {boolean}
 */
export function isTimeFormatString(string) {
  // deny not string
  if (typeof string !== "string") {
    return false;
  }

  // [start to check hyphen]
  const split = string.split(":");

  // allows split.length is 1 or 2 or 3
  if (split.length !== 2 && split.length !== 3) {
    return false;
  }

  // check year, month, date
  if (
    0 < split.length &&
    (split[0].length !== 2 ||
      isNaN(Number(split[0])) ||
      !validateHours(Number(split[0])))
  ) {
    // hours
    return false;
  }

  if (
    1 < split.length &&
    (split[1].length !== 2 ||
      isNaN(Number(split[1])) ||
      !validateMinute(Number(split[1])))
  ) {
    // minutes
    return false;
  }
  if (
    2 < split.length &&
    (split[2].length !== 2 ||
      isNaN(Number(split[2])) ||
      !validateSeconds(Number(split[2])))
  ) {
    // seconds
    return false;
  }

  switch (split.length) {
    case 2:
      return moment(string, TIME_FORMAT_hh_mm).isValid();
    case 3:
      return moment(string, TIME_FORMAT_hh_mm_ss).isValid();
  }

  return false;
}

/**
 * - 가 없는 핸드폰 번호를 - 가 있는 핸드폰 번호로 변환
 * 예) 기존 : 01055554444
 *    변경 : 010-5555-4444
 * @param {string} phoneNumber
 * @returns {string}
 */
export function getFormattedPhoneNumber(phoneNumber) {
  if (phoneNumber) {
    if (phoneNumber.length > 3 && phoneNumber.length < 8) {
      phoneNumber =
        phoneNumber.substring(0, 3) + "-" + phoneNumber.substring(3);
    } else if (phoneNumber.length >= 8) {
      if (phoneNumber.length === 8) {
        phoneNumber =
          phoneNumber.substring(0, 4) +
          "-" +
          phoneNumber.substring(4);
      } else if (phoneNumber.length === 10) {
        phoneNumber =
          phoneNumber.substring(0, 3) +
          "-" +
          phoneNumber.substring(3, 6) +
          "-" +
          phoneNumber.substring(6);
      } else {
        phoneNumber =
          phoneNumber.substring(0, 3) +
          "-" +
          phoneNumber.substring(3, 7) +
          "-" +
          phoneNumber.substring(7);
      }
    }
  } else {
    phoneNumber = "";
  }
  return phoneNumber;
}

/**
 * - 가 없는 전화번호를 - 가 있는 전화번호로 변환
 * 예) 기존 : 021234567
 *    변경 : 02-123-4567
 * @param {string} telNumber 전화번호
 * @param {string} firstFormat 지역번호 다음 포맷
 * @return {string}
 */
export function getFormattedTelNumber(telNumber, firstFormat = "-") {
  if (telNumber) {
    if (telNumber.length > 3 && telNumber.length < 9) {
      if (telNumber.length === 7) {
        telNumber = telNumber.substring(0, 3) + "-" + telNumber.substring(3);
      } else {
        telNumber = telNumber.substring(0, 4) + "-" + telNumber.substring(4);
      }
    } else {
      if (telNumber.substring(0, 2) === "02") {
        if (telNumber.length === 9) {
          telNumber =
            telNumber.substring(0, 2) +
            firstFormat +
            telNumber.substring(2, 5) +
            "-" +
            telNumber.substring(5);
        } else {
          telNumber =
            telNumber.substring(0, 2) +
            firstFormat +
            telNumber.substring(2, 6) +
            "-" +
            telNumber.substring(6);
        }
      } else {
        if (telNumber.length === 10) {
          telNumber =
            telNumber.substring(0, 3) +
            firstFormat +
            telNumber.substring(3, 6) +
            "-" +
            telNumber.substring(6);
        } else {
          telNumber =
            telNumber.substring(0, 3) +
            firstFormat +
            telNumber.substring(3, 7) +
            "-" +
            telNumber.substring(7);
        }
      }
    }
  } else {
    telNumber = "";
  }

  return telNumber;
}

/**
 * 글자 Byte 수 가져오기.
 * @param str : 글자 Byte 수 구할 데이터
 * @returns {number}
 */
export function getByteLength(str) {
  if (!str) {
    return 0;
  }
  let b, i, c;
  // eslint-disable-next-line no-empty
  for (b = i = 0; (c = str.charCodeAt(i++)); b += c >> 7 ? 2 : 1) {}
  return b;
}

/**
 * 글자 Byte 단위로 문자열 자르기
 * @param { string } str
 * @param { number } len
 */
export function subStrByte(str, len) {
  if (str) {
    str = str.trim();
    if (getByteLength(str) <= len) {
      return str;
    } else {
      let s = 0;
      for (let i = 0; i < str.length; i++) {
        s += str.charCodeAt(i) > 128 ? 2 : 1;
        if (s > len) {
          return str.substring(0, i).concat("...");
        }
      }
      return str;
    }
  } else {
    return "";
  }
}

/**
 * 글자 카멜케이스로 변환
 * @param { string } str
 */
export function replaceByCamelCase(str) {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}


/**
 * Sentry error tracking, api url을 정규식으로 만들어서 반환한다.
 * @param url
 * @returns {RegExp}
 */
export function createRegExpForUrl(url) {
  const escapedUrl = url.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  return new RegExp(`^${escapedUrl}`);
}