export const PATH_LOST_ITEM_REGISTRATION = '/lost-item-management';

export function getLostItemList(args) {
    return this.http.get(`${PATH_LOST_ITEM_REGISTRATION}/items`, {
        params: args
    });
}

export function deleteLostItems(deleteIds) {
    return this.http.delete(`${PATH_LOST_ITEM_REGISTRATION}/items`, {
        data: deleteIds
    });
}

export function patchLostItems(records) {
    return this.http.patch(`${PATH_LOST_ITEM_REGISTRATION}/items`, records);
}

export function postLostItem(tgLostMst) {
    return this.http.post(`${PATH_LOST_ITEM_REGISTRATION}/items`, tgLostMst);
}

export function getLostItemImages(lostId) {
    return this.http.get(`${PATH_LOST_ITEM_REGISTRATION}/items/${lostId}/images`);
}

export function saveLostItemImageFileRecord(lostId, imageId) {
    return this.http.post(`${PATH_LOST_ITEM_REGISTRATION}/items/${lostId}/images/${imageId}`);
}

export function deleteLostItemImageFileSoft(lostId, imageId) {
    return this.http.delete(`${PATH_LOST_ITEM_REGISTRATION}/items/${lostId}/images/${imageId}`);
}
