<template>
  <ejs-toast ref="toast" />
</template>

<script>
import { v4 as createUUID } from "uuid";
import { closest } from "@syncfusion/ej2-base";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "FToast",
  mixins: [messagePopupDialogMixin],
  data() {
    return {
      isAliveSessionExpiredToast: false,
    };
  },
  methods: {
    info(message, listeners) {
      this.$refs["toast"].show({
        content: message,
        cssClass: "e-toast-info",
        icon: "e-info toast-icons",
        position: { X: listeners?.positionX ? listeners?.positionX : "Right", Y: listeners?.positionY ? listeners?.positionY : "Bottom" },
        timeOut: listeners?.timeOut ? listeners?.timeOut : 3000,
        title: this.$t("main.title.infoToast"),
        showCloseButton: true,
        close: listeners?.close,
      });
    },
    error({ message, listeners }) {
      this.$refs["toast"].show({
        content: message,
        cssClass: "e-toast-danger",
        icon: "e-error toast-icons",
        position: { X: "Right", Y: "Bottom" },
        timeOut: listeners?.timeOut ? listeners?.timeOut : 3000,
        title: this.$t("main.title.errorToast"),
        showCloseButton: true,
        close: listeners?.close,
      });
    },
    pinned({ message, listeners }) {
      this.$refs["toast"].show({
        content: message,
        icon: "toast-icons",
        position: { X: "Right", Y: "Bottom" },
        timeOut: 0,
        title: " ",
        showCloseButton: true,
        close: listeners?.close,
      });
    },
    sessionExpired({ listeners }) {
      if (this.isAliveSessionExpiredToast) {
        return;
      }

      this.isAliveSessionExpiredToast = true;
      this.$refs["toast"].show({
        content: "세션시간이 만료되었습니다. 재로그인 해주세요.",
        icon: "toast-icons",
        position: { X: "Right", Y: "Bottom" },
        timeOut: 5000,
        title: " ",
        showCloseButton: true,
        close: () => {
          this.isAliveSessionExpiredToast = false;
          listeners?.close?.();
        },
      });
    },
    task({ message, listeners, promise, interrupt }) {
      const specificClass = createUUID();

      this.$refs["toast"].show({
        buttons: [
          {
            model: { content: "중지" },
            click: (e) => {
              const toastEle = closest(e.target, `.e-toast`);
              this.$refs["toast"].hide(toastEle);

              interrupt();
            },
          },
        ],
        content: message,
        cssClass: `${specificClass}`,
        icon: "toast-icons",
        position: { X: "Right", Y: "Bottom" },
        timeOut: 0,
        title: this.$t("main.title.taskToast"),
        showCloseButton: true,
        close: listeners?.close,
      });

      if (promise) {
        promise.then(() => {
          const target = document.getElementsByClassName(specificClass)[0];
          const toastEle = closest(target, `.e-toast`);
          this.$refs["toast"].hide(toastEle);
        });
      }
    },
  },
};
</script>

<style scoped></style>
