import firebase from "firebase/app";
import "firebase/messaging";

let firebaseApp = null;

export function initializeFirebase() {
  if (firebase.messaging.isSupported()) {
    console.log("Firebase messaging is supported.");
    const firebaseConfig = {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FCM_SENDER_ID,
      appId: process.env.VUE_APP_FIREBASE_APP_ID,
    };

    if (!firebase.apps.length) {
      try {
        console.log("Initialize firebase app.");
        firebaseApp = firebase.initializeApp(firebaseConfig);
      } catch (e) {
        console.error("Failed to initialize firebase app.", e);
        firebaseApp = null;
      }
    } else {
      console.log("Firebase app already initialized.");
    }
  } else {
    console.log("Firebase messaging is not supported.");
  }
}

export function getFcmMessage() {
  if (!firebase.messaging.isSupported()) {
    return null;
  } else {
    return firebaseApp?.messaging?.();
  }
}
