import Vue from "vue";
import gridTemplateDestroyMixin from "../../../mixins/gridTemplateDestroyMixin";

export default function gridCheckAllHeaderTemplate(
  field,
  headerText,
  selectAllCheckFunction,
  grid
) {
  return {
    template: Vue.component("gridCheckAllHeaderTemplate", {
      mixins: [gridTemplateDestroyMixin],
      template: `<div class="gridCheckboxEdit" style="cursor: default;"><div class="gridCheckboxEdit-wrap" style="margin-bottom: -3px; cursor: pointer;"><input type="checkbox" ref="checkbox" @change="check" :id="id"><i></i><div class="label">${headerText}</div></div></div>`,
      data() {
        return {
          field: field,
          headerText: headerText,
          selectAllCheckFunction: selectAllCheckFunction,
          id: "checkAll_Header_" + field,
          grid: grid,
          data: {
            data: {},
          },
        };
      },
      methods: {
        check() {
          if (!grid) {
            throw new Error("Invalid checkbox : grid data is nothing");
          }

          const batchCurrentViewRecords = grid.getBatchCurrentViewRecords();
          const toCheckBatchCurrentViewRecords = grid.getCheckBatchCurrentViewRecords(
            field
          );

          let checkboxValue = false;
          if (toCheckBatchCurrentViewRecords.length > 0) {
            // 체크할 record가 있는 경우에만 처리함
            // checkboxValue 설정하기
            // - 원래는 checkboxValue 값을 this.$refs.checkbox.checked(체크박스의 현재 체크 값) 으로 초기화하였음
            // - 그러나, 체크박스가 체크되어야 하는데 체크되지 않는 버그가 발생
            //   버그 상세 : 체크박스 하나 체크(체크되지 않은 체크박스 남아 있음) --> focusout 하지 않고 --> 헤더의 checkall 체크박스 클릭 --> 체크가 되어야 하는데 체크가 되지 않음
            // - 따라서, 이 값을 체크박스의 현재 체크값에서 가져오지 않고 현재 record 데이터를 가지고 판단하도록 수정
            //   1) 전부 다 체크되지 않음 : 헤더 checkall 체크박스 클릭 시 전부 다 체크되어야 함. 따라서 true
            //   2) 전부 다 체크됨 : 헤더 checkall 체크박스 클릭 시 전부 다 체크 해제되어야 함. 따라서 false
            checkboxValue = !(
              toCheckBatchCurrentViewRecords.filter(
                (toCheckRecord) => toCheckRecord[field]
              ).length === toCheckBatchCurrentViewRecords.length
            );

            const valueChangedRowIndexes = [];
            batchCurrentViewRecords.forEach((record, idx) => {
              if (record[field] !== checkboxValue) {
                let isUpdate = true;
                if (selectAllCheckFunction) {
                  isUpdate = selectAllCheckFunction(record);
                }
                if ((checkboxValue && isUpdate) || !checkboxValue) {
                  valueChangedRowIndexes.push(idx);
                  grid.updateCell(idx, field, checkboxValue);
                }
              }
            });

            this.grid.$emit("headerSelectAllCheckboxClicked", {
              field: field,
              value: checkboxValue,
              valueChangedRowIndexes: valueChangedRowIndexes,
            });
          }

          // header 체크박스 값 세팅
          this.$refs.checkbox.checked = checkboxValue;
        },
      },
    }),
  };
}
