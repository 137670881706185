export function maskingNameFormat(name) {
  if (name.length > 2) {
    const baseName = name.split("");
    baseName.forEach((str,i) => { if(i === 0 || i === baseName.length - 1) {return;} baseName[i] = '*'; });
    return baseName.join('');
  } else {
    return name.replace(/.$/, '*');
  }

}
export function maskingNameFormat2(name) {
  if (name.length > 2) {
    const baseName = name.split("");
    baseName.forEach((str,i) => { if(i === 0) {return;} baseName[i] = '*'; });
    return baseName.join('');
  } else {
    return name.replace(/.$/, '*');
  }
}
export function removeNotAlphabet(str){
  const alphabetOnly = str.trim().replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣0-9]/, '');
  return /^[a-zA-Z]/.test(alphabetOnly) ? alphabetOnly : '';
}

export function memberNoFormatter(memberNo) {
  if (memberNo) {
    memberNo =
      memberNo.substring(0, 2) +
      "-" +
      memberNo.substring(2, 6) +
      "-" +
      memberNo.substring(6);
  } else {
    memberNo = "";
  }
  return memberNo;
}

export function removeHyphensFromColumns(gridData, fieldNames) {
  return gridData.map(item => {
    let updatedItem = { ...item };
    fieldNames.forEach((fieldName) => updatedItem[fieldName] = removeHyphenFromValue(updatedItem[fieldName]));
    return updatedItem;
  });
}

function removeHyphenFromValue(value) {
    return value ? value.replace(/-/g, '') : null;
}

export function regNoFormatter(regNo) {
  if(!regNo) { return; }
  const extractedRegNo = regNo.replace(/\D/g, '');
  if(extractedRegNo.length !== 13) { return; }
  return extractedRegNo.replace(/(\d{6})(\d{7})/, '$1-$2');
}

export function bizNoFormatter(regNo) {
  if(!regNo) { return; }
  return regNo.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
}

export function phoneNumberFormatter(data = "") {
  if (data?.startsWith("050")) {
    if (4 < data?.length) {
      if (data?.length < 9) {
        return data?.replace(/^([\d*]{4})([\d*]*)$/, "$1-$2");
      } else if (data?.length < 13) {
        return data?.replace(/^([\d*]{4})([\d*]*)([\d*]{4})$/, "$1-$2-$3");
      }
    }
  } else if (data?.startsWith("02")) {
    if (2 < data?.length) {
      if (data?.length < 7) {
        return data?.replace(/^([\d*]{2})([\d*]*)$/, "$1-$2");
      } else if (data?.length < 11) {
        return data?.replace(/^([\d*]{2})([\d*]*)([\d*]{4})$/, "$1-$2-$3");
      }
    }
  } else {
    if (3 < data?.length) {
      if (data?.length === 8) {
        return data?.replace(/^([\d*]{4})([\d*]{4})$/, "$1-$2");
      } else if (data?.length < 8) {
        return data?.replace(/^([\d*]{3})([\d*]*)$/, "$1-$2");
      } else if (data?.length < 12) {
        return data?.replace(/^([\d*]{3})([\d*]*)([\d*]{4})$/, "$1-$2-$3");
      }
    }
  }

  switch (data?.length) {
    case 8:
      return data?.replace(/^([\d*]{4})([\d*]{4})$/, "$1-$2");
    case 9:
      return data?.replace(/^([\d*]{2})([\d*]{3})([\d*]{4})$/, "$1-$2-$3");
    case 10:
      return data?.replace(/^([\d*]{3})([\d*]{3})([\d*]{4})$/, "$1-$2-$3");
    case 11:
      return data?.replace(/^([\d*]{3})([\d*]{4})([\d*]{4})$/, "$1-$2-$3");
    case 12:
      return data?.replace(/^([\d*]{4})([\d*]{4})([\d*]{4})$/, "$1-$2-$3");
  }

  return data;
}
