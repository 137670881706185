export const PATH_LOG = '/log-management';

export function getUserLoginHist(args) {
    return  this.http.get(`${PATH_LOG}/login-history`, {
        params: {...args},
    });
}

export function getProgramUseHist(args) {
    return this.http.get(`${PATH_LOG}/program/use-history`, {
        params: {...args},
    });
}
export function createProgramUseHist(args) {
    return this.http.post(`${PATH_LOG}/program/use-history`, args);
}

export function getProgramButtonUseHist(args) {
    return this.http.get(`${PATH_LOG}/program/button-history`, {
        params: {...args},
    });
}
export function createProgramButtonUseHist(data) {
    return this.http.post(`${PATH_LOG}/program/button-history`, data, {
        isBackground: true,
        isErrorMessageView: false,
    });
}

export function getHomePagePasswordChangeHist(args) {
    return this.http.get(`${PATH_LOG}/change-password/home`, {
        params: {...args},
    });
}
export function getErpPasswordChangeHist(args) {
	return this.http.get(`${PATH_LOG}/change-password/erp`, {
		params: {...args},
	});
}

