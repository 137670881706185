<template>
  <span>{{ parseInt(data.index) + 1 + pageSize * (currentPage - 1) }}</span>
</template>

<script>
import gridTemplateDestroyMixin from "../../../mixins/gridTemplateDestroyMixin";

export default {
  name: "gridNumberTemplate",
  mixins: [gridTemplateDestroyMixin],
  props: {
    pageSize: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      data: {},
    };
  },
};
</script>
