<template>
  <input
    ref="input"
    class="input-text"
    v-bind="inputAttributes"
    spellcheck="false"
    autocomplete="off"
    v-on="inputListeners"
  />
</template>

<script>
export default {
  name: "InputText",
  data() {
    return {
      previousValue: this.$attrs.value || "",
      previousInputValue: this.$attrs.value || "",

      defaultAutoComplete: "off",
      defaultClasses: ["e-input"],
      defaultMaxLength: 50,

      inputListeners: Object.assign({}, this.$listeners, {
        input: (event) => {
          if (
            event.target.value !== "" &&
            this.inputAttributes.digitOnly &&
            !/^\d+$/.test(event.target.value)
          ) {
            event.preventDefault();
            event.target.value = this.previousInputValue;

            return;
          }

          this.previousInputValue = event.target.value;
          this.$emit("input", event.target.value);
        },
        /**
         * @deprecated since of using syncfusion
         * @returns {string}
         */
        change: (event) => {
          const newValue = event.target.value;
          this.$emit("change", {
            ...event,
            event,
            value: newValue,
            previousValue: this.previousValue,
          });

          this.previousValue = newValue;
        },
        /**
         * @deprecated since of using syncfusion
         * @returns {string}
         */
        blur: (event) => {
          this.$emit("blur", {
            ...event,
            event,
            value: this.value,
          });
        },
        keypress: (event) => {
          if (this.inputAttributes.digitOnly) {
            if (isNaN(Number(event.key))) {
              event.preventDefault();
            }
          }
        },
      }),
    };
  },
  computed: {
    /**
     * @deprecated since of using syncfusion
     * @returns {string}
     */
    value() {
      return this.$attrs.value;
    },
    inputAttributes() {
      const classes = Object.assign([], this.defaultClasses);
      if (typeof this.$attrs.class === "string") {
        classes.push(this.$attrs.class);
      } else if (Array.isArray(this.$attrs.class)) {
        classes.concat(classes);
      } else if (typeof this.$attrs.class === "object") {
        classes.push(this.$attrs.class);
      }

      return Object.assign({}, this.$attrs, {
        autocomplete: this.$attrs.autocomplete || this.defaultAutoComplete,
        class: classes,
        type: "text",
        maxlength: this.$attrs.maxlength || this.defaultMaxLength,
        digitOnly:
          this.$attrs["digit-only"] === "" ||
          this.$attrs["digit-only"] === "digit-only" ||
          Boolean(this.$attrs["digit-only"]),
      });
    },
  },
  methods: {
    /**
     * @deprecated since of using syncfusion
     */
    allFocus() {
      this.$refs["input"].select();
    },
    focusIn() {
      this.$refs["input"].focus();
    },
    focus() {
      this.$refs["input"].focus();
    },
    blur() {
      this.$refs["input"].blur();
    },
    setEmpty() {
      this.previousValue = "";
    },
  },
};
</script>

<style scoped>
input.input-text:focus {background-color: rgba(250, 244, 192, 0.2) !important;}
</style>
