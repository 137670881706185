import { stringify as qs_stringify } from "qs";

export const PATH_MEMBER = `/members`;
export const PATH_MEMBER_MANUFACTURE = `/member`;
export const PATH_MEMBER_MANAGEMENT = `/member_management`;
export const PATH_MEMBER_GRADE_MANAGEMENT = `/member-grade-management`;
export const PATH_MEMBERSHIP = `/memberships`;

/**
 * 연회비 리스트 조회
 *  */
export function fetchMembershipAnnualFee(args) {
  return this.http.get(`${PATH_MEMBER}/membership-annual-fee`, {
    params: {
      membershipId: args.membershipId,
    },
  });
}
/**
 * 연회비 상세 내역 조회
 *  */
export function fetchMembershipAnnualFeeDetail(args) {
  return this.http.get(`${PATH_MEMBER}/membership-annual-fee/detail`, {
    params: {
      annualFeeId: args.annualFeeId,
    },
  });
}
/**
 * 연회비 상세 내역 수정, 추가
 *  */
export function membershipAnnualFeeSave(args) {
  return this.http.patch(`${PATH_MEMBER}/membership-annual-fee`, args);
}
/**
 * 연회비 상세 내역 삭제
 *  */
export function deleteMembershipAnnualFee(args) {
  return this.http.delete(`${PATH_MEMBER}/membership-annual-fee`, {
    params: {
      annualFeeId: args.annualFeeId,
    },
  });
}
/**
 * 주소 시군 조회
 *  */
export function fetchPostSigun() {
  return this.http.get(`${PATH_MEMBER}/post-sigun`);
}

export function getMemberGradeChangeHistoryCouponPub(args) {
  return this.http.get(`${PATH_MEMBER_GRADE_MANAGEMENT}/issue-coupons`, {
    paramsSerializer(params) {
      return qs_stringify(params, { arrayFormat: "indices", allowDots: true });
    },
    params: args,
  });
}

export function postMemberGradeChangeHistoryCouponPub(args) {
  return this.http.post(`${PATH_MEMBER_GRADE_MANAGEMENT}/issue-coupons`, args, {
    timeout: 600000,
  });
}

export function createMember(member) {
  return this.http.post(`${PATH_MEMBER}`, member);
}

export function fetchMember(id, isOut) {
  return this.http.get(`${PATH_MEMBER}/${id}`, {
    params: {
      isOut
    },
  });
}

export function fetchMemberHistory(id) {
  return this.http.get(`${PATH_MEMBER}/${id}/history`);
}

export function patchMember(memberId, member) {
  return this.http.patch(`${PATH_MEMBER}/${memberId}`, member);
}

export function deleteMember(memberId) {
  return this.http.delete(`${PATH_MEMBER}/${memberId}`);
}

export function createMemberWebIdInformation(memberId, webIdInformation) {
  return this.http.post(
    `${PATH_MEMBER}/${memberId}/webIdInformation`,
    webIdInformation
  );
}

export function patchMemberWebIdInformation(memberId, webIdInformation) {
  return this.http.patch(
    `${PATH_MEMBER}/${memberId}/webIdInformation`,
    webIdInformation
  );
}

export function deleteMemberImageFile(memberId) {
  return this.http.delete(`${PATH_MEMBER}/${memberId}/imageFile`);
}

export function fetchCouponPubDetailList() {
  return this.http.get(`${PATH_MEMBER_MANAGEMENT}/coupon/pub/list`, {});
}

export function fetchCouponUnitList(dcKind, useFlag) {
  return this.http.get(`${PATH_MEMBER_MANAGEMENT}/coupon/unit`, {
    params: {
      dcKind,
      useFlag,
    },
  });
}

export function fetchCouponUnitDetail(kindId) {
  return this.http.get(`${PATH_MEMBER_MANAGEMENT}/coupon/unit/detail`, {
    params: {
      kindId,
    },
  });
}

export function postCouponUnitDetail(couponRequest) {
  return this.http.post(
    `${PATH_MEMBER_MANAGEMENT}/coupon/unit/detail`,
    couponRequest
  );
}

export function postCouponPub(args) {
  return this.http.post(`/deep-search-members/issue-coupons`, args, {
    timeout: 1000 * 60 * 60,
  });
}

export function deleteCouponUnitDetail(args) {
  return this.http.delete(`${PATH_MEMBER_MANAGEMENT}/coupon/unit/detail`, args);
}

export function deleteCouponPubStatus(args) {
  return this.http.delete(`${PATH_MEMBER_MANAGEMENT}/coupon/pub-status`, args);
}

export function fetchCouponUnitHistory(kindId) {
  return this.http.get(`${PATH_MEMBER_MANAGEMENT}/coupon/unit/history`, {
    params: {
      kindId,
    },
  });
}

export function fetchCouponPubStatusHistory(couponId) {
  return this.http.get(
    `${PATH_MEMBER_MANAGEMENT}/coupon/pub-status/detail/history`,
    {
      params: {
        couponId,
      },
    }
  );
}
export function putCouponPubStatusDetail(couponRequest) {
  return this.http.put(
    `${PATH_MEMBER_MANAGEMENT}/coupon/pub-status/detail`,
    couponRequest
  );
}

export function postCouponPublishPaper(couponRequest) {
  return this.http.post(
    `${PATH_MEMBER_MANAGEMENT}/coupon/pub-status/paper`,
    couponRequest
  );
}

export function fetchCouponPubStatusList(args) {
  return this.http.get(`${PATH_MEMBER_MANAGEMENT}/coupon/pub-status`, {
    params: {
      ...args,
    },
  });
}

export function fetchTicketPubStatus(args) {
  return this.http.get(`${PATH_MEMBER_MANAGEMENT}/coupon/ticket-pub-status`, {
    params: {
      ...args,
    },
  });
}

export function fetchCouponPubStatusDetail(couponId) {
  return this.http.get(`${PATH_MEMBER_MANAGEMENT}/coupon/pub-status/detail`, {
    params: {
      couponId,
    },
  });
}

export function fetchCouponUseSelect(args) {
  return this.http.get(`${PATH_MEMBER_MANAGEMENT}/coupon/coupon-use-select`, {
    params: args,
  });
}

export function fetchCouponUseCheck(args) {
  return this.http.get(`${PATH_MEMBER_MANAGEMENT}/coupon/coupon-use-check`, {
    params: args,
  });
}

export function fetchCouponPubPaperInfo() {
  return this.http.get(`${PATH_MEMBER_MANAGEMENT}/coupon/pub-status/paper`, {
    params: {},
  });
}

export function couponPubPrintConfirm(args) {
  return this.http.patch(`${PATH_MEMBER_MANAGEMENT}/coupon/pub-status/print-confirm`, args);
}

export function fetchTgMembershipFitResves(membershipId, bizCode) {
  return this.http.get(`${PATH_MEMBER_MANUFACTURE}/membership-fit-resves`, {
    params: {
      membershipId: membershipId,
      bizCode: bizCode,
    },
  });
}

export function fetchTgMembershipFitPaymts(membershipId, bizCode) {
  return this.http.get(`${PATH_MEMBER_MANUFACTURE}/membership-fit-paymts`, {
    params: {
      membershipId: membershipId,
      bizCode: bizCode,
    },
  });
}

export function patchTgMembershipFitResves(tgMembershipFitResves) {
  return this.http.patch(
    `${PATH_MEMBER_MANUFACTURE}/membership-fit-resves`,
    tgMembershipFitResves
  );
}

export function patchTgMembershipFitPaymts(tgMembershipFitPaymts) {
  return this.http.patch(
    `${PATH_MEMBER_MANUFACTURE}/membership-fit-paymts`,
    tgMembershipFitPaymts
  );
}

export function fetchMembership(membershipId) {
  return this.http.get(`${PATH_MEMBERSHIP}/${membershipId}`);
}

export function fetchLinkMembership(membershipId, isBackground = false) {
  return this.http.get(`${PATH_MEMBERSHIP}/${membershipId}/link`, {
    isBackground: isBackground,
  });
}

export function createWebIdMember(member) {
  return this.http.post(`${PATH_MEMBER}/webIdInformationMember`, member);
}

export function patchWebIdMember(memberId, member) {
  return this.http.patch(
    `${PATH_MEMBER}/webIdInformationMember/${memberId}`,
    member
  );
}

export function postAutoIndividualBenefitSetting(data) {
  return this.http.post(`${PATH_MEMBERSHIP}/auto-individual-benefit-setting`, data);
}

export function fetchTicketPubInfo(couponStartStr) {
  console.log("couponStartStr.===>", couponStartStr);
  return this.http.get(`${PATH_MEMBER_MANAGEMENT}/coupon/ticket-pub-info`, {
    params: {
      couponStartStr,
    },
  });
}
