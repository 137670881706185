<template>
  <div class="sideRight-content">
    <div class="sideRight-header">
      <div class="header-left">
        <div class="header-title">회원 예약/내장</div>
      </div>
      <div class="header-right">
        <ul class="header-button">
          <li :class="isRightExtendActive ? 'reduce' : 'extend'">
            <erp-button
              button-div="GET"
              :tabindex="-1"
              @click.native="onExtendButtonClicked"
            >
              확장/축소
            </erp-button>
          </li>
          <li class="close">
            <erp-button button-div="CLOSE" @click.native="onCloseButtonClicked" :tabindex="-1">
              닫기
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="sideRight-body">
      <div class="window memberView">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field bsnDate">
                    <div class="content">
                      <ul class="row">
                        <li class="item form-group input">
                          <div class="form">
                            <input-date
                              ref="visitSearchDatePicker"
                              v-model="bsnDate"
                              type="lookup-condition"
                              :notClear="true"
                              :isGetDateInfo="true"
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="lookup-right">
                <div class="lookup-detail">
                  <erp-button
                    button-div="GET"
                    :disabled="commonMixinIsButtonDisableByAuth('commonMemberResveVisitGet')"
                    @click.native="onSearchDetailPopupOpen"
                  >
                    상세검색
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field memberName">
                    <div class="content">
                      <ul class="row">
                        <li
                          class="item form-group input"
                          :style="commonCodesGetStandardInfo('courseClass') === 'R' ? 'width: calc(100% - 106px)' : ''"
                        >
                          <div class="form">
                            <input-text
                              ref="memberName"
                              placeholder="회원명, 연락처 4자리"
                              v-model="searchOptions.memberName"
                              @click="onMemberNameClick"
                              @change="onMemberNameChange"
                            />
                          </div>
                        </li>
                        <li class="item form-group button">
                          <ul class="button">
                            <li class="search">
                              <erp-button
                                button-div="GET"
                                :disabled="commonMixinIsButtonDisableByAuth('commonMemberResveVisitGet')"
                                @click.native="onMemberFindButtonClicked"
                              >
                                조회
                              </erp-button>
                            </li>
                          </ul>
                        </li>
                        <li
                          class="item"
                          v-if="commonCodesGetStandardInfo('courseClass') === 'R'"
                        >
                          <ul class="check">
                            <li>
                              <label>
                                <input
                                  type="checkbox"
                                  v-model="isLinkMember"
                                  @change="isLinkMemberChanged"
                                />
                                <i></i>
                                <div class="label">
                                  연계회원
                                </div>
                              </label>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="lookup-right">
                <div class="lookup-lookup">
                  <erp-button
                    button-div="GET"
                    :disabled="commonMixinIsButtonDisableByAuth('commonMemberResveVisitGet')"
                    @click="searchButtonClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="body-card" v-if="!!memberInfo.membershipId">
              <ul class="box">
                <li class="card">
                  <div class="photo">
                    <div
                      class="item"
                      :style="{
                        backgroundImage: memberInfo.imagePath
                          ? 'url(' +
                          memberInfo.imagePath +
                          ')'
                          : null,
                        backgroundColor: memberInfo.imagePath
                          ? '#fff'
                          : 'transparent',
                      }"
                    />
                  </div>
                  <ul class="content">
                    <li class="item">
                      <strong>{{ memberInfo.memberName }}</strong>
                      <span v-if="memberInfo.memberGrade">
                        ({{ commonCodesGetComName('MEMBER_GRADE', memberInfo.memberGrade) }})
                      </span>
                    </li>
                    <li class="item">
                      {{ getFormattedPhoneNumber(memberInfo.contactTel) }}
                    </li>
                    <li class="item">
                      가용횟수 주중 {{ memberInfo.weekdayLmttCnt }}회 주말 {{ memberInfo.weekendLmttCnt }}회
                    </li>
                    <li class="item">
                      {{ memberInfo.workPlace }} (근무처)
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">골프예약</div>
                      <div class="header-caption">[{{ reservations.length }}건]</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="memberReservationGrid"
                      :height="!!memberInfo.membershipId ? 388 : 512"
                      :provides="grid"
                      :columns="reservationsGridColumns"
                      :noColumnWidth="35"
                      :dataSource="reservations"
                      :selectionSettings="selectionSettings"
                      :allowFiltering="false"
                      :allowSorting="false"
                      :allowGrouping="true"
                      :groupSettings="memberReservationGridGroupSettings"
                      :aggregates="memberReservationGridAggregates"
                      @headerCellInfo="memberReservationGridHeaderCellInfo"
                      @queryCellInfo="memberReservationGridQueryCellInfo"
                    />
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">최근 5회 방문</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="memberVisitGrid"
                      :height="139"
                      :provides="grid"
                      :columns="visitGridColumns"
                      :noColumnWidth="40"
                      :dataSource="visits"
                      :selectionSettings="selectionSettings"
                      :allowFiltering="false"
                      :allowSorting="false"
                      @headerCellInfo="memberVisitGridHeaderCellInfo"
                      @queryCellInfo="memberVisitGridQueryCellInfo"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
    <reservation-info-popup
      v-if="isReservationInfoPopupOpen"
      ref="reservationInfoPopup"
      @popupConfirmed="onReservationInfoPopupConfirm"
      @popupClosed="onReservationInfoPopupClose"
    />
    <right-member-view-search-detail-popup
      v-if="isSearchDetailPopupOpen"
      ref="rightMemberViewSearchDetailPopup"
      :searchOptions="searchOptions"
      @popupSearch="searchButtonClicked"
      @popupInit="onInitButtonClicked"
      @popupClosed="onSearchDetailPopupClose"
    />
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {
  Aggregate,
  ExcelExport,
  Filter,
  ForeignKey,
  Group,
  Resize,
  Page,
} from '@syncfusion/ej2-vue-grids';
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import {
  commonCodesGetColorValue,
  commonCodesGetComName,
  commonCodesGetCommonCode,
  commonCodesGetStandardInfo,
} from '@/utils/commonCodes';
import {getTodayDate} from '@/utils/date';
import {getFormattedPhoneNumber} from '@/utils/string';
import {formPreventEnterEvent} from '@/utils/formUtil';
import {gridUtilGetTelColumnAccess} from '@/utils/gridUtil';
import {openNewWindow} from '@/utils/appInfo';
import EjsGridWrapper from '@/components/common/EjsGridWrapper';
import InputText from '@/components/common/text/InputText';
import InputDate from '@/components/common/datetime/InputDate';
import reservationInfoPopup from "@/views/common/ReservationInfoPopup";
import RightMemberViewSearchDetailPopup from "@/views/layout/components/rightComponents/popup/RightMemberViewSearchDetailPopup";
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import {mapGetters, mapMutations} from 'vuex';
import ErpButton from "@/components/button/ErpButton";

export default {
  name: 'RightMemberView',
  components: {
    EjsGridWrapper,
    InputText,
    InputDate,
    reservationInfoPopup,
    RightMemberViewSearchDetailPopup,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  computed: {
    ...mapGetters({
      isRightExtendActive: "isRightExtendActive",
      _isReservationInfoPopupOpen: "isRightMemberPopupOpened"
    }),
    isReservationInfoPopupOpen: {
      get() {
        return this._isReservationInfoPopupOpen;
      },
      set(isReservationInfoPopupOpen) {
        this.SET_IS_RIGHT_MEMBER_POPUP_OPENED(isReservationInfoPopupOpen);
      }
    },
  },
  async created() {
    this.bsnDate = await getTodayDate();
  },
  data() {
    return {
      isLinkMember: false,
      commonCodeFields: {text: 'comName', value: 'comCode'},
      grid: [Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey, Page],
      selectionSettings: {
        type: 'Single',
        mode: 'Both',
        enableToggle: false,
        persistSelection: false,
      },
      visitGridColumns: [
        {
          field: 'chkinId',
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: 'visitDate',
          headerText: '내장일자',
          width: 80,
          textAlign: 'center',
        },
        {
          field: 'dropdownMenu',
          headerText: '',
          width: 24,
          template: () => {
            return {
              template: {
                extends: Vue.component('visitGridDropdownTemplate', {
                  template:
                      '<div class=\'dropdownbutton\' style=\'\'><ejs-dropdownbutton :items=\'meunItems\' :select=\'onMenuSelect\'></ejs-dropdownbutton></div>',
                  props: ['visitGridMenuSelectedCallback'],
                  data() {
                    return {
                      meunItems: [],
                      data: {
                        data: {},
                      },
                    };
                  },
                  mounted() {
                    this.meunItems = [
                      {
                        text: '내장화면이동',
                        value: 'VISIT_PAGE_MOVE',
                      },
                    ];
                  },
                  methods: {
                    onMenuSelect: function(args) {
                      let eventParam = {
                        menu: args.item.value,
                        data: this.data,
                      };
                      this.visitGridMenuSelectedCallback(eventParam);
                    },
                  },
                }),
                propsData: {
                  visitGridMenuSelectedCallback: this.onVisitGridMenuSelected,
                },
              },
            };
          },
          type: 'string',
          allowFiltering: false,
        },
        {
          field: 'dwCode',
          headerText: '요일',
          width: 50,
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'DW_CODE',
        },
        {
          field: 'bsnCode',
          headerText: '영업',
          width: 50,
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'BSN_CODE',
        },
        {
          field: 'startTime',
          headerText: '시간',
          width: 50,
          textAlign: 'center',
        },
        {
          field: 'startCourse',
          headerText: '코스',
          width: 60,
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'COURSE_CODE',
        },
        {
          field: 'resveName',
          headerText: '예약자명',
          width: 90,
        },
        {
          field: 'grpName',
          headerText: '단체명',
          width: 90,
        },
        {
          field: 'visitName1',
          headerText: '내장객(1)',
          width: 90,
        },
        {
          field: 'visitName2',
          headerText: '내장객(2)',
          width: 90,
        },
        {
          field: 'visitName3',
          headerText: '내장객(3)',
          width: 90,
        },
        {
          field: 'visitName4',
          headerText: '내장객(4)',
          width: 90,
        },
        {
          field: 'visitName5',
          headerText: '내장객(5)',
          width: 90,
        },
        {
          field: 'insertUserName',
          headerText: '등록자',
          width: 90,
        },
        {
          field: 'insertDt',
          headerText: '등록일시',
          width: 130,
          textAlign: 'center',
        },
      ],
      reservationsGridColumns: [
        {
          field: 'timeId',
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: 'yearMonth',
          headerText: '구분',
          visible: false,
        },
        {
          field: 'monthDayLabel',
          headerText: '일자',
          width: 53,
          textAlign: 'center',
        },
        {
          field: 'dropdownMenu',
          headerText: '',
          width: 24,
          template: () => {
            return {
              template: {
                extends: Vue.component('reservationGridDropdownTemplate', {
                  template:
                      '<div class=\'dropdownbutton\' style=\'\'><ejs-dropdownbutton :items=\'meunItems\' :select=\'onMenuSelect\'></ejs-dropdownbutton></div>',
                  props: ['reservationGridMenuSelectedCallback'],
                  data() {
                    return {
                      meunItems: [],
                      data: {
                        data: {},
                      },
                    };
                  },
                  mounted() {
                    this.meunItems = [
                      {
                        text: '예약화면이동',
                        value: 'RESERVATION_PAGE_MOVE',
                      },
                    ];
                  },
                  methods: {
                    onMenuSelect: function(args) {
                      let eventParam = {
                        menu: args.item.value,
                        data: this.data,
                      };
                      this.reservationGridMenuSelectedCallback(eventParam);
                    },
                  },
                }),
                propsData: {
                  reservationGridMenuSelectedCallback: this
                      .onReservationGridMenuSelected,
                },
              },
            };
          },
          type: 'string',
          allowFiltering: false,
        },
        {
          field: 'dwCode',
          headerText: '요일',
          width: 60,
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'DW_CODE',
        },
        {
          field: 'bsnCode',
          headerText: '영업',
          width: 60,
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'BSN_CODE',
        },
        {
          field: 'resveTime',
          headerText: '시간',
          width: 60,
          textAlign: 'center',
        },
        {
          field: 'resveCourse',
          headerText: '코스',
          width: 60,
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'COURSE_CODE',
        },
        {
          field: 'resveName',
          headerText: '예약자명',
          width: 90,
        },
        {
          field: 'memberDiv',
          headerText: '회원구분',
          width: 90,
          isCommonCodeField: true,
          groupCode: 'MEMBER_DIV',
        },
        {
          field: 'memberGrade',
          headerText: '회원등급',
          width: 90,
          isCommonCodeField: true,
          groupCode: 'MEMBER_GRADE',
        },
        {
          field: 'contactTel',
          headerText: '연락처',
          width: 100,
          textAlign: 'center',
          valueAccessor: gridUtilGetTelColumnAccess,
        },
        {
          field: 'grpName',
          headerText: '단체명',
          width: 90,
        },
        {
          field: 'resveRemarks',
          headerText: '예약비고',
          width: 150,
        },
        {
          field: 'insertUserName',
          headerText: '등록자',
          width: 90,
        },
        {
          field: 'insertDt',
          headerText: '등록일시',
          width: 130,
          textAlign: 'center',
        },
      ],
      memberReservationGridGroupSettings: {
        showDropArea: false,
        columns: ['yearMonth'],
      },
      memberReservationGridAggregates: [
        {
          columns: [
            {
              field: 'resveDate',
              aggregationType: 'GroupCaption',
              customAggregate: (aggregates) => {
                return moment(aggregates.items[0].resveDate)
                    .format('M')
                    .concat('월');
              },
            },
            {
              field: 'dwCode',
              aggregationType: 'GroupCaption',
              customAggregate: (aggregates) => {
                let weekdayCnt = 0;
                aggregates.items.forEach((data) => {
                  if (data.bsnCode === 'WEEKDAY') {
                    weekdayCnt += 1;
                  }
                });
                return `주중 ${weekdayCnt}`;
              },
            },
            {
              field: 'bsnCode',
              aggregationType: 'GroupCaption',
              customAggregate: (aggregates) => {
                let weekendCnt = 0;
                aggregates.items.forEach((data) => {
                  if (data.bsnCode === 'WEEKEND') {
                    weekendCnt += 1;
                  }
                });
                return `주말 ${weekendCnt}`;
              },
            },
          ],
        },
      ],
      isSearchDetailPopupOpen: false,
      memberPopupPosition: {x: 'center', y: 'center'},
      bsnDate: moment().format(DATE_FORMAT_YYYY_MM_DD),
      searchOptionsDataSource: {
        courseCodeOptions: commonCodesGetCommonCode('COURSE_CODE', true),
        bsnCodeOptions: commonCodesGetCommonCode('BSN_CODE', true),
        partDivOptions: commonCodesGetCommonCode('PART_DIV', true),
        dwCodeOptions: commonCodesGetCommonCode('DW_CODE', true),
        memberDivOptions: commonCodesGetCommonCode('MEMBER_DIV', true),
      },
      searchOptions: {
        memberName: null,
        memberNo: null,
        membershipId: null,
        courseCodes: [],
        bsnCodes: [],
        partDivs: [],
        dwCodes: [],
        memberDivs: [],
        grpName: null,
        contactTelIdx: null,
        remarks: null,
      },
      memberInfo: {
        memberId: null,
        memberName: null,
        memberGrade: null,
        membershipId: null,
        contactTel: null,
        benefitReservationContents: null,
        workPlace: null,
        imageId: null,
        imagePath: null,
        weekdayLmttCnt: 0,
        weekendLmttCnt: 0,
      },
      reservations: [],
      visits: [],
    };
  },
  methods: {
    ...mapMutations(["SET_IS_RIGHT_MEMBER_POPUP_OPENED"]),
    commonCodesGetStandardInfo,
    commonCodesGetComName,
    getFormattedPhoneNumber,
    formPreventEnterEvent,
    onExtendButtonClicked() {
      if (this.isRightExtendActive) {
        this.$EventBus.$emit('reduceRightMenu');
      } else {
        this.$EventBus.$emit('extendRightMenu');
      }
    },
    onCloseButtonClicked() {
      this.$EventBus.$emit('closeRightMenu');
    },
    onMemberNameClick() {
      this.searchOptionsMemberInfoInit();
    },
    onMemberNameChange(args) {
      if (args.event && args.previousValues !== args.value) {
        if (args.value === '') {
          this.searchOptionsMemberInfoInit();
          return;
        }
        this.reservationInfoPopupOpen(args.value);
      }
    },
    searchOptionsMemberInfoInit() {
      this.searchOptions.memberName = null;
      this.searchOptions.memberNo = null;
      this.searchOptions.membershipId = null;
    },
    searchOptionsMemberInfoSetting(data) {
      this.bsnDate = data.resveDate;
      this.searchOptions.memberName = data.resveName;
      this.searchOptions.memberNo = data.memberNo;
      this.searchOptions.membershipId = data.membershipId;
    },
    async onReservationInfoPopupConfirm(data) {
      if (data) {
        if (!data.resveName) {
          this.searchOptionsMemberInfoInit();
        } else {
          this.searchOptionsMemberInfoSetting(data);
          await this.searchButtonClicked();
        }
      }
    },
    async reservationInfoPopupOpen() {
      this.isReservationInfoPopupOpen = true;
      const bsnDateFrom = moment(this.bsnDate).format(DATE_FORMAT_YYYY_MM_DD);
      const bsnDateTo = moment(this.bsnDate).add(30, "day").format(DATE_FORMAT_YYYY_MM_DD);
      await this.$nextTick();
      await this.$refs.reservationInfoPopup.showReservationInfoPopup(
        bsnDateFrom,
        bsnDateTo,
        this.searchOptions.memberName,
        this.searchOptions.memberNo,
      );
    },
    onReservationInfoPopupClose() {
      this.isReservationInfoPopupOpen = false;
    },
    async onMemberFindButtonClicked() {
      await this.reservationInfoPopupOpen();
    },
    async searchButtonClicked() {
      if (!this.bsnDate) {
        this.errorToast(this.$t("main.validationMessage.requiredMessage", ["기준일자"]));
        return;
      }
      if (!this.searchOptions.memberName) {
        this.errorToast('회원을 지정후 조회해 주시기 바랍니다.');
        return;
      }

      const args = {
        membershipId: this.searchOptions.membershipId,
        bsnDate: this.bsnDate,
        courseCode: this.searchOptions.courseCodes,
        bsnCode: this.searchOptions.bsnCodes,
        partDiv: this.searchOptions.partDivs,
        dwCode: this.searchOptions.dwCodes,
        memberDiv: this.searchOptions.memberDivs,
        grpName: this.searchOptions.grpName,
        contactTelIdx: this.searchOptions.contactTelIdx,
        resveRemarks: this.searchOptions.remarks,
        memberName: this.searchOptions.membershipId ? null : this.searchOptions.memberName,
        isLinkMember: this.isLinkMember,
      };

      const data = await GolfErpAPI.fetchMemberReservationVisitSearch(args);

      let weekdayLmttCnt = 0;
      let weekendLmttCnt = 0;

      if (data?.membership?.id) {
        const membershipFitResves = data?.membership?.membershipFitResves
          ?.filter(data => data.benItem === 'TOT_CNT');
        const membershipGrpResves = data?.membership?.membershipGrpResves
          ?.filter(data => data.benItem === 'TOT_CNT');

        if (data?.membership?.hasBenefit) {
          weekdayLmttCnt =
            membershipFitResves.filter((data) => data.bsnCode === 'WEEKDAY')[0]
              ?.lmttCnt || 0;
          weekendLmttCnt =
            membershipFitResves.filter((data) => data.bsnCode === 'WEEKEND')[0]
              ?.lmttCnt || 0;
        } else {
          weekdayLmttCnt =
            membershipGrpResves.filter((data) => data.bsnCode === 'WEEKDAY')[0]
              ?.lmttCnt || 0;
          weekendLmttCnt =
            membershipGrpResves.filter((data) => data.bsnCode === 'WEEKEND')[0]
              ?.lmttCnt || 0;
        }
      }

      this.memberInfo.membershipId = data?.membership?.id;
      this.memberInfo.memberId = data?.membership?.memberId;
      this.memberInfo.memberName = data?.membership?.member.name;
      this.memberInfo.memberGrade = data?.membership?.memberGrade;
      this.memberInfo.contactTel = data?.membership?.member.phoneNumber;
      this.memberInfo.workPlace = data?.membership?.member.workPlace;
      this.memberInfo.imageId = data?.membership?.member.imageFile?.id;
      this.memberInfo.imagePath = data?.membership?.member.imageFile?.path;
      this.memberInfo.weekdayLmttCnt = weekdayLmttCnt;
      this.memberInfo.weekendLmttCnt = weekendLmttCnt;

      this.reservations = data?.reservations?.map((data) => {
        const yearMonth = moment(data.resveDate).format('YYYYMM');
        const monthDayLabel = moment(data.resveDate).format('MM-DD');
        return {
          ...data,
          yearMonth,
          monthDayLabel,
        };
      });
      this.visits = data.visits;
    },
    memberReservationGridHeaderCellInfo(args) {
      const {
        cell: {column: {field}},
        node,
      } = args;
      if (field === 'dropdownMenu') {
        node.classList.add(this.$t('className.grid.contextMenuArea'));
      }
    },
    memberReservationGridQueryCellInfo(args) {
      const field = args.column.field;
      if (field === 'bsnCode') {
        args.cell.style.color = commonCodesGetColorValue(
          'BSN_CODE',
          args.data.bsnCode,
        );
      }
    },
    memberVisitGridHeaderCellInfo(args) {
      const {
        cell: {column: {field}},
        node,
      } = args;
      if (field === 'dropdownMenu') {
        node.classList.add(this.$t('className.grid.contextMenuArea'));
      }
    },
    memberVisitGridQueryCellInfo(args) {
      const field = args.column.field;
      if (field === 'bsnCode') {
        args.cell.style.color = commonCodesGetColorValue(
          'BSN_CODE',
          args.data.bsnCode,
        );
      }
      if (field === 'resveName') {
        if (args.data.membershipId === this.memberInfo.membershipId) {
          args.cell.style.color = '#4472c4';
          args.cell.style.fontWeight = 'bold';
        }
      }
      for (let i = 1; i <= 5; i++) {
        const key = `visitName${i}`;
        if (field === key) {
          const visitMembershipId = `visitMembershipId${i}`;
          if (args.data[visitMembershipId] === this.memberInfo.membershipId) {
            args.cell.style.color = '#4472c4';
            args.cell.style.fontWeight = 'bold';
          }
        }
      }
    },
    onSearchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.rightMemberViewSearchDetailPopup.showPopup();
      });
    },
    onInitButtonClicked() {
      this.searchOptions.courseCodes = [];
      this.searchOptions.bsnCodes = [];
      this.searchOptions.partDivs = [];
      this.searchOptions.dwCodes = [];
      this.searchOptions.memberDivs = [];
      this.searchOptions.grpName = null;
      this.searchOptions.contactTelIdx = null;
      this.searchOptions.remarks = null;
    },
    onSearchDetailPopupClose() {
      this.isSearchDetailPopupOpen = false;
    },
    onVisitGridMenuSelected(args) {
      if (args.menu === 'VISIT_PAGE_MOVE' && !this.commonMixinIsButtonDisableByAuth('resveReceiptResveGet')) {
        this.visitPageMove(args.data);
      } else {
        this.errorToast(this.$t('main.popupMessage.noAuth'));
      }
    },
    onReservationGridMenuSelected(args) {
      if (args.menu === 'RESERVATION_PAGE_MOVE' && !this.commonMixinIsButtonDisableByAuth('resveReceiptResveGet')) {
        this.reservationPageMove(args.data);
      } else {
        this.errorToast(this.$t('main.popupMessage.noAuth'));
      }
    },
    visitPageMove(data) {
      if (this.$route.name !== 'frontCheckinVisit') {
        let routeData = this.$router.resolve({
          name: 'frontCheckinVisit',
          query: {
            bsnDate: data.visitDate,
            timeId: data.timeId,
          },
        });
        openNewWindow(routeData);
      } else {
        this.$EventBus.$emit('setInTheMenuOnTheRight', {
          bsnDate: data.visitDate,
          timeId: data.timeId,
        });
      }
    },
    reservationPageMove(data) {
      if (this.$route.name !== 'reservationReceiptRegistration') {
        let routeData = this.$router.resolve({
          name: 'reservationReceiptRegistration',
          query: {
            bsnDate: data.resveDate,
            timeId: data.timeId,
            resveCourse: data.resveCourse,
          },
        });
        openNewWindow(routeData);
      } else {
        this.$EventBus.$emit('setInTheMenuOnTheRight', {
          resveDate: data.resveDate,
          timeId: data.timeId,
          resveCourse: data.resveCourse,
        });
      }
    },
    /* onShowMemberImageClick(imagePath) {
      if (!imagePath) {
        return;
      }
      this.$viewerApi({
        images: [imagePath],
      });
    }, */
    isLinkMemberChanged() {
      this.searchButtonClicked();
    }
  },
};
</script>
