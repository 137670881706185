const deviceInfoKey = "Device-Infos-Serial-Van";
const usePosPrinterKey = "Use-Pos-Printer";
import store from "@/store/index";

export function checkBrowser() {
  var agt = navigator.userAgent.toLowerCase();
  if (agt.indexOf("chrome") !== -1) {
    return "Chrome";
  }
  if (agt.indexOf("opera") !== -1) {
    return "Opera";
  }
  if (agt.indexOf("staroffice") !== -1) {
    return "Star Office";
  }
  if (agt.indexOf("webtv") !== -1) {
    return "WebTV";
  }
  if (agt.indexOf("beonex") !== -1) {
    return "Beonex";
  }
  if (agt.indexOf("chimera") !== -1) {
    return "Chimera";
  }
  if (agt.indexOf("netpositive") !== -1) {
    return "NetPositive";
  }
  if (agt.indexOf("phoenix") !== -1) {
    return "Phoenix";
  }
  if (agt.indexOf("firefox") !== -1) {
    return "Firefox";
  }
  if (agt.indexOf("safari") !== -1) {
    return "Safari";
  }
  if (agt.indexOf("skipstone") !== -1) {
    return "SkipStone";
  }
  if (agt.indexOf("netscape") !== -1) {
    return "Netscape";
  }
  if (agt.indexOf("mozilla/5.0") !== -1) {
    return "Mozilla";
  }
  if (agt.indexOf("msie") !== -1) {
    // 익스플로러 일 경우
    let rv = -1;
    if (navigator.appName === "Microsoft Internet Explorer") {
      const ua = navigator.userAgent;
      const re = new RegExp("MSIE ([0-9]{1,}[.0-9]{0,})");
      if (re.exec(ua) !== null) {
        rv = parseFloat(RegExp.$1);
      }
    }
    return "Internet Explorer " + rv;
  }
}

export function checkOS() {
  let os;
  let device = "PC";
  const ua = navigator.userAgent;
  if (ua.match(/Win(dows )?NT 6\.0/)) {
    os = "Windows Vista";
  } else if (ua.match(/Win(dows )?(NT 5\.1|XP)/)) {
    os = "Windows XP";
  } else {
    if (
      ua.indexOf("Windows NT 5.1") !== -1 ||
      ua.indexOf("Windows XP") !== -1
    ) {
      os = "Windows XP";
    } else if (
      ua.indexOf("Windows NT 7.0") !== -1 ||
      ua.indexOf("Windows NT 6.1") !== -1
    ) {
      os = "Windows 7";
    } else if (
      ua.indexOf("Windows NT 8.0") !== -1 ||
      ua.indexOf("Windows NT 6.2") !== -1
    ) {
      os = "Windows 8";
    } else if (
      ua.indexOf("Windows NT 8.1") !== -1 ||
      ua.indexOf("Windows NT 6.3") !== -1
    ) {
      os = "Windows 8.1";
    } else if (
      ua.indexOf("Windows NT 10.0") !== -1 ||
      ua.indexOf("Windows NT 6.4") !== -1
    ) {
      os = "Windows 10";
    } else if (
      ua.indexOf("iPad") !== -1 ||
      ua.indexOf("iPhone") !== -1 ||
      ua.indexOf("iPod") !== -1
    ) {
      os = "Apple iOS";
      device = "Mobile";
    } else if (ua.indexOf("Android") !== -1) {
      os = "Android Phone";
      device = "Mobile";
    } else if (ua.match(/Win(dows )?NT( 4\.0)?/)) {
      os = "Windows NT";
    } else if (ua.match(/Mac|PPC/)) {
      os = "Mac OS";
    } else if (ua.match(/Linux/)) {
      os = "Linux";
    } else if (ua.match(/(Free|Net|Open)BSD/)) {
      os = RegExp.$1 + "BSD";
    } else if (ua.match(/SunOS/)) {
      os = "Solaris";
    }
  }
  if (os.indexOf("Windows") !== -1) {
    if (
      navigator.userAgent.indexOf("WOW64") > -1 ||
      navigator.userAgent.indexOf("Win64") > -1
    ) {
      os += " 64bit";
    } else {
      os += " 32bit";
    }
  }

  return { os, device };
}

export function getWigetLocalization(language) {
  const localizationObj = {};

  switch (language) {
    case "en":
      break;
    case "kr":
      localizationObj.currencysymbol = "₩";
      localizationObj.days = {
        // full day names
        names: ["일", "월", "화", "수", "목", "금", "토"],
        // abbreviated day names
        namesAbbr: ["일", "월", "화", "수", "목", "금", "토"],
        // shortest day names
        namesShort: ["일", "월", "화", "수", "목", "금", "토"],
      };
      localizationObj.months = {
        // full month names (13 months for lunar calendards -- 13th month should be "" if not lunar)
        names: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월",
          "",
        ],
        // abbreviated month names
        namesAbbr: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월",
          "",
        ],
      };
      break;
  }

  return localizationObj;
}

//기존에 작업이 되어있던게 많아 기존 methods 를 그대로 유지하며 안에 로직을 localStorage => Store 로 변경

export function saveDeviceInfo(obj,localStorageSetting = false) {
  if(localStorageSetting) {
    const kitchenPaperNumber = { kitchenPaperNumber:  obj.kitchenPaperNumber };
    changeDeviceInfo(kitchenPaperNumber);
  }
  if (obj) {
    store.dispatch("deviceSettingState/saveDeviceInfo",obj);
  }
}

export function changeDeviceInfo(obj) {
  if(obj){
    let returnObj = JSON.parse(localStorage.getItem(usePosPrinterKey));
    Object.assign(returnObj,obj);
    localStorage.setItem(usePosPrinterKey, JSON.stringify(returnObj));
  }
}
/**
 * 디바이스 설정값 가져오기
 * @returns {
 *  isCardUse: 카드영수증 단말기 사용여부
    cardDaemonPort: 카드영수증 단말기 daemon port
    isSerialUse: 시리얼통신용 daemon 사용여부
    serialDaemonPort: 시리얼통신용 daemon port
    isBillPrinterUse: Bill 프린터 사용여부,
    billPrinterComPort: Bill 프린터가 연결된 Com port,
    billPrinterBitRate: Bill 프린터와의 시리얼통신을 위한 bit rate
    isClientMonitorUse: 고객 모니터 사용여부
 * }
 */
export function getDeviceInfo() {
  return store.getters['deviceSettingState/getDevice'];
}
