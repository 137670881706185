<template>
  <!-- 활성 : active / 즐겨찾기 : favorites -->
  <div
    v-show="currentRouterName !== $t('main.mainRouteName')"
    class="header-favorites active"
    :class="isMenuUserMarked ? 'favorites' : null"
    @click="addRemoveMenuUserMark"
  >
    <erp-button button-div="CLOSE"> 즐겨찾기 </erp-button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "menuUserMark",
  mixins: [confirmDialogMixin],
  components: {
    ErpButton,
  },
  data() {
    return {
      currentRouterName: this.$router.currentRoute.name,
    };
  },
  watch: {
    $route() {
      this.currentRouterName = this.$router.currentRoute.name;
    },
  },
  computed: {
    ...mapGetters(["menuUserMarkList", "currentMenu"]),
    isMenuUserMarked() {
      return (
        this.menuUserMarkList.findIndex(
          (userMark) => userMark.routerName === this.currentRouterName
        ) !== -1
      );
    },
  },
  methods: {
    ...mapMutations(["SET_MENU_USER_MARKS"]),
    async addRemoveMenuUserMark() {
      if (this.isMenuUserMarked) {
        if (await this.confirm("즐겨찾기에서 삭제하시겠습니까?")) {
          const currentMenu = this.currentMenu(this.currentRouterName);

          if (currentMenu) {
            const menuUserMarks = await GolfErpAPI.deleteMenuUserMark(
              currentMenu.menuId
            );
            this.SET_MENU_USER_MARKS(menuUserMarks);
            this.infoToast("삭제하였습니다");

            this.$EventBus.$emit("leftMenuReload");
          } else {
            this.errorToast("메뉴 정보를 얻어오지 못했습니다");
          }
        }
      } else {
        if (await this.confirm("즐겨찾기에 추가하시겠습니까?")) {
          const currentMenu = this.currentMenu(this.currentRouterName);

          if (currentMenu) {
            const menuUserMarks = await GolfErpAPI.postMenuUserMark(
              currentMenu.menuId
            );
            this.SET_MENU_USER_MARKS(menuUserMarks);
            this.infoToast("추가하였습니다");

            this.$EventBus.$emit("leftMenuReload", true);
          } else {
            this.errorToast("메뉴 정보를 얻어오지 못했습니다");
          }
        }
      }
    },
  },
};
</script>
