<template>
  <div class="sideRight-content">
    <div class="sideRight-header">
      <div class="header-left">
        <div class="header-title">날씨</div>
        <div class="header-caption">
          <a
            :href="weatherInfoUrl"
            target="_blank"
          >
            [{{ weatherAreaName }}]
          </a>
        </div>
      </div>
      <div class="header-right">
        <ul class="header-button">
          <li class="refresh">
            <erp-button
              button-div="GET"
              :tabindex="-1"
              @click.native="onClickRefresh"
            >
              새로고침
            </erp-button>
          </li>
          <li :class="isRightExtendActive ? 'reduce' : 'extend'">
            <erp-button
              button-div="GET"
              :tabindex="-1"
              @click.native="onExtendButtonClicked"
            >
              확장/축소
            </erp-button>
          </li>
          <li class="close">
            <erp-button
              button-div="CLOSE"
              :tabindex="-1"
              @click.native="onCloseButtonClicked"
            >
              닫기
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="sideRight-body">
      <div class="weather-wrapper" v-if="isLoaded">
        <div
          v-for="no in getWeatherDays" :key="no"
          class="weather-box"
        >
          <div
            :class="[
              'weather-info',
              no <= 3 && 'cursor',
              getBackgroundColor(no), no === 1 && 'today',
            ]"
            @click="onChangeActiveWeatherNo(no)"
          >
            <div class="item date">
              <div class="label" :style="{ color: getDayColorValue(no) }">
                {{ getWeatherLabel(no) }}
              </div>
              <div>
                {{ getWeatherRegdate(no) }}
              </div>
            </div>
            <div class="item icon" style="margin-right: 5px">
              <div class="prec">
                <span v-show="getWeatherPrecipitation(no, 'Am') !== undefined">
                  {{ getWeatherPrecipitation(no, 'Am') }}%
                </span>
              </div>
              <div class="icon">
                <img
                  v-show="getWeatherIcon(no, 'Am')"
                  :src="getWeatherIcon(no, 'Am')"
                  alt="icon"
                />
              </div>
            </div>
            <div class="item icon">
              <div class="prec">
                <span v-show="no < 9">
                  {{ getWeatherPrecipitation(no, 'Pm') }}%
                </span>
              </div>
              <div class="icon">
                <img
                  v-show="no < 9"
                  :src="getWeatherIcon(no, 'Pm')"
                  alt="icon"
                />
              </div>
            </div>
            <div class="item info">
              <div
                v-show="getWeatherIcon(no, 'Am')"
                class="rowTp"
              >
                {{ getWeatherTemperature(no, 'Min') }}°
              </div>
              <div
                v-show="getWeatherIcon(no, 'Am')"
                class="divider"
              >
                /
              </div>
              <div class="highTp">
                {{ getWeatherTemperature(no, 'Max') }}°
              </div>
            </div>
          </div>
          <div
            v-if="no <= 3 && activeWeatherNo === no"
            class="short-weather-info"
          >
            <div
              v-if="no > 1"
              class="prev"
              @click="onChangeTabWeatherPage"
            />
            <line-chart
              :options="options"
              :styles="{height: '140px'}"
              :chart-data="chartDataSource(no, getWeatherRegdate(no))"
            />
            <div
              v-if="no > 1"
              class="next"
              @click="onChangeTabWeatherPage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.sideRight-body > .weather-wrapper {margin: 1rem}
.sideRight-body > .weather-wrapper > .weather-box {display: flex; flex-direction: column}
.sideRight-body > .weather-wrapper > .weather-box:not(:last-child) {margin-bottom: .5rem}
.sideRight-body > .weather-wrapper > .weather-box > .weather-info {display: flex; padding: .5rem 0 calc(.5rem - 2px); font-family: NanumSquare-Regular, serif; border-bottom: 2px solid rgba(0, 0, 0, .1); border-radius: 8px; background: linear-gradient(to right, #FEE590, #FFFDAE)}
.sideRight-body > .weather-wrapper > .weather-box > .weather-info.cursor {cursor: pointer}
.sideRight-body > .weather-wrapper > .weather-box > .weather-info.cursor:hover {box-shadow: 0 0 0 500rem #0000FF11 inset}
.sideRight-body > .weather-wrapper > .weather-box > .weather-info.cloud {background: linear-gradient(to right, #F5F5F2, #FDFDFD)}
.sideRight-body > .weather-wrapper > .weather-box > .weather-info.rain {background: linear-gradient(to right, #C1CBD8, #CED4E2)}
.sideRight-body > .weather-wrapper > .weather-box > .weather-info.snow {background: linear-gradient(to right, #E1EFFC, #EBFDFE)}
.sideRight-body > .weather-wrapper > .weather-box > .weather-info > .item.date {display: flex; align-items: center; justify-content: center; flex-direction: column; width: 60px}
.sideRight-body > .weather-wrapper > .weather-box > .weather-info > .item.date > .label {font-size: 13px; font-weight: bold}
.sideRight-body > .weather-wrapper > .weather-box > .weather-info > .item.icon {display: flex; align-items: center; justify-content: center; width: 60px}
.sideRight-body > .weather-wrapper > .weather-box > .weather-info > .item.icon > .prec {display: flex; align-items: center; justify-content: center; width: 30px; margin: 0 3px 0 1px; color: #7294eb; font-size: 11px; font-weight: bold}
.sideRight-body > .weather-wrapper > .weather-box > .weather-info > .item.icon > .icon {width: 30px; height: 30px}
.sideRight-body > .weather-wrapper > .weather-box > .weather-info > .item.icon > .icon > img {width: inherit; height: inherit}
.sideRight-body > .weather-wrapper > .weather-box > .weather-info > .item.info {display: flex; flex: 1; align-items: center; justify-content: center}
.sideRight-body > .weather-wrapper > .weather-box > .weather-info > .item.info > .rowTp {color: #999; font-size: 13px; font-weight: bold}
.sideRight-body > .weather-wrapper > .weather-box > .weather-info > .item.info > .divider {margin: 0 5px 0 5px; color: #ccc; font-size: 10px}
.sideRight-body > .weather-wrapper > .weather-box > .weather-info > .item.info > .highTp {color: #dc6163; font-size: 15px; font-weight: bold}
.sideRight-body > .weather-wrapper > .weather-box > .short-weather-info {position: relative; margin-top: .5rem; border: 1px solid #d1d7de; border-radius: 5px}
.sideRight-body > .weather-wrapper > .weather-box > .short-weather-info > .prev,
.sideRight-body > .weather-wrapper > .weather-box > .short-weather-info > .next {display: flex; width: 8px; height: 15px; position: absolute; top: calc(50% - 7.5px); cursor: pointer}
.sideRight-body > .weather-wrapper > .weather-box > .short-weather-info > .prev {left: 8px; background-image: url(../../../../assets/images/common/accordion-left.png)}
.sideRight-body > .weather-wrapper > .weather-box > .short-weather-info > .next {left: unset; right: 8px; background-image: url(../../../../assets/images/common/accordion-right.png)}
.sideRight-body > .weather-wrapper > .weather-box > .short-weather-info > .prev:hover {background-image: url(../../../../assets/images/common/accordion-left-black.png)}
.sideRight-body > .weather-wrapper > .weather-box > .short-weather-info > .next:hover {background-image: url(../../../../assets/images/common/accordion-right-black.png)}
</style>

<script>
import moment from 'moment';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import LineChart from '@/utils/charts/LineChart.js';
import {commonCodesGetColorValue} from '@/utils/commonCodes';
import {mapGetters} from 'vuex';
import ErpButton from "@/components/button/ErpButton";

export default {
  name: 'RightWeatherView',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    LineChart,
    ErpButton,
  },
  data() {
    return {
      tmFc: moment().format('YYYYMMDD0600'),
      weatherInfo: {},
      weatherAreaName: null,
      weatherInfoUrl: null,
      activeWeatherNo: 1,
      tabWeatherPage: 1,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {enabled: false},
        hover: {animationDuration: 0},
        animation: {
          duration: 1,
          onComplete: function() {
            const self = this.chart;
            const { ctx, width, scales } = self;
            ctx.fillStyle = '#333';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            this.data.datasets.forEach(function (dataset, i) {
              const meta = self.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                const len = meta.data.length;
                if (len <= 13 || index % 2 === 0 || width > 255) {
                  const data = dataset.data[index];
                  const sky = dataset.sky[index];
                  const pty = dataset.pty[index];
                  ctx.fillText(`${data}°`, bar._model.x, bar._model.y - 5);
                  if (
                    (len <= 13 && (index % 2 === 0 || width > 255)) ||
                    (len > 13 && (index % 4 === 0 || (width > 255 && index % 2 === 0)))
                  ) {
                    const image = new Image();
                    image.src = require(`../../../../assets/images/weather/${pty || sky}.png`);
                    const xAxis = scales['x-axis-0'];
                    const yAxis = scales['y-axis-0'];
                    const x = xAxis.getPixelForTick(index);
                    const bottom = yAxis.bottom;
                    ctx.drawImage(image, x - 10, bottom + 5, 20, 20);
                  }
                }
              });
            });
          }
        },
        scales: {
          xAxes: [{
            gridLines: {display: false},
            ticks: {fontSize: 11, padding: 20},
          }],
          yAxes: [{display: false}],
        },
        layout: {padding: {top: 20}},
        legend: {display: false},
      },
      isLoaded: false,
    };
  },
  async mounted() {
    const info = sessionStorage.getItem('Standard-Infos');
    this.weatherAreaName = JSON.parse(info)?.weatherAreaName;
    this.weatherInfoUrl = JSON.parse(info)?.weatherInfoUrl;
    await this.refresh();
  },
  computed: {
    ...mapGetters(["isRightExtendActive"]),
    getWeatherDays() {
      const hour = Number(moment().format('HH'));
      return Array.from({length: hour >= 17 ? 8 : 11}, (_, i) => i + (hour >= 17 ? 4 : 1));
    },
    vilageFcstList() {
      return this.weatherInfo?.vilageFcstList;
    },
  },
  methods: {
    chartDataSource(no, date) {
      const nowHour = moment().format('HH00');
      const pureDataset = category => this.vilageFcstList
        ?.filter(item =>
          item.category === category &&
          (item.fcstTime.substr(0, 2) >= nowHour || no > 1) &&
          moment(item.fcstDate).format('M.DD') === date
        );
      const dataset = category => no > 1
        ? pureDataset(category)
            ?.slice(
              this.tabWeatherPage === 1 ? 0 : 12,
              this.tabWeatherPage === 1 ? 12 : 24
            )
        : pureDataset(category);
      const data = dataset('TMP');
      const datasets = [{
        data: data
          ?.map(item => item.fcstValue),
        sky: dataset('SKY')
          ?.map(item => this.skyCodeToWeatherCode(item.fcstValue)),
        pty: dataset('PTY')
          ?.map(item => this.ptyCodeToWeatherCode(item.fcstValue)),
        borderWidth: 1,
        borderColor: '#e2e6eb',
        backgroundColor: '#e2e6eb',
        tension: 0,
        fill: false,
      }];
      return {
        labels: data
          ?.map(item => `${item.fcstTime.substr(0, 2)}시`),
        datasets
      };
    },
    skyCodeToWeatherCode(code) {
      return [1, 1, null, 4, 10][parseInt(code || "0")];
    },
    ptyCodeToWeatherCode(code) {
      return [null, 8, 7, 6, 13][parseInt(code || "0")];
    },
    getWeatherLabel(no) {
      const dayLabels = ['오늘', '내일', '모레'];
      return no <= 3
        ? dayLabels[no - 1]
        : moment()
          .add(no - 1, 'days')
          .format('ddd');
    },
    getDayColorValue(no) {
      const day = moment()
        .add(no - 1, 'days')
        .format('ddd');
      if (day === '토' || day === '일') {
        return commonCodesGetColorValue('DW_CODE', day === '토' ? '7' : '1');
      }
      return null;
    },
    getWeatherRegdate(no) {
      return moment()
        .add(no - 1, 'days')
        .format('M.DD');
    },
    getWeatherIcon(no, meridiem) {
      const weatherIcon = this.weatherInfo[`wf${no - 1}${no < 9 ? meridiem : ''}`];
      return weatherIcon
        ? this.changeToRomanChar(
          weatherIcon.replace(/ +/g, ''),
        ) ?
          require(`../../../../assets/images/weather/${this.changeToRomanChar(
            weatherIcon.replace(/ +/g, ''),
          )}.png`)
          : null
        : null;
    },
    getWeatherTemperature(no, heightDiv) {
      return this.weatherInfo[`ta${heightDiv}${no - 1}`];
    },
    getWeatherPrecipitation(no, meridiem) {
      return this.weatherInfo[`rnSt${no - 1}${no < 9 ? meridiem : ''}`];
    },
    changeToRomanChar(text) {
      let result;
      switch (text) {
        case "맑음":
          result = "1";
          break;
        case '구름많고눈':
        case "흐리고눈":
          result = '2';
          break;
        case '구름많고비':
        case '구름많고가끔비':
        case '구름많고한때비':
          result = '3';
          break;
        case '구름많음':
          result = '4';
          break;
        case '구름조금':
          result = '5';
          break;
        case '눈':
          result = '6';
          break;
        case '눈비':
          result = '7';
          break;
        case '비':
          result = '8';
          break;
        case '흐리고비':
        case '흐리고가끔비':
        case '흐리고한때비':
          result = '9';
          break;
        case '흐림':
          result = '10';
          break;
        case '구름많고눈비':
        case "구름많고비/눈":
          result = '11';
          break;
        case '흐리고눈비':
        case "흐리고비/눈":
          result = '12';
          break;
        case '소나기':
        case '흐리고가끔소나기':
        case '흐리고한때소나기':
          result = '13';
          break;
        default:
          result = null;
          break;
      }
      return result;
    },
    getBackgroundColor(no) {
      const weatherAMName = this.weatherInfo[`wf${no - 1}${no < 9 ? 'Am' : ''}`];
      const weatherPMName = this.weatherInfo[`wf${no - 1}${no < 9 ? 'Pm' : ''}`];
      const weatherName = weatherAMName
        ? weatherAMName
        : weatherPMName;
      if (!weatherName) {
        return null;
      }
      const text = weatherName.replace(/ +/g, '');
      const clouds = [
        '구름많음',
        '구름조금',
        '흐리고가끔비',
        '흐리고한때비',
        '흐리고가끔소나기',
        '흐리고한때소나기',
        '흐림',
      ];
      if (clouds.includes(text)) {
        return 'cloud';
      }
      const rains = [
        '구름많고비',
        '구름많고가끔비',
        '구름많고한때비',
        '비',
        '소나기',
        '흐리고비',
      ];
      if (rains.includes(text)) {
        return 'rain';
      }
      const snows = [
        '구름많고눈',
        '구름많고눈비',
        '눈',
        '눈비',
        '흐리고눈비',
        "구름많고비/눈",
        "흐리고비/눈",
        "흐리고눈",
      ];
      if (snows.includes(text)) {
        return 'snow';
      }
      return null;
    },
    onExtendButtonClicked() {
      if (this.isRightExtendActive) {
        this.$EventBus.$emit('reduceRightMenu');
      } else {
        this.$EventBus.$emit('extendRightMenu');
      }
    },
    onCloseButtonClicked() {
      this.$EventBus.$emit('closeRightMenu');
    },
    onChangeActiveWeatherNo(no) {
      this.activeWeatherNo = no;
      this.tabWeatherPage = 1;
    },
    onChangeTabWeatherPage() {
      this.tabWeatherPage = this.tabWeatherPage === 1 ? 2 : 1;
    },
    onClickRefresh() {
      this.refresh();
    },
    async refresh() {
      this.isLoaded = false;
      const {status, data} = await GolfErpAPI.fetchWeathersInfo({tmFc: this.tmFc});
      if (status !== 'OK') {
        return this.errorToast('기상 정보 조회에 실패했습니다');
      }
      this.weatherInfo = data;
      this.isLoaded = true;
    },
  },
};
</script>
