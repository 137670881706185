import request from "@/utils/request";

/**
 * @return Promise
 */
export function getMemberList(searchConditions, isBackground = false) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/member_management/member/",
    method: "get",
    isBackground: isBackground,
    params: {
      memberNameNo:
        searchConditions.memberNameNo === "" ||
        searchConditions.memberNameNo === null ||
        searchConditions.memberNameNo === undefined
          ? null
          : searchConditions.memberNameNo.trim(),
      contactTelIdx:
        searchConditions.contactTelIdx === "" ||
        searchConditions.contactTelIdx === null ||
        searchConditions.contactTelIdx === undefined
          ? null
          : searchConditions.contactTelIdx.trim(),
      cprDiv: searchConditions.cprDiv === "" ? null : searchConditions.cprDiv,
      sexCodes:
        searchConditions.sexCodes !== null &&
        searchConditions.sexCodes !== undefined
          ? searchConditions.sexCodes.toString()
          : searchConditions.sexCodes,
      memberDiv:
        searchConditions.memberDiv === "" ? null : searchConditions.memberDiv,
      memberGrade:
        searchConditions.memberGrade === ""
          ? null
          : searchConditions.memberGrade,
      cprName:
        searchConditions.cprName === "" ||
        searchConditions.cprName === null ||
        searchConditions.cprName === undefined
          ? null
          : searchConditions.cprName.trim(),
      hpNoIdx:
        searchConditions.hpNoIdx === "" ||
        searchConditions.hpNoIdx === null ||
        searchConditions.hpNoIdx === undefined
          ? null
          : searchConditions.hpNoIdx.trim(),
      webId:
        searchConditions.webId === "" ||
        searchConditions.webId === null ||
        searchConditions.webId === undefined
          ? null
          : searchConditions.webId.trim(),
      groupCompanyCode:
        searchConditions.groupCompanyCode === ""
          ? null
          : searchConditions.groupCompanyCode,
      smsRecptnDiv:
        searchConditions.smsRecptnDiv === ""
          ? null
          : searchConditions.smsRecptnDiv,
      membershipStatus:
        searchConditions.membershipStatus === ""
          ? null
          : searchConditions.membershipStatus,
      acqsDtUseFlag: searchConditions.acqsDtUseFlag,
      acqsDtFrom: searchConditions.acqsDtFrom,
      acqsDtTo: searchConditions.acqsDtTo,
      birthDay:
        searchConditions.birthDay === "" ? null : searchConditions.birthDay,
      memberFlag: searchConditions.memberFlag,
      outFlag: searchConditions.outFlag,
      bsnDate: searchConditions.bsnDate,
    },
  });
}

/**
 * @return Promise
 */
export function deleteWebId(memberDetail) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/member_management/web-member/",
    method: "delete",
    data: memberDetail,
  });
}

/**
 * @return Promise
 */
export function getMemberDetail(memberId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/member_management/member/detail",
    method: "get",
    params: {
      memberId: memberId,
    },
  });
}

/**
 * @return Promise
 */
export function getMemberLinkListForRegist(memberNo, linkmemberId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/member_management/member/linkno",
    method: "get",
    params: {
      memberNo: memberNo,
      linkmemberId: linkmemberId,
    },
  });
}

/**
 * @return Promise
 */
export function getMemberAppnLockerBoards(bsnDate, appnLocker, appnMemberId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/member_management/member/appnlocker",
    method: "get",
    params: {
      bsnDate: bsnDate,
      appnLocker: appnLocker,
      appnMemberId: appnMemberId,
    },
  });
}