export const PATH_VISITORS = `/visitors`;

export function fetchVisitorBills(salesDate, storeCode, visitorId, gcDiv) {
  return this.http.get(`${PATH_VISITORS}/${visitorId}/bills`, {
    params: {
      salesDate,
      storeCode,
      gcDiv,
    },
  });
}

export function fetchCheckForTheSameVisitorName(args) {
  return this.http.get(`${PATH_VISITORS}/check-for-the-same-visitor-name`, {
    params: {
      visitDate: args.visitDate,
      visitName: args.visitName,
      visitId: args.visitId
    }
  });
}
