export const PATH_DEPOSIT_MONEY = `/deposit-money`;

export function fetchDepositMoneyMembers(args) {
  return this.http.get(`${PATH_DEPOSIT_MONEY}/members`, {
    params: {
      memberDiv: args.memberDiv,
      dpmnyStatus: args.dpmnyStatus,
      searchData: args.searchData,
      noConfirmFlag: args.noConfirmFlag,
    },
  });
}

export function fetchDepositMoneyMember(dpmnyId) {
  return this.http.get(`${PATH_DEPOSIT_MONEY}/member`, {
    params: {
      dpmnyId: dpmnyId,
    },
  });
}

export function fetchDepositMoneyMemberCount(membershipId) {
  return this.http.get(`${PATH_DEPOSIT_MONEY}/member/count`, {
    params: {
      membershipId: membershipId,
    },
  });
}

export function fetchDepositMoneyIncomCount(dpmnyId) {
  return this.http.get(`${PATH_DEPOSIT_MONEY}/incom/count`, {
    params: {
      dpmnyId: dpmnyId,
    },
  });
}

export function fetchDepositMoneyStatus(args) {
  return this.http.get(`${PATH_DEPOSIT_MONEY}/status`, {
    params: {
      dpmnyKind: args.dpmnyKind,
      dpmnyDiv: args.dpmnyDiv,
      dateFrom: args.dateFrom,
      dateTo: args.dateTo,
      searchData: args.searchData,
      confirmFlag: args.confirmFlag,
      dpmnyDivTap: args.dpmnyDivTap,
    },
  });
}

export function fetchDepositMoneyMemberBatchChange(args) {
  return this.http.get(`${PATH_DEPOSIT_MONEY}/member/batch-change`, {
    params: {
      depositMoneyStatus: args.depositMoneyStatus,
      dateFrom: args.dateFrom,
      dateTo: args.dateTo,
      searchData: args.searchData,
      smsSendFlag: args.smsSendFlag,
      isGradeChange: args.isGradeChange
    },
  });
}

export function fetchDepositMoneyHistory(args) {
  return this.http.get(`${PATH_DEPOSIT_MONEY}/incom/history`, {
    params: {
      dpmnyId: args.dpmnyId
    }
  });
}

export function depositMoneySave(depositMoneyInfo) {
  return this.http.put(`${PATH_DEPOSIT_MONEY}/save`, depositMoneyInfo);
}

export function depositMoneyDelete(incomId, dpmnyId) {
  return this.http.delete(`${PATH_DEPOSIT_MONEY}/delete`, {
    params: {
      incomId: incomId,
      dpmnyId: dpmnyId,
    },
  });
}

export function depositMoneyMemberGradeBatchChange(memberships) {
  return this.http.patch(
    `${PATH_DEPOSIT_MONEY}/member/batch-change`,
    memberships
  );
}
