import qs from 'qs';

export const PATH_PAYMENTS = `/payments`;

export function fetchCreditReturns(args) {
  return this.http.get(`${PATH_PAYMENTS}/credit-returns`, {
    params: {
      bsnStartDate: args.bsnStartDate,
      bsnEndDate: args.bsnEndDate,
      dateDiv: args.dateDiv,
      visitName: args.visitName,
      creditReturnFlag: args.creditReturnFlag,
      bsnId: args.bsnId,
      storeCode: args.storeCode,
      creditTitle: args.creditTitle,
      remarks: args.remarks,
      isApplyByDate: args.isApplyByDate,
    },
  });
}

export function fetchCreditReturnReports(args) {
  return this.http.get(`${PATH_PAYMENTS}/credit-return-reports`, {
    params: {
      bsnStartDate: args.bsnStartDate,
      bsnEndDate: args.bsnEndDate,
      visitName: args.visitName,
      creditReturnFlag: args.creditReturnFlag,
      bsnId: args.bsnId,
      storeCode: args.storeCode,
      creditTitle: args.creditTitle,
      remarks: args.remarks,
    },
  });
}

export function fetchCreditReturnPayHistory(payId) {
  return this.http.get(`${PATH_PAYMENTS}/credit-return-historys`, {
    params: {
      payId: payId,
    },
  });
}

export function fetchDeposit(depositId) {
  return this.http.get(`${PATH_PAYMENTS}/deposit`, {
    params: {
      depositId: depositId,
    },
  });
}

export function fetchDeposits(args) {
  return this.http.get(`${PATH_PAYMENTS}/deposits`, {
    params: args,
  });
}

export function fetchDepositIncom(incomId) {
  return this.http.get(`${PATH_PAYMENTS}/deposit-incom`, {
    params: {
      incomId: incomId,
    },
  });
}

export function fetchDepositIncoms(args) {
  return this.http.get(`${PATH_PAYMENTS}/deposit-incoms`, {
    params: args,
  });
}

export function fetchDepositCard(cardId) {
  return this.http.get(`${PATH_PAYMENTS}/deposit-card`, {
    params: {
      cardId: cardId,
    },
  });
}

export function fetchDepositCardCount(cardNo, cardId) {
  return this.http.get(`${PATH_PAYMENTS}/deposit-card-count`, {
    params: {
      cardNo: cardNo,
      cardId: cardId,
    },
  });
}

export function fetchDepositCards(depositId) {
  return this.http.get(`${PATH_PAYMENTS}/deposit-cards`, {
    params: {
      depositId: depositId,
    },
  });
}

export function fetchDepositUses(depositId, useCancelViewFlag) {
  return this.http.get(`${PATH_PAYMENTS}/deposit-uses`, {
    params: {
      depositId: depositId,
      useCancelViewFlag: useCancelViewFlag,
    },
  });
}

export function fetchDepositsBalanceStatus(condition, showEmptyBalanceAmount) {
  return this.http.get(`${PATH_PAYMENTS}/deposits/balanceStatus`, {
    params: {
      condition: condition,
      showEmptyBalanceAmount: showEmptyBalanceAmount,
    },
  });
}

export function postDepositUse(depositUse) {
  return this.http.post(`${PATH_PAYMENTS}/deposit-use`, {
    depositUse,
  });
}

export function postDeposit(deposit) {
  return this.http.post(`${PATH_PAYMENTS}/deposit`, deposit);
}

export function patchDeposit(deposit) {
  const depositId = deposit.depositId;
  return this.http.patch(`${PATH_PAYMENTS}/deposit`, {
    depositId,
    deposit,
  });
}

export function patchDepositIncom(depositIncom) {
  const incomId = depositIncom.incomId;
  const depositId = depositIncom.depositId;
  return this.http.patch(`${PATH_PAYMENTS}/deposit-incom`, {
    incomId,
    depositId,
    depositIncom,
  });
}

export function patchDepositCard(depositCard) {
  return this.http.patch(`${PATH_PAYMENTS}/deposit-card`, {
    depositCard,
  });
}

export function deleteDepositCard(cardId) {
  return this.http.delete(`${PATH_PAYMENTS}/deposit-card/${cardId}`);
}

export function deleteDeposit(depositId) {
  return this.http.delete(`${PATH_PAYMENTS}/deposit/${depositId}`);
}

export function deleteDepositUse(depositUse) {
  return this.http.delete(`${PATH_PAYMENTS}/deposit-use`, {
    data: depositUse,
  });
}

export function postPay(payRequest) {
  return this.http.post(`${PATH_PAYMENTS}`, payRequest);
}

export function postPayTransaction(payTransactionRequest) {
  return this.http.post(`${PATH_PAYMENTS}/transactions`, payTransactionRequest);
}

export function putPayTransaction(tid, updatePayTransactionRequest) {
  return this.http.put(`${PATH_PAYMENTS}/transactions/${tid}`, updatePayTransactionRequest);
}

export function getPayTransaction(tid) {
  return this.http.get(`${PATH_PAYMENTS}/transactions/${tid}`);
}

export function fetchCashReceipt(id) {
  return this.http.get(`${PATH_PAYMENTS}/cash-receipts/${id}`);
}

export function fetchCashReceipts(payDateFrom, payDateTo, approvalNo, proofNo) {
  return this.http.get(`${PATH_PAYMENTS}/cash-receipts`, {
    params: {
      payDateFrom: payDateFrom,
      payDateTo: payDateTo,
      approvalNo: approvalNo,
      proofNo: proofNo,
    },
  });
}

export function postPayCashReceipt(payCashReceiptRequest) {
  return this.http.post(
  `${PATH_PAYMENTS}/cash-receipts`,
  payCashReceiptRequest,
  );
}

export function patchPayCashReceipt(cashId, tid) {
  return this.http.patch(`${PATH_PAYMENTS}/cash-receipts/${cashId}/${tid}`);
}

export function fetchDepositUseReceipt(id) {
  return this.http.get(`${PATH_PAYMENTS}/deposit-use-receipt`, {
    params: {
      id: id,
    },
  });
}

export function fetchPayTax(taxRequest) {
  return this.http.get(`${PATH_PAYMENTS}/utils/tax`, {
    params: {
      currentPayAmount: taxRequest.currentPayAmount,
      totalTaxAmount: taxRequest.totalTaxAmount,
      totalNoTaxAmount: taxRequest.totalNoTaxAmount,
      totalVatAmount: taxRequest.totalVatAmount,
      totalServiceAmount: taxRequest.totalServiceAmount,
    },
  });
}

export function fetchDepositIncomeTax(notaxCode, totalAmount) {
  return this.http.get(`${PATH_PAYMENTS}/utils/deposit/tax`, {
    params: {
      notaxCode: notaxCode,
      totalAmount: totalAmount,
    },
  });
}

export function patchEventPayments(eventPayments) {
  return this.http.patch(`${PATH_PAYMENTS}/event-payments`, eventPayments);
}

export function getPaymentEventsSchedule(args) {
  return this.http.get(`${PATH_PAYMENTS}/event-payments-schedule`, {
    params: {
      bsnDate: args.bsnDate,
      addDay: args.addDay,
      memberGrade: args.memberGrade,
      timeInterval: args.timeInterval,
    },
  });
}

export function getPaymentEvent(eventId) {
  return this.http.get(`${PATH_PAYMENTS}/event-payment`, {
    params: {
      eventId: eventId,
    },
  });
}

export function patchEventPayment(tsPaymtEvent) {
  return this.http.patch(`${PATH_PAYMENTS}/event-payment`, tsPaymtEvent);
}

export function fetchPaymentStatement(args) {
  return this.http.get(`${PATH_PAYMENTS}/payment-statement`, {
    params: {
      bsnDate: args.bsnDate,
      chkinId: args.chkinId,
      grpNo: args.grpNo,
    },
  });
}

export function fetchPaymentHistory(args) {
  return this.http.get(`${PATH_PAYMENTS}/payment-history`, {
    params: {
      bsnDateFrom: args.bsnDateFrom,
      bsnDateTo: args.bsnDateTo,
      salesDiv: args.salesDiv,
      storeCode: args.storeCode,
      payDiv: args.payDiv,
      insertName: args.insertName,
      prpayFrpyDiv: args.prpayFrpyDiv,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, {arrayFormat: 'repeat'});
    },
  });
}

export function patchTgPayCreditReturns(request) {
  return this.http.patch(`${PATH_PAYMENTS}/credit-returns`, request);
}

export function fetchDepositHistory(depositId) {
  return this.http.get(`${PATH_PAYMENTS}/deposit/${depositId}/history`);
}

export function fetchDepositIncomHistory(incomId) {
  return this.http.get(`${PATH_PAYMENTS}/deposit-incom/${incomId}/history`);
}

export function fetchDepositCardHistory(cardId) {
  return this.http.get(`${PATH_PAYMENTS}/deposit-card/${cardId}/history`);
}

export function fetchDepositUseHistory(useId) {
  return this.http.get(`${PATH_PAYMENTS}/deposit-use/${useId}/history`);
}

export function fetchDepositIncomStatus(args) {
  return this.http.get(`${PATH_PAYMENTS}/deposit/incom-status`, {
    params: {
      depositKinds: args.depositKinds,
      memberName: args.memberName,
      incomDivs: args.incomDivs,
      dateFrom: args.dateFrom,
      dateTo: args.dateTo,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, {arrayFormat: 'repeat'});
    },
  });
}

export function fetchDepositUseStatus(args) {
  return this.http.get(`${PATH_PAYMENTS}/deposit/use-status`, {
    params: {
      depositKinds: args.depositKinds,
      memberName: args.memberName,
      depositUseDivs: args.depositUseDivs,
      dateFrom: args.dateFrom,
      dateTo: args.dateTo,
      useName: args.useName,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, {arrayFormat: 'repeat'});
    },
  });
}