import qs from 'qs';

export const PATH_TEAMS = '/teams';

/**
 * id
 * course
 * reserveName
 * isPaying
 * isTeamCheckout
 * checkOutDate
 * time
 * group {
 *   no
 *   name
 *   kind
 * }
 * membership {
 *   id
 *   linkId
 *   no
 *   div
 *   isRepresentative
 *   corporateDiv
 *   member {
 *     id
 *     name
 *     phoneNumber
 *     workPlace
 *     gender
 *     image {
 *       id
 *       div
 *       path
 *       name
 *       isDeleted
 *     }
 *   }
 * }
 * caddies [{
 *   id
 *   name
 *   number
 *   cart {
 *     number
 *   }
 *   image {
 *     ...
 *   }
 * }]
 * visitors [{
 *   id
 *   name
 *   order
 *   gender
 *   isCheckOut
 *   checkOutDate
 *   isDeleted
 *   membership {
 *     ...
 *   }
 *   locker {
 *     number
 *   }
 *   team {
 *     ...
 *   }
 *   salesSlip {
 *     ...
 *   }
 * }]
 * */
export function fetchTeams(
salesDate,
storeCode,
q,
isCheckOut,
courseCode,
partDiv,
) {
  return this.http.get(`${PATH_TEAMS}`, {
    params: {
      salesDate,
      storeCode,
      q,
      isCheckOut,
      courseCode,
      partDiv,
    },
  });
}

export function fetchPayLock(chkinId, errorMessageViewFlag) {
  return this.http.get(`${PATH_TEAMS}/pay-lock`, {
    params: {
      chkinId: chkinId,
      errorMessageViewFlag: errorMessageViewFlag,
    },
  });
}

export function fetchMatchProgressTimetable(visitDate) {
  return this.http.get(`${PATH_TEAMS}/match-progress-timetable`, {
    params: {
      visitDate: visitDate,
    },
  });
}

export function fetchMatchProgressTimetableDaily(visitDate) {
  return this.http.get(`${PATH_TEAMS}/match-progress-timetable/daily`, {
    params: {
      visitDate: visitDate,
    },
  });
}

export function fetchVisitScoreList(args) {
  return this.http.get(`${PATH_TEAMS}/visit-score-list`, {
    params: {
      visitDate: args.visitDate,
      time: args.time,
      courseCode: args.courseCode,
    },
  });
}

export function fetchAwardCertificate(args) {
  return this.http.get(`${PATH_TEAMS}/award-certificate`, {
    params: {
      visitDateFrom: args.visitDateFrom,
      visitDateTo: args.visitDateTo,
      crtfCode: args.crtfCode,
      visitName: args.visitName,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, {arrayFormat: 'repeat'});
    },
  });
}

export function fetchVisitByVisitDateAndLockerNo(args) {
  return this.http.get(`${PATH_TEAMS}/find-locker-no`, {
    params: {
      visitDate: args.visitDate,
      lockerNo: args.lockerNo
    }
  });
}

export function patchAwardCertificate(args) {
  return this.http.patch(`${PATH_TEAMS}/award-certificate`, args);
}

export function deleteAwardCertificate(args) {
  return this.http.delete(`${PATH_TEAMS}/award-certificate`, {
    params: {
      crtfId: args.crtfId,
    },
  });
}

export function putVisitScoreList(data) {
  return this.http.put(`${PATH_TEAMS}/visit-score-list`, data);
}

export function putMatchProgressTimetable(data) {
  return this.http.put(`${PATH_TEAMS}/match-progress-timetable`, data);
}

export function patchPayLock(args) {
  return this.http.patch(`${PATH_TEAMS}/pay-lock`, args);
}

export function fetchIsCompulsoryPayment(teamIds) {
  return this.http.get(`${PATH_TEAMS}/is-compulsory-payment`, {
    params: {
      teamIds: teamIds
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, {arrayFormat: 'repeat'});
    },
  });
}

export function postCompulsoryPayment(teamIds) {
  return this.http.post(`${PATH_TEAMS}/compulsory-payment`,teamIds);
}

export function fetchPaymtCodeCountByMembershipIds(args) {
  return this.http.get(`${PATH_TEAMS}/paymt-code-count/by-membership-id`, {
    params: {
      visitDate: args.visitDate,
      membershipIds: args.membershipIds
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, {arrayFormat: 'repeat'});
    }
  });
}

export function fetchPaymtCodeCountByChkinId(args) {
  return this.http.get(`${PATH_TEAMS}/paymt-code-count/by-check-in-id`, {
    params: {
      visitDate: args.visitDate,
      chkinId: args.chkinId
    }
  });
}