import moment from "moment";
import { Internationalization } from "@syncfusion/ej2-base";
import { getDateTime } from "@/api/common";
import { isInteger } from "@/utils/number";
import { getCalendarList } from "@/api/calendar";
import { commonCodesGetColorValue } from "./commonCodes";

moment.locale("ko");

export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";
export const DATE_FORMAT_YYYY_MM = "YYYY-MM";
export const DATE_FORMAT_YYYY_M = "YYYY-M";
export const DATE_FORMAT_YYYY = "YYYY";
export const DATE_FORMAT_M = "M";

const baseDayOfWeekCaptionData = ["일", "월", "화", "수", "목", "금", "토"];

export function getDateFromServer() {
  let momentObj = moment();
  return new Promise((resolve) => {
    const { value } = getDateTime();
    momentObj = moment(value);
    resolve(momentObj);
  });
}

export async function getTodayNavigationDate(format = "YYYY-MM-DD dddd") {
  const date = await getDateFromServer();
  return date.format(format);
}

export function getTodayDate() {
  const momentObj = moment();
  return momentObj.format("YYYY-MM-DD");
}

export function getTodayDateTime() {
  const momentObj = moment();
  return momentObj.format("YYYY-MM-DD HH:mm:ss");
}

export function getFormattedDate(date, format = "YYYY-MM-DD") {
  if (date) {
    const momentObj = moment(date);
    return momentObj.format(format);
  } else {
    return null;
  }
}

export function getAddDays(date, days = 0, format = "YYYY-MM-DD") {
  if (date) {
    return moment(date).add("day", days).format(format);
  } else {
    return null;
  }
}

export function getDayColorValue(dwCode, bsnCode = null) {
  return dwCode === '1' || dwCode === '7'
    ? commonCodesGetColorValue('DW_CODE', dwCode.toString())
    : bsnCode === 'WEEKEND'
      ? commonCodesGetColorValue('BSN_CODE', bsnCode.toString())
      : commonCodesGetColorValue('DW_CODE', dwCode.toString());
}

export function getMonthFormattedDate(date) {
  const momentObj = moment(date);
  return momentObj.format("YYYY-MM");
}

export function getFormattedDateTimePicker(dropdownArgs, format) {
  const intl = new Internationalization();
  let textVal = intl.formatDate(dropdownArgs.value, { format: format });
  return textVal;
}

export function getAge(date) {
  return date ? getFormattedDate(getTodayDate(), DATE_FORMAT_YYYY) - getFormattedDate(date, DATE_FORMAT_YYYY) + 1 : null;
}

export function getDayOfWeekAndCaption(date) {
  const momentObj = moment(date, "YYYY-MM-DD");
  let caption =
    momentObj.format("YYYY-MM-DD(ddd)") + " " + getWeekdayWeekendCaption(date);
  return caption;
}

export function getWeekdayWeekendCaption(date) {
  const momentObj = moment(date, "YYYY-MM-DD");
  let retCaption = null;

  if (momentObj.day() === 6 || momentObj.day() === 0) {
    retCaption = "주말";
  } else {
    retCaption = "주중";
  }

  return retCaption;
}

export function getWeekdayWeekendCaptionColor(date) {
  const momentObj = moment(date, "YYYY-MM-DD");
  let retColor = null;

  if (momentObj.day() === 6 || momentObj.day() === 0) {
    retColor = "#2f55f8";
  } else {
    retColor = "#000000";
  }

  return retColor;
}

// 특정 년도(year)의 오늘 날짜를 가져옴
export function getTodayNavigationDateOfYear(year) {
  const momentObj = moment();
  momentObj.year(year);
  return momentObj.format("YYYY-MM-DD");
}

export function getDayOfWeekCaption(date) {
  if (date) {
    if (typeof date === "string") {
      const momentObj = moment(date, DATE_FORMAT_YYYY_MM_DD);

      return baseDayOfWeekCaptionData[momentObj.toDate().getDay()];
    }
    if (date instanceof Date) {
      return baseDayOfWeekCaptionData[date.getDay()];
    }
  } else {
    return null;
  }
}

export function getDayOfWeekCaptionColor(date, bsnCode, dwCode) {
  if (date) {
    const dayOfWeek = date.getDay();
    if (dayOfWeek === 6) {
      return "#2f55f8";
    } else if (dayOfWeek === 0) {
      return "#f82f2f";
    }
  } else {
    if (bsnCode && dwCode) {
      return dwCode === "1" || dwCode === "7"
        ? commonCodesGetColorValue("DW_CODE", dwCode)
        : bsnCode === "WEEKEND" || bsnCode === "CLOSE"
        ? commonCodesGetColorValue("BSN_CODE", bsnCode)
        : commonCodesGetColorValue("DW_CODE", dwCode);
    }
    return null;
  }
}

export function getDayOfWeekCaptionColorByRoom(date, roomBsnCode, dwCode) {
  if (date) {
    const dayOfWeek = date.getDay();
    if (dayOfWeek === 6) {
      return "#2f55f8";
    } else if (dayOfWeek === 0) {
      return "#f82f2f";
    }
  } else {
    if (roomBsnCode && dwCode) {
      return dwCode === "1" || dwCode === "7"
        ? commonCodesGetColorValue("DW_CODE", dwCode)
        : roomBsnCode === "WEEKEND" || roomBsnCode === "SEASON"
          ? commonCodesGetColorValue("ROOM_BSN_CODE", roomBsnCode)
          : commonCodesGetColorValue("DW_CODE", dwCode);
    }
    return null;
  }
}

// FROM, TO 날짜 범위의 날짜들을 가져온다.(FROM, TO 포함)
export function getDateRange(fromDate, toDate, format = "YYYY-MM-DD") {
  if (!fromDate || !toDate) {
    return [];
  }

  let dateArr = [];
  let forDate = JSON.parse(JSON.stringify(fromDate));

  dateArr.push(moment(forDate).format(format));

  while (moment(forDate).isBefore(toDate)) {
    forDate = moment(forDate).add("day", 1).format(format);

    dateArr.push(forDate);
  }

  return dateArr;
}

// 특정 날짜의 다음 날짜를 가져옴
export function getDateOfNext(date) {
  const momentObj = moment(date);

  momentObj.add("day", 1).calendar();

  return momentObj.format("YYYY-MM-DD");
}

// 특정 날짜의 이전 날짜를 가져옴
export function getDateOfPrevious(date) {
  const momentObj = moment(date);

  momentObj.add("day", -1).calendar();

  return momentObj.format("YYYY-MM-DD");
}

/**
 * 해당 년월의 마지막 일 수를 구한다.
 * @param year {number}
 * @param month {number}
 * @returns {number}
 */
export function getEndOfMonth(year, month) {
  if (!validateYear(year)) {
    throw new Error(`Invalid year value. input: ${year}`);
  }

  if (!validateMonth(month)) {
    throw new Error(`Invalid month value. input: ${month}`);
  }

  const yearNumberPad = `${year}`.padStart(4, "0");
  const monthNumberPad = `${month}`.padStart(2, "0");

  return moment(`${yearNumberPad}-${monthNumberPad}-01`, "YYYY-MM-DD")
    .endOf("month")
    .get("date");
}

/**
 * 유효한 년도인지 확인한다.
 * @param year {number}
 * @returns {boolean}
 */
export function validateYear(year) {
  if (!isInteger(year)) {
    return false;
  }

  return 1 <= year && year <= 9999;
}

/**
 * 유효한 월인지 확인한다.
 * @param month {number}
 * @returns {boolean}
 */
export function validateMonth(month) {
  if (!isInteger(month)) {
    return false;
  }

  return 1 <= month && month <= 12;
}

/**
 * 유효한 일자인지 확인한다.
 * @param date {number}
 * @param currentYear {number}
 * @param currentMonth {number}
 * @returns {boolean}
 */
export function validateDate(date, currentYear, currentMonth) {
  if (!isInteger(date)) {
    return false;
  }

  if (!validateYear(currentYear) || !validateMonth(currentMonth)) {
    return false;
  }

  return 1 <= date && date <= getEndOfMonth(currentYear, currentMonth);
}

/**
 * 날짜 비교
 * @param d1 {Date}
 * @param d2 {Date}
 * @returns {number}
 */
export function compareDate(d1, d2) {
  const d1Moment = moment(d1);
  const d2Moment = moment(d2);

  const d1Year = d1Moment.get("year");
  const d2Year = d2Moment.get("year");
  if (d1Year !== d2Year) {
    return d1Year - d2Year < 0 ? -1 : 1;
  }

  const d1month = d1Moment.get("month");
  const d2month = d2Moment.get("month");
  if (d1month !== d2month) {
    return d1month - d2month < 0 ? -1 : 1;
  }

  const d1date = d1Moment.get("date");
  const d2date = d2Moment.get("date");
  if (d1date !== d2date) {
    return d1date - d2date < 0 ? -1 : 1;
  }

  return 0;
}

/**
 * 년도 데이터를 가져 와서 calendarListMap에 가져온 데이터를 추가한 후 휴장, 토요일인 주말, 토요일이 아닌 주말 날짜 td에 css class 추가
 * @param contentElement datepicker, daterangepicker의 dom
 * @param dateMoment 로딩할 날짜
 * @param calendarListMap 로딩한 calender 값을 가지고 있는 map
 * @param closeDayClass 휴장일 css class
 * @param weekendSaturdayClass 주말-토요일 css class
 * @param weekendNotSaturdayClass 토요일이 아닌 주말 css class
 * @returns {Promise<void>}
 */
export async function getCalendarInfoAndSetCalendarContentElementDayClassNew(
  contentElement,
  dateMoment,
  calendarListMap,
  closeDayClass = "dev-calendar-close",
  weekendSaturdayClass = "dev-calendar-weekend-saturday",
  weekendNotSaturdayClass = "dev-calendar-weekend-not-saturday"
) {
  try {
    // calendarListMap에 데이터가 없으면 api에서 조회해 옴
    const year = dateMoment.format(DATE_FORMAT_YYYY);
    const month = dateMoment.format(DATE_FORMAT_M);
    const yearMonth = dateMoment.format(DATE_FORMAT_YYYY_M);
    if (calendarListMap.get(yearMonth) === undefined) {
      const {
        value: { calendarList },
      } = await getCalendarList(year, month, true);
      calendarListMap.set(yearMonth, JSON.stringify(calendarList));

      // year, month에 해당하는 데이터를 가져와서 세팅
      const currentCalendarList = JSON.parse(calendarListMap.get(yearMonth));
      if (Array.isArray(currentCalendarList)) {
        const closeDays = currentCalendarList
          .filter((day) => day.bsnCode === "CLOSE")
          .map((dayFiltered) => dayFiltered.bsnDate);
        const weekends = currentCalendarList
          .filter((day) => day.bsnCode === "WEEKEND")
          .map((dayFiltered) => dayFiltered.bsnDate);

        setCalendarContentElementDayClass(
          contentElement,
          closeDays,
          weekends,
          closeDayClass,
          weekendSaturdayClass,
          weekendNotSaturdayClass
        );
      }
    }
  } catch (e) {
    console.error(e);
  }
}

/**
 * DateTimePicker에서 키보드 방향키로 이동 시 현재 선택한 날짜 정보를 가져온다
 * @param datePickerInstance ejs-datepicker의 ej2instance
 * @returns {moment.Moment} 현재 선택한 날짜. moment 객체로 생성하여 return
 */
export function getCurrentSelectedDateMomentInDateTimePicker(
  datePickerInstance
) {
  let currentElement = datePickerInstance.popupObj.element.querySelector(
    ".e-focused-date"
  );
  if (!currentElement) {
    currentElement = datePickerInstance.popupObj.element.querySelector(
      ".e-selected"
    );
  }
  const focusedDate = new Date(parseInt(currentElement.getAttribute("id"), 10));
  return moment(focusedDate);
}

/**
 * DatePicker의 각 날짜를 표시하는 span 태그에 ID를 붙임
 * ID 형식 : D[YYYY-MM-DD] ex) D2020-07-30
 * @param date renderDayCell 이벤트의 파라미터로 넘어오는, 현재 그리고 있는 cell의 날짜. ex) "2020-07-30"
 * @param spanElement 현재 그리고 있는 cell의 span element
 */
export function setIdInDatePickerSpan(date, spanElement) {
  spanElement.setAttribute("id", "D" + date);
}

/**
 * DatePicker, DateRangePicker의 rendreCell 이벤트에서, 각 날짜의 영업구분(주중/주말/휴장)에 따른 css class를 설정하여 날짜 색깔을 설정함
 * @param date renderDayCell 이벤트에서 넘어오는 날짜
 * @param tdElement 현재 그리고 있는 cell의 td element
 * @param calendarListMap 로딩한 calender 값을 가지고 있는 map
 */
export function setDateElementCssClassOnRenderCell(
  date,
  tdElement,
  calendarListMap
) {
  const dateYYYYM = new moment(date).format(DATE_FORMAT_YYYY_M);
  const dateYYYYMMDD = moment(date).format(DATE_FORMAT_YYYY_MM_DD);
  const currentCalendarListStr = calendarListMap.get(dateYYYYM);
  if (currentCalendarListStr) {
    const currentCalendarList = JSON.parse(currentCalendarListStr);
    const calendarInfoOfDayArray = currentCalendarList.filter(
      (day) => day.bsnDate === dateYYYYMMDD
    );
    if (calendarInfoOfDayArray.length === 1) {
      const bsnCode = calendarInfoOfDayArray[0].bsnCode;
      if (bsnCode === "CLOSE") {
        tdElement.classList.add("dev-calendar-close"); // 휴장(연한 회색)
      } else if (bsnCode === "WEEKEND") {
        const dayOfWeek = moment(date).day();
        if (dayOfWeek === 6) {
          tdElement.classList.add("dev-calendar-weekend-saturday"); // 주말 토요일(파랑)
        } else {
          tdElement.classList.add("dev-calendar-weekend-not-saturday"); // 토요일이 아닌 주말(빨강)
        }
      }
    }
  }
}

/**
 * 년도 데이터를 가져 와서 휴장, 토요일인 주말, 토요일이 아닌 주말 날짜 td에 css class 추가 - deprecated
 * @param year
 * @param month
 * @returns {Promise<void>}
 */
export async function getCalendarInfoAndSetCalendarContentElementDayClass(
  contentElement,
  year,
  month,
  closeDayClass,
  weekendSaturdayClass,
  weekendNotSaturdayClass
) {
  try {
    const {
      value: { calendarList },
    } = await getCalendarList(year, month, true);

    const closeDays = calendarList
      .filter((day) => day.bsnCode === "CLOSE")
      .map((dayFiltered) => dayFiltered.bsnDate);
    const weekends = calendarList
      .filter((day) => day.bsnCode === "WEEKEND")
      .map((dayFiltered) => dayFiltered.bsnDate);

    setCalendarContentElementDayClass(
      contentElement,
      closeDays,
      weekends,
      closeDayClass,
      weekendSaturdayClass,
      weekendNotSaturdayClass
    );
  } catch (e) {
    console.error(e);
  }
}

/**
 * 휴장, 토요일인 주말, 토요일이 아닌 주말 날짜 td에 css class 추가
 * @param contentElement contentElement 달력 content element
 * @param closeDays 휴장일 목록
 * @param weekends 주말 목록
 * @param closeDayClass 휴장일 td 에 추가할 css class 이름
 * @param weekendSaturdayClass 주말 토요일 td 에 추가할 css class 이름
 * @param weekendNotSaturdayClass 토요일이 아닌 주말 td 에 추가할 css class 이름
 */
function setCalendarContentElementDayClass(
  contentElement,
  closeDays,
  weekends,
  closeDayClass,
  weekendSaturdayClass,
  weekendNotSaturdayClass
) {
  closeDays.forEach((closeDay) => {
    const dayElement = contentElement.querySelector("#D" + closeDay);
    if (dayElement) {
      dayElement.parentElement.classList.add(closeDayClass); // 휴장(연한 회색)
    }
  });
  weekends.forEach((weekend) => {
    const dayElement = contentElement.querySelector("#D" + weekend);
    const dayOfWeek = moment(weekend).day();
    if (dayElement && dayElement.parentElement) {
      if (dayOfWeek === 6) {
        dayElement.parentElement.classList.add(weekendSaturdayClass); // 주말 토요일(파랑)
      } else {
        dayElement.parentElement.classList.add(weekendNotSaturdayClass); // 토요일이 아닌 주말(빨강)
      }
    }
  });
}
