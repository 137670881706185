<template>
  <div>
    <ejs-dialog
      allowDragging="true"
      header="확인"
      isModal="true"
      ref="dialog"
      showCloseIcon="true"
      width="320"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <!-- 확인창 활성(Class) : typePos-confirm -->
      <div class="typePos">
        <div class="typePos-wrapper-main">
          <template v-for="(line, index) in messageLines">
            {{ line }}
            <br
              v-if="index + 1 < messageLines.length"
              v-bind:key="`f-messages-linebreak-${index}`"
            />
          </template>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <button
                class="ui-button i-typePrimary i-iconConfirm"
                @click="onConfirmClick"
              >
                <div class="i-item">{{confirmBtnLabel}}</div>
              </button>
            </li>
            <li class="typePos-cancel">
              <!-- 비활성(Attribute) : disabled -->
              <button class="ui-button" @click="onCancelClick">
                <div class="i-item">{{cancelBtnLabel}}</div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-main {overflow: hidden; padding: 17px 20px 16px 20px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-cancel .ui-button .i-item::before {background-position-x: calc(-20px * 9);}

.typePos.typePos-confirm .typePos-wrapper-footer .typePos-button > li.typePos-cancel {display: none;}
</style>

<script>
export default {
  name: "ConfirmDialog",
  data() {
    return {
      confirmBtnLabel: "확인",
      cancelBtnLabel: "취소",
      message: "",
      callback: () => {},
    };
  },
  computed: {
    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
      };
    },
    messageLines() {
      return this.message?.split("\n") || [];
    },
  },
  methods: {
    show({ message = "", callback = () => {}, confirmBtnLabel = "확인", cancelBtnLabel = "취소" }) {
      this.confirmBtnLabel = confirmBtnLabel;
      this.cancelBtnLabel = cancelBtnLabel;
      this.message = message;
      this.callback = callback;
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },

    onConfirmClick() {
      this.callback?.(true);
      this.hide();
    },
    onCancelClick() {
      this.callback?.(false);
      this.hide();
    },
  },
};
</script>
