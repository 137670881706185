import {getDeviceInfo} from "../utils/device";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {mapGetters, mapMutations} from "vuex";

export default {
    mixins: [confirmDialogMixin],
    data() {
        return {
            eposScriptUrl: 'https://gms-web-erp.greenit.cc/libs/epos/epos-2.17.0.js',
            billEposDev: null,
            billPrinter: null,

            kitchenEposDev: null,
            kitchenPrinter: null,
            deviceInfo: getDeviceInfo()
        };
    },
    methods: {
        ...mapMutations("epsonPrinter", {
            setBillPrinter: "SET_bILL_PRINTER",
            setKitchenPrinter: "SET_KITCHEN_PRINTER",
        }),
        addEposScriptAndConnect(isBillPrinterUse, isKitchenBillPrinterUse) {
            //ipAddress, port, printer , ePosDev 필요
            let recaptchaScript = document.createElement('script');
            recaptchaScript.setAttribute('type', "text/javascript");
            recaptchaScript.setAttribute('src', this.eposScriptUrl);
            recaptchaScript.onload = () => {
                if (isBillPrinterUse && this.deviceInfo.billPrinterCommunication === "IP") {
                    const port = this.deviceInfo.billPrinterIpPort;

                    //////////////////////////////////////////////////////////////////////
                    // eslint-disable-next-line no-undef
                    this.billEposDev = new epson.ePOSDevice();
                    const ipAddress = this.deviceInfo.billPrinterIpAddress;

                    this.billEposDev.connect(ipAddress, port, this.Callback_connect);
                }

                if (isKitchenBillPrinterUse && this.deviceInfo.kitchenBillPrinterCommunication === "IP") {
                    const port = this.deviceInfo.kitchenBillPrinterIpPort;

                    //////////////////////////////////////////////////////////////////////
                    // eslint-disable-next-line no-undef
                    this.kitchenEposDev = new epson.ePOSDevice();
                    const ipAddress = this.deviceInfo.kitchenBillPrinterIpAddress;

                    this.kitchenEposDev.connect(ipAddress, port, this.Callback_connect_kitchen);
                }

            };
            document.head.appendChild(recaptchaScript);
        },
        // 페이지에서 빠져나갈 때 epos 스크립트를 헤더에서 삭제
        removeEposScript() {
            let scripts = document.getElementsByTagName("script");
            for (let i = 0; i < scripts.length; i++) {
                const scriptElement = scripts[i];
                if (scriptElement.src?.indexOf(this.eposScriptUrl) >= 0) {
                    scriptElement.parentNode.removeChild(scriptElement);
                }
            }
        },
        Callback_connect(data) {
            let deviceID = this.deviceInfo.billPrinterDeviceId;
            let crypto = false;
            let buffer = false;
            let options = {'crypto': crypto, 'buffer': buffer};

            if (data === 'OK') {
                this.billEposDev.createDevice(deviceID, this.billEposDev.DEVICE_TYPE_PRINTER, options, this.callbackCreateDevice_printer);
            } else if (data === 'SSL_CONNECT_OK') {
                this.billEposDev.createDevice(deviceID, this.billEposDev.DEVICE_TYPE_PRINTER, options, this.callbackCreateDevice_printer);
            } else {
                console.log("connected to ePOS Device Service Interface is failed. [" + data + "]");
            }
        },
        Callback_connect_kitchen(data) {
            let deviceID = this.deviceInfo.kitchenBillPrinterDeviceId;
            let crypto = false;
            let buffer = false;
            let options = {'crypto': crypto, 'buffer': buffer};

            if (data === 'OK') {
                this.kitchenEposDev.createDevice(deviceID, this.kitchenEposDev.DEVICE_TYPE_PRINTER, options, this.callbackCreateDevice_printer_kitchen);
            } else if (data === 'SSL_CONNECT_OK') {
                this.kitchenEposDev.createDevice(deviceID, this.kitchenEposDev.DEVICE_TYPE_PRINTER, options, this.callbackCreateDevice_printer_kitchen);
            } else {
                console.log("connected to ePOS Device Service Interface is failed. [" + data + "]");
            }
        },
        callbackCreateDevice_printer(data, code) {
            if (data == null) {
                console.log(code);
                return;
            }
            this.billPrinter = data;
            this.billPrinter.timeout = 60000;

            // this.billPrinter.interval = 10000;
            // this.billPrinter.startMonitor();

            this.setBillPrinter(this.billPrinter);
            this.billPrinter.onstatuschange = (status) => {
                console.log(this.getStatusText(this.billPrinter, status));
            };
        },
        callbackCreateDevice_printer_kitchen(data, code) {
            if (data == null) {
                console.log(code);
                return;
            }
            this.kitchenPrinter = data;
            this.kitchenPrinter.timeout = 60000;

            // this.kitchenPrinter.interval = 10000;
            // this.kitchenPrinter.startMonitor();


            this.setKitchenPrinter(data);
            this.kitchenPrinter.onstatuschange = (status) => {
                console.log(this.getStatusText(this.kitchenPrinter, status));
            };
        },
        getStatusText(e, status) {
            let s = 'Status: \n';
            if (status & e.ASB_NO_RESPONSE) {
                s += ' No printer response\n';
            }
            if (status & e.ASB_PRINT_SUCCESS) {
                s += ' Print complete\n';
            }
            if (status & e.ASB_DRAWER_KICK) {
                s += ' Status of the drawer kick number 3 connector pin = "H"\n';
            }
            if (status & e.ASB_OFF_LINE) {
                s += ' Offline status\n';
            }
            if (status & e.ASB_COVER_OPEN) {
                s += ' Cover is open\n';
            }
            if (status & e.ASB_PAPER_FEED) {
                s += ' Paper feed switch is feeding paper\n';
            }
            if (status & e.ASB_WAIT_ON_LINE) {
                s += ' Waiting for online recovery\n';
            }
            if (status & e.ASB_PANEL_SWITCH) {
                s += ' Panel switch is ON\n';
            }
            if (status & e.ASB_MECHANICAL_ERR) {
                s += ' Mechanical error generated\n';
            }
            if (status & e.ASB_AUTOCUTTER_ERR) {
                s += ' Auto cutter error generated\n';
            }
            if (status & e.ASB_UNRECOVER_ERR) {
                s += ' Unrecoverable error generated\n';
            }
            if (status & e.ASB_AUTORECOVER_ERR) {
                s += ' Auto recovery error generated\n';
            }
            if (status & e.ASB_RECEIPT_NEAR_END) {
                s += ' No paper in the roll paper near end detector\n';
            }
            if (status & e.ASB_RECEIPT_END) {
                s += ' No paper in the roll paper end detector\n';
            }
            if (status & e.ASB_BUZZER) {
                s += ' Sounding the buzzer (certain model)\n';
            }
            if (status & e.ASB_SPOOLER_IS_STOPPED) {
                s += ' Stop the spooler\n';
            }
            return s;
        },
    },
    computed: {
        ...mapGetters('epsonPrinter', {
            epsonBillPrinter: "billPrinter",
            epsonKitchenPrinter: "kitchenPrinter"
        }),
    }
};
