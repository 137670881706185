import qs from "qs";

export const PATH_VOC = `/voc`;

export function fetchVocList(args) {
  return this.http.get(`${PATH_VOC}/list`, {
    params: {
      dateFrom : args.dateFrom,
      dateTo: args.dateTo,
      vocSections: args.vocSections,
      vocCodes: args.vocCodes,
      vocManagts: args.vocManagts,
      searchName: args.searchName,
      searchNameDiv: args.searchNameDiv,
      orderDiv: args.orderDiv,
      membershipId: args.membershipId,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    }
  });
}

export function fetchVocInfo(vocId) {
  return this.http.get(`${PATH_VOC}`, {
    params: {
      vocId: vocId
    }
  });
}

export function fetchVocInfoByTeam(args) {
  return this.http.get(`${PATH_VOC}/caddie/by-team`, {
    params: {
      chkinId: args.chkinId,
      argmtId: args.argmtId
    }
  });
}

export function putVocInfoByTeam(data) {
  return this.http.put(`${PATH_VOC}/caddie/by-team`, data);
}

export function putVocInfo(data) {
  return this.http.put(`${PATH_VOC}`, data);
}

export function deleteVocInfo(vocId) {
  return this.http.delete(`${PATH_VOC}`, {
    params: {
      vocId: vocId
    }
  });
}