import qs from "qs";
export const PATH_ROOM_RESERVATION = `/room/reservation`;

export function fetchRoomReservationStatusList(args) {
  return this.http.get(`${PATH_ROOM_RESERVATION}/status-list`, {
    params: {
      bsnDate: args.bsnDate,
    },
  });
}

export function fetchRoomReservations(args) {
  return this.http.get(`${PATH_ROOM_RESERVATION}`, {
    params: args,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

export function fetchRoomReservationDetail(args) {
  return this.http.get(`${PATH_ROOM_RESERVATION}/detail`, {
    params: {
      rresveNo: args.rresveNo,
      stayIds: args.stayIds,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

export function fetchGolfResveMember(resveNo) {
  return this.http.get(`${PATH_ROOM_RESERVATION}/golf-resve-member`, {
    params: {
      resveNo: resveNo,
    },
  });
}

export function fetchRoomResveStayPaymtDetail(stayId) {
  return this.http.get(`${PATH_ROOM_RESERVATION}/resve-stay-paymt/detail`, {
    params: {
      stayId: stayId
    }
  });
}

export function fetchVacantRoom(args) {
  return this.http.get(`${PATH_ROOM_RESERVATION}/vacant-room`, {
    params: args,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

export function fetchGolfPachkageLink(args) {
  return this.http.get(`${PATH_ROOM_RESERVATION}/golf-package-link`, {
    params: {
      resveDateFrom: args.resveDateFrom,
      resveDateTo: args.resveDateTo,
      partDiv: args.partDiv,
      bsnCode: args.bsnCode,
      searchValue: args.searchValue,
    },
  });
}

export function fetchRoomReservationHistory(stayId) {
  return this.http.get(`${PATH_ROOM_RESERVATION}/${stayId}/history`);
}

export function golfPackageLink(rresveNo, resveNos) {
  return this.http.post(`${PATH_ROOM_RESERVATION}/golf-package-link/${rresveNo}`, resveNos);
}

export function roomReservationDetailSave(data) {
  return this.http.patch(`${PATH_ROOM_RESERVATION}/detail`, data);
}

export function roomReservationNamingCancel(stayId) {
  return this.http.patch(`${PATH_ROOM_RESERVATION}/naming-cancel/${stayId}`);
}

export function roomResveStayPaymtsSave(data) {
  return this.http.patch(`${PATH_ROOM_RESERVATION}/resve-stay-paymt/list`, data);
}

export function roomReservationDetailCancel(args) {
  return this.http.delete(`${PATH_ROOM_RESERVATION}/detail/cancel`, {
    params: {
      stayIds: args.stayIds,
      cancelReason: args.cancelReason,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

export function deleteGolfPackageLink(args) {
  return this.http.delete(`${PATH_ROOM_RESERVATION}/golf-package-link/${args.rresveNo}`, {
    params: {
      resveNo: args.resveNo,
      delReason: args.delReason,
    },
  });
}

export function checkAvailableRooms(args) {
  return this.http.post(`${PATH_ROOM_RESERVATION}/check-available-rooms`, args);
}