import layout from "@/views/layout/layout";

const roomFrontRouter = {
    path: "/room-front",
    component: layout,
    redirect: "room-management/room-indicator",
    children: [
        {
            path: "room-management/room-indicator",
            name: "RoomIndicator",
            component: () =>
                import("@/views/room-front/room-management/RoomIndicator"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "check-in/room-check-in",
            name: "RoomCheckIn",
            component: () =>
              import("@/views/room-front/room-check-in/RoomCheckIn"),
            meta: {
                enableMemo: true,
                currentView: 'ROOM',
                useUsualStyles: true,
            },
        },
    ],
};
export default roomFrontRouter;
