<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="rightMemberViewSearchDetailPopup"
      header="상세검색"
      allowDragging="true"
      showCloseIcon="true"
      width="387"
      isModal="true"
      @close="onRightMemberViewSearchDetailPopupClosed"
    >
      <div class="window lookupDetail-advancePaymentRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">코스</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="courseCodeMultiSelect"
                        cssClass="lookupDetail-condition-multiselect"
                        placeHolder="전체"
                        v-model="searchOptions.courseCodes"
                        :dataSource="searchOptionsDataSource.courseCodeOptions"
                        :fields="commonCodeFields"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">영업구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="bsnCodeMultiSelect"
                        cssClass="lookupDetail-condition-multiselect"
                        placeHolder="전체"
                        v-model="searchOptions.bsnCodes"
                        :dataSource="searchOptionsDataSource.bsnCodeOptions"
                        :fields="commonCodeFields"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">부구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="partDivMultiSelect"
                        cssClass="lookupDetail-condition-multiselect"
                        placeHolder="전체"
                        v-model="searchOptions.partDivs"
                        :dataSource="searchOptionsDataSource.partDivOptions"
                        :fields="commonCodeFields"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">요일</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="dwCodeMultiSelect"
                        cssClass="lookupDetail-condition-multiselect"
                        placeHolder="전체"
                        v-model="searchOptions.dwCodes"
                        :dataSource="searchOptionsDataSource.dwCodeOptions"
                        :fields="commonCodeFields"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">단체명</div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        v-model="searchOptions.grpName"
                        @keydown.native="formPreventEnterEvent"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">비고</div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        v-model="searchOptions.remarks"
                        @keydown.native="formPreventEnterEvent"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                button-div="GET"
                :disabled="commonMixinIsButtonDisableByAuth('commonMemberResveVisitGet')"
                @click.native="searchButtonClicked"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button button-div="GET" @click.native="onInitButtonClicked">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="onSearchDetailPopupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputText from '@/components/common/text/InputText';
import ErpButton from "@/components/button/ErpButton";

import {formPreventEnterEvent} from '@/utils/formUtil';
import {commonCodesGetCommonCode} from "@/utils/commonCodes";

export default {
  name: "RightMemberViewSearchDetailPopup",
  components: {
    InputText,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  props: {
    searchOptions: {
      type: Object,
      default: function () {
        return {
          memberName: null,
          memberNo: null,
          membershipId: null,
          courseCodes: [],
          bsnCodes: [],
          partDivs: [],
          dwCodes: [],
          memberDivs: [],
          grpName: null,
          contactTelIdx: null,
          remarks: null,
        };
      },
    }
  },
  computed: {},
  created() {},
  mounted() {},
  data() {
    return {
      commonCodeFields: {text: 'comName', value: 'comCode'},
      searchOptionsDataSource: {
        courseCodeOptions: commonCodesGetCommonCode('COURSE_CODE', true),
        bsnCodeOptions: commonCodesGetCommonCode('BSN_CODE', true),
        partDivOptions: commonCodesGetCommonCode('PART_DIV', true),
        dwCodeOptions: commonCodesGetCommonCode('DW_CODE', true),
      },
    };
  },
  methods: {
    formPreventEnterEvent,
    showPopup() {},
    onRightMemberViewSearchDetailPopupClosed() {
      this.$emit("popupClosed");
    },
    searchButtonClicked() {
      this.$emit("popupSearch");
    },
    onInitButtonClicked() {
      this.$emit("popupInit");
    },
    onSearchDetailPopupClose() {
      this.$refs.rightMemberViewSearchDetailPopup.hide();
    }
  },
};
</script>