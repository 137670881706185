export function convertJsonEmptyStringValueToNull(
  element,
  parent = null,
  key = null
) {
  if (Array.isArray(element)) {
    traverseArray(element);
  } else if (typeof element === "object" && element !== null) {
    traverseObject(element);
  } else {
    if (parent !== null) {
      if (typeof element === "string" && element === "") {
        parent[key] = null;
      }
    }
    // console.log(`value:${element}`)
  }
}

function traverseArray(arr) {
  arr.forEach((element) => {
    convertJsonEmptyStringValueToNull(element);
  });
}

function traverseObject(obj) {
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      convertJsonEmptyStringValueToNull(obj[key], obj, key);
    }
  }
}
