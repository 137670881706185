<template>
  <div
    :id="_componentUUID"
    :class="['dev-edit-component']"
    @focusout="handleFocusout"
    @focusin="handleFocusin"
  >
    <div class="inputDateRange" :class="[{ focus: active }, { disabled: disabled }]">
      <div class="inputDateRange-calendar">
        <ejs-daterangepicker
          ref="daterangepicker"
          :cssClass="`inputDateRange-${
            type ? `${type}` : 'grid'
          } dev-edit-component`"
          :disabled="disabled"
          :enabled="!disabled"
          :startDate="fromDateValue"
          :endDate="toDateValue"
          @open="handleDaterangepickerOpen"
          @close="handleDaterangepickerClose"
          @renderDayCell="handleRenderDayCell"
          @navigated="handleNavigated"
        />
      </div>
      <div class="inputDateRange-input from">
        <input-date
          ref="from"
          v-bind="fromDateProps"
          v-model="fromValueModel"
          :isGetDateInfo="false"
          @change="onDateRangeFromChange"
        />
      </div>
      <!-- TODO : 추후 적용 예정 -->
      <!--      <div-->
      <!--        :style="{-->
      <!--          color: getDayOfWeekCaptionColor(-->
      <!--            null,-->
      <!--            dateRangeFrom.bsnCode,-->
      <!--            dateRangeFrom.dwCode-->
      <!--          )-->
      <!--        }"-->
      <!--      >-->
      <!--        {{ commonCodesGetComName("DW_CODE", dateRangeFrom.dwCode) }}-->
      <!--      </div>-->
      <div class="inputDateRange-dash">~</div>
      <div class="inputDateRange-input to">
        <input-date
          ref="to"
          v-bind="toDateProps"
          v-model="toValueModel"
          :isGetDateInfo="false"
          @change="onDateRangeToChange"
        />
      </div>
      <!-- TODO : 추후 적용 예정 -->
      <!--      <div-->
      <!--        :style="{-->
      <!--          color: getDayOfWeekCaptionColor(-->
      <!--            null,-->
      <!--            dateRangeTo.bsnCode,-->
      <!--            dateRangeTo.dwCode-->
      <!--          )-->
      <!--        }"-->
      <!--      >-->
      <!--        {{ commonCodesGetComName("DW_CODE", dateRangeTo.dwCode) }}-->
      <!--      </div>-->
      <div class="inputDateRange-clear" v-if="!isEmpty">
        <button
          type="button"
          :tabindex="-1"
          @click="handleClearClick"
          :disabled="disabled"
        >
          Clear
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.inputDateRange {box-sizing: border-box;position: relative;width: 100%;border: 1px solid #e0e0e0;border-radius: 3px;}
.inputDateRange .inputDateRange-calendar >>> .e-input-group {border: none !important;}
.inputDateRange .inputDateRange-calendar >>> .e-input-group.e-input-focus {border: none !important;}
.inputDateRange .inputDateRange-calendar >>> .e-input-group .e-input {visibility: hidden;}
.inputDateRange .inputDateRange-calendar >>> .e-input-group .e-clear-icon {visibility: hidden;}
.inputDateRange .inputDateRange-calendar >>> .e-input-group .e-input-group-icon.e-input-group-icon:before {width: 12px !important;height: 12px !important;background-image: url("../../../assets/images/common/input-calender.png") !important;}
.inputDateRange .inputDateRange-input {position: absolute;left: 0;top: 0;width: calc((100% - 47px) / 2);background-color: rgba(255, 0, 0, 0.2);}
.inputDateRange .inputDateRange-input.to {left: calc(((100% - 47px) / 2) + 9px);}
.inputDateRange .inputDateRange-input >>> .inputDate {border: none;}
.inputDateRange .inputDateRange-input >>> .inputDate .inputDate-calendar {display: none;}
.inputDateRange .inputDateRange-input >>> .inputDate .inputDate-input {width: 100%;}
.inputDateRange .inputDateRange-input >>> .inputDate .inputDate-clear {display: none;}
.inputDateRange .inputDateRange-dash {position: absolute;left: calc((100% - 47px) / 2);top: 0;width: 9px;color: #666;font-size: 12px;line-height: 21px;font-family: "돋움", Dotum, Arial, Verdana, sans-serif;text-align: center;}
.inputDateRange .inputDateRange-clear {position: absolute;right: 25px;top: 0;}
.inputDateRange .inputDateRange-clear button {overflow: hidden;width: 13px;height: 21px;padding: 0;border: none;background: transparent url("../../../assets/images/common/input-clear.png") no-repeat center center;text-indent: -1000px;cursor: pointer;outline: none;}
.inputDateRange.focus {border-color: #999;}
.inputDateRange.disabled {border-color: #e0e0e0;background-color: #f0f0f0;}
.inputDateRange.disabled .inputDate-input input[type="text"] {color: #bbb;cursor: default;}
.inputDateRange.disabled .inputDateRange-dash {color: #bbb;}
.inputDateRange.disabled .inputDateRange-clear {display: none;opacity: 0.5;}
</style>

<script>
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { inputType } from "@/utils/formUtil";
import { isDateFormatString } from "@/utils/string";
import {
  DATE_FORMAT_YYYY_MM_DD,
  getCalendarInfoAndSetCalendarContentElementDayClassNew,
  setIdInDatePickerSpan,
  setDateElementCssClassOnRenderCell,
  getDayOfWeekCaptionColor,
} from "@/utils/date";
import { commonCodesGetComName } from "@/utils/commonCodes";

import InputDate from "@/components/common/datetime/InputDate";

export default {
  name: "InputDateRange",
  components: { InputDate },
  props: {
    value: {
      type: Object,
      validator(value) {
        if (!value) {
          return true;
        }

        const { from, to } = value;

        let valid = true;
        if (from) {
          valid = valid && isDateFormatString(from);
        }

        if (to) {
          valid = valid && isDateFormatString(to);
        }

        return valid;
      },
    },
    type: String,
    disabled: {
      type: [String, Boolean],
      validator(disabled) {
        return `${disabled}` === "true" || `${disabled}` === "false";
      },
      default: () => false,
    },
  },
  async created() {
    this.data = this.value || {
      from: undefined,
      to: undefined,
    };

    // TODO : 추후 적용 예정
    // if (!!this.data.from) {
    //   const data = await GolfErpAPI.fetchCalenderInfo(this.data.from, false);
    //
    //   this.dateRangeFrom.bsnCode = data.bsnCode;
    //   this.dateRangeFrom.dwCode = data.dwCode;
    // }
    //
    // if (!!this.data.to) {
    //   const data = await GolfErpAPI.fetchCalenderInfo(this.data.to, false);
    //
    //   this.dateRangeTo.bsnCode = data.bsnCode;
    //   this.dateRangeTo.dwCode = data.dwCode;
    // }
  },
  watch: {
    value(value) {
      this.data = value || {
        from: undefined,
        to: undefined,
      };
      this.$emit("change", {
        data: value,
      });
    },
    active(active) {
      if (active) {
        this.$emit("active");
      } else {
        this.$emit("inactive");
      }

      if (!active) {
        this.$emit("input", this.data);
      }
    },
    deep: true,
  },
  data() {
    return {
      active: false,
      data: {
        from: undefined,
        to: undefined,
      },
      calendarListMap: new Map(),
      dateRangeFrom: {
        bsnCode: null,
        dwCode: null,
      },
      dateRangeTo: {
        bsnCode: null,
        dwCode: null,
      },
    };
  },
  computed: {
    fromValueModel: {
      get() {
        return this.data.from;
      },
      set(value) {
        this.data.from = value;
      },
    },
    toValueModel: {
      get() {
        return this.data.to;
      },
      set(value) {
        this.data.to = value;
      },
    },

    _componentUUID() {
      return uuidv4();
    },
    fromDateValue() {
      const fromMoment = moment(this.data.from, DATE_FORMAT_YYYY_MM_DD);
      if (!this.data.from || !fromMoment.isValid()) {
        return null;
      }

      return fromMoment.toDate();
    },
    toDateValue() {
      const toMoment = moment(this.data.to, DATE_FORMAT_YYYY_MM_DD);
      if (!this.data.to || !toMoment.isValid()) {
        return null;
      }

      return toMoment.toDate();
    },
    inputType() {
      return inputType.dateTimeRange;
    },
    fromDateProps() {
      return {
        disabled: this.disabled,
        format: DATE_FORMAT_YYYY_MM_DD,
        usingDatepicker: true,
        type: this.type,
      };
    },
    toDateProps() {
      return {
        disabled: this.disabled,
        format: DATE_FORMAT_YYYY_MM_DD,
        usingDatepicker: true,
        type: this.type,
      };
    },
    isEmpty() {
      return !this.data || (!this.data.from && !this.data.to);
    },
  },
  methods: {
    commonCodesGetComName,
    getDayOfWeekCaptionColor,
    handleFocusout(event) {
      if (!event.relatedTarget) {
        this.active = false;

        return;
      }

      if (
        this.containsDevEditComponentElement(
          event.relatedTarget,
          this._componentUUID
        )
      ) {
        return;
      }

      this.active = false;
    },
    handleFocusin() {
      this.active = true;
    },

    handleClearClick() {
      this.data = {};
      this.$nextTick(() => {
        this.$refs["from"].setSelectRangeFrontAndFocus();
      });
    },
    handleDaterangepickerChange(args) {
      this.$emit("input", {
        from: args.startDate
          ? moment(args.startDate).format(DATE_FORMAT_YYYY_MM_DD)
          : undefined,
        to: args.endDate
          ? moment(args.endDate).format(DATE_FORMAT_YYYY_MM_DD)
          : undefined,
      });
    },
    async handleDaterangepickerOpen() {
      this.active = true;

      // map 초기화
      this.calendarListMap = new Map();

      let from = this.value ? this.value.from : null;
      let to = this.value ? this.value.to : null;

      // 값이 없으면 오늘 날짜로 설정
      if (!from) {
        from = new moment().format(DATE_FORMAT_YYYY_MM_DD);
      }
      if (!to) {
        to = new moment().format(DATE_FORMAT_YYYY_MM_DD);
      }

      // from, to의 년/월이 같으면 to의 월을 +1
      let fromMoment = new moment(from);
      let toMoment = new moment(to);

      if (
        fromMoment.year() === toMoment.year() &&
        fromMoment.month() === toMoment.month()
      ) {
        toMoment.add(1, "M");
      }

      // keyboard event 처리
      const dateRangePickerInstance = this.$refs.daterangepicker;
      dateRangePickerInstance.ej2Instances.popupWrapper.addEventListener(
        "keyup",
        async () => {
          if (dateRangePickerInstance.ej2Instances.popupObj) {
            const currentElement = dateRangePickerInstance.ej2Instances.popupObj.element.querySelector(
              ".e-focused-date"
            );
            if (currentElement) {
              // enter key 칠 때에는 currentElement가 null. 무시해도 됨 ㄱㄷ
              const focusedDate = new Date(
                parseInt(currentElement.getAttribute("id"), 10)
              );
              // console.log(new moment(focusedDate).format(DATE_FORMAT_YYYY_MM_DD));

              await getCalendarInfoAndSetCalendarContentElementDayClassNew(
                this.$refs.daterangepicker.ej2Instances.popupWrapper,
                new moment(focusedDate),
                this.calendarListMap
              );
            }
          }
        }
      );

      // 조회
      await getCalendarInfoAndSetCalendarContentElementDayClassNew(
        this.$refs.daterangepicker.ej2Instances.popupWrapper,
        fromMoment,
        this.calendarListMap
      );
      await getCalendarInfoAndSetCalendarContentElementDayClassNew(
        this.$refs.daterangepicker.ej2Instances.popupWrapper,
        toMoment,
        this.calendarListMap
      );
    },
    handleDaterangepickerClose(args) {
      this.active = false;

      this.$emit("input", {
        from: args.model.startDate
          ? moment(args.model.startDate).format(DATE_FORMAT_YYYY_MM_DD)
          : undefined,
        to: args.model.endDate
          ? moment(args.model.endDate).format(DATE_FORMAT_YYYY_MM_DD)
          : undefined,
      });
    },
    handleRenderDayCell(args) {
      // 모든 날짜 span element에 오늘 날짜로 ID 설정. 날짜가 표시된 부분에만 ID를 넣는다
      if (args.element && !args.element.classList.contains("e-other-month")) {
        const date = moment(args.date).format(DATE_FORMAT_YYYY_MM_DD);
        const spanElement = args.element.firstElementChild;

        // ID 설정
        setIdInDatePickerSpan(date, spanElement);

        // calendarListMap 에 데이터가 있으면 표시해주기
        setDateElementCssClassOnRenderCell(
          args.date,
          spanElement.parentElement,
          this.calendarListMap
        );
      }
    },
    async handleNavigated(args) {
      if (args.view === "Month") {
        const navigatedDateMoment = moment(args.date);
        await getCalendarInfoAndSetCalendarContentElementDayClassNew(
          this.$refs.daterangepicker.ej2Instances.contentElement,
          navigatedDateMoment,
          this.calendarListMap
        );
      }
    },
    isDevEditComponentElement(element, id) {
      return element && element.dataset && element.dataset.id === id;
    },
    containsDevEditComponentElement(element, id) {
      if (this.isDevEditComponentElement(element, id)) {
        return true;
      } else {
        if (element.parentElement) {
          return this.containsDevEditComponentElement(
            element.parentElement,
            id
          );
        } else {
          return false;
        }
      }
    },
    validate() {
      if (
        this.fromValueModel === undefined ||
        this.toValueModel === undefined
      ) {
        return false;
      }
      const fromMoment = moment(this.fromValueModel, DATE_FORMAT_YYYY_MM_DD);
      const toMoment = moment(this.toValueModel, DATE_FORMAT_YYYY_MM_DD);
      return fromMoment.isSameOrBefore(toMoment);
    },
    onDateRangeFromChange() {
      // TODO : 추후 적용 예정
      // this.dateRangeFrom.bsnCode = args.bsnCode;
      // this.dateRangeFrom.dwCode = args.dwCode;
    },
    onDateRangeToChange() {
      // TODO : 추후 적용 예정
      // this.dateRangeTo.bsnCode = args.bsnCode;
      // this.dateRangeTo.dwCode = args.dwCode;
    },
    focus() {
      this.$refs["from"].focus();
    },
  },
};
</script>

<style scoped></style>
