<template>
  <button
    ref="button"
    v-bind="$_button_attributes"
    v-on="$_button_listeners"
    :class="{
      'e-control': useSyncfusionComponentStyle,
      'e-btn': useSyncfusionComponentStyle,
      'e-lib': useSyncfusionComponentStyle,
    }"
  >
    <slot />
  </button>
</template>

<script>
import { v4 as createUUID } from "uuid";

export default {
  name: "FButton",
  props: {
    useSyncfusionComponentStyle: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      uid: createUUID(),
    };
  },
  computed: {
    $_button_attributes() {
      return Object.assign({}, this.$attrs, {});
    },
    $_button_listeners() {
      return Object.assign({}, this.$listeners, {});
    },
  },
  methods: {
    blur() {
      this.$refs["button"].blur();
    },
    click() {
      this.$refs["button"].click();
    },
  },
};
</script>
