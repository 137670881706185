<template>
  <div
      :id="[{ 'body-grid': useCommonStyles }]"
      :class="[
      { 'body-grid': useCommonStyles },
      { 'ejs-grid': !useCommonStyles },
    ]"
      @keydown.capture="onGridKeyDown"
  >
    <ejs-grid
        :id="id"
        ref="grid"
        locale="ko"
        :selectedRowIndex="selectedRowIndex"
        :enableVirtualization="enableVirtualization"
        :dataSource="computedDataSource"
        :columns="columnComputed"
        :width="width"
        :height="height"
        :gridLines="useCommonStyles ? gridLines : undefined"
        :selectionSettings="selectionSettings"
        :editSettings="editSettings"
        :allowResizing="allowResizing"
        :rowHeight="useCommonStyles ? rowHeight : undefined"
        :allowSorting="allowSorting"
        :allowFiltering="allowFiltering"
        :allowKeyboard="allowKeyboard"
        :allowPaging="allowPaging"
        :pageSettings="pageSettings"
        :filterSettings="defaultFilterSettings"
        :allowSelection="allowSelection"
        :groupSettings="groupSettings"
        :allowGrouping="allowGrouping"
        :aggregates="aggregateComputed"
        :enableHover="useCommonStyles ? enableHover : undefined"
        :enableInfiniteScrolling="enableInfiniteScrolling"
        :frozenRows="frozenRows"
        :frozenColumns="frozenColumns"
        :allowExcelExport="allowExcelExport"
        :contextMenuItems="contextMenuItems"
        :allowPdfExport="allowPdfExport"
        :validationRules="validationRules"
        :validationModification="validationModification"
        :allowRowDragAndDrop="allowRowDragAndDrop"
        :rowDropSettings="rowDropSettings"
        :childGrid="childGrid"
        @batchDelete="onBatchDelete"
        @created="onCreated"
        @rowDrop="onRowDrop"
        @cellEdit="onCellEdit"
        @cellSave="onCellSave"
        @cellSaved="onCellSaved"
        @headerCellInfo="onHeaderCellInfo"
        @load="onLoad"
        @dataBound="onDataBound"
        @rowSelecting="onRowSelecting"
        @rowSelected="onRowSelected"
        @rowDeselected="onRowDeselected"
        @cellSelected="onCellSelected"
        @rowDataBound="onRowDataBound"
        @recordClick="onRecordClick"
        @recordDoubleClick="onRecordDoubleClick"
        @keyPressed="onKeyPressed"
        @actionBegin="onActionBegin"
        @actionComplete="onActionComplete"
        @beforeBatchDelete="onBeforeBatchDelete"
        @batchAdd="onBatchAdd"
        @contextMenuClick="onContextMenuClick"
        @cellSelecting="onCellSelecting"
        @cellDeselected="onCellDeselected"
        @cellDeselecting="onCellDeselecting"
        @destroyed="onDestroyed"
        @excelExportComplete="onExcelExportComplete"
        @queryCellInfo="onQueryCellInfo"
    >
    </ejs-grid>
  </div>
</template>

<script>
import {Filter, Sort} from "@syncfusion/ej2-vue-grids";
// import numberTemplate from "@/views/common/template/GridNumberTemplate";
import gridInputNumberEditTemplate from "@/components/common/gridTemplate/GridInputNumberEditTemplate";
import gridInputDateTemplate from "@/components/common/datetime/getDateEditTemplate";
import gridInputTimeTemplate from "@/components/common/datetime/getTimeEditTemplate";
import gridCheckboxEditTemplate from "@/components/common/gridTemplate/GridCheckboxEditTemplate";
import gridCheckAllHeaderTemplate from "@/components/common/gridTemplate/GridCheckAllHeaderTemplate";
import gridStringEditTemplate from "@/components/common/gridTemplate/GridStringEditTemplate";
import gridAggregationCaptionTemplate from "@/components/common/gridTemplate/GridAggregationCaptionTemplate";
import gridAggregationTotalSumTemplate from "@/components/common/gridTemplate/GridAggregationTotalSumTemplate";
import gridAggregationTotalAvgTemplate from "@/components/common/gridTemplate/GridAggregationTotalAvgTemplate";
import gridAggregationTotalCountTemplate from "@/components/common/gridTemplate/GridAggregationTotalCountTemplate";

import {getBatchCurrentViewRecords, inputNumberValueAccessor, isSameNumeric,} from "@/utils/gridUtil";
import {
  formNotPassedMaxLengthCheck,
  formNotPassedRangeCheck,
  formNotPassedRegularExpressionCheck,
  formNotPassedRequiredCheck,
  hpRegExp,
  ValidType,
} from "@/utils/formUtil";
import {numberWithCommas} from "@/utils/number";
import {commonCodesGetColorValue, commonCodesGetCommonCode, commonCodesGetComName,} from "@/utils/commonCodes";
import {deepDiffs} from "@/utils/ObjectUtil";
import {maxBy} from "@/utils/ArrayUtil";
import {isDateFormatString} from "@/utils/string";
// import isEqual from "lodash/isEqual";
import transform from "lodash/transform";
import orderBy from "lodash/orderBy";
import round from "lodash/round";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import gridTelephoneEditTemplate from "@/components/common/gridTemplate/GridTelephoneEditTemplate";
import cloneDeep from "lodash/cloneDeep";

const RULE_REQUIRED = "required";
const RULE_MAX_LENGTH = "maxLength";
const RULE_REGEX = "regex";
const RULE_DUPLICATE_CHECK = "duplicateCheck";
const RULE_MIN = "min";
const RULE_MAX = "max";
const RULE_RANGE = "range";
const RULE_CUSTOM = "custom";
const RULE_TYPE = "type";
const RULE_DATE_FORMAT = "dateFormat";

const GRID_NO_HEADER_TEXT = "NO";
const GRID_RID_PRIMARY_KEY_FIELD = "_rid";

export default {
  name: "ejsGridWrapper",
  mixins: [confirmDialogMixin],
  props: {
    id: {
      type: String,
      default: function () {
        return null;
      },
    },
    // provides. Sort, Filter는 제외하고 넣으면 됨
    provides: {
      type: Array,
      default: function () {
        return [];
      },
    },
    useCommonStyles: {
      type: Boolean,
      default: true,
    },
    // wrapping props
    enableVirtualization: {
      type: Boolean,
      default: false,
    },
    dataSource: {
      type: Array,
    },
    width: {
      default: "100%",
    },
    height: {
      default: "100%",
    },
    gridLines: {
      type: String,
      default: "Both",
    },
    selectionSettings: {
      default: function () {
        return { type: "Multiple", mode: "Both", enableToggle: false };
      },
    },
    editSettings: {
      default: function () {
        return {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        };
      },
    },
    allowResizing: {
      type: Boolean,
      default: true,
    },
    rowHeight: {
      type: Number,
      default: 24,
    },
    allowSorting: {
      type: Boolean,
      default: true,
    },
    allowFiltering: {
      type: Boolean,
      default: true,
    },
    allowKeyboard: {
      type: Boolean,
      default: true
    },
    allowPaging: {
      type: Boolean,
      default: false,
    },
    pageSettings: {
      default: function () {
        return {
          currentPage: 1,
          pageSize: 12,
          pageCount: 8,
          enableQueryString: false,
          pageSizes: false,
          template: null,
        };
      },
    },
    allowSelection: {
      type: Boolean,
      default: true,
    },
    groupSettings: {
      default: function () {
        return {
          showDropArea: true,
          showToggleButton: false,
          showGroupedColumn: false,
          showUngroupButton: true,
          columns: [],
        };
      },
    },
    allowGrouping: {
      type: Boolean,
      default: false,
    },
    aggregates: {
      type: Array,
      default: function () {
        return [];
      },
    },
    enableHover: {
      type: Boolean,
      default: true,
    },
    enableInfiniteScrolling: {
      type: Boolean,
      default: false,
    },
    frozenRows: {
      type: Number,
      default: 0,
    },
    frozenColumns: {
      type: Number,
      default: 0,
    },
    allowExcelExport: {
      type: Boolean,
      default: false,
    },
    contextMenuItems: {
      type: Array,
      default: null,
    },
    allowPdfExport: {
      type: Boolean,
      default: false,
    },
    selectedRowIndex: {
      type: Number,
    },
    /**
     * ### custom props section
     */
    /**
     * NO 컬럼 출력 여부
     */
    isNOColumnDisplay: {
      type: Boolean,
      default: true,
    },
    /**
     * NO 컬럼 Width
     */
    noColumnWidth: {
      type: Number,
      default: null,
    },
    /**
     * 그리드 컬럼 정의. 기존에 <column> 태그로 사용하던 값과 동일
     */
    columns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    /**
     * 기존에 선택했던 row를 조회 이후에도 유지할지 여부. 기준은 isPrimaryKey 컬럼 값
     */
    isSelectedRowRetain: {
      type: Boolean,
      default: true,
    },
    /**
     * 자동으로 row를 select 처리할지 여부.
     */
    isAutoSelectRow: {
      type: Boolean,
      default: true,
    },
    /**
     * 자동으로 cell을 select 처리할지 여부.
     */
    isAutoSelectCell: {
      type: Boolean,
      default: true,
    },
    /**
     * 그리드 validation rules
     */
    validationRules: {
      type: Object,
      default: function () {
        return {};
      },
    },
    validationModification: {
      type: Boolean,
      default: true,
    },
    /**
     * 팝업에서 사용하는 그리드인지 여부
     */
    isInPopup: {
      type: Boolean,
      default: false,
    },
    /**
     * 드래그 앤 드롭 여부
     */
    allowRowDragAndDrop: {
      type: Boolean,
      default: false,
    },
    /**
     * rowDropSettings
     */
    rowDropSettings: {
      default: function () {
        return {
          targetID: "",
        };
      },
    },
    isShowProgress: {
      type: Boolean,
      default: true,
    },
    /**
     * 키보드 액션(엔터 키, 화살표 키) 커스터마이징을
     *  - 직접 하겠다면 true
     *  - 그리드 공통모듈을 사용하겠다면 false
     */
    isCustomizeKeyboardAction: {
      type: Boolean,
      default: false,
    },
    childGrid: {
      type: Object,
      default: null,
    },
  },
  computed: {
    computedDataSource() {
      // datasource 변경(조회/재조회)할 때 sorting/filtering 해제
      this.clearSorting();
      this.clearFiltering();

      return (this.dataSource || []).map((element, index) => ({
        ...element,
        _rid: index + 1,
        _no: index + 1,
      }));
    },
    columnComputed() {
      const columns = cloneDeep(this.columns);

      const isPrimaryKeyVoid = columns.findIndex((column) => column.isPrimaryKey) === -1;
      if (isPrimaryKeyVoid) {
        const primaryKeyColumn = {
          allowEditing: false,
          field: GRID_RID_PRIMARY_KEY_FIELD,
          textAlign: "Center",
          visible: false,
          isPrimaryKey: true,
        };
        columns.unshift(primaryKeyColumn);
      }

      if (this.isNOColumnDisplay) {
        columns.unshift({
          headerText: GRID_NO_HEADER_TEXT,
          allowEditing: false,
          minWidth: this.useCommonStyles ? 16 : 20,
          width: this.noColumnWidth || (this.useCommonStyles ? 50 : 75),
          allowSorting: false,
          allowFiltering: false,
          textAlign: "Center",
          template:'<span></span>',
        });
      }
      return this.setColumnDefaults(columns);
    },
    // computedDataSource() {
    //   // datasource 변경(조회/재조회)할 때 sorting/filtering 해제
    //   return (this.dataSource || []).map((element, index) => ({
    //     ...element,
    //     _rid: index + 1,
    //     index: index + 1,
    //   }));
    // },
    // columnComputed() {
    //   let columnComputed = cloneDeep(this.columns);
    //   const columns = cloneDeep(this.columns);
    //
    //   const isPrimaryKeyVoid = columns.some((column) => !('isPrimaryKey' in column));
    //   if (isPrimaryKeyVoid) {
    //     const primaryKeyColumn = {
    //       allowEditing: false,
    //       field: GRID_RID_PRIMARY_KEY_FIELD,
    //       textAlign: "Center",
    //       visible: false,
    //       isPrimaryKey: true,
    //     };
    //     columns.push(primaryKeyColumn);
    //   }
    //
    //   // PrimaryKey 컬럼이 없으면 추가한다
    //   // if (columns.findIndex((column) => column.isPrimaryKey) === -1) {
    //   //   columns.unshift({
    //   //     allowEditing: false,
    //   //     field: GRID_RID_PRIMARY_KEY_FIELD,
    //   //     textAlign: "Center",
    //   //     visible: false,
    //   //     isPrimaryKey: true,
    //   //   });
    //   // }
    //
    //   if (this.isNOColumnDisplay) {
    //     columns.unshift({
    //       headerText: GRID_NO_HEADER_TEXT,
    //       allowEditing: false,
    //       minWidth: this.useCommonStyles ? 16 : 20,
    //       width: this.noColumnWidth || (this.useCommonStyles ? 50 : 75),
    //       allowSorting: false,
    //       allowFiltering: false,
    //       template: this.numberTemplate,
    //       textAlign: "Center",
    //     });
    //   }
    //
    //   this.setColumnDefaults(columnComputed);
    //   return columns;
    // },
    aggregateComputed() {
      let aggregateComputed = this.aggregates.slice(0);

      const returnAggregates = aggregateComputed.map((aggregateColumns) => {
        let columns = aggregateColumns.columns;

        if (columns && Array.isArray(columns)) {
          columns = aggregateColumns.columns.map((column) => {
            let type = column.type;
            let customAggregate = column.customAggregate;
            let groupFooterTemplate = column.groupFooterTemplate;
            let footerTemplate = column.footerTemplate;
            let templateStyle = {
              textAlign: column.textAlign,
              textColor: column.textColor,
              stringFormat: column.stringFormat,
            };

            switch (column.aggregationType) {
              case "GroupCaption": // 소계 text
                type = "Custom";
                if (column.customAggregate instanceof Function) {
                  customAggregate = column.customAggregate;
                } else if (typeof column.customAggregate === "string") {
                  customAggregate = () => column.customAggregate;
                }
                if (!groupFooterTemplate) {
                  groupFooterTemplate = this.getAggregationCaptionTemplate(
                      templateStyle
                  );
                }
                break;
              case "GroupSum": // 소계 총합
                type = "Custom";
                customAggregate = (aggregates, aggregateColumn) => {
                  const aggregateSum = this.getAggregateSum(
                      aggregates,
                      aggregateColumn
                  );
                  return aggregateSum ? aggregateSum.sum : NaN;
                };
                if (!groupFooterTemplate) {
                  groupFooterTemplate = this.getAggregationCaptionTemplate(
                      templateStyle
                  );
                }
                break;
              case "GroupAvg": // 소계 평균
                type = "Custom";
                customAggregate = (aggregates, aggregateColumn) => {
                  const aggregateSum = this.getAggregateSum(
                      aggregates,
                      aggregateColumn
                  );

                  if (aggregateSum && aggregateSum.length > 0) {
                    return round(aggregateSum.sum / aggregateSum.length, 3);
                  } else {
                    return NaN;
                  }
                };
                if (!groupFooterTemplate) {
                  groupFooterTemplate = this.getAggregationCaptionTemplate(
                      templateStyle
                  );
                }
                break;
              case "GroupCount": // 소계 갯수
                type = "Custom";
                customAggregate = (aggregates) => {
                  const aggregateList = this.getAggregateList(aggregates);
                  return aggregateList ? aggregateList.length : NaN;
                };
                if (!templateStyle.stringFormat) {
                  // stringFormat 기본값
                  templateStyle.stringFormat = "${value} 건";
                }
                if (!groupFooterTemplate) {
                  groupFooterTemplate = this.getAggregationCaptionTemplate(
                      templateStyle
                  );
                }
                break;
              case "TotalCaption": // 전체 text
                type = "Custom";
                if (column.customAggregate instanceof Function) {
                  customAggregate = column.customAggregate;
                } else if (typeof column.customAggregate === "string" || typeof column.customAggregate === "number") {
                  customAggregate = () => column.customAggregate;
                }
                if (!footerTemplate) {
                  footerTemplate = this.getAggregationCaptionTemplate(
                      templateStyle
                  );
                }
                break;
              case "TotalSum": // 전체 총합
                type = "Sum";
                if (!footerTemplate) {
                  footerTemplate = this.getAggregationTotalSumTemplate(
                      templateStyle
                  );
                }
                break;
              case "TotalAvg": // 전체 평균
                type = "Average";
                if (!footerTemplate) {
                  footerTemplate = this.getAggregationTotalAvgTemplate(
                      templateStyle
                  );
                }
                break;
              case "TotalCount": // 전체 갯수(필터링 적용)
                type = "Count";
                if (!templateStyle.stringFormat) {
                  // stringFormat 기본값
                  templateStyle.stringFormat = "${value} 건";
                }
                if (!footerTemplate) {
                  footerTemplate = this.getAggregationTotalCountTemplate(
                      templateStyle
                  );
                }
                break;
              default:
                break;
            }

            return {
              field: column.field,
              textAlign: column.textAlign,
              type: type,
              customAggregate: customAggregate,
              groupFooterTemplate: groupFooterTemplate,
              footerTemplate: footerTemplate,
            };
          });

          return {
            columns: columns,
          };
        }
      });

      return returnAggregates;
    },
  },
  provide() {
    if (this.propsData && Array.isArray(this.propsData.provides)) {
      return { grid: [Sort, Filter, ...this.propsData.provides] }; // provide the services from parent component to the grid
    }
  },
  data() {
    return {
      mouseUpHandler: null,
      keyUpHandler: null,
      focusOutHandler: null,
      currentMaxRid: 1,
      currentPage: 1,
      // numberTemplate() {
      //   return {
      //     template: {
      //       extends: numberTemplate,
      //       propsData: {
      //         pageSize: this.pageSettings?.pageSize ?? 1,
      //         currentPage: this.currentPage,
      //       },
      //     },
      //   };
      // },
      inputNumberValueAccessor: inputNumberValueAccessor,
      selectedKey: null,
      initSelectedRowIndex: this.selectedRowIndex || -1,
      editingCellField: null, // 현재 편집중인 셀의 Field. onCellEdit에서 설정되고, onCellSaved, ESC 키 누를 경우 초기화 된다
      editingCellObject: null, // 현재 편집중인 셀의 column object. onCellEdit에서 설정되고, onCellSaved, ESC 키 누를 경우초기화 된다
      currentSelectedRowIndex: -1, // 현재 선택된 row index. onRowSelected에서 설졍되고, onRowDeselected에서 초기화 된다
      selectedCellIndex: -1, // 현재 선택된 cell index. cellSelected, cellEdit 이벤트 발생 시 갱신된다
      defaultFilterSettings: {
        type: "Menu",
      },
      /**
       * 그리드 편집 중 ESC 키 입력 시 처리를 위함
       *  - 그리드 편집 중 ESC 키 입력 시 상황 : 그리드 편집 --> ESC 키 입력 --> 입력 내용 취소 --> rowDeselecting 이벤트 --> rowDeselected 이벤트
       *  - isEscPressed 변수는 keyPressed 이벤트에서 ESC 키가 입력되었을 경우 true, ESC 키가 입력된 후 rowDeslected 이벤트 발생 시 false
       *  - rowDeselected 이벤트에서, isEscPressed가 true인 경우, rowSelect 처리
       */
      //isEscPressed: false,
      /**
       * 셀 편집 시 focus 컨트롤을 위한 state
       */
      target: null,
      /**
       * GridCheckboxEditTemplate 과의 이벤트 통신을 위한 Object
       */
      checkboxEditTemplateEventObj: {
        isFromClick: false, // 클릭으로 checkbox에 진입한 경우, true로 설정
      },
    };
  },
  mounted() {},
  methods: {
    /**
     * ### wrapping method section - ejs-grid 의 method wrapping
     */
    numberWithCommas,
    commonCodesGetComName,
    commonCodesGetCommonCode,
    getBatchChanges() {
      return this.$refs.grid.getBatchChanges();
    },
    getRowByIndex(index) {
      return this.$refs.grid.getRowByIndex(index);
    },
    patchBatchChanges() {
      const {
        addedRecords,
        changedRecords,
        deletedRecords,
      } = this.getBatchChanges();
      const pkFieldName = this.getPkFieldName();

      return {
        addedRecords,
        changedRecords: this.computedDataSource
            .filter(
                ({ _rid }) =>
                    !!changedRecords?.find(
                        (changedRecord) => _rid === changedRecord._rid
                    )
            )
            .map((oldObject) => {
              if (
                  !deepDiffs(
                      oldObject,
                      changedRecords.find(
                          (changedRecord) => oldObject._rid === changedRecord._rid
                      )
                  )
              ) {
                return;
              }
              return {
                _rid: oldObject._rid,
                [pkFieldName]: oldObject[pkFieldName],
                ...deepDiffs(
                    oldObject,
                    changedRecords.find(
                        (changedRecord) => oldObject._rid === changedRecord._rid
                    )
                )
              };
            })
            .filter(item => item),
        deletedRecords,
      };
    },
    batchSave() {
      this.$refs.grid.ej2Instances.editModule.batchSave();
    },
    batchCancel() {
      this.$refs.grid.ej2Instances.editModule.batchCancel();
    },
    editCell(index, field) {
      if (this.$refs.grid.ej2Instances.editModule) {
        this.$refs.grid.ej2Instances.editModule.editCell(index, field);
      }
    },
    endEdit() {
      this.$refs.grid.ej2Instances.editModule.endEdit();
    },
    selectRow(index, isToggle = false) {
      return this.$refs.grid.selectRow(index, isToggle);
    },
    selectCell({ rowIndex, cellIndex, field }, isToggle = false) {
      cellIndex  = cellIndex?? this.$refs.grid.columns.findIndex((column) => column.field === field);
      return this.$refs.grid.selectCell({ rowIndex, cellIndex }, isToggle);
    },
    sortColumn(columnName, direction, isMultiSort = false) {
      this.$refs.grid.sortColumn(columnName, direction, isMultiSort);
    },
    updateCell(rowIndex, field, value) {
      this.$refs.grid.updateCell(rowIndex, field, value);
    },
    getColumnFieldNames() {
      return this.$refs.grid.getColumnFieldNames();
    },
    getCurrentViewRecords() {
      return this.$refs.grid.getCurrentViewRecords();
    },
    getColumnHeaderByField(field) {
      return this.$refs.grid.getColumnHeaderByField(field);
    },
    saveCell() {
      this.$refs.grid.saveCell();
    },
    clearSelection() {
      this.$refs.grid.clearSelection();
    },
    addRecord(data, index) {
      // no 추가
      if (this.isNOColumnDisplay) {
        const dataRows = this.$refs.grid.getDataRows();
        let addIndex = dataRows.length + 1;

        // if (dataRows.length > 0) {
        //   const gridNoFieldColumnIndex = this.columnComputed.findIndex(
        //     (column) => column.headerText === GRID_NO_HEADER_TEXT
        //   );
        //
        //   // 가장 마지막 row의 No + 1
        //   addIndex = getGridNextNoOfGridElement(
        //     dataRows,
        //     gridNoFieldColumnIndex
        //   );
        // }

        if (data) {
          data._no = addIndex;
        } else {
          data = {
            _no: addIndex,
          };
        }
      }
      // _rid 추가
      data[GRID_RID_PRIMARY_KEY_FIELD] = this.currentMaxRid;
      this.currentMaxRid++;
      this.$refs.grid.ej2Instances.editModule.addRecord(data, index);

      return this.currentMaxRid - 1;
    },
    deleteRecord(fieldname, data) {
      this.$refs.grid.ej2Instances.editModule.deleteRecord(fieldname, data);
    },
    refresh() {
      this.$refs.grid.refresh();
    },
    getSelectedRecords() {
      return this.$refs.grid.getSelectedRecords();
    },
    getRowInfo(dom) {
      return this.$refs.grid.getRowInfo(dom);
    },
    clearSorting() {
      if (this.$refs.grid) {
        this.$refs.grid.clearSorting();
      }
    },
    clearFiltering() {
      if (this.$refs.grid) {
        this.$refs.grid.clearFiltering();
      }
    },
    getRowIndexByPrimaryKey(value) {
      return this.$refs.grid.getRowIndexByPrimaryKey(value);
    },
    getSelectedRowIndexes() {
      return this.$refs.grid.getSelectedRowIndexes();
    },
    updateRow(index, data) {
      this.$refs.grid.updateRow(index, data);
    },
    getContent() {
      return this.$refs.grid.getContent();
    },
    getDataRows() {
      return this.$refs.grid.getDataRows();
    },
    selectRows(rowIndexes) {
      this.$refs.grid.selectRows(rowIndexes);
    },
    getSelectedRows() {
      return this.$refs.grid.getSelectedRows();
    },
    renderEmptyRow() {
      if (
          this.$refs.grid.ej2Instances &&
          this.$refs.grid.ej2Instances.renderModule
      ) {
        this.$refs.grid.ej2Instances.renderModule.renderEmptyRow();
      }
    },
    selectRowsByRange(startIndex, endIndex) {
      this.$refs.grid.ej2Instances.selectRowsByRange(startIndex, endIndex);
    },
    sortSettings() {
      return this.$refs.grid.ej2Instances.sortSettings;
    },
    /**
     * Grouping 컬럼이 1개일 때에만 제대로 동작함
     * (Grouping 컬럼은 최대 1개)
     */
    getAllowGroupingFilterdRecords() {
      const filteredRecords = this.getFilteredRecords();
      let newFilteredRecords = [];
      filteredRecords.forEach((filteredRecord) => {
        newFilteredRecords = newFilteredRecords.concat(filteredRecord.items);
      });
      return newFilteredRecords;
    },
    /**
     * Sorting, Filtering 적용한 그리드 데이터 Count 출력
     */
    getGridBatchCount() {
      const filteredRecords = this.allowGrouping
          ? this.getAllowGroupingFilterdRecords()
          : this.getFilteredRecords();
      return filteredRecords.length > 0
          ? filteredRecords.length
          : this.computedDataSource.length;
    },
    /**
     * Sorting, Filtering 적용한 그리드 데이터 출력
     */
    getGridBatchData(options = []) {
      const filteredRecords = this.allowGrouping
          ? this.getAllowGroupingFilterdRecords()
          : this.getFilteredRecords();

      let result =
          filteredRecords.length > 0
              ? JSON.parse(
                  JSON.stringify(
                      this.allowPaging
                          ? filteredRecords
                          : filteredRecords.records
                              ? filteredRecords.records
                              : filteredRecords
                      // page 적용 안 된 경우, filteredRecords.records의 값을 사용해야 할 경우가 있을 수 있음. 확인중
                  )
              )
              : JSON.parse(JSON.stringify(this.computedDataSource));

      if (filteredRecords.length < 1) {
        const sortSettings = this.sortSettings();
        if (sortSettings.columns.length > 0) {
          const fields = sortSettings.columns.map((item) => item.field);
          const directions = sortSettings.columns.map((item) =>
              item.direction === "Ascending" ? "asc" : "desc"
          );
          result = orderBy(result, fields, directions);
        }
      }

      if (!!options && options.length > 0) {
        options
            .filter((option) => option.format === "comCode")
            .map((option) => {
              result = result.map((item) => {
                const pureData = item[option.field];
                item[option.field] = commonCodesGetComName(
                    option.comCode,
                    item[option.field]
                );
                if (
                    option.comCode === "DW_CODE" ||
                    option.comCode === "BSN_CODE" ||
                    option.comCode === "RESVE_CHANGE_DIV"
                ) {
                  const colorCode = commonCodesGetColorValue(
                      option.comCode,
                      pureData
                  );
                  item[option.targetField || option.field] = `<C.${colorCode}>${
                      item[option.field]
                  }</C>`;
                }
                return item;
              });
            });
        options
            .filter(
                (option) => option.format === "userId" || option.format === "tell" || option.format === "memberNo"
            )
            .map((option) => {
              result = result.map((item) => {
                if (item[option.field]) {
                  if (option.format === "userId") {
                    item[option.field] = item[option.field].replace(
                        /([0-9]{2})([0-9]{2})([0-9]{4})/,
                        "$1-$2-$3"
                    );
                  } else if (option.format === "tell") {
                    item[option.field] = item[option.field].replace(
                        /([0-9]{3})([0-9]{3,4})([0-9]{4})/,
                        "$1-$2-$3"
                    );
                  } else if (option.format === "memberNo") {
                    item[option.field] = item[option.field].replace(
                        /([0-9]{2})([0-9]{4})([0-9]{2})/,
                        "$1-$2-$3"
                    );
                  }
                }
                return item;
              });
            });
        options
            .filter(
                (option) => option.format === "boolean"
            )
            .map((option) => {
              result = result.map((item) => {
                if (item[option.field] !== null) {
                  if (option.format === "boolean") {
                    item[option.field] = item[option.field] ? "예" : "아니오";
                  }
                }
                return item;
              });
            });
      }

      return result;
    },
    getFilteredRecords() {
      return this.$refs.grid.getFilteredRecords();
    },
    excelExport(args) {
      this.$refs.grid.excelExport(args);
    },
    onExcelExportComplete(args) {
      this.$emit("excelExportComplete",args);
    },
    pdfExport() {
      this.$refs.grid.pdfExport();
    },
    /**
     * GridVisitEjsDropdownlistEditTemplate에서 dropdown 값이 바뀌어서 visitEjsDropdownListEditTemplateChanged 이벤트를 emit 하고자 할 때 호출하는 메소드
     */
    onVisitEjsDropdownListEditTemplateChanged(args) {
      this.$emit("visitEjsDropdownListEditTemplateChanged", args);
    },
    /**
     * GridCheckboxEditTemplate에서 체크 변경 시 gridCheckboxChanged 이벤트를 emit 하고자 할 때 호출하는 메소드
     */
    onGridCheckboxChanged(args) {
      // 전체 선택 체크박스 처리
      try {
        const selectAllColumns = this.$refs.grid
            .getColumns()
            .filter(
                (column) =>
                    column.field === args.columnName && column.isSelectAllColumn
            );
        if (selectAllColumns.length === 1) {
          this.setSelectAllColumnCheckbox(
              selectAllColumns[0].field,
              args.rowIndex,
              args.columnName,
              args.value
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$emit("gridCheckboxChanged", args);
      }
    },
    /**
     * ### custom methods section - 기존 ejs-grid에는 없는 method grid wrapper 에서 사용하기 위해 새로 생성
     */
    getBatchCurrentViewRecords() {
      const pkFieldName = this.getPkFieldName();
      return getBatchCurrentViewRecords(this.$refs.grid, pkFieldName);
    },
    async getSortedFilteredDataSource() {
      // empty data source
      if (!this.dataSource || this.dataSource.length === 0) {
        return [];
      } else {
        if (this.$refs.grid.ej2Instances.filterSettings.columns.length > 0) {
          // filtering이 적용된 경우
          return this.getFilteredRecords();
        } else if (
            this.$refs.grid.ej2Instances.sortSettings.columns.length > 0
        ) {
          // filtering이 적용되지 않고 sorting이 적용된 경우
          let query = this.$refs.grid.getDataModule().generateQuery(true);
          try {
            let {
              result: sortedRecords,
            } = await this.$refs.grid.getDataModule().getData({}, query);
            return sortedRecords;
          } catch (e) {
            console.error(e);
            return [];
          }
        } else {
          // filtering, sorting 모두 적용되지 않음
          return this.dataSource.slice(0);
        }
      }
      /*let sortedColumns = this.$refs.grid.ej2Instances.sortModule.sortSettings.columns.map((column) => ({
          field: column.field,
          direction: column.direction
        }));
        let sortedColumnFields = sortedColumns.map((column) => column.field);
        let sortedColumnDirection = sortedColumns.map((column) =>
          column.direction === "Ascending" ? "asc" : (column.direction === "Descending" ? "desc" : null)
        );

        return _.orderBy(this.dataSource, sortedColumnFields, sortedColumnDirection);*/
    },
    /**
     * Popup 그리드에서 더블클릭 or Enter 시 이벤트 fire
     */
    dialogGridDoubleClickedOrEnterKeyed() {
      if (this.isInPopup) {
        this.saveCell();

        // event fire
        setTimeout(() => {
          // TODO : 이벤트 이상으로 인한 timer 처리. 문의 후에 정상 동작하면 수정해야 함
          this.$emit("onGridDialogDoubleClickedOrEnterKeyed");
        }, 200);
      }
    },
    /**
     * 저장된 데이터가 있는지 여부 체크
     */
    isGridModified() {
      this.saveCell();

      const {
        addedRecords,
        changedRecords,
        deletedRecords,
      } = this.getBatchChanges();
      if (
          addedRecords.length === 0 &&
          changedRecords.length === 0 &&
          deletedRecords.length === 0
      ) {
        return false;
      }
      return true;
    },
    /**
     * 그리드 validation. 저장된 데이터 여부 있는지를 체크하고, rule을 체크한다
     */
    validate() {
      this.saveCell(); // 현재 편집중인 셀을 저장하여야 함. scroll이 넘어가는 컬럼에서, 뒷 부분에 추가 텍스트 입력 후 다른 화면 클릭 시 저장 표시 되지 않는 버그 있음 TODO : 공유

      // #1. 저장된 데이터 여부 체크 : validationModification property가 true인 경우에만
      if (this.validationModification && !this.isGridModified()) {
        this.$EventBus.$emit(
            "errToast",
            this.$t("main.validationMessage.noChanges")
        );
        return false;
      }

      // #2. rule 체크
      const batchCurrentViewRecords = this.getBatchCurrentViewRecords();
      try {
        for (let validationKey in this.validationRules) {
          if (
              !this.validateRules(
                  validationKey,
                  this.validationRules[validationKey],
                  batchCurrentViewRecords
              )
          ) {
            return false;
          }
        }
        return true;
      } catch (e) {
        console.error(e);
        this.$EventBus.$emit(
            "errToast",
            this.$t("main.validationMessage.invalidRule")
        );
        return false;
      }
    },
    getElement() {
      return this.$refs.grid.ej2Instances.element;
    },
    /**
     * ### custom util method section - wrapper 내부에서 사용하기 위한 methods
     */
    /**
     * 하나의 rule 집합 validation
     */
    validateRules(field, rule, batchCurrentViewRecords) {
      for (let ruleKey in rule) {
        if (
            !this.validateRule(
                field,
                ruleKey,
                rule[ruleKey],
                batchCurrentViewRecords
            )
        ) {
          return false;
        }
      }
      return true;
    },
    validateRule(field, key, value, batchCurrentViewRecords) {
      if (!Array.isArray(batchCurrentViewRecords)) {
        throw new Error("batchCurrentViewRecords is not array");
      }

      const headerText = this.getHeaderTextInColumns(field);

      switch (key) {
        case RULE_REQUIRED:
          if (value) {
            const notExistingIdx = batchCurrentViewRecords.findIndex((record) =>
                formNotPassedRequiredCheck(record[field])
            );
            if (notExistingIdx !== -1) {
              this.showErrorToastAndEditCell(
                  "main.validationMessage.requiredMessage",
                  [headerText],
                  notExistingIdx,
                  field
              );
              return false;
            }
          }
          return true;
        case RULE_MAX_LENGTH:
          // eslint-disable-next-line no-case-declarations
          const longerIdx = batchCurrentViewRecords.findIndex((record) =>
              formNotPassedMaxLengthCheck(record[field], value)
          );
          if (longerIdx !== -1) {
            this.showErrorToastAndEditCell(
                "main.validationMessage.maxLengthMessage",
                [headerText, value],
                longerIdx,
                field
            );
            return false;
          }
          return true;
        case RULE_REGEX:
          return this.regExpValidate(
              value,
              batchCurrentViewRecords,
              field,
              headerText
          );
        case RULE_MIN:
          // eslint-disable-next-line no-case-declarations
          const minIdx = batchCurrentViewRecords.findIndex((record) =>
              formNotPassedRangeCheck(record[field], value, true)
          );
          if (minIdx !== -1) {
            this.showErrorToastAndEditCell(
                "main.validationMessage.minMessage",
                [headerText, value],
                minIdx,
                field
            );
            return false;
          }
          return true;
        case RULE_MAX:
          // eslint-disable-next-line no-case-declarations
          const maxIdx = batchCurrentViewRecords.findIndex((record) =>
              formNotPassedRangeCheck(record[field], value, false)
          );
          if (maxIdx !== -1) {
            this.showErrorToastAndEditCell(
                "main.validationMessage.maxMessage",
                [headerText, value],
                maxIdx,
                field
            );
            return false;
          }
          return true;
        case RULE_RANGE:
          // eslint-disable-next-line no-case-declarations
          const rangeIdx = batchCurrentViewRecords.findIndex(
              (record) =>
                  formNotPassedRangeCheck(record[field], value[0], true) ||
                  formNotPassedRangeCheck(record[field], value[1], false)
          );
          if (rangeIdx !== -1) {
            this.showErrorToastAndEditCell(
                "main.validationMessage.rangeMessage",
                [headerText, value[0], value[1]],
                rangeIdx,
                field
            );
            return false;
          }
          return true;
        case RULE_DUPLICATE_CHECK:
          // eslint-disable-next-line no-case-declarations
          let transformResult = true;
          if (value) {
            transform(
                batchCurrentViewRecords,
                (reduceResult, reduceValue, reduceKey) => {
                  const checkValue = reduceValue[field];
                  const checkArray = reduceResult.filter(
                      (r) => r.value === checkValue
                  );

                  if (checkArray.length > 0) {
                    this.showErrorToastAndEditCell(
                        "main.validationMessage.duplicateCheckMessage",
                        [headerText],
                        checkArray[0].idx,
                        field
                    );
                    transformResult = false;
                    return false;
                  }

                  reduceResult.push({
                    value: checkValue,
                    idx: reduceKey,
                  });
                  return reduceResult;
                },
                []
            );
          }
          return transformResult;
        case RULE_TYPE:
          switch (value) {
            case ValidType.HP:
              return this.regExpValidate(
                  hpRegExp,
                  batchCurrentViewRecords,
                  field,
                  headerText
              );
            default:
              throw new Error("Invalid grid validation rule");
          }
        case RULE_CUSTOM:
          // eslint-disable-next-line no-case-declarations
          const invalidIdx = batchCurrentViewRecords.findIndex(
              (record) => !value.method(record)
          );
          if (invalidIdx !== -1) {
            this.showErrorToastAndEditCell(
                value.message,
                [headerText].concat(value.messageValue),
                invalidIdx,
                field
            );
            return false;
          }
          return true;
        case RULE_DATE_FORMAT:
          // eslint-disable-next-line no-case-declarations
          const isDateFormat = batchCurrentViewRecords.findIndex(
              (record) => !isDateFormatString(record[field])
          );

          if (isDateFormat !== -1) {
            this.showErrorToastAndEditCell(
                "main.validationMessage.invalidateDateFormat",
                [headerText],
                isDateFormat,
                field
            );
            return false;
          }
          return true;
        default:
          throw new Error("Invalid grid validation rule");
      }
    },
    regExpValidate(ruleRegExp, batchCurrentViewRecords, field, headerText) {
      const notMatchedIdx = batchCurrentViewRecords.findIndex((record) => {
        const value = record[field];
        if (value === null || value === undefined || value === "") {
          // null, undefined, empty string은 regexp 에서는 체크하지 않는다
          return false;
        }
        return formNotPassedRegularExpressionCheck(record[field], ruleRegExp);
      });
      if (notMatchedIdx !== -1) {
        this.showErrorToastAndEditCell(
            "main.validationMessage.regexMessage",
            [headerText],
            notMatchedIdx,
            field
        );
        return false;
      }
      return true;
    },
    getPkFieldName() {
      let primaryKeyField = this.$refs.grid
          .getColumns()
          .find((column) => column.isPrimaryKey);
      if (!primaryKeyField) {
        throw new Error("Primary Key is required!");
      }
      return primaryKeyField.field;
    },
    getHeaderTextInColumns(field) {
      const fieldType = this.columns.find((column) => column.field === field);
      if (fieldType) {
        return fieldType.headerText;
      } else {
        return null;
      }
    },
    showErrorToastAndEditCell(
        contentMessageKey,
        contentMessageValues,
        editIdx,
        field
    ) {
      this.$EventBus.$emit(
          "errToast",
          this.$t(contentMessageKey, contentMessageValues)
      );
      this.editCell(editIdx, field);
    },
    getEditTemplate(column) {
      if (column.isNumericType) {
        return () =>
            gridInputNumberEditTemplate(column.field, {
              ...column.inputNumberProperty,
              align: column.textAlign?.toLowerCase() || "right",
              useCommonStyles: this.useCommonStyles,
            });
      } else if (column.isDateType) {
        return () => gridInputDateTemplate(column.field, column.dateProperty);
      } else if (column.isTimeType) {
        return () => gridInputTimeTemplate(column.field, column.dateProperty);
      } else if (this.isCheckboxColumn(column)) {
        return () => gridCheckboxEditTemplate(column.field, this);
      } else if (this.isStringEditColumn(column)) {
        return () =>
            gridStringEditTemplate(column.field, { maxLength: column.maxLength });
      } else if (this.isTelephoneEditColumn(column)) {
        return () =>
            gridTelephoneEditTemplate(column.field, { maxLength: 11 });
      } else {
        return column.editTemplate ? column.editTemplate : null;
      }
    },
    isCheckboxColumn(column) {
      return (
          column.editType === "booleanedit" &&
          column.type === "boolean" &&
          column.allowEditing
      );
    },
    isStringEditColumn(column) {
      return (
          column.editType === "stringedit" &&
          column.allowEditing &&
          column.type === "string" &&
          !column.editTemplate &&
          (!column.edit ||
              (column.edit &&
                  !Object.prototype.hasOwnProperty.call(column.edit, "create") &&
                  !Object.prototype.hasOwnProperty.call(column.edit, "read") &&
                  !Object.prototype.hasOwnProperty.call(column.edit, "destroy") &&
                  !Object.prototype.hasOwnProperty.call(column.edit, "write")))
      );
    },
    isTelephoneEditColumn(column) {
      return (
          column.editType === "telephoneedit" &&
          column.allowEditing &&
          column.type === "string" &&
          !column.editTemplate &&
          (!column.edit ||
              (column.edit &&
                  !Object.prototype.hasOwnProperty.call(column.edit, "create") &&
                  !Object.prototype.hasOwnProperty.call(column.edit, "read") &&
                  !Object.prototype.hasOwnProperty.call(column.edit, "destroy") &&
                  !Object.prototype.hasOwnProperty.call(column.edit, "write")))
      );
    },
    isDropdownColumn(column) {
      return (
          (column.isCommonCodeField || column.editType === "dropdownedit") &&
          column.allowEditing
      );
    },
    /**
     * 공통코드 ForeignKey 컬럼의 dataSource에 고유한 ForeignKeyValue 필드 추가
     * 그리드 컬럼의 ForeignKeyValue 값은 고유해야 한다. 고유하지 않으면 필터링 버그 발생
     */
    setConvertedCommonCodeDataSourceComName(dataSource, columnField) {
      dataSource.forEach((data) => {
        data[`comName_${columnField}`] = data.comName;
        // data.comName property는 유지해야 한다. 그렇지 않으면 예약접수등록 그리드의 2번째 코스부터는 홀 값이 제대로 설정되지 않음
      });
    },
    /**
     * 그리드 one-click editing 기능 모듈 추가. 그리드의 load 이벤트 핸들러에 추가하면 됨
     *
     * @param gridInstance 그리드 Object ej2instance
     * @param eventParam load 이벤트 파라미터
     */
    setGridOneClickEdit(gridInstance, eventParam) {
      if (eventParam.target.classList.contains("e-rowcell")) {
        let index = gridInstance
            .getDataRows()
            .findIndex((dataRow) => dataRow === eventParam.target.parentElement);
        if (index === -1) {
          index = gridInstance
              .getMovableDataRows()
              .findIndex(
                  (dataRow) => dataRow === eventParam.target.parentElement
              );
        }
        let colindex = parseInt(
            eventParam.target.getAttribute("aria-colindex")
        );
        let column = gridInstance.getColumns()[colindex];
        let field = column.field;

        if (this.isCheckboxColumn(column)) {
          // checkbox 셀을 클릭하여 editTemplate을 띄웠다고 GridCheckboxEditTemplate에 전달
          // - 클릭으로 진입한 경우, 체크박스의 값을 바로 바꿔줘야 하는데
          //   cellSave / cellSaved 이벤트의 previousValue 값을 정확하게 받기 위해서는 editTemplate 내에서 값을 바꿔야 한다.
          this.checkboxEditTemplateEventObj.isFromClick = true;
          return this.editCell(index, field);
        } else if (this.isDropdownColumn(column)) {
          return this.editCell(index, field);
        }

        // 편집 가능한 셀만 editCell 호출
        // allowEditing === true 이고, PK가 아닌 셀
        // allowEditing === true 이고 PK인 셀 중 추가된 record의 셀
        const primaryKeyFieldName = gridInstance.getPrimaryKeyFieldNames()[0];
        if (
            // isEditCell &&
            column.allowEditing &&
            (primaryKeyFieldName !== field ||
                (primaryKeyFieldName === field &&
                    eventParam.target.classList.contains("e-updatedtd")))
        ) {
          this.editCell(index, field);
        }
      }
    },
    // setColumnDefaults(columns) {
    //   if (!Array.isArray(columns)) {
    //     throw new Error("Invalid Columns Props");
    //   }
    //
    //   // 기본값을 설정하는 함수
    //   const setDefault = (value, defaultValue) => {
    //     return value !== undefined ? value : defaultValue;
    //   };
    //
    //   // 새로운 컬럼 객체에 기본값을 설정하는 함수
    //   const getDefaultColumnObject = (column) => ({
    //     ...column,
    //     allowFiltering: setDefault(column.allowFiltering, true),
    //     allowSorting: setDefault(column.allowSorting, true),
    //     isPrimaryKey: setDefault(column.isPrimaryKey, false),
    //     textAlign: setDefault(column.textAlign, "Left"),
    //     headerTextAlign: setDefault(column.headerTextAlign, null),
    //     type: setDefault(column.type, null),
    //     format: setDefault(column.format, undefined),
    //     allowEditing: setDefault(column.allowEditing, true),
    //     minWidth: setDefault(column.minWidth, ""),
    //     maxWidth: setDefault(column.maxWidth, ""),
    //     width: setDefault(column.width, ""),
    //     editType: setDefault(column.editType, "stringedit"),
    //     displayAsCheckBox: setDefault(column.displayAsCheckBox, false),
    //     defaultValue: setDefault(column.defaultValue, null),
    //     columns: setDefault(column.columns, null),
    //     visible: setDefault(column.visible, true),
    //     template: setDefault(column.template, null),
    //     isNumericType: setDefault(column.isNumericType, false),
    //     isDateType: setDefault(column.isDateType, false),
    //     isTimeType: setDefault(column.isTimeType, false),
    //     maxLength: setDefault(column.maxLength, 50),
    //     // 추가 설정이 필요한 컬럼은 여기에 추가
    //   });
    //
    //   return columns.map((column) => {
    //     const newColumn = getDefaultColumnObject(column);
    //
    //     if (newColumn.isCommonCodeField && newColumn.dataSource) {
    //       this.setConvertedCommonCodeDataSourceComName(newColumn.dataSource, newColumn.field);
    //     }
    //
    //     if (newColumn.isSelectAllColumn) {
    //       newColumn.headerTemplate = () =>
    //         gridCheckAllHeaderTemplate(
    //             newColumn.field,
    //             newColumn.headerText,
    //             newColumn.selectAllCheckFunction,
    //             this
    //         );
    //       newColumn.allowFiltering = false;
    //       newColumn.allowSorting = false;
    //     }
    //
    //     // 재귀적으로 하위 컬럼에 대해서도 기본값 설정
    //     if (Array.isArray(newColumn.columns)) {
    //       newColumn.columns = this.setColumnDefaults(newColumn.columns);
    //     }
    //
    //     return newColumn;
    //   });
    // },
    /**
     * column array default 값 설정
     * 재귀호출을 통해 array에 columns 필드도 모두 설정
     */
    setColumnDefaults(columns) {
      if (!Array.isArray(columns)) {
        throw new Error("Invalid Columns Props");
      }
      columns.forEach((column) => {
        column.allowFiltering =
            column.allowFiltering !== undefined ? column.allowFiltering : true;
        column.allowSorting =
            column.allowSorting !== undefined ? column.allowSorting : true;
        column.isPrimaryKey =
            column.isPrimaryKey !== undefined ? column.isPrimaryKey : false;
        column.textAlign = column.textAlign ? column.textAlign : "Left";
        column.headerTextAlign = column.headerTextAlign
            ? column.headerTextAlign
            : null;
        column.type = column.type ? column.type : null;
        column.format = column.format ? column.format : undefined;
        column.allowEditing =
            column.allowEditing !== undefined ? column.allowEditing : true;
        column.minWidth = column.minWidth ? column.minWidth : "";
        column.maxWidth = column.maxWidth ? column.maxWidth : "";
        column.width = column.width ? column.width : "";
        column.editType = column.editType ? column.editType : "stringedit";
        column.foreignKeyField = column.isCommonCodeField
            ? "comCode"
            : column.foreignKeyField;
        column.foreignKeyValue = column.isCommonCodeField
            ? `comName_${column.field}` // 그리드 컬럼의 ForeignKeyValue 값은 고유해야 한다. 고유하지 않으면 필터링 버그 발생
            : column.foreignKeyValue;
        // TODO : 모든 dataSource 속성은 groupCode로 대체중임.
        column.dataSource = column.groupCode
            ? commonCodesGetCommonCode(column.groupCode, column.allowEditing, column.isDropdowneditInit, column.dropdowneditInitValue)
            : column.dataSource
                ? column.dataSource
                : null;
        // 그리드 컬럼의 ForeignKeyValue 값을 고유하게 하기 위하여, column.dataSource 속성 추가
        // 참고) column.dataSource 자체를 변경(ex) column.dataSource = [])하는 경우,
        //        예약접수등록 그리드에서 infinite update loop 가 발생하므로, 아래와 같이 속성을 추가해야 함
        if (column.isCommonCodeField && column.dataSource) {
          this.setConvertedCommonCodeDataSourceComName(
              column.dataSource,
              column.field
          );
        }
        column.edit =
            column.edit ?
                column.edit :
                (
                    // 공통코드로 데이터 셋팅하는 경우 DropDownList의 기본 정렬을 사용하지 않는다.
                    column.isCommonCodeField && column.dataSource ?
                        { params: { sortOrder: "none" } } :
                        {}
                );
        column.displayAsCheckBox =
            column.displayAsCheckBox !== undefined
                ? column.displayAsCheckBox
                : false;
        column.defaultValue = column.defaultValue ? column.defaultValue : null;
        column.columns = column.columns ? column.columns : null;
        column.valueAccessor =
            column.isNumericType && !column.valueAccessor
                ? inputNumberValueAccessor
                : column.valueAccessor;
        column.visible = column.visible !== undefined ? column.visible : true;
        column.template = column.template ? column.template : null;
        column.isNumericType =
            column.isNumericType !== undefined ? column.isNumericType : false;
        column.isDateType =
            column.isDateType !== undefined ? column.isDateType : false;
        column.isTimeType =
            column.isTimeType !== undefined ? column.isTimeType : false;
        column.editTemplate = this.getEditTemplate(column);
        column.maxLength =
            column.maxLength !== undefined ? column.maxLength : 50;
        /*column.sortComparer = (reference, comparer) => { // 고려 중
            if (reference < comparer) {
              return -1;
            }
            if (reference > comparer) {
              return 1;
            }
            return 0;
          };*/
        if (column.isSelectAllColumn) {
          column.headerTemplate = column.isSelectAllColumn
              ? () =>
                  gridCheckAllHeaderTemplate(
                      column.field,
                      column.headerText,
                      column.selectAllCheckFunction,
                      this
                  )
              : null;
          column.allowFiltering = false;
          column.allowSorting = false;
        }

        // 밑의 depth default 설정
        if (Array.isArray(column.columns)) {
          this.setColumnDefaults(column.columns);
        }
      });
      return columns;
    },
    /**
     * header template의 select all 체크박스 값 초기화
     */
    setSelectAllColumnCheckboxes() {
      const selectAllColumns = this.$refs.grid
          .getColumns()
          .filter((column) => column.isSelectAllColumn);
      selectAllColumns.forEach((selectAllColumn) => {
        this.setSelectAllColumnCheckbox(selectAllColumn.field);
      });
    },
    /**
     * 전체 선택 체크박스 관련하여, 전체 선택을 체크해야 하는 모든 row data를 가져옴
     * 컬럼에 selectAllCheckFunction 이 적용되어 있으면, selectAllCheckFunction로 체크를 통과한 row data를 가져옴
     * gridCheckboxChanged 이벤트를 태웠으므로, 변경한 값이 getCheckBatchCurrentViewRecords()의 결과에 반영되지 않았음. 따라서 보정을 위해 rowInidex, columnName, value 파라미터를 둠
     * @param field 컬럼 field명
     * @param rowIndex 보정을 위한 파라미터 : 수정하였지만 아직 반영되지 않은 rowIndex
     * @param columnName 보정을 위한 파라미터 : 수정하였지만 아직 반영되지 않은 컬럼 이름
     * @param value 보정을 위한 파라미터 : 수정하였지만 아직 반영되지 않은 값
     * @returns {*}
     */
    getCheckBatchCurrentViewRecords(
        field,
        rowIndex = -1,
        columnName = null,
        value = null
    ) {
      const column = this.$refs.grid
          .getColumns()
          .find((column) => column.field === field);
      const batchCurrentViewRecords = JSON.parse(
          JSON.stringify(this.getBatchCurrentViewRecords())
      );

      // gridCheckboxChanged 이벤트를 태웠으므로, 변경한 값이 getCheckBatchCurrentViewRecords()의 결과에 반영되지 않았음. 따라서 보정하는 것임
      if (rowIndex > -1 && columnName !== null && value !== null) {
        batchCurrentViewRecords[rowIndex][columnName] = value;
      }

      const toCheckBatchCurrentViewRecords = batchCurrentViewRecords.filter(
          (viewRecord) => {
            return column.selectAllCheckFunction
                ? column.selectAllCheckFunction(viewRecord)
                : true;
          }
      );
      return toCheckBatchCurrentViewRecords;
    },
    setSelectAllColumnCheckbox(
        field,
        rowIndex = -1,
        columnName = null,
        value = null
    ) {
      const toCheckBatchCurrentViewRecords = this.getCheckBatchCurrentViewRecords(
          field,
          rowIndex,
          columnName,
          value
      );

      let isCheckedAll = true;
      if (toCheckBatchCurrentViewRecords.length === 0) {
        isCheckedAll = false;
      } else {
        toCheckBatchCurrentViewRecords.some((viewRecord) => {
          if (!viewRecord[field]) {
            isCheckedAll = false;
            return false;
          }
        });
      }

      /*let isCheckedAll =
          batchCurrentViewRecords.filter(record => record[field]).length >=
          batchCurrentViewRecords.filter(viewRecord => {
            return column.selectAllCheckFunction ? column.selectAllCheckFunction(viewRecord) : true;
          }).length;*/
      const checkAllCheckbox = this.$refs.grid
          .getHeaderContent()
          .querySelector(`#checkAll_Header_${field}`);
      checkAllCheckbox.checked = isCheckedAll;
    },
    getColumnObject(field) {
      return this.getColumnObjectInList(this.columns, field);
    },
    getColumnObjectInList(columns, field) {
      try {
        let returnColumn = columns.find((column) => column.field === field);
        if (returnColumn) {
          return returnColumn;
        } else {
          columns.some((column) => {
            if (column.columns && column.columns.length > 0) {
              returnColumn = this.getColumnObjectInList(column.columns, field);
              if (returnColumn) {
                return true;
              }
            }
          });
          if (returnColumn) {
            return returnColumn;
          }
        }
      } catch (e) {
        console.error(e);
        return null;
      }
      return null;
    },
    /**
     * Wrapping Events
     */
    onBatchDelete(args) {
      this.clearSelection();
      this.$emit("batchDelete", args);

      // 삭제 후 row 선택 : 삭제된 row 바로 위 행 선택. 없으면 첫 번째 행 선택
      const currentGridRowLength = this.$refs.grid.getDataRows().length;
      const rowIndex = args.rowIndex;
      let selectRowIndexAfterDelete = -1;

      if (currentGridRowLength > 0) {
        if (rowIndex > 0) {
          selectRowIndexAfterDelete = rowIndex - 1;
        } else if (rowIndex === 0) {
          selectRowIndexAfterDelete = 0;
        }
      }

      if (selectRowIndexAfterDelete >= 0) {
        this.$refs.grid.selectRow(selectRowIndexAfterDelete);
      }
    },
    onCreated(args) {
      this.$emit("created", args);
    },
    onRowDrop(args) {
      this.$emit("rowDrop",args);
    },
    onQueryCellInfo(args) {
      if(this.isNOColumnDisplay && !args.column.field && args.column.index === 0) {
        args.cell.innerText = args.data._no;
      }
      this.$emit("queryCellInfo", args);
    },
    onCellEdit(args) {
      this.selectedCellIndex = args.cell.cellIndex;
      this.$emit("cellEdit", args);
      this.editingCellField = args.columnName;
      this.editingCellObject = args.columnObject;

      // 공통코드 combobox인 경우, focusing 시에 펼쳐짐
      if (args.columnObject.isCommonCodeField) {
        this.$nextTick(() => {
          const inputObject = args.cell.querySelector("input");
          if (inputObject) {
            inputObject.ej2_instances[0].showPopup();
          }
        });
      }
    },
    onCellSave(args) {
      if (this.target && this.containsDevEditComponentElement(this.target)) {
        args.cancel = true;
        this.target = null;

        return;
      }

      if (isSameNumeric(args)) {
        args.value = args.previousValue;
      } else if (args.columnObject.isCommonCodeField) {
        // 공통코드 combobox인 경우, tab 키를 눌러서 빠져나가는 경우에도 현재 선택된 값이 저장되도록 함
        const selectedItemData = args.cell.querySelector("input")
            .ej2_instances[0].itemData;
        args.value = selectedItemData?.comCode;
      }
      this.$emit("cellSave", args);
    },
    onCellSaved(args) {
      this.editingCellField = null;
      this.editingCellObject = null;
      this.$emit("cellSaved", args);
    },
    onHeaderCellInfo(args) {
      // 필터링이 적용된 그리드에서 필터링 기능을 사용하지 않는 컬럼에 넣는 class -> 모두 다 가운데 정렬로 가기로 하였으므로 주석 처리(2020/04/21)
      /*if (this.allowFiltering && !args.cell.column.allowFiltering) {
          args.node.classList.add(this.$t("className.grid.noSortFilter"));
        }*/
      this.$emit("headerCellInfo", args);
    },
    onLoad(args) {
      this.addGridEventHandlers();

      // 적용하지 않기로 함
      /*this.$refs.grid.ej2Instances.element.addEventListener("focusout", e => {
          if (
            !parentsUntil(e.relatedTarget, "e-editedbatchcell") &&
            parentsUntil(e.target, "e-editedbatchcell")
          ) {
          this.$refs.grid.ej2Instances.saveCell();
          }
        });
      */
      this.$emit("load", args);
    },
    addGridEventHandlers() {
      const elem = this.$refs.grid.ej2Instances.element;

      this.mouseUpHandler = (e) => {
        this.setGridOneClickEdit(this.$refs.grid.ej2Instances, e);
      };
      this.keyUpHandler = (e) => {
        if (e.keyCode === 13 && e.ctrlKey) {
          this.setGridOneClickEdit(this.$refs.grid.ej2Instances, e, true);
        }
      };
      this.focusOutHandler = (e) => {
        this.target = e.relatedTarget;
      };

      elem.addEventListener("mouseup", this.mouseUpHandler);
      elem.addEventListener("keyup", this.keyUpHandler);
      elem.addEventListener("focusout", this.focusOutHandler);
    },
    removeGridEventHandlers() {
      const elem = this.$refs.grid?.ej2Instances.element;

      if(elem) {
        elem.removeEventListener("mouseup", this.mouseUpHandler);
        elem.removeEventListener("keyup", this.keyUpHandler);
        elem.removeEventListener("focusout", this.focusOutHandler);
      }
    },
    /**
     * 데이터와 함께 완전히 렌더링되고 모든 그리드 컨텐츠가 DOM에 바인딩 된 후 발생
     * 데이터바인딩 완료
     * 그리드의 모든 로우가 렌더링되고 사용가능한 상태
     */
    onDataBound(args) {
      // select all 처리
      this.setSelectAllColumnCheckboxes();

      // Grouping 적용한 그리드에서 Grouping으로 접었다 폈다 하는 부분 제거
      if (this.groupSettings.columns.length > 0) {
        let ele = document.getElementsByClassName("e-groupcaption");
        for (let i = 0; i < ele.length; i++) {
          ele[i].parentElement.style.display = "none";
        }
      }

      this.$emit("dataBound", args);
    },
    async onRowSelecting(args) {
      await this.$nextTick();
      const $el = document.querySelector("#confirmDialogOkay");
      if ($el) {
        args.cancel = true;
        setTimeout(function () {
          document.activeElement.blur();
          $el.focus();
        });
      }

      this.$emit("rowSelecting", args);
    },
    onRowSelected(args) {
      if (this.isSelectedRowRetain) {
        // 선택한 row 재 선택을 위하여 key 값을 저장
        const pkFieldName = this.getPkFieldName();
        this.selectedKey = args.data[pkFieldName];
        this.initSelectedRowIndex = args.rowIndex;
      }

      this.currentSelectedRowIndex = args.rowIndex;
      this.$emit("rowSelected", args);
    },
    onRowDeselected(args) {
      // ESC 키 입력으로 인한 rowDeselected 이벤트인 경우, selectCell 처리
      /*if (this.isEscPressed) {
        this.isEscPressed = false;

        this.$nextTick(() => {
          this.selectCell(
            {
              rowIndex: this.initSelectedRowIndex,
              cellIndex: this.selectedCellIndex
            },
            true
          );
          // this.editCell(this.initSelectedRowIndex, this.editingCellField);
        });
      }*/

      this.currentSelectedRowIndex = -1;
      this.$emit("rowDeselected", args);
    },
    onCellSelected(args) {
      const { cellIndex, rowIndex } = args.cellIndex;

      this.selectedCellIndex = cellIndex;

      if (this.isNOColumnDisplay && cellIndex === 0) {
        this.$nextTick(() => {
          this.$refs.grid.selectCell({
            rowIndex,
            cellIndex: 1,
          });
        });
      } else {
        this.$emit("cellSelected", args);
      }
    },
    onRowDataBound(args) {
      this.$emit("rowDataBound", args);
    },
    onRecordClick(args) {
      this.$emit("recordClick", args);
    },
    onRecordDoubleClick(args) {
      this.$emit("recordDoubleClick", args);
      this.dialogGridDoubleClickedOrEnterKeyed(); // 모든 이벤트를 처리하고 제일 마지막에 발생시켜야 한다
    },
    onKeyPressed(args) {
      // ESC 키 누를 경우 선택 중 object 초기화
      if (args.code === "Escape") {
        this.editingCellField = null;
        this.editingCellObject = null;
      }
      this.$emit("keyPressed", args);

      if (args.key === "Enter") {
        // 기본 이벤트 동작 제외
        // args.cancel = true;

        // 현재 편집 빠져나오기
        // this.saveCell();
        // this.selectRow(this.initSelectedRowIndex);
        // this.$refs.grid.selectCell({ rowIndex: this.initSelectedRowIndex, cellIndex: this.selectedCellIndex }, true);

        if (this.isInPopup) {
          // isInPopup이 true이면 이벤트 취소
          args.cancel = true;
        }
        this.dialogGridDoubleClickedOrEnterKeyed();
      }
    },
    /**
     * 정렬, 필터링, 그뤃봐, 편집 등이 시작될 때 발생하는 이벤트
     * 특정 동작을 시작했을 때 바로 트리거
     *
     * - 사용자가 데이터를 정렬하거나 필터링하기 전에 유효성 검사를 수행하고 싶을 때.
     * - 특정 조건에서 사용자의 액션을 방지하고 싶을 때.
     * - 액션이 시작되기 전에 로딩 인디케이터를 표시하고 싶을 때.
     * - 액션이 시작되기 전에 사용자에게 확인 메시지를 표시하고 싶을 때.
     *
     * e.requestType, e.cancel
     * 속성을 액션의 후속작업과 액션실행을 중지시킬 수 있다.
     */
    onActionBegin(args) {
      if (
          this.isShowProgress &&
          (args.requestType === "refresh" ||
              args.requestType === "filtering" ||
              args.requestType === "sorting" ||
              args.requestType === "paging")
      ) {
        this.$EventBus.$emit("gridLoaderOn");
      }

      if (args.requestType === "sorting") {
        const {
          addedRecords,
          changedRecords,
          deletedRecords,
        } = this.getBatchChanges();
        if (
            addedRecords.length > 0 ||
            changedRecords.length > 0 ||
            deletedRecords.length > 0
        ) {
          args.cancel = true;
          this.$EventBus.$emit("gridLoaderOff");
          this.errorToast("수정된 데이터가 있어 정렬할 수 없습니다"); // this.$t("main.popupMessage.deleted")
        }
      } else if (args.requestType === "paging") {
        this.currentPage = args.currentPage;
      }
      this.$emit("actionBegin", args);
    },
    /**
     * 그리드의 특정 작업(정렬, 필터링, 그룹화, 편집 등)이 완료된 후에 발생
     * e.requestType속성으로 어떤 동작이 완료되었는지 알 수 있음
     */
    onActionComplete(args) {
      if (
          this.isShowProgress &&
          (args.requestType === "refresh" ||
              args.requestType === "filtering" ||
              args.requestType === "sorting" ||
              args.requestType === "paging")
      ) {
        this.$EventBus.$emit("gridLoaderOff");
      }
      if (
          (args.requestType === "refresh" ||
              args.requestType === "sorting" ||
              args.requestType === "filtering")
      ) {
        // maxRid 설정
        const { _rid: maxRid } = maxBy(this.computedDataSource, "_rid") || {
          _rid: 0,
        };
        this.currentMaxRid = maxRid + 1;

        // 선택한 row 재 선택 처리
        if (this.isSelectedRowRetain) {
          const pkFieldName = this.getPkFieldName();
          const currentviewRecords = this.getBatchCurrentViewRecords();
          const idxToSelect = currentviewRecords.findIndex(
              (record) => record[pkFieldName] === this.selectedKey
          );
          if (idxToSelect >= 0) {
            this.$refs.grid.selectRow(idxToSelect);
          } else {
            this.selectedKey = null;
            this.initSelectedRowIndex = null;
            if (this.isAutoSelectRow) {
              this.$refs.grid.selectRow(0);
            }
            if (this.isAutoSelectCell) {
              const getAllowedFieldIndex = this.columns.findIndex(
                  (item) => item.visible && item.allowEditing && !item.isPrimaryKey
              ) || (this.isNOColumnDisplay ? 1 : 0);

              this.$nextTick(() => {
                if (this.getGridBatchCount() > 0) {
                  try {
                    this.$refs.grid?.selectCell({
                      rowIndex: 0,
                      cellIndex: getAllowedFieldIndex,
                    });
                  } catch (e) {
                    console.log(e);
                  }
                }
              });
            }
          }
        }
      } else if (args.requestType === "filterafteropen") {
        const currentFilterObject =
            args.filterModel.filterObj.actualPredicate[args.columnName];

        // 문자열 필터링 & 필터링 값이 없을 경우 기본값 설정
        if (args.columnType === "string" && !currentFilterObject) {
          args.filterModel.flMuiObj.dropOptr.value = "contains";
        }

        const autoCompleteInput = args.filterModel.dlgDiv.querySelector(
            ".e-autocomplete"
        );
        if (autoCompleteInput) {
          autoCompleteInput.ej2_instances[0].autofill = false;
        }
      }
      this.$emit("actionComplete", args);
    },
    onBeforeBatchDelete(args) {
      this.$emit("beforeBatchDelete", args);
    },
    onBatchAdd(args) {
      this.$emit("batchAdd", args);
    },
    onContextMenuClick(args) {
      this.$emit("contextMenuClick", args);
    },
    isDevEditComponentElement(element) {
      return (
          element &&
          element.className &&
          !(element.className.indexOf("dev-edit-component") < 0)
      );
    },
    containsDevEditComponentElement(element) {
      if (this.isDevEditComponentElement(element)) {
        return true;
      } else {
        if (element.parentElement) {
          return this.containsDevEditComponentElement(element.parentElement);
        } else {
          return false;
        }
      }
    },
    getColumnIndexByField(field) {
      return this.$refs.grid.getColumnIndexByField(field);
    },
    getRows() {
      return this.$refs.grid.getRows();
    },
    getColumns() {
      return this.$refs.grid.getColumns();
    },
    setCellValue(key, field, value) {
      this.$refs.grid.setCellValue(key, field, value);
    },
    filterByColumn(fieldName, filterOperator, filterValue, predicate) {
      this.$refs.grid.filterByColumn(
          fieldName,
          filterOperator,
          filterValue,
          predicate
      );
    },
    getAggregationCaptionTemplate(templateStyle) {
      return () => gridAggregationCaptionTemplate(templateStyle);
    },
    getAggregationTotalSumTemplate(templateStyle) {
      return () => gridAggregationTotalSumTemplate(templateStyle);
    },
    getAggregationTotalAvgTemplate(templateStyle) {
      return () => gridAggregationTotalAvgTemplate(templateStyle);
    },
    getAggregationTotalCountTemplate(templateStyle) {
      return () => gridAggregationTotalCountTemplate(templateStyle);
    },
    getAggregateList(aggregates) {
      const aggreateField = aggregates.field;
      const aggrefateKeyValue = aggregates.key;
      return this.getGridBatchData().filter(
          (record) => record[aggreateField] === aggrefateKeyValue
      );
    },
    getAggregateSum(aggregates, aggregateColumn) {
      const aggreateField = aggregates.field;
      const aggrefateKeyValue = aggregates.key;
      const field = aggregateColumn.field;

      const calculateGridData = this.getAggregateList(aggregates);
      const sum = calculateGridData.reduce((acc, cur) => {
        if (cur[aggreateField] === aggrefateKeyValue) {
          return acc + cur[field];
        } else {
          return acc;
        }
      }, 0);

      return {
        sum: sum,
        length: calculateGridData.length,
      };
    },
    getSelectedRowCellIndexes() {
      return this.$refs.grid.getSelectedRowCellIndexes();
    },
    onCellSelecting(args) {
      this.$emit("cellSelecting", args);
    },
    onCellDeselected(args) {
      this.$emit("cellDeselected", args);
    },
    onCellDeselecting(args) {
      this.$emit("cellDeselecting", args);
    },
    /**
     * 그리드 키보드 action(화살표 상/하 이동, 엔터키) 구현을 위함
     * @param e
     */
    onGridKeyDown(e) {
      if (this.isCustomizeKeyboardAction) {
        return;
      }
      const currentRowIndex = this.currentSelectedRowIndex;
      const editingCellField = this.editingCellField;
      const editingCellObject = this.editingCellObject;
      const totalVisitsGridNumber = this.getDataRows().length;

      switch (e.keyCode) {
        case 13: // ENTER
          if (
              editingCellField &&
              currentRowIndex === totalVisitsGridNumber - 1
          ) {
            // 편집 가능한 가장 마지막 Row에 포커스 - 이벤트 취소 후 save & focus
            // timer를 쓰지 않기 위해, target에 event handler를 추가함 - 그리드의 기본 동작을 끝내고, 아래의 핸들러를 실행하여 cell editing
            e.target.addEventListener(
                "keydown",
                () => {
                  this.saveCell();
                  this.editCell(currentRowIndex, editingCellField);
                },
                {
                  once: true,
                }
            );

            /*setTimeout(() => {
              visitsGrid.editCell(currentRowIndex, editingCellField);
            }, 10);*/
          }
          break;
        case 38: // UP ARROW
          if (editingCellField && editingCellObject) {
            if (
                !this.isDropdownColumn(editingCellObject) &&
                currentRowIndex > 0
            ) {
              // dropdown 제외. 1st row에 포커싱 시 제외
              e.target.addEventListener(
                  "keydown",
                  () => {
                    this.saveCell();
                    this.editCell(currentRowIndex - 1, editingCellField);
                  },
                  {
                    once: true,
                  }
              );
              /*e.stopPropagation();
              e.preventDefault();
              this.editCell(currentRowIndex - 1, editingCellField);*/
            }
            if (
                !this.isDropdownColumn(editingCellObject) &&
                currentRowIndex === 0
            ) {
              // dropwodn 제외. 1st row에 포커싱 시 이벤트 취소
              e.stopPropagation();
              e.preventDefault();
            }
          }
          break;
        case 40: // DOWN ARROW
          if (editingCellField && editingCellObject) {
            if (
                !this.isDropdownColumn(editingCellObject) &&
                currentRowIndex < totalVisitsGridNumber - 1
            ) {
              // dropdown 제외. 마지막 row에 포커싱 시 제외
              e.target.addEventListener(
                  "keydown",
                  () => {
                    this.saveCell();
                    this.editCell(currentRowIndex + 1, editingCellField);
                  },
                  {
                    once: true,
                  }
              );
              /*e.stopPropagation();
              e.preventDefault();
              this.editCell(currentRowIndex + 1, editingCellField);*/
            }
            if (
                !this.isDropdownColumn(editingCellObject) &&
                currentRowIndex === totalVisitsGridNumber - 1
            ) {
              // dropwodn 제외. 마지막 row에 포커싱 시 이벤트 취소
              e.stopPropagation();
              e.preventDefault();
            }
          }
          break;
        case 45: // INS
        case 46: // DEL
          if (!editingCellField || !editingCellObject) {
            // 편집 중이 아닐 때에만 동작 취소
            e.stopPropagation();
            e.preventDefault();
          }
          break;
        default:
          break;
      }
    },
    showColumns(key, showBy) {
      this.$refs.grid.showColumns(key, showBy);
    },
    hideColumns(key, hideBy) {
      this.$refs.grid.hideColumns(key, hideBy);
    },
    refreshColumns() {
      this.$refs.grid.refreshColumns();
    },
    onDestroyed() {
      this.$EventBus.$emit("gridWrapperDestroyed", this._uid);
    }
  },
  beforeDestroy() {
    if(this.$refs.grid) {
      this.$refs.grid.ej2Instances.destroy();
    }
    this.removeGridEventHandlers();
  },
};
</script>
