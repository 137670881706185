import Vue from "vue";
import gridTemplateDestroyMixin from "../../../mixins/gridTemplateDestroyMixin";

export default function GridAggregationTotalAvgTemplate(templateStyle) {
  return {
    template: Vue.component("gridAggregationTotalAvgTemplate", {
      mixins: [gridTemplateDestroyMixin],
      template: `<span style="line-height:24px; vertical-align:middle;" :style="{ color : templateStyle && templateStyle.textColor ? templateStyle.textColor : null }"><b>{{getDataAvg()}}</b></span>`,
      data() {
        return {
          data: {},
          templateStyle: templateStyle,
        };
      },
      methods: {
        getDataAvg() {
          let returnValue = this.data.Average || "-";

          if (returnValue !== 0) {
            returnValue =
              typeof returnValue !== "number"
                ? "-"
                : returnValue.toLocaleString(undefined, {
                    minimumFractionDigits: 3,
                  });
          }

          return returnValue;
        },
      },
    }),
  };
}
