import {mapMutations} from 'vuex';

export default {
  data() {
    return {
      overlayMixinOverlayId: "appApiCallOverlayId_overlay_",
      overlayMixinOverlayGridId: "appGridLoadOverlayId_overlay_",
    };
  },
  methods: {
    ...mapMutations(["SET_FOOTER_MESSAGE"]),
    overlayMixinCheckIsApiOverlayOpened() {
      if (this.overlayMixinOverlay) {
        this.SET_FOOTER_MESSAGE("서버와 통신중입니다. 통신이 끝난 후 다시 시도하십시오!");
        return true;
      } else {
        this.SET_FOOTER_MESSAGE();
        return false;
      }
    },
    overlayMixinCheckIsGridOveralyOpened() {
      if (this.overlayMixinOverlayGrid) {
        return true;
      }
      return false;
    },
  },
};
