import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("dialog", {
      $_dialog_alert: "alert",
      $_dialog_confirm: "confirm",
    }),
    confirm(message) {
      return this.$_dialog_confirm({
        title: this.$t("main.title.confirm"),
        message,
      });
    },
    alert(message) {
      return this.$_dialog_alert({
        title: this.$t("main.title.confirm"),
        message,
      });
    },
    errorPopup(message) {
      return this.$_dialog_alert({
        title: this.$t("main.title.errorPopup"),
        message,
      });
    },
    errorToast(message, listeners) {
      this.$EventBus.$emit("errToast", message, listeners);
    },
    infoToast(message, listeners) {
      this.$EventBus.$emit("infoToast", message, listeners);
    },
    pinnedToast({ message, listeners }) {
      this.$EventBus.$emit("pinnedToast", { message, listeners });
    },
    taskToast({ message, promise, interrupt, listeners }) {
      this.$EventBus.$emit("taskToast", {
        message,
        promise,
        interrupt,
        listeners,
      });
    },
  },
};
