export const PATH_LOCKER = `/locker`;

export function fetchLockerDailyInfo(bsnDate, lockerNo) {
  return this.http.get(`${PATH_LOCKER}/locker-daily-info`, {
    params: {
      bsnDate: bsnDate,
      lockerNo: lockerNo,
    },
  });
}

export function putLockerDailyStatusByBsnDateAndLockerNoBetween(data) {
  return this.http.put(`${PATH_LOCKER}/locker-daily-status/batch`, data);
}

export function putLockerDiv(data) {
  return this.http.put(`${PATH_LOCKER}/locker-div`, data);
}

export function fetchAutoLockerBoard(args) {
  /*
    @param bsnDate : 영업일자
    @param lockerDiv : 락카구분(남/여/가변)
    @param previousLockerNo : 이전 락카번호
    @param isStatusUpdate : 락카 상태 업데이트 여부
   */
  return this.http.get(`${PATH_LOCKER}/auto-locker`, {
    params: {
      bsnDate: args.bsnDate,
      lockerDiv: args.lockerDiv,
      previousLockerNo: args.previousLockerNo,
      isStatusUpdate: args.isStatusUpdate,
      visitId: args.visitId,
    },
  });
}

export function fetchLockerBoard(args) {
  return this.http.get(`${PATH_LOCKER}/locker-assign`, {
    params: {
      bsnDate: args.bsnDate,
      lockerNo: args.lockerNo,
      previousLockerNo: args.previousLockerNo,
      visitId: args.visitId,
    },
  });
}

export function autoArgmtFlagSettingByVariableLocker(data) {
  return this.http.put(`${PATH_LOCKER}/auto-argmt-flag-setting/variable`, data);
}