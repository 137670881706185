// import * as Sentry from '@sentry/vue';
import axios from 'axios';
import store from '@/store';
import {
  createAuthorityGroup,
  deleteAuthorityGroup,
  fetchAuthorities,
  fetchAuthorityGroupAuthorities,
  fetchAuthorityGroups,
  fetchAuthorityGroupUsers,
  issueAuthorization,
  updateAuthorityGroup,
  updateAuthorityGroupAuthorities,
  updateAuthorityGroupUsers,
  updatePassword,
  fetchAuthGroupsBySearchContents,
  getGroupAuth,
  removeAuthorization,
  getMenuList,
  getMenu,
  saveMenu,
  patchMenu,
  deleteMenu,
  moveMenu,
  putAuthGroups,
} from '@/api/v2/auth/Authorization';
import BadRequestError from '@/error/BadRequestError';
import ForbiddenError from '@/error/ForbiddenError';
import NotFoundError from '@/error/NotFoundError';
import UnauthorizedError from '@/error/UnauthorizedError';
import InternalServerError from '@/error/InternalServerError';
import NotImplementedError from "@/error/NotImplementedError";
import BadGatewayError from "@/error/BadGatewayError";
import ServiceUnavailableError from "@/error/ServiceUnavailableError";
import GatewayTimeoutError from "@/error/GatewayTimeoutError";
import HTTPVersionNotSupportedError from "@/error/HTTPVersionNotSupportedError";
import {getCurrentBizCode} from '@/utils/pageKeyUtil';
import {
  fetchBusinessPartners,
  fetchBusinessPlaces,
  fetchNow,
  fetchProductClasses,
  fetchWorkPlaces,
  fetchPartnerCertification,
  fetchSystemConfigCourseClass,
  fetchClientMonitorImages,
  fetchSystemVersion,
  postMenuUserMark,
  deleteMenuUserMark,
  putImageUpload,
  patchImageUploadSortNo,
  deleteImage,
  findPassword,
} from '@/api/v2/common/Common';
import {setDeviceInfoAPI} from "@/api/v2/device/Device";
import {
  fetchPosTables,
  fetchSlips,
  fetchStoreProductCategory,
  fetchStoreProducts,
  fetchStores,
  fetchStoreSalesAmount,
  registerStoreProducts,
  removeStoreProducts,
  storeCheckout,
  fetchStorePosMemo,
  productSortNoResetting,
  fetchStoreCoupon,
} from '@/api/v2/store/Store';
import {
  fetchCreditReturns,
  fetchCreditReturnReports,
  fetchCreditReturnPayHistory,
  fetchDeposit,
  fetchDeposits,
  fetchDepositIncom,
  fetchDepositIncoms,
  fetchDepositCard,
  fetchDepositCards,
  fetchDepositCardCount,
  fetchDepositUses,
  postDepositUse,
  patchDeposit,
  patchDepositIncom,
  patchDepositCard,
  deleteDeposit,
  deleteDepositCard,
  deleteDepositUse,
  postPay,
  fetchDepositsBalanceStatus,
  postPayTransaction,
  getPayTransaction,
  fetchCashReceipts,
  fetchCashReceipt,
  postPayCashReceipt,
  patchPayCashReceipt,
  fetchDepositUseReceipt,
  fetchPayTax,
  fetchDepositIncomeTax,
  patchEventPayments,
  getPaymentEventsSchedule,
  getPaymentEvent,
  patchEventPayment,
  fetchPaymentStatement,
  fetchPaymentHistory,
  postDeposit,
  putPayTransaction,
  patchTgPayCreditReturns,
  fetchDepositHistory,
  fetchDepositIncomHistory,
  fetchDepositCardHistory,
  fetchDepositUseHistory,
  fetchDepositIncomStatus,
  fetchDepositUseStatus,
} from '@/api/v2/payment/Payment';
import {
  createSalesSlip,
  createSalesSlipMemo,
  deleteSalesSlip,
  deleteSalesSlipMemo,
  fetchNextSlipNo,
  fetchSalesSlip,
  fetchSalesSlipMemos,
  fetchSalesSlipPayments,
  fetchKitchenOrderStatements,
  fetchDeleteSlipKitchenOrderStatements,
  fetchKitchenOrderStatementsReissue,
  updateKitchenPrtSno,
  updateSalesSlipSales,
  updateSalesSlipStoreTable,
  updateSalesSlipVisitor,
  updateSalesSlipVisitorsCount,
  fetchSalesDetailsByItem,
  putSalesSlipByTableAddUp,
  patchTableActivation,
} from '@/api/v2/sales/SalesSlip';
import {
  fetchAccountCardCompanies,
  getPlacesOfCardPurchase,
  fetchConfigMemberGrade,
  putConfigMemberGrade,
} from '@/api/v2/config/Config';
import {
  fetchTeams,
  fetchPayLock,
  patchPayLock,
  fetchMatchProgressTimetable,
  fetchMatchProgressTimetableDaily,
  putMatchProgressTimetable,
  fetchVisitScoreList,
  fetchAwardCertificate,
  patchAwardCertificate,
  deleteAwardCertificate,
  putVisitScoreList,
  fetchIsCompulsoryPayment,
  postCompulsoryPayment,
  fetchVisitByVisitDateAndLockerNo,
  fetchPaymtCodeCountByMembershipIds,
  fetchPaymtCodeCountByChkinId
} from '@/api/v2/team/Team';
import {
  fetchReservationGuestBooks,
  fetchReservationStatus,
  fetchGroupReservationStatus,
  patchTeamOrVisitors,
  postRegisterWalkIn,
  postAdditionalHole,
  fetchReservationHistory,
  fetchResvePromtnAll,
  fetchGolfMonthlyReservationStatus
} from '@/api/v2/reservation/Reservation';
import {
  fetchProductPriceUnits,
} from '@/api/v2/product/Products';
import {
  deleteCouponUnitDetail,
  fetchCouponPubDetailList,
  fetchCouponPubStatusList,
  fetchCouponPubStatusDetail,
  fetchCouponUnitDetail,
  fetchCouponUnitHistory,
  fetchCouponUnitList,
  postCouponPub,
  postCouponUnitDetail,
  fetchCouponUseSelect,
  fetchCouponUseCheck,
  fetchCouponPubPaperInfo,
  deleteCouponPubStatus,
  putCouponPubStatusDetail,
  fetchCouponPubStatusHistory,
  postCouponPublishPaper,
  fetchTgMembershipFitResves,
  fetchTgMembershipFitPaymts,
  patchTgMembershipFitResves,
  patchTgMembershipFitPaymts,
  fetchMember,
  createMember,
  patchMember,
  deleteMember,
  deleteMemberImageFile,
  createMemberWebIdInformation,
  patchMemberWebIdInformation,
  getMemberGradeChangeHistoryCouponPub,
  postMemberGradeChangeHistoryCouponPub,
  fetchMembership,
  fetchLinkMembership,
  createWebIdMember,
  patchWebIdMember,
  fetchMemberHistory,
  postAutoIndividualBenefitSetting,
  couponPubPrintConfirm,
  fetchPostSigun,
  fetchTicketPubStatus,
  fetchTicketPubInfo,
  fetchMembershipAnnualFee,
  fetchMembershipAnnualFeeDetail,
  membershipAnnualFeeSave,
  deleteMembershipAnnualFee,
} from '@/api/v2/member/Member';
import {
  sendSMS,
  sendSmsByReservationBatch,
  sendSMSByCouponPubStatus,
  sendSMSByDeepSearchMembers,
  fetchSmsWords,
  fetchAllSmsWords,
  fetchSmsSendInfos,
  fetchSelfCheckInUrl,
  fetchSelfCheckOutUrl
} from '@/api/v2/sms/SMS';
import {
  fetchVisitorBills,
  fetchCheckForTheSameVisitorName,
} from '@/api/v2/visitor/Visitor';
import {
  createUser,
  deleteUser,
  fetchUser,
  fetchUsers,
  patchUser,
  reIssuancePassword,
  initPasswordToUser,
  updateKeepDoNotChangePassword,
} from '@/api/v2/user/User';
import {
  downloadExcelMemberGradeChanges,
  downloadExcelMemberGradeManagement,
  downloadExcelMemberRegistration,
  downloadExcelMembers,
  downloadExcelWebMemberRegistration,
  fetchCashReceiptsReports,
  fetchCreditCardReports,
  fetchRecordsAndCountMemberGradeChanges,
  fetchRecordsAndCountMemberGradeManagement,
  fetchRecordsAndCountMemberRegistration,
  getMaxMemberNo,
  fetchRecordsAndCountMembers,
  fetchRecordsAndCountWebMemberRegistration,
  fetchVisitorStatement,
  fetchProductSalesSummaryTableByPeriod,
  fetchPaymtCodeByVisitStatements,
  fetchReservationReceptionRecords,
  downloadExcelSearchReservationConditions,
  postReservationHistory,
  fetchSettlementStatusByHole,
  fetchStatusOfUtilizationByReservationChannel,
} from '@/api/v2/reports/Report';
import {
  fetchCloseCheck,
  getCloseDiv,
  fetchDailyStoreReport,
  putStoredProcedureExecute,
  fetchSalesStatusByPartDiv,
  fetchMaxBsnDate,
  deleteDailyCloseCreatePenlty,
  putCloseExecuteByBsnDate,
  fetchReservationFrontDeskWeeklyBusinessReport,
  fetchIncomeAmountAggregationTable,
  getReportFeeDivision,
  getReportSalesByPeriod,
  getReportDailyVisitPer,
} from '@/api/v2/close/Close';
import {
  fetchTsConfReceiptMsts,
  fetchTsConfReceiptDtls,
  patchTsConfReceiptMsts,
  patchTsConfReceiptDtls,
} from '@/api/v2/receiptCode/ReceiptCode';
import {
  getUserLoginHist,
  getProgramUseHist,
  createProgramUseHist,
  getProgramButtonUseHist,
  createProgramButtonUseHist,
  getHomePagePasswordChangeHist,
  getErpPasswordChangeHist,
} from '@/api/v2/logManagement/LogManagement';
import {
  getNameChangeApplicationConfirmation,
  getNameChangeApplicationConfirmationDetails,
  patchNameChangeApplicationConfirmationDetail,
  deleteNameChangeApplicationConfirmationDetail,
  confirmNameChangeApplicationConfirmation,
  cancelNameChangeApplicationConfirmation,
  getMembershipChangeStatusIndividualCorporate,
  getMembershipChangeStatus,
  getMembershipChangeReportList,
  saveMembershipChangeReportList,
  getGeneratedReportFiles,
  getDataForReportGeneration,
  patchReportBasic,
  getSeizureList,
  patchSeizure,
  getSeizureDetail,
  deleteSeizure,
  getMembershipHistory,
  getSeizureCheck,
  getMiddleMembershipNumber,
  fetchMembershipSeizure,
} from '@/api/v2/transferOfName/TransferOfName';
import {
  fetchCaddieIncomeAttendStatus,
  fetchCaddieRoundTotalTable,
  fetchCaddieArrangementStatementList,
  fetchCheckForDuplicateCartNumbers,
  fetchCaddieTaxSubmissionStatements
} from '@/api/v2/caddie/Caddie';
import {
  fetchWeatherInfo,
  patchWeather,
  deleteWeather,
} from '@/api/v2/weather/Weather';
import {fetchWeathersInfo} from '@/api/v2/weathers/Weathers';

import {
  fetchLockerDailyInfo,
  putLockerDailyStatusByBsnDateAndLockerNoBetween,
  putLockerDiv,
  fetchAutoLockerBoard,
  fetchLockerBoard,
  autoArgmtFlagSettingByVariableLocker,
} from '@/api/v2/locker/Locker';

import {
  fetchCalenderInfo,
  fetchCalenderInfos,
  fetchReservationCalendar,
  fetchReservationCalendarWeathers,
} from '@/api/v2/calender/Calender';
import {compactObjectValues} from '@/utils/ObjectUtil';

import {
  fetchReservationTimes,
  fetchReservationTimesByTimeIds,
  fetchReservationTimesDetail,
  reservationTimesHoldCancel,
  reservationTimeHoldResnModify,
  reservationTimeCancel,
  reservationTimeMove,
  reservationWebTimesSetting,
  reservationTimesStatusSetting,
  reservationSave,
  fetchReservationVisitorFind,
  fetchOneOrMoreReservations,
  fetchOneOrMoreReservationInfos,
  reservationUngroup,
  fetchResveCmpns,
  fetchReservationStatusList,
  fetchReservationCartCount,
  batchPromotionRegistration,
  batchPromotionDelete,
  reservationRainCancel,
  reservationCopy,
  reservationSelfTimeChange,
  reservationTimeAgencySetting,
  reservationTimeAgencyDelete,
} from '@/api/v2/reservation/ReservationTime';

import {
  fetchDepositMoneyMembers,
  fetchDepositMoneyMember,
  fetchDepositMoneyMemberCount,
  fetchDepositMoneyIncomCount,
  fetchDepositMoneyStatus,
  fetchDepositMoneyMemberBatchChange,
  depositMoneySave,
  depositMoneyDelete,
  depositMoneyMemberGradeBatchChange,
  fetchDepositMoneyHistory
} from '@/api/v2/member/DepositMoney';

import {getCheckinHistory, getMemberBenefitPaymtCode} from '@/api/frontManagementCheckin';

import {
  fetchMemberGradeManagements,
  fetchTgLogMemberGrade,
  putMemberGradeChange,
} from '@/api/v2/member/MemberGradeManagement';

import {fetchMemberReservationVisitSearch} from '@/api/v2/member/MemberSearch';

import {getAccessToken} from '@/utils/session/SessionUtil';
import {fetchSlipHistory} from '@/api/v2/slip/Slip';

import {
  fetchVocList,
  fetchVocInfo,
  fetchVocInfoByTeam,
  putVocInfoByTeam,
  putVocInfo,
  deleteVocInfo
} from "@/api/v2/voc/Voc";

import {
  fetchAdditionalRegistrationCheck
} from "@/api/v2/penalty/Penalty";

import {
  fetchReservationLottery,
  fetchReservationLotteryByLotteryId,
  fetchReservationLotteryAggregates,
  fetchCountByRceptStatusCheck,
  reservationLotterySave,
  reservationLotteryProvisionalAssignmentByLotteryIdAndTimeId,
  reservationLotteryScoreAggregation,
  reservationLotteryProvisionalAssignment,
  reservationLotteryProvisionalAssignmentCancel,
  reservationLotteryProvisionalAssignmentReservationConfirm,
  tgResveLotteryCmpetrtInsertByAggregates
} from "@/api/v2/reservation/ReservationLottery";

import {
  fetchAirPortIncheonInterfaceData,
  fetchLogCountByBsnDateAndLinkDiv,
  airportInterfaceLogSave,
  postAirPortIncheonInterfaceData,
  fetchIncheonAirportInterfaceDataMonthCloseList,
} from "@/api/v2/airPort/AirPort";

import {
  fetchMileageAccrualUsageStatus,
  fetchMileageByMileageId,
  patchMileage,
  deleteMileage,
} from "@/api/v2/mileage/mileage";

import {
  fetchGroupHistory,
  fetchGroupInUse,
} from "@/api/v2/group/Group";

import  {
  annualGroupRegistrationStatus,
  getGroupDetailedInquiry,
  patchGroupReceptionStatusChange,
  deleteGroupRegistration
} from "@/api/v2/group/GroupReceiptStatus";

import {fetchReservationPenaltyHistory} from "./reservation/ReservationPenalty";

import {
  fetchMemberVisitStatus,
} from "@/api/v2/member/MemberVisitStatus";

import {
  reservationMemoConfirm,
  reservationMemoConfirmByStayId,
} from "@/api/v2/reservation/ReservationMemo";

import {
  payDivide,
  getVisitThumbnail,
  patchDutchPayByItem,
  fetchCompanionMember,
  patchCompanionMember,
} from "@/api/v2/front/Front";

import {
  fetchReservationInfo,
  fetchMonthlySalesAndUnitPrice,
  fetchVisitorStatusStatistics,
  fetchMonthlyVisitorStatus,
  fetchMonthlySalesStatusByMemberType,
  fetchTotalMonthlySales,
  fetchMonthlyYearOverYearSales,
  fetchWeeklyMeetingReport,
  fetchMonthlySalesIncreasStatus,
} from "@/api/v2/management/management";

import {
  getRoomNumber,
  getRoomDetailNumber,
  patchRoomDetailNumber,
  deleteRoomDetailNumber,
  deleteRoomDetailNumberSeveral,
  postRoomNumber,
  patchRoomDetailNumberSeveral,
  getAvailableRooms,
  getAvailableRoomsByRaw,
  createAvailableRooms,
  patchRoomUsefulSeveral,
  deleteAvailableRooms,
  getRoomCalendarList,
  getRoomCalendarByFromDateAndToDate,
  patchRoomCalendarList,
  getAddRoomCalendarInfo,
  postAddRoomCalendarInfo,
  deleteRoomCalendar,
  putRoomCalendarRealtimeSetting,
  putRoomCalendarRealtimeSettingInit,
  getRoomRateBaseSetting,
  patchRoomRateBaseSetting,
  getRoomRatePeriodSetting,
  patchRoomRatePeriodSetting,
} from "@/api/v2/room/Room";

import {
  fetchRoomReservationStatusList,
  fetchRoomReservations,
  fetchRoomReservationDetail,
  fetchRoomResveStayPaymtDetail,
  roomReservationDetailSave,
  roomReservationDetailCancel,
  fetchVacantRoom,
  roomReservationNamingCancel,
  golfPackageLink,
  deleteGolfPackageLink,
  roomResveStayPaymtsSave,
  fetchGolfPachkageLink,
  checkAvailableRooms,
  fetchGolfResveMember,
  fetchRoomReservationHistory,
} from "@/api/v2/room/RoomReservation";

import {
  fetchRoomCheckIns,
  roomCheckInDetailSave,
  checkInRoomCancel,
  fetchRoomCheckInDetail,
  fetchRoomCheckInPosting,
  roomPostingSave,
  fetchCheckInRooms,
  roomPostingDelete,
  roomCheckout,
  roomCheckoutByAll,
  roomTotalSum,
  roomRestoration,
  fetchRoomCheckInDetailByStayId,
  fetchRoomCheckInDetailByOrSearch,
  roomExtensionTrans,
} from "@/api/v2/room/RoomCheckIn";

import {
  fetchBoardWithPosts,
  fetchSearchPostsById,
  createPost,
  savePost,
  saveImage,
  deletePost,
} from "@/api/v2/board/Board";
import {
  getLostItemList,
  postLostItem,
  deleteLostItems,
  patchLostItems,
  getLostItemImages,
  saveLostItemImageFileRecord,
  deleteLostItemImageFileSoft,
} from "@/api/v2/lost/LostItemRegistration";
import {
  fetchDestructionOfPersonalInformation,
  fetchDestructionOfPersonalInformationStatus,
  destructionOfPersonalInformationSmsSend,
  destructionOfPersonalInformation,
} from "@/api/v2/member/DormantMembers";


export const GOLF_ERP_STATUS_CODE_MESSAGES = {
  ID_PASSWORD_MISMATCH: '아이디 또는 패스워드를 잘못 입력하셨습니다.',
  INVALID_AUTH_CUSTOM_ERROR: '권한 처리 중 오류가 발생하였습니다.',
  NO_AUTH: '권한이 없습니다.',
  FRONT_LOCK:
  '현재 프론트 정산 중입니다. 프론트에 연락 후 LOCK 해제 요청바랍니다.',
  CHECK_OUT_COMPLETE: '현재 체크아웃이 완료되었습니다.',
  PAY_COMPLETE: '현재 정산 완료된 전표입니다.',
  PART_PAY_COMPLETE: '현재 부분 정산 완료된 전표입니다.',
  PAY_DIVIDE_COMPLETE: '현재 분할 처리 완료된 전표입니다.',
  PAYER_CHANGE_COMPLETE: '현재 지불자 변경 처리 완료된 전표입니다.',
  PAYMENT_UNCORRECTABLE:
  '해당 전표는 [지불자 변경] 또는 [분할 처리] 및 [정산]이 완료된 전표라 추가/수정이 불가능합니다.',
  NOT_FOUND_TEAM_INFO_DATA: '해당 팀 정보가 존재하지 않습니다.',
  NO_REQUIRED_DATA: '필수 데이터가 존재하지 않습니다.',
  INPUT_DATA_INVALID: '입력 데이터가 올바르지 않습니다.',
  FAULT_STATUS_DIV: '상태 체크 구분이 잘못되었습니다.',
  ALREADY_TABLE_USING: '상태 체크 구분이 잘못되었습니다.',
  // 명의개서
  DUPLICATE_MEMBER_NO: '해당 양수자는 이미 명의개서 진행중입니다. 다른 회원번호를 선택해주세요.',
  DUPLICATE_MEMBERSHIP_ID: '해당 양도자는 이미 명의개서 진행중입니다. 다른 양도자를 선택해주세요.',
  NOT_FOUND_MEMBERSHIP: '양도자의 멤버쉽 정보가 존재하지 않습니다.',
  NOT_FOUND_MEMBERSHIP_RENAME_REQUEST_ID: '해당 명의개서 정보가 존재하지 않습니다.',
  NOT_CONFIRMED_MEMBERSHIP_RENAME_REQUEST: '확정상태의 명의변경건만 확정취소 할 수 있습니다.',
  CONFIRMED_MEMBERSHIP_RENAME_REQUEST: '해당 멤버쉽은 이미 명의개서가 확정처리 되었습니다.',
  MEMBERSHIP_RENAME_REQUEST_NOT_EXIST: '해당 명의개서 정보가 존재하지 않습니다.',
  OUT_MEMBERSHIP: '탈회 처리할 회원권이 없습니다.',
  EXIST_DEPOSIT: '미사용 선수금/예치금이 존재합니다. 확인 후 삭제바랍니다.'
};

export const JWT_DEFAULT_SCHEME_PREFIX = 'com.fixelsoft.golf.erp.scheme:';

import router from "@/router";


const getMenuId = () => {
  let menuId = router.currentRoute.query.menuId;
  if (!Number(menuId) && menuId) {
    // 메뉴ID가 숫자가 아닌 경우
    // 예) 즐겨찾기 등록된 메뉴
    if (menuId.indexOf("_") >= 0) {
      menuId = menuId.substring(menuId.indexOf("_") + 1);
    }
  }
  if (menuId && Number(menuId)) {
    return Number(menuId);
  }

  const routerName = router.currentRoute.name;
  if (!routerName) {
    return null;
  }

  const menuList = store.getters.menuList;

  const menuIds = menuList.filter(item => item.routerName === routerName && !item.isMenuUserMark);
  if (menuIds.length > 1) {
    return null;
  }

  return menuIds.length > 0 ? (menuIds[0].menuId ? Number(menuIds[0].menuId) : null) : null;
};

class GolfErpAPI {
  constructor() {
    const http = axios.create({
      baseURL: `${process.env.VUE_APP_HOST_API_BASE_V2}`,
      timeout: 30000,
    });
    //axios.defaults.withCredentials = true; was/web 분리로 설정
    http.interceptors.request.use(
        (config) => {
          //const csrfToken = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\s*([^;]*).*$)|^.*$/, '$1'); getcookie - csrfToken
          const menuId = getMenuId();
          const bizCode = getCurrentBizCode();
          const accessToken = getAccessToken();
          config.headers["menuId"] = menuId;

          // if(csrfToken !=  ""){
          //   config.headers["X-CSRF-TOKEN"] = csrfToken.substring(1);
          // } headers add csrf token
          //config.withCredentials = true; was/web 분리로 설정

          if (accessToken && !!bizCode) {
            config.headers['Authorization'] = `${bizCode} ${accessToken}`;
          }

          // Do something before request is sent
          if (!config.isBackground) {
            store._vm.$EventBus.$emit('loaderOn');
          }

          switch (config.method) {
            case 'get':
              if (config.params) {
                config.params = compactObjectValues(
                    JSON.parse(JSON.stringify(config.params)),
                    true,
                    false,
                );
              }
              break;
            case 'post':
            case 'put':
            case 'patch':
            case 'delete':
              if (config.data) {
                config.data = compactObjectValues(
                    JSON.parse(JSON.stringify(config.data)),
                    false,
                    true,
                );
              }
              break;
          }

          return config;
        },
        (error) => {
          // Do something with request error
          store._vm.$EventBus.$emit('loaderOff');


          /* sentry 임시 주석
          if(process.env.NODE_ENV === 'production') {
            Sentry.withScope(scope => {
              const { method, url } = error.config;

              scope.setFingerprint([method, url]);
              scope.setTags({
                url: error.config.url,
                method: error.config.method,
              });

              scope.setContext("axios_request_error", {
                message: error.message,
                url: error.config.url,
                method: error.config.method,
                data: error.config.data,
                params: error.config.params,
              });
              Sentry.captureException(error);
            });
          }*/

          return Promise.reject(error);
        },
    );
    http.interceptors.response.use(
        (response) => {
          if (!response.config.isBackground) {
            store._vm.$EventBus.$emit('loaderOff');
          }
          //response.config.withCredentials = true; was/web 분리로 설정

          const {data} = response;
          const {status} = data;

          if (response.headers['content-type'] === 'application/vnd.ms-excel') {
            const filename = response.headers['x-suggested-filename'];

            const url = window.URL.createObjectURL(
                new Blob([data], {type: 'application/vnd.ms-excel'}),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          } else {
            if (GOLF_ERP_STATUS_CODE_MESSAGES[status]) {
              store._vm.$EventBus.$emit(
                  'errToast',
                  GOLF_ERP_STATUS_CODE_MESSAGES[status],
              );
            }
          }

          return data;
        },
        async (error) => {
          if (!error.response) {
            store._vm.$EventBus.$emit('loaderOff');

            store._vm.$EventBus.$emit(
                'errToast',
                '서버로부터 응답을 받을 수 없습니다.',
            );

            return Promise.reject(error);
          }

          if (!error?.config?.isBackground) {
            store._vm.$EventBus.$emit('loaderOff');
          }

          const {response} = error;
          if (!response) {
            return Promise.reject(error);
          }

          if (response.status === 401) {
            const isSessionExpired =
                error.response.data?.status === 'SESSION_EXPIRED';

            if (isSessionExpired) {
              store._vm.$EventBus.$emit("sessionExpiredToast");
            }
            store._vm.$EventBus.$emit("logout", {
              isSessionExpired
            });
          } else if (response.status === 403) {
            if (error.response?.data?.status === 'BLOCK_ID') {
              store._vm.$EventBus.$emit(
                  'errToast',
                  error.response?.data?.data ||
                  '요청하신 아이디는 현재 사용중지 상태입니다.\n관리자에게 문의 바랍니다.',
              );
            } else if (
                error.response?.data?.status === 'BLOCK_ID_DURING_SESSION'
            ) {
              store._vm.$EventBus.$emit('alert', {
                title: '차단',
                message:
                    error.response?.data?.data ||
                    '요청하신 아이디는 현재 사용중지 상태입니다.\n관리자에게 문의 바랍니다.',
              });
            } else if (error.response?.data?.status === 'BLOCK_PASSWORD') {
              store._vm.$EventBus.$emit(
                  'errToast',
                  error.response?.data?.data ||
                  '요청하신 아이디는 로그인 오류 횟수를 초과하였습니다.\n관리자에게 문의 바랍니다.',
              );
            } else if (
                error.response?.data?.status === 'BLOCK_PASSWORD_DURING_SESSION'
            ) {
              store._vm.$EventBus.$emit('alert', {
                title: '차단',
                message:
                    error.response?.data?.data ||
                    '요청하신 아이디는 로그인 오류 횟수를 초과하였습니다.\n관리자에게 문의 바랍니다.',
              });
            } else {
              store._vm.$EventBus.$emit(
                  "errToast",
                  error.response?.data?.data
              );
            }
          } else {
            if (GOLF_ERP_STATUS_CODE_MESSAGES[response?.data?.status]) {
              if (response?.data?.status === "FRONT_LOCK") {
                const isErrorMessageView =
                    response?.config?.params?.errorMessageViewFlag === null || response?.config?.params?.errorMessageViewFlag === undefined ?
                        true :
                        response?.config?.params?.errorMessageViewFlag;
                if (isErrorMessageView) {
                  store._vm.$EventBus.$emit(
                      'errToast',
                      GOLF_ERP_STATUS_CODE_MESSAGES[response?.data?.status],
                  );
                }
              } else {
                store._vm.$EventBus.$emit(
                    'errToast',
                    GOLF_ERP_STATUS_CODE_MESSAGES[response?.data?.status],
                );
              }
            } else if (response?.data?.status === 'CONFIRM') {
              store._vm.$EventBus.$emit('infoToast', response.data.data);
            } else if (typeof response?.data?.data === 'string') {
              const isErrorMessageView =
                response?.config?.isErrorMessageView === undefined || response?.config?.isErrorMessageView === null ?
                  true :
                  response?.config?.isErrorMessageView;
              if (isErrorMessageView) {
                store._vm.$EventBus.$emit('errToast', response.data.data);
              }
            }
          }

          // if(process.env.NODE_ENV === 'production' && response.status === 500) {
          //   Sentry.withScope(scope => {
          //     const { method, url } = error.config;
          //     const { status } = response;
          //
          //     scope.setFingerprint([method, status, url]);
          //     scope.setTags({
          //       url: error.config.url,
          //       method: error.config.method,
          //       status: error.response?.status ?? null,
          //     });
          //
          //     scope.setContext("axios_response_error", {
          //       message: error.message,
          //       url: error.config.url,
          //       method: error.config.method,
          //       data: error.config.data,
          //       params: error.config.params,
          //       response: error.response ? {
          //         status: error.response.status,
          //         data: error.response.data,
          //       } : null,
          //     });
          //     Sentry.captureException(error);
          //   });
          // }

          switch (response.status) {
            case 400:
              throw new BadRequestError(response.data);
            case 401:
              throw new UnauthorizedError(response.data);
            case 403:
              throw new ForbiddenError(response.data);
            case 404:
              throw new NotFoundError(response.data);
            case 500:
              throw new InternalServerError(response.data);
            case 501:
              throw new NotImplementedError(response.data);
            case 502:
              throw new BadGatewayError(response.data);
            case 503:
              throw new ServiceUnavailableError(response.data);
            case 504:
              throw new GatewayTimeoutError(response.data);
            case 505:
              throw new HTTPVersionNotSupportedError(response.data);

          }

          return Promise.reject(error);
        },
    );

    this.http = http;
    this.interruptTokeSourceMap = {};
  }

  interrupt(interruptTokenId, message) {
    const cancelTokenSource = this.interruptTokeSourceMap[interruptTokenId];
    if (cancelTokenSource) {
      cancelTokenSource.cancel(message);
      delete this.interruptTokeSourceMap[interruptTokenId];
    }
  }

  async login(email, password) {
    const {
      status,
      data: {accessToken},
    } = await issueAuthorization.apply(this, [email, password]);

    return {status, accessToken};
  }

  async logout() {
    await removeAuthorization.apply(this);
  }

  // --------------------------------------------------------------------------------------

  async updatePassword(oldPassword, newPassword) {
    await updatePassword.apply(this, [oldPassword, newPassword]);
  }

  async fetchNow() {
    const {data} = await fetchNow.apply(this, []);

    return data;
  }

  async fetchBusinessPartners(q, id) {
    const {data: businessPartners} = await fetchBusinessPartners.apply(this, [
      q,
      id,
    ]);

    return businessPartners;
  }

  async fetchBusinessPlaces() {
    const {data: businessPlaces} = await fetchBusinessPlaces.apply(this, []);

    return businessPlaces;
  }

  async fetchWorkPlaces() {
    const {data: workPlaces} = await fetchWorkPlaces.apply(this, []);

    return workPlaces;
  }

  async postRegisterWalkIn(
      reservationTime,
      visitDate,
      reservationKind,
      reservationChannel,
      reservationName,
      reservationRemarks,
      membershipId,
      memberNumber,
      memberDivision,
      memberGrade,
      contactName,
      contactTel,
      groupNumber,
      groupKind,
      groupName,
      posNo
  ) {
    return await postRegisterWalkIn.apply(this, [
      reservationTime,
      visitDate,
      reservationKind,
      reservationChannel,
      reservationName,
      reservationRemarks,
      membershipId,
      memberNumber,
      memberDivision,
      memberGrade,
      contactName,
      contactTel,
      groupNumber,
      groupKind,
      groupName,
      posNo
    ]);
  }

  async postAdditionalHole(
      groupNumber,
      checkInId,
      holeDivision,
      visitDate,
      reservationTime,
      posNo,
      groupKind,
      groupName,
  ) {
    return await postAdditionalHole.apply(this, [
      groupNumber,
      checkInId,
      holeDivision,
      visitDate,
      reservationTime,
      posNo,
      groupKind,
      groupName,
    ]);
  }

  async patchTeamOrVisitors(
      teamInfo,
      addedRecords,
      changedRecords,
      reservationId,
      posNo
  ) {
    const { data } = await patchTeamOrVisitors.apply(this, [
      teamInfo,
      addedRecords,
      changedRecords,
      [],
      reservationId,
      null,
      posNo
    ]);

    return data;
  }

  async deleteVisitor(teamInfo, deleteVisitors, reservationId, delReason, posNo) {
    const { data } = await patchTeamOrVisitors.apply(this, [
      teamInfo,
      [],
      [],
      deleteVisitors,
      reservationId,
      delReason,
      posNo
    ]);

    return data;
  }

  async fetchUsers({q, bizCode, authGroupId, filters, orders} = {}) {
    const {data: users} = await fetchUsers.apply(this, [
      {q, bizCode, authGroupId, filters, orders},
    ]);

    return users;
  }

  async fetchUser(id) {
    const {data: user} = await fetchUser.apply(this, [id]);

    return user;
  }

  async createUser({
                     name,
                     email,
                     password,
                     remarks,
                     phone,
                     baseBizCode,
                     isBlocked,
                     useLimit,
                     authGroupIds,
                   }) {
    await createUser.apply(this, [
      {
        name,
        email,
        password,
        remarks,
        phone,
        baseBizCode,
        isBlocked,
        useLimit,
        authGroupIds,
      },
    ]);
  }

  async patchUser(
      id,
      {
        name,
        email,
        password,
        remarks,
        deptAdminFlag,
        phone,
        baseBizCode,
        isBoardManager,
        isBlocked,
        useLimit,
      },
  ) {
    await patchUser.apply(this, [
      id,
      {
        name,
        email,
        password,
        remarks,
        deptAdminFlag,
        phone,
        baseBizCode,
        isBoardManager,
        isBlocked,
        useLimit,
      },
    ]);
  }

  async updateKeepDoNotChangePassword(id) {
    await updateKeepDoNotChangePassword.apply(this, [id]);
  }

  async deleteUser(id) {
    await deleteUser.apply(this, [id]);
  }

  async reIssuancePassword(id) {
    await reIssuancePassword.apply(this, [id]);
  }

  async fetchAuthorities() {
    const {data: authorities} = await fetchAuthorities.apply(this, []);

    return authorities;
  }

  async fetchAuthorityGroups(q) {
    const {data: authorityGroups} = await fetchAuthorityGroups.apply(this, [
      q,
    ]);

    return authorityGroups;
  }

  async fetchAuthorityGroupAuthorities(groupId) {
    const {
      data: groupAuthorities,
    } = await fetchAuthorityGroupAuthorities.apply(this, [groupId]);

    return groupAuthorities;
  }

  async fetchAuthorityGroupUsers(groupId) {
    const {data: groupUsers} = await fetchAuthorityGroupUsers.apply(this, [
      groupId,
    ]);

    return groupUsers;
  }

  async createAuthorityGroup(id, name, description, order) {
    await createAuthorityGroup.apply(this, [id, name, description, order]);
  }

  async updateAuthorityGroup(groupId, name, description, order) {
    await updateAuthorityGroup.apply(this, [groupId, name, description, order]);
  }

  async updateAuthorityGroupAuthorities(groupId, authorizations) {
    await updateAuthorityGroupAuthorities.apply(this, [
      groupId,
      authorizations,
    ]);
  }

  async updateAuthorityGroupUsers(groupId, users) {
    await updateAuthorityGroupUsers.apply(this, [groupId, users]);
  }

  async deleteAuthorityGroup(groupId) {
    await deleteAuthorityGroup.apply(this, [groupId]);
  }

  async getMenuList(highMenuId, middleMenuId ){
    const {data: menuList} = await getMenuList.apply(this, [
      highMenuId, 
      middleMenuId
    ]);

    return menuList;
  }

  async getMenu(menuId){
    const { data: data } = await getMenu.apply(this, [menuId]);
    return data;
  }

  async saveMenu(args){
    await saveMenu.apply(this, [args]);
  }

  async patchMenu(menuId, args){
    await patchMenu.apply(this, [menuId, args]);
  }

  async deleteMenu(menuId){
    await deleteMenu.apply(this, [menuId]);
  }

  async moveMenu(firstMenuId, lastMenuId){
    await moveMenu.apply(this, [firstMenuId, lastMenuId]);
  }

  async fetchProductClasses(depth, parentCode) {
    const {data: productClasses} = await fetchProductClasses.apply(this, [
      depth,
      parentCode,
    ]);

    return productClasses;
  }

  async fetchStores(usingPos) {
    const {data: stores} = await fetchStores.apply(this, [usingPos]);

    return stores;
  }

  async fetchStoreSalesAmount(storeCode, salesDate) {
    const {data: storeSalesAmount} = await fetchStoreSalesAmount.apply(this, [
      storeCode,
      salesDate,
    ]);

    return storeSalesAmount;
  }

  async fetchStoreProductCategory(storeCode, parentCategoryCode) {
    const {
      data: storeProductCategory,
    } = await fetchStoreProductCategory.apply(this, [
      storeCode,
      parentCategoryCode,
    ]);

    return storeProductCategory;
  }

  async fetchStoreProducts(storeCode, categoryCode, salesDate) {
    const {data: storeProducts} = await fetchStoreProducts.apply(this, [
      storeCode,
      categoryCode,
      salesDate,
    ]);

    return storeProducts;
  }

  async fetchPosTables(storeCode, salesDate) {
    const {data: tables} = await fetchPosTables.apply(this, [
      storeCode,
      salesDate,
    ]);

    return tables;
  }

  async fetchNextSlipNo(salesDate, storeCode) {
    const {data: slipNo} = await fetchNextSlipNo.apply(this, [
      salesDate,
      storeCode,
    ]);

    return slipNo;
  }

  async fetchSalesSlipMemos(salesId) {
    const {data: memos} = await fetchSalesSlipMemos.apply(this, [salesId]);

    return memos;
  }

  async fetchSalesSlipPayments(salesId, gcDiv) {
    const {data: salesSlipPayments} = await fetchSalesSlipPayments.apply(
        this,
        [salesId, gcDiv],
    );

    return salesSlipPayments;
  }

  async createSalesSlipMemo(salesId, memoContents) {
    const {data: memos} = await createSalesSlipMemo.apply(this, [
      salesId,
      memoContents,
    ]);

    return memos;
  }

  async updateSalesSlipStoreTable(salesId, storeCode, salesDate, tableId) {
    const {data: storeTable} = await updateSalesSlipStoreTable.apply(this, [
      salesId,
      storeCode,
      salesDate,
      tableId,
    ]);

    return storeTable;
  }

  async updateSalesSlipSales(salesId, storeCode, salesDate, sales) {
    const {data: storeTable} = await updateSalesSlipSales.apply(this, [
      salesId,
      storeCode,
      salesDate,
      sales,
    ]);

    return storeTable;
  }

  async updateSalesSlipVisitorsCount(salesId, visitorsCount) {
    const {data: salesSlip} = await updateSalesSlipVisitorsCount.apply(this, [
      salesId,
      visitorsCount,
    ]);

    return salesSlip;
  }

  async updateSalesSlipVisitor(salesId, visitorId, bsnDate) {
    const {data: salesSlip} = await updateSalesSlipVisitor.apply(this, [
      salesId,
      visitorId,
      bsnDate,
    ]);

    return salesSlip;
  }

  async deleteSalesSlip(salesId, deleteReason, bsnDate, storeCode) {
    await deleteSalesSlip.apply(this, [
      salesId,
      deleteReason,
      bsnDate,
      storeCode,
    ]);
  }

  async deleteSalesSlipMemo(salesId, memoId) {
    await deleteSalesSlipMemo.apply(this, [salesId, memoId]);
  }

  async createSalesSlip(
      slipNo,
      tableId,
      visitorsCount,
      visitorId,
      salesDate,
      storeCode,
      sales,
      posNo
  ) {
    return await createSalesSlip.apply(this, [
      slipNo,
      tableId,
      visitorsCount,
      visitorId,
      salesDate,
      storeCode,
      sales,
      posNo
    ]);
  }

  async fetchSlips(storeCode, salesDate, gcDiv, filters, orders,visitorName,grpName) {
    const {data: slips} = await fetchSlips.apply(this, [
      storeCode,
      salesDate,
      gcDiv,
      filters,
      orders,
      visitorName,
      grpName
    ]);

    return slips;
  }

  async fetchSlipHistory(slipId) {
    const {data: history} = await fetchSlipHistory.apply(this, [slipId]);

    return history;
  }

  async storeCheckout(storeCode, salesDate, visitorId, salesId) {
    await storeCheckout.apply(this, [storeCode, salesDate, visitorId, salesId]);
  }

  async registerStoreProducts(storeCode, products) {
    await registerStoreProducts.apply(this, [storeCode, products]);
  }

  async removeStoreProducts(storeCode, products) {
    await removeStoreProducts.apply(this, [storeCode, products]);
  }

  async fetchSalesSlip(salesId) {
    const {data: salesSlip} = await fetchSalesSlip.apply(this, [salesId]);

    return salesSlip;
  }

  async fetchProductPriceUnits(productCode, salesDate, storeCode) {
    const {data: productPriceUnits} = await fetchProductPriceUnits.apply(
        this,
        [productCode, salesDate, storeCode],
    );

    return productPriceUnits;
  }

  async fetchTeams(salesDate, storeCode, q, isCheckOut, courseCode, partDiv) {
    const {data: teams} = await fetchTeams.apply(this, [
      salesDate,
      storeCode,
      q,
      isCheckOut,
      courseCode,
      partDiv,
    ]);

    return teams;
  }

  async fetchVisitorBills(salesDate, storeCode, visitorId, gcDiv) {
    const {data: salesSlips} = await fetchVisitorBills.apply(this, [
      salesDate,
      storeCode,
      visitorId,
      gcDiv,
    ]);

    return salesSlips;
  }

  async fetchCreditReturns(args) {
    const {data: creditReturns} = await fetchCreditReturns.apply(this, [
      args,
    ]);

    return creditReturns;
  }

  async fetchCreditReturnReports(args) {
    const {data} = await fetchCreditReturnReports.apply(this, [
      args,
    ]);

    return data;
  }

  async fetchCreditReturnPayHistory(payId) {
    const {
      data: creditReturnPayHistory,
    } = await fetchCreditReturnPayHistory.apply(this, [payId]);

    return creditReturnPayHistory;
  }

  async getPlacesOfCardPurchase(vanKind) {
    const {
      data: placesOfCardPurchase,
    } = await getPlacesOfCardPurchase.apply(this, [vanKind]);

    return placesOfCardPurchase;
  }

  async fetchAccountCardCompanies() {
    const {
      data: accountCardCompanies,
    } = await fetchAccountCardCompanies.apply(this, []);

    return accountCardCompanies;
  }

  async fetchReservationGuestBooks(args) {
    const {
      data: reservationGuestBooks,
    } = await fetchReservationGuestBooks.apply(this, [args]);

    return reservationGuestBooks;
  }

  async fetchReservationHistory(id) {
    const {data: rawHistories} = await fetchReservationHistory.apply(this, [
      id,
    ]);

    return rawHistories;
  }

  async fetchReservationStatus(resveDate) {
    const {data: reservationStatus} = await fetchReservationStatus.apply(
        this,
        [resveDate],
    );

    return reservationStatus;
  }

  async fetchGroupReservationStatus(args) {
    const {
      data: groupReservationStatus,
    } = await fetchGroupReservationStatus.apply(this, [args]);

    return groupReservationStatus;
  }

  async fetchDailyStoreReport(bsnDate, storeCode) {
    const {data: dailyStoreReport} = await fetchDailyStoreReport.apply(this, [
      bsnDate,
      storeCode,
    ]);

    return dailyStoreReport;
  }

  async fetchGolfMonthlyReservationStatus(bsnDate){
    const {
      data: golfMonthlyReservationStatus,
    } = await fetchGolfMonthlyReservationStatus.apply(this, [bsnDate]);

    return golfMonthlyReservationStatus;
  }

  async createMember(member) {
    const {data: resultMember} = await createMember.apply(this, [member]);

    return resultMember;
  }

  async fetchMember(id, isOut) {
    const {data: member} = await fetchMember.apply(this, [id, isOut]);

    return member;
  }

  async fetchMemberHistory(id) {
    const {data: rawHistory} = await fetchMemberHistory.apply(this, [id]);

    return rawHistory;
  }

  async patchMember(memberId, member) {
    const {data: resultMember} = await patchMember.apply(this, [
      memberId,
      member,
    ]);

    return resultMember;
  }

  async deleteMember(memberId) {
    const {data} = await deleteMember.apply(this, [memberId]);

    return data;
  }

  async createMemberWebIdInformation(memberId, webIdInformation) {
    const {data} = await createMemberWebIdInformation.apply(this, [
      memberId,
      webIdInformation,
    ]);

    return data;
  }

  async patchMemberWebIdInformation(memberId, webIdInformation) {
    const {data} = await patchMemberWebIdInformation.apply(this, [
      memberId,
      webIdInformation,
    ]);

    return data;
  }

  async deleteMemberImageFile(memberId) {
    const {data} = await deleteMemberImageFile.apply(this, [memberId]);

    return data;
  }

  async fetchRecordsAndCountMemberRegistration({
                                                 q,
                                                 corporationDivision,
                                                 memberDivision,
                                                 memberGrade,
                                                 corporationName,
                                                 endOfHpNumber,
                                                 webMemberId,
                                                 groupCompanyCode,
                                                 affltsCompanyCode,
                                                 smsReceiptDivision,
                                                 membershipStatus,
                                                 acquireDateFrom,
                                                 acquireDateTo,
                                                 nearbyCode,
                                                 expireDateFrom,
                                                 expireDateTo,
                                                 birthday,
                                                 gender,
                                                 isOut,
                                                 limit,
                                                 offset,
                                                 filters,
                                                 orders,
                                               }) {
    const {data} = await fetchRecordsAndCountMemberRegistration.apply(this, [
      {
        q,
        corporationDivision,
        memberDivision,
        memberGrade,
        corporationName,
        endOfHpNumber,
        webMemberId,
        groupCompanyCode,
        affltsCompanyCode,
        smsReceiptDivision,
        membershipStatus,
        acquireDateFrom,
        acquireDateTo,
        nearbyCode,
        expireDateFrom,
        expireDateTo,
        birthday,
        gender,
        isOut,
        limit,
        offset,
        filters,
        orders,
      },
    ]);

    return data;
  }

  async fetchRecordsAndCountWebMemberRegistration({
                                                    q,
                                                    endOfHpNumber,
                                                    webMemberId,
                                                    acquireDateFrom,
                                                    acquireDateTo,
                                                    gender,
                                                    filters,
                                                    orders,
                                                    limit,
                                                    offset,
                                                  }) {
    const {data} = await fetchRecordsAndCountWebMemberRegistration.apply(
        this,
        [
          {
            q,
            endOfHpNumber,
            webMemberId,
            acquireDateFrom,
            acquireDateTo,
            gender,
            filters,
            orders,
            limit,
            offset,
          },
        ],
    );

    return data;
  }

  async downloadExcelMemberRegistration(
      {
        q,
        corporationDivision,
        memberDivision,
        memberGrade,
        corporationName,
        endOfHpNumber,
        webMemberId,
        hasWebInfo,
        groupCompanyCode,
        affltsCompanyCode,
        smsReceiptDivision,
        membershipStatus,
        acquireDateFrom,
        acquireDateTo,
        expireDateFrom,
        expireDateTo,
        birthday,
        gender,
        isOut,
        filters,
        orders,
        columns,
      },
      {interruptTokenId},
  ) {
    await downloadExcelMemberRegistration.apply(this, [
      {
        q,
        corporationDivision,
        memberDivision,
        memberGrade,
        corporationName,
        endOfHpNumber,
        webMemberId,
        hasWebInfo,
        groupCompanyCode,
        affltsCompanyCode,
        smsReceiptDivision,
        membershipStatus,
        acquireDateFrom,
        acquireDateTo,
        expireDateFrom,
        expireDateTo,
        birthday,
        gender,
        isOut,
        filters,
        orders,
        columns,
      },
      {interruptTokenId},
    ]);
  }

  async downloadExcelWebMemberRegistration(
      {
        q,
        endOfHpNumber,
        webMemberId,
        acquireDateFrom,
        acquireDateTo,
        gender,
        filters,
        orders,
        columns,
      },
      {interruptTokenId},
  ) {
    await downloadExcelWebMemberRegistration.apply(this, [
      {
        q,
        endOfHpNumber,
        webMemberId,
        acquireDateFrom,
        acquireDateTo,
        gender,
        filters,
        orders,
        columns,
      },
      {interruptTokenId},
    ]);
  }

  async getMaxMemberNo() {
    const { data } = await getMaxMemberNo.apply(this);
    return data;
  }

  async fetchRecordsAndCountMembers({
                                      memberDivisions,
                                      memberGrades,
                                      memberName,
                                      memberNumberFrom,
                                      memberNumberTo,
                                      gender,
                                      birthYear,
                                      birthMonth,
                                      birthDay,
                                      ageCodes,
                                      endOfHpNumber,
                                      allowSmsReceiptDivision,
                                      allowReceiptEmail,
                                      corporationDivision,
                                      corporationName,
                                      workPlace,
                                      acquireDateFrom,
                                      acquireDateTo,
                                      expireDateFrom,
                                      expireDateTo,
                                      loginCount,
                                      isOut,
                                      membershipStatus,
                                      entryOrders,
                                      nearbyCodes,
                                      areaCodes,

                                      reservationDateFrom,
                                      reservationDateTo,
                                      reservationTimeFrom,
                                      reservationTimeTo,
                                      reservationBsnCode,
                                      reservationDayOfWeeks,
                                      reservationCount,
                                      reservationChannels,
                                      excludeExistReservationDateFrom,
                                      excludeExistReservationDateTo,
                                      excludePenaltyReservationDateFrom,
                                      excludePenaltyReservationDateTo,
                                      reservationKinds,

                                      visitDateFrom,
                                      visitDateTo,
                                      visitTimeFrom,
                                      visitTimeTo,
                                      visitBsnCode,
                                      visitDayOfWeeks,
                                      visitCount,
                                      paymentCodes,

                                      salesDateFrom,
                                      salesDateTo,
                                      salesBsnCode,
                                      storeCodes,
                                      fitOrTeamSalesDivision,
                                      salesAmount,
                                      unitPrice,

                                      excludeMembersByMembershipId,

                                      limit,
                                      offset,
                                      filters,
                                      orders,
                                    }) {
    const {data} = await fetchRecordsAndCountMembers.apply(this, [
      memberDivisions,
      memberGrades,
      memberName,
      memberNumberFrom,
      memberNumberTo,
      gender,
      birthYear,
      birthMonth,
      birthDay,
      ageCodes,
      endOfHpNumber,
      allowSmsReceiptDivision,
      allowReceiptEmail,
      corporationDivision,
      corporationName,
      workPlace,
      acquireDateFrom,
      acquireDateTo,
      expireDateFrom,
      expireDateTo,
      loginCount,
      isOut,
      membershipStatus,
      entryOrders,
      nearbyCodes,
      areaCodes,

      reservationDateFrom,
      reservationDateTo,
      reservationTimeFrom,
      reservationTimeTo,
      reservationBsnCode,
      reservationDayOfWeeks,
      reservationCount,
      reservationChannels,
      excludeExistReservationDateFrom,
      excludeExistReservationDateTo,
      excludePenaltyReservationDateFrom,
      excludePenaltyReservationDateTo,
      reservationKinds,

      visitDateFrom,
      visitDateTo,
      visitTimeFrom,
      visitTimeTo,
      visitBsnCode,
      visitDayOfWeeks,
      visitCount,
      paymentCodes,

      salesDateFrom,
      salesDateTo,
      salesBsnCode,
      storeCodes,
      fitOrTeamSalesDivision,
      salesAmount,
      unitPrice,

      excludeMembersByMembershipId,

      limit,
      offset,
      filters,
      orders,
    ]);

    return data;
  }

  async downloadExcelMembers(
      {
        memberDivisions,
        memberGrades,
        memberName,
        memberNumberFrom,
        memberNumberTo,
        gender,
        birthYear,
        birthMonth,
        birthDay,
        ageCodes,
        endOfHpNumber,
        allowSmsReceiptDivision,
        allowReceiptEmail,
        corporationDivision,
        corporationName,
        workPlace,
        acquireDateFrom,
        acquireDateTo,
        loginCount,
        expireDate,
        isOut,
        membershipStatus,
        entryOrders,
        nearbyCodes,
        areaCodes,

        reservationDateFrom,
        reservationDateTo,
        reservationTimeFrom,
        reservationTimeTo,
        reservationBsnCode,
        reservationDayOfWeeks,
        reservationCount,
        reservationChannels,
        excludeExistReservationDateFrom,
        excludeExistReservationDateTo,
        excludePenaltyReservationDateFrom,
        excludePenaltyReservationDateTo,
        reservationKinds,

        visitDateFrom,
        visitDateTo,
        visitTimeFrom,
        visitTimeTo,
        visitBsnCode,
        visitDayOfWeeks,
        visitCount,
        paymentCodes,

        salesDateFrom,
        salesDateTo,
        salesBsnCode,
        storeCodes,
        fitOrTeamSalesDivision,
        salesAmount,
        unitPrice,

        excludeMembersByMembershipId,

        filters,
        orders,
        columns,
      },
      {interruptTokenId},
  ) {
    await downloadExcelMembers.apply(this, [
      {
        memberDivisions,
        memberGrades,
        memberName,
        memberNumberFrom,
        memberNumberTo,
        gender,
        birthYear,
        birthMonth,
        birthDay,
        ageCodes,
        endOfHpNumber,
        allowSmsReceiptDivision,
        allowReceiptEmail,
        corporationDivision,
        corporationName,
        workPlace,
        acquireDateFrom,
        acquireDateTo,
        loginCount,
        expireDate,
        isOut,
        membershipStatus,
        entryOrders,
        nearbyCodes,
        areaCodes,

        reservationDateFrom,
        reservationDateTo,
        reservationTimeFrom,
        reservationTimeTo,
        reservationBsnCode,
        reservationDayOfWeeks,
        reservationCount,
        reservationChannels,
        excludeExistReservationDateFrom,
        excludeExistReservationDateTo,
        excludePenaltyReservationDateFrom,
        excludePenaltyReservationDateTo,
        reservationKinds,

        visitDateFrom,
        visitDateTo,
        visitTimeFrom,
        visitTimeTo,
        visitBsnCode,
        visitDayOfWeeks,
        visitCount,
        paymentCodes,

        salesDateFrom,
        salesDateTo,
        salesBsnCode,
        storeCodes,
        fitOrTeamSalesDivision,
        salesAmount,
        unitPrice,

        excludeMembersByMembershipId,

        filters,
        orders,
        columns,
      },
      {interruptTokenId},
    ]);
  }

  async fetchRecordsAndCountMemberGradeManagement({
                                                    bsnDateFrom,
                                                    bsnDateTo,
                                                    visitCnt,
                                                    memberGrades,
                                                    membershipId,
                                                    memberName,
                                                    memberGradeChanges,
                                                    limit,
                                                    offset,
                                                    filters,
                                                    orders,
                                                  }) {
    const {data} = await fetchRecordsAndCountMemberGradeManagement.apply(
        this,
        [
          bsnDateFrom,
          bsnDateTo,
          visitCnt,
          memberGrades,
          membershipId,
          memberName,
          memberGradeChanges,
          limit,
          offset,
          filters,
          orders,
        ],
    );

    return data;
  }

  async downloadExcelMemberGradeManagement(
      {
        bsnDateFrom,
        bsnDateTo,
        visitCnt,
        memberGrades,
        membershipId,
        memberName,
        memberGradeChanges,
        filters,
        orders,
        columns,
      },
      {interruptTokenId},
  ) {
    await downloadExcelMemberGradeManagement.apply(this, [
      {
        bsnDateFrom,
        bsnDateTo,
        visitCnt,
        memberGrades,
        membershipId,
        memberName,
        memberGradeChanges,
        filters,
        orders,
        columns,
      },
      {interruptTokenId},
    ]);
  }

  async fetchRecordsAndCountMemberGradeChanges({
                                                 changeDateFrom,
                                                 changeDateTo,
                                                 memberGrades,
                                                 changeMemberGradeDivisions,
                                                 memberName,
                                                 membershipId,
                                                 isCouponPub,
                                                 filters,
                                                 orders,
                                                 limit,
                                                 offset,
                                               }) {
    const {data} = await fetchRecordsAndCountMemberGradeChanges.apply(this, [
      changeDateFrom,
      changeDateTo,
      memberGrades,
      changeMemberGradeDivisions,
      memberName,
      membershipId,
      isCouponPub,
      filters,
      orders,
      limit,
      offset,
    ]);

    return data;
  }

  async downloadExcelMemberGradeChanges(
      {
        changeDateFrom,
        changeDateTo,
        memberGrades,
        changeMemberGradeDivisions,
        memberName,
        membershipId,
        isCouponPub,
        filters,
        orders,
        columns,
      },
      {interruptTokenId},
  ) {
    await downloadExcelMemberGradeChanges.apply(this, [
      {
        changeDateFrom,
        changeDateTo,
        memberGrades,
        changeMemberGradeDivisions,
        memberName,
        membershipId,
        isCouponPub,
        filters,
        orders,
        columns,
      },
      {interruptTokenId},
    ]);
  }

  async fetchMemberGradeManagements(args) {
    const {data: data} = await fetchMemberGradeManagements.apply(this, [
      args,
    ]);

    return data;
  }

  async getMemberGradeChangeHistoryCouponPub(args) {
    const {data} = await getMemberGradeChangeHistoryCouponPub.apply(this, [
      args,
    ]);

    return data;
  }

  async postMemberGradeChangeHistoryCouponPub(args) {
    return await postMemberGradeChangeHistoryCouponPub.apply(this, [args]);
  }

  async fetchCouponPubDetailList(args) {
    const {data} = await fetchCouponPubDetailList.apply(this, [args]);

    return data;
  }

  async fetchCouponUnitList(dcKind, useFlag) {
    const {data} = await fetchCouponUnitList.apply(this, [dcKind, useFlag]);
    return data;
  }

  async postCouponPub(args) {
    return await postCouponPub.apply(this, [args]);
  }

  async fetchCouponUnitDetail(kindId) {
    const {data} = await fetchCouponUnitDetail.apply(this, [kindId]);
    return data;
  }

  async fetchCouponUnitHistory(kindId) {
    const {data} = await fetchCouponUnitHistory.apply(this, [kindId]);
    return data;
  }

  async fetchCouponPubStatusHistory(kindId) {
    const {data} = await fetchCouponPubStatusHistory.apply(this, [kindId]);
    return data;
  }

  async fetchCouponPubStatusList(args) {
    const {data} = await fetchCouponPubStatusList.apply(this, [args]);
    return data;
  }

  async fetchTicketPubStatus(args) {
    const {data} = await fetchTicketPubStatus.apply(this, [args]);
    return data;
  }

  async fetchCouponPubStatusDetail(args) {
    const {data} = await fetchCouponPubStatusDetail.apply(this, [args]);
    return data;
  }

  async fetchCouponPubPaperInfo() {
    const {data} = await fetchCouponPubPaperInfo.apply(this, []);
    return data;
  }

  async couponPubPrintConfirm(args) {
    return await couponPubPrintConfirm.apply(this, [args]);
  }

  async postCouponUnitDetail(args) {
    return await postCouponUnitDetail.apply(this, [args]);
  }

  async deleteCouponUnitDetail(args) {
    return await deleteCouponUnitDetail.apply(this, [args]);
  }

  async deleteCouponPubStatus(args) {
    return await deleteCouponPubStatus.apply(this, [args]);
  }

  async putCouponPubStatusDetail(args) {
    return await putCouponPubStatusDetail.apply(this, [args]);
  }

  async postCouponPublishPaper(args) {
    return await postCouponPublishPaper.apply(this, [args]);
  }

  async sendSMS(newSMSRequestList) {
    const {data: ids} = await sendSMS.apply(this, [newSMSRequestList]);

    return ids;
  }

  async sendSMSByCouponPubStatus(smsRequestList) {
    return await sendSMSByCouponPubStatus.apply(this, smsRequestList);
  }

  async sendSMSByDeepSearchMembers(args) {
    return await sendSMSByDeepSearchMembers.apply(this, [args]);
  }

  async fetchSmsWords() {
    const {data} = await fetchSmsWords.apply(this);

    return data;
  }

  async fetchAllSmsWords() {
    const {data} = await fetchAllSmsWords.apply(this);

    return data;
  }

  async fetchDeposit(depositId) {
    const {data: depositInfo} = await fetchDeposit.apply(this, [depositId]);

    return depositInfo;
  }

  async fetchDeposits(args) {
    const {data: depositList} = await fetchDeposits.apply(this, [args]);

    return depositList;
  }

  async fetchDepositIncom(incomId) {
    const {data: depositIncom} = await fetchDepositIncom.apply(this, [
      incomId,
    ]);

    return depositIncom;
  }

  async fetchDepositIncoms(args) {
    const {data: depositIncomList} = await fetchDepositIncoms.apply(this, [
      args,
    ]);

    return depositIncomList;
  }

  async fetchDepositCard(cardId) {
    const {data: depositCard} = await fetchDepositCard.apply(this, [cardId]);

    return depositCard;
  }

  async fetchDepositCards(depositId) {
    const {data: depositCardList} = await fetchDepositCards.apply(this, [
      depositId,
    ]);

    return depositCardList;
  }

  async fetchDepositCardCount(cardNo, cardId) {
    const {data: depositCardCount} = await fetchDepositCardCount.apply(this, [
      cardNo,
      cardId,
    ]);

    return depositCardCount;
  }

  async fetchDepositUses(depositId, useCancelViewFlag) {
    const {data: depositUseList} = await fetchDepositUses.apply(this, [
      depositId,
      useCancelViewFlag,
    ]);

    return depositUseList;
  }

  async postDepositUse(depositUse) {
    const {data: status} = await postDepositUse.apply(this, [depositUse]);

    return status;
  }

  async patchDeposit(deposit) {
    const {data: depositInfo} = await patchDeposit.apply(this, [deposit]);

    return depositInfo;
  }

  async patchDepositIncom(depositIncom) {
    const {data: status} = await patchDepositIncom.apply(this, [
      depositIncom,
    ]);

    return status;
  }

  async patchDepositCard(depositCard) {
    const {data: depositCardList} = await patchDepositCard.apply(this, [
      depositCard,
    ]);

    return depositCardList;
  }

  async deleteDeposit(depositId) {
    await deleteDeposit.apply(this, [depositId]);
  }

  async deleteDepositCard(cardId) {
    await deleteDepositCard.apply(this, [cardId]);
  }

  async deleteDepositUse(depositUse) {
    await deleteDepositUse.apply(this, [depositUse]);
  }

  async fetchCreditCardReports(payerName, dateDivision, dateFrom, dateTo, filterOptions) {
    const {data: creditCardReports} = await fetchCreditCardReports.apply(
        this,
        [payerName, dateDivision, dateFrom, dateTo, filterOptions],
    );

    return creditCardReports;
  }

  async fetchVisitorStatement(args) {
    const {data: visitorStatement} = await fetchVisitorStatement.apply(this, [
      args,
    ]);

    return visitorStatement;
  }

  async fetchCaddieIncomeAttendStatus(args) {
    const {
      data: caddieIncomeAttendStatus,
    } = await fetchCaddieIncomeAttendStatus.apply(this, [args]);

    return caddieIncomeAttendStatus;
  }

  async fetchCaddieRoundTotalTable(args) {
    const {
      data: caddieRoundTotalTable,
    } = await fetchCaddieRoundTotalTable.apply(this, [args]);

    return caddieRoundTotalTable;
  }

  async fetchAwardCertificate(args) {
    const {
      data: awardCertificate,
    } = await fetchAwardCertificate.apply(this, [args]);

    return awardCertificate;
  }

  async patchAwardCertificate(args) {
    return patchAwardCertificate.apply(this, [args]);
  }

  async deleteAwardCertificate(args) {
    return deleteAwardCertificate.apply(this, [args]);
  }

  async fetchCaddieArrangementStatementList(args) {
    const {
      data: caddieArrangementStatement,
    } = await fetchCaddieArrangementStatementList.apply(this, [args]);

    return caddieArrangementStatement;
  }

  async fetchWeatherInfo(args) {
    return fetchWeatherInfo.apply(this, [args]);
  }

  async patchWeather(args) {
    return patchWeather.apply(this, [args]);
  }

  async deleteWeather(args) {
    return deleteWeather.apply(this, [args]);
  }

  async fetchWeathersInfo(args) {
    return fetchWeathersInfo.apply(this, [args]);
  }

  async fetchCashReceiptsReports(
      payerName,
      dateDivision,
      dateFrom,
      dateTo,
      filterOptions,
  ) {
    const {
      data: cashReceiptsReports,
    } = await fetchCashReceiptsReports.apply(this, [
      payerName,
      dateDivision,
      dateFrom,
      dateTo,
      filterOptions,
    ]);

    return cashReceiptsReports;
  }

  async postPay(payRequest) {
    const {data: payId} = await postPay.apply(this, [payRequest]);

    return payId;
  }

  async fetchDepositsBalanceStatus(condition, showEmptyBalanceAmount) {
    const {
      data: depositsBalanceStatus,
    } = await fetchDepositsBalanceStatus.apply(this, [
      condition,
      showEmptyBalanceAmount,
    ]);

    return depositsBalanceStatus;
  }

  async fetchSmsSendInfos(args) {
    const {data: smsSendInfo} = await fetchSmsSendInfos.apply(this, [args]);

    return smsSendInfo;
  }

  async postPayTransaction(payTransactionRequest) {
    let data = {
      status: null,
      data: null
    };
    try {
      const payTransaction = await postPayTransaction.apply(this, [
        payTransactionRequest,
      ]);

      data.status = payTransaction.status;
      data.data = payTransaction.data;
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        throw e;
      }

      data.status = e?.getOriginData?.()?.status;
      data.data = e?.getOriginData?.()?.data;
    }

    return data;
  }

  async getPayTransaction(tid) {
    const {data: payTransaction} = await getPayTransaction.apply(this, [tid]);
    return payTransaction;
  }

  async fetchCashReceipts(payDateFrom, payDateTo, approvalNo, proofNo) {
    const {data: cashReceipts} = await fetchCashReceipts.apply(this, [
      payDateFrom,
      payDateTo,
      approvalNo,
      proofNo,
    ]);
    return cashReceipts;
  }

  async fetchCashReceipt(id) {
    const {data: cashReceipt} = await fetchCashReceipt.apply(this, [id]);
    return cashReceipt;
  }

  async postPayCashReceipt(payCashReceiptRequest) {
    const {data: id} = await postPayCashReceipt.apply(this, [
      payCashReceiptRequest,
    ]);
    return id;
  }

  async patchPayCashReceipt(cashId, tid) {
    const {data: id} = await patchPayCashReceipt.apply(this, [cashId, tid]);

    return id;
  }

  async fetchCouponUseSelect(args) {
    const {data: couponUseSelectList} = await fetchCouponUseSelect.apply(
        this,
        [args],
    );

    return couponUseSelectList;
  }

  async fetchCouponUseCheck(args) {
    const {status: status} = await fetchCouponUseCheck.apply(this, [args]);

    return status;
  }

  async fetchPayLock(chkinId, errorMessageViewFlag) {
    const {status: status} = await fetchPayLock.apply(this, [
      chkinId,
      errorMessageViewFlag,
    ]);

    return status;
  }

  async patchPayLock(args) {
    const {status: status} = await patchPayLock.apply(this, [args]);

    return status;
  }

  async fetchDepositUseReceipt(id) {
    const {data: depositUseReceipt} = await fetchDepositUseReceipt.apply(
        this,
        [id],
    );

    return depositUseReceipt;
  }

  async fetchPayTax(taxRequest) {
    const {data: payTax} = await fetchPayTax.apply(this, [taxRequest]);

    return payTax;
  }

  async fetchDepositIncomeTax(notaxCode, totalAmount) {
    const {data: payTax} = await fetchDepositIncomeTax.apply(this, [
      notaxCode,
      totalAmount,
    ]);

    return payTax;
  }

  async fetchTsConfReceiptMsts(receiptCodes) {
    const {data: receiptCodeMsts} = await fetchTsConfReceiptMsts.apply(this, [
      receiptCodes,
    ]);

    return receiptCodeMsts;
  }

  async fetchTsConfReceiptDtls(receiptId, useFlag) {
    const {data: receiptCodeDtls} = await fetchTsConfReceiptDtls.apply(this, [
      receiptId,
      useFlag,
    ]);

    return receiptCodeDtls;
  }

  async fetchPartnerCertification(args) {
    const {
      data: partnerCertificationData,
    } = await fetchPartnerCertification.apply(this, [args]);

    return partnerCertificationData;
  }

  async fetchSystemConfigCourseClass() {
    const {data: courseClass} = await fetchSystemConfigCourseClass.apply(
        this,
    );

    return courseClass;
  }

  async fetchClientMonitorImages(imagePattern) {
    const {data} = await fetchClientMonitorImages.apply(this, [imagePattern]);

    return data;
  }

  async fetchSystemVersion() {
    const {data} = await fetchSystemVersion.apply(this);

    return data;
  }

  async postMenuUserMark(menuId) {
    const {data: menuUserMarks} = await postMenuUserMark.apply(this, [
      menuId,
    ]);

    return menuUserMarks;
  }

  async deleteMenuUserMark(menuId) {
    const {data: menuUserMarks} = await deleteMenuUserMark.apply(this, [
      menuId,
    ]);

    return menuUserMarks;
  }

  async patchTsConfReceiptMsts(tsConfReceiptMsts) {
    const {status: status} = await patchTsConfReceiptMsts.apply(this, [
      tsConfReceiptMsts,
    ]);

    return status;
  }

  async patchTsConfReceiptDtls(tsConfReceiptDtls) {
    const {status: status} = await patchTsConfReceiptDtls.apply(this, [
      tsConfReceiptDtls,
    ]);

    return status;
  }

  async fetchTgMembershipFitResves(membershipId, bizCode) {
    const {
      data: tgMembershipFitResves,
    } = await fetchTgMembershipFitResves.apply(this, [membershipId, bizCode]);

    return tgMembershipFitResves;
  }

  async fetchTgMembershipFitPaymts(membershipId, bizCode) {
    const {
      data: tgMembershipFitPaymts,
    } = await fetchTgMembershipFitPaymts.apply(this, [membershipId, bizCode]);

    return tgMembershipFitPaymts;
  }

  async patchTgMembershipFitResves(tgMembershipFitResves) {
    const {status: status} = await patchTgMembershipFitResves.apply(this, [
      tgMembershipFitResves,
    ]);

    return status;
  }

  async patchTgMembershipFitPaymts(tgMembershipFitPaymts) {
    const {status: status} = await patchTgMembershipFitPaymts.apply(this, [
      tgMembershipFitPaymts,
    ]);

    return status;
  }

  async fetchLockerDailyInfo(bsnDate, lockerNo) {
    const {data: lockerDailyInfo} = await fetchLockerDailyInfo.apply(this, [
      bsnDate,
      lockerNo,
    ]);

    return lockerDailyInfo;
  }

  async putLockerDailyStatusByBsnDateAndLockerNoBetween(data) {
    const {
      status: status,
    } = await putLockerDailyStatusByBsnDateAndLockerNoBetween.apply(this, [
      data,
    ]);

    return status;
  }

  async putLockerDiv(data) {
    const {status: status} = await putLockerDiv.apply(this, [data]);

    return status;
  }

  async fetchCalenderInfo(bsnDate, isBackground = false) {
    return await fetchCalenderInfo.apply(this, [bsnDate, isBackground]);
  }

  async patchEventPayments(eventPayments) {
    const {status: status} = await patchEventPayments.apply(this, [
      eventPayments,
    ]);

    return status;
  }

  async fetchReservationTimes(args) {
    const {data: reservationTimes} = await fetchReservationTimes.apply(this, [
      args,
    ]);

    return reservationTimes;
  }

  async fetchReservationTimesByTimeIds(timeIds) {
    const {
      data: reservationTimes,
    } = await fetchReservationTimesByTimeIds.apply(this, [timeIds]);

    return reservationTimes;
  }

  async fetchReservationTimesDetail(args) {
    let data = {
      status: null,
      data: null,
    };
    try {
      const reservationTimesDetail = await fetchReservationTimesDetail.apply(
          this,
          [args],
      );

      data.status = reservationTimesDetail.status;
      data.data = reservationTimesDetail.data;
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        throw e;
      }

      data.status = e?.getOriginData?.()?.status;
      data.data = e?.getOriginData?.()?.data;
    }

    return data;
  }

  async reservationTimesHoldCancel(timeIds) {
    let data = {
      status: null,
      data: null,
    };
    try {
      const reservationTimeHoldCancelData = await reservationTimesHoldCancel.apply(this, [
        timeIds,
      ]);

      data.status = reservationTimeHoldCancelData.status;
      data.data = reservationTimeHoldCancelData.data;
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        throw e;
      }

      data.status = e?.getOriginData?.()?.status;
      data.data = e?.getOriginData?.()?.data;
    }

    return data;
  }

  async reservationTimeCancel(reservationCancelTimes) {
    const {status: status} = await reservationTimeCancel.apply(this, [
      reservationCancelTimes,
    ]);

    return status;
  }

  async reservationTimeMove(reservationTimeMoveInfo) {
    const {status: status} = await reservationTimeMove.apply(this, [
      reservationTimeMoveInfo,
    ]);

    return status;
  }

  async reservationWebTimesSetting(reservationWebTimes) {
    const {status: status} = await reservationWebTimesSetting.apply(this, [
      reservationWebTimes,
    ]);

    return status;
  }

  async reservationTimesStatusSetting(reservationTimes) {
    const {status: status} = await reservationTimesStatusSetting.apply(this, [
      reservationTimes,
    ]);

    return status;
  }

  async reservationSave(reservationRequest) {
    const {data: newResveNo, status} = await reservationSave.apply(this, [
      reservationRequest,
    ]);
    switch (status) {
      case "OK":
        return newResveNo;
      default :
        return new Error(status);
    }
  }

  async getPaymentEventsSchedule(args) {
    const {
      data: paymentEventsSchedule,
    } = await getPaymentEventsSchedule.apply(this, [args]);

    return paymentEventsSchedule;
  }

  async getPaymentEvent(eventId) {
    const {data: tsPaymtEvent} = await getPaymentEvent.apply(this, [eventId]);

    return tsPaymtEvent;
  }

  async patchEventPayment(tsPaymtEvent) {
    const {status: status} = await patchEventPayment.apply(this, [
      tsPaymtEvent,
    ]);

    return status;
  }

  async fetchCalenderInfos(bsnDateFrom, bsnDateTo) {
    const {data: calenderInfos} = await fetchCalenderInfos.apply(this, [
      bsnDateFrom,
      bsnDateTo,
    ]);

    return calenderInfos;
  }

  async fetchDepositMoneyMembers(args) {
    const {
      data: depositMoneyMembers,
    } = await fetchDepositMoneyMembers.apply(this, [args]);

    return depositMoneyMembers;
  }

  async fetchDepositMoneyMember(dpmnyId) {
    const {
      data: depositMoneyMember,
    } = await fetchDepositMoneyMember.apply(this, [dpmnyId]);

    return depositMoneyMember;
  }

  async depositMoneySave(depositMoneyInfo) {
    const {data: dpmnyId} = await depositMoneySave.apply(this, [
      depositMoneyInfo,
    ]);

    return dpmnyId;
  }

  async depositMoneyDelete(incomId, dpmnyId) {
    const {status: status} = await depositMoneyDelete.apply(this, [
      incomId,
      dpmnyId,
    ]);

    return status;
  }

  async fetchDepositMoneyMemberCount(membershipId) {
    const {
      data: depositMoneyMemberCount,
    } = await fetchDepositMoneyMemberCount.apply(this, [membershipId]);

    return depositMoneyMemberCount;
  }

  async fetchDepositMoneyIncomCount(dpmnyId) {
    const {
      data: depositMoneyIncomCount,
    } = await fetchDepositMoneyIncomCount.apply(this, [dpmnyId]);

    return depositMoneyIncomCount;
  }

  async fetchDepositMoneyStatus(args) {
    const {
      data: depositMoneyStatus,
    } = await fetchDepositMoneyStatus.apply(this, [args]);

    return depositMoneyStatus;
  }

  async fetchMembership(membershipId) {
    const {data: membership} = await fetchMembership.apply(this, [
      membershipId,
    ]);

    return membership;
  }

  async fetchLinkMembership(membershipId, isBackground = false) {
    const {data: membership} = await fetchLinkMembership.apply(this, [
      membershipId,
      isBackground,
    ]);

    return membership;
  }

  async createWebIdMember(member) {
    const {data: createdMember} = await createWebIdMember.apply(this, [
      member,
    ]);

    return createdMember;
  }

  async patchWebIdMember(memberId, member) {
    const {data: patchedMember} = await patchWebIdMember.apply(this, [
      memberId,
      member,
    ]);

    return patchedMember;
  }

  async fetchDepositMoneyMemberBatchChange(args) {
    const {
      data: depositMoneyMemberBatchChange,
    } = await fetchDepositMoneyMemberBatchChange.apply(this, [args]);

    return depositMoneyMemberBatchChange;
  }

  async depositMoneyMemberGradeBatchChange(memberships) {
    const {
      status: status,
    } = await depositMoneyMemberGradeBatchChange.apply(this, [memberships]);

    return status;
  }

  async getMemberBenefitPaymtCode({
    bsnDate,
    memberDiv,
    memberGrade,
    membershipId,
    isBackground = false,
    grpNo,
    selfFlag,
  }) {
    let data = {
      status: null,
      data: null,
    };
    try {
      const paymtCode = await getMemberBenefitPaymtCode.apply(this, [{
        bsnDate,
        memberDiv,
        memberGrade,
        membershipId,
        isBackground,
        grpNo,
        selfFlag
      }]);

      data.status = paymtCode.status;
      data.data = paymtCode.data;
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        throw e;
      }

      data.status = e?.getOriginData?.()?.status;
      data.data = e?.getOriginData?.()?.data;
    }

    return data;
  }

  async getGroupAuth(groupId) {
    const { data: data } = await getGroupAuth.apply(this,[groupId]);
    return data;
  }
  async putAuthGroups(args) {
    const {data: data} = await putAuthGroups.apply(this,[args]);
    return data;
  }
  async fetchAuthGroupsBySearchContents(searchContents) {
    const {data: data} = await fetchAuthGroupsBySearchContents.apply(this, [
      searchContents,
    ]);

    return data;
  }

  async fetchTgLogMemberGrade(membershipId) {
    const {data: data} = await fetchTgLogMemberGrade.apply(this, [
      membershipId,
    ]);

    return data;
  }

  async putMemberGradeChange(args) {
    const {status: status} = await putMemberGradeChange.apply(this, [args]);

    return status;
  }

  async fetchConfigMemberGrade() {
    const {data: data} = await fetchConfigMemberGrade.apply(this);

    return data;
  }

  async putConfigMemberGrade(saveData) {
    const {status: status} = await putConfigMemberGrade.apply(this, [
      saveData,
    ]);

    return status;
  }

  async fetchCloseCheck(args) {
    const {status: status} = await fetchCloseCheck.apply(this, [args]);

    return status;
  }

  async getCloseDiv(args) {
    return await getCloseDiv.apply(this, [args]);
  }

  async putStoredProcedureExecute(request) {
    const {status: status} = await putStoredProcedureExecute.apply(this, [
      request,
    ]);

    return status;
  }

  async putImageUpload(request) {
    const {data: data} = await putImageUpload.apply(this, [request]);

    return data;
  }

  async patchImageUploadSortNo(request) {
    const {data: data} = await patchImageUploadSortNo.apply(this, [request]);

    return data;
  }

  async deleteImage(imageId, imagePattern = null) {
    const {status: status} = await deleteImage.apply(this, [imageId, imagePattern]);

    return status;
  }

  async findPassword({email, name}) {
    await findPassword.apply(this, [{email, name}]);
  }

  async setDeviceInfoAPI(args) {
    await setDeviceInfoAPI.apply(this,[args]);
  }

  async fetchReservationCalendar(args) {
    const {data: data} = await fetchReservationCalendar.apply(this, [args]);

    return data;
  }

  async fetchReservationCalendarWeathers(args) {
    const {data: data} = await fetchReservationCalendarWeathers.apply(this, [args]);

    return data;
  }

  async fetchAutoLockerBoard(args) {
    const {data: data} = await fetchAutoLockerBoard.apply(this, [args]);

    return data;
  }

  async fetchPaymentStatement(args) {
    const {data: data} = await fetchPaymentStatement.apply(this, [args]);

    return data;
  }

  async fetchPaymentHistory(args) {
    const {data: data} = await fetchPaymentHistory.apply(this, [args]);

    return data;
  }

  async fetchLockerBoard(args) {
    const {data: data} = await fetchLockerBoard.apply(this, [args]);

    return data;
  }

  async fetchKitchenOrderStatements(slipIds) {
    const {data: data} = await fetchKitchenOrderStatements.apply(this, [
      slipIds,
    ]);

    return data;
  }

  async fetchDeleteSlipKitchenOrderStatements(salesId) {
    const {
      data: data,
    } = await fetchDeleteSlipKitchenOrderStatements.apply(this, [salesId]);

    return data;
  }

  async updateKitchenPrtSno(salesId, kitchenOrderStatementRecords) {
    const {status: status} = await updateKitchenPrtSno.apply(this, [
      salesId,
      kitchenOrderStatementRecords,
    ]);

    return status;
  }

  async fetchKitchenOrderStatementsReissue(salesId) {
    const {data: data} = await fetchKitchenOrderStatementsReissue.apply(
        this,
        [salesId],
    );

    return data;
  }

  async fetchMemberReservationVisitSearch(args) {
    const {data: data} = await fetchMemberReservationVisitSearch.apply(this, [
      args,
    ]);

    return data;
  }

  async postDeposit(deposit) {
    const {data: data} = await postDeposit.apply(this, [deposit]);

    return data;
  }

  async fetchStorePosMemo(args) {
    const {data: data} = await fetchStorePosMemo.apply(this, [args]);

    return data;
  }

  async fetchSalesDetailsByItem(args) {
    const {data: data} = await fetchSalesDetailsByItem.apply(this, [args]);

    return data;
  }

  async fetchMatchProgressTimetable(visitDate) {
    const {data: data} = await fetchMatchProgressTimetable.apply(this,
        [visitDate]);

    return data;
  }

  async fetchMatchProgressTimetableDaily(visitDate) {
    const {data: data} = await fetchMatchProgressTimetableDaily.apply(this,
        [visitDate]);

    return data;
  }

  async putMatchProgressTimetable(data) {
    const {status: status} = await putMatchProgressTimetable.apply(this,
        [data]);

    return status;
  }

  async fetchVisitScoreList(args) {
    const {data: data} = await fetchVisitScoreList.apply(this, [args]);

    return data;
  }

  async putVisitScoreList(data) {
    const {status: status} = await putVisitScoreList.apply(this, [data]);

    return status;
  }

  async fetchReservationVisitorFind(args) {
    const {data: data} = await fetchReservationVisitorFind.apply(this, [args]);

    return data;
  }

  async fetchCheckForTheSameVisitorName(args) {
    const {value: value} = await fetchCheckForTheSameVisitorName.apply(this,
        [args]);

    return value;
  }

  async putSalesSlipByTableAddUp(data) {
    const {status: status} = await putSalesSlipByTableAddUp.apply(this, [data]);

    return status;
  }

  async fetchCheckForDuplicateCartNumbers(args) {
    const {data: data} = await fetchCheckForDuplicateCartNumbers.apply(this, [args]);

    return data;
  }

  async fetchProductSalesSummaryTableByPeriod(args) {
    const {data: data} = await fetchProductSalesSummaryTableByPeriod.apply(this, [args]);

    return data;
  }

  async postAutoIndividualBenefitSetting(data) {
    const {status: status} = await postAutoIndividualBenefitSetting.apply(this, [data]);

    return status;
  }

  async fetchPaymtCodeByVisitStatements(args) {
    const {data: data} = await fetchPaymtCodeByVisitStatements.apply(this, [args]);

    return data;
  }

  async fetchIsCompulsoryPayment(teamIds) {
    const {data: data} = await fetchIsCompulsoryPayment.apply(this, [teamIds]);

    return data;
  }

  async postCompulsoryPayment(teamIds) {
    const {status: status} = await postCompulsoryPayment.apply(this, [teamIds]);

    return status;
  }

  async fetchVisitByVisitDateAndLockerNo(args) {
    const {data: data} = await fetchVisitByVisitDateAndLockerNo.apply(this, [args]);

    return data;
  }

  async fetchVocList(args) {
    const {data: data} = await fetchVocList.apply(this, [args]);

    return data;
  }

  async fetchVocInfo(vocId) {
    const {data: data} = await fetchVocInfo.apply(this, [vocId]);

    return data;
  }

  async fetchVocInfoByTeam(args) {
    const {data: data} = await fetchVocInfoByTeam.apply(this, [args]);

    return data;
  }

  async putVocInfoByTeam(data) {
    const {status: status} = await putVocInfoByTeam.apply(this, [data]);

    return status;
  }

  async putVocInfo(request) {
    const {data: data} = await putVocInfo.apply(this, [request]);

    return data;
  }

  async deleteVocInfo(vocId) {
    const {status: status} = await deleteVocInfo.apply(this, [vocId]);

    return status;
  }

  async fetchPaymtCodeCountByMembershipIds(args) {
    const {data: data} = await fetchPaymtCodeCountByMembershipIds.apply(this, [args]);

    return data;
  }

  async fetchPaymtCodeCountByChkinId(args) {
    const {data: data} = await fetchPaymtCodeCountByChkinId.apply(this, [args]);

    return data;
  }

  async fetchOneOrMoreReservations(args) {
    const {data: data} = await fetchOneOrMoreReservations.apply(this, [args]);

    return data;
  }

  async fetchOneOrMoreReservationInfos(args) {
    const {data: data} = await fetchOneOrMoreReservationInfos.apply(this, [args]);

    return data;
  }

  async fetchDepositMoneyHistory(args) {
    const {data: data} = await fetchDepositMoneyHistory.apply(this, [args]);

    return data;
  }

  async productSortNoResetting(storeCode, data) {
    const {status: status} = await productSortNoResetting.apply(this, [storeCode, data]);

    return status;
  }

  async reservationUngroup(resveId) {
    const {status: status} = await reservationUngroup.apply(this, [resveId]);

    return status;
  }

  async fetchAdditionalRegistrationCheck(args) {
    const {data: data} = await fetchAdditionalRegistrationCheck.apply(this, [args]);

    return data;
  }

  async fetchSalesStatusByPartDiv(args) {
    const {data: data} = await fetchSalesStatusByPartDiv.apply(this, [args]);

    return data;
  }

  async fetchResveCmpns(resveId) {
    const {data: data} = await fetchResveCmpns.apply(this, [resveId]);

    return data;
  }

  async fetchSelfCheckInUrl(args) {
    const {data: data} = await fetchSelfCheckInUrl.apply(this, [args]);

    return data;
  }

  async fetchSelfCheckOutUrl(args) {
    const {data: data} = await fetchSelfCheckOutUrl.apply(this, [args]);

    return data;
  }

  async putPayTransaction(tid, updatePayTransactionRequest) {
    const {status: status} = await putPayTransaction.apply(this, [tid, updatePayTransactionRequest]);

    return status;
  }

  async fetchReservationLottery({
                                  fromDate,
                                  toDate,
                                  memberNameNumber,
                                  bsnCode,
                                  lotteryRceptStatusList,
                                  filters,
                                  orders,
                                  limit,
                                  offset,
                                }) {
    const {data: data} = await fetchReservationLottery.apply(
        this,
        [
          {
            fromDate,
            toDate,
            memberNameNumber,
            bsnCode,
            lotteryRceptStatusList,
            filters,
            orders,
            limit,
            offset,
          }
        ]
    );

    return data;
  }

  async fetchReservationLotteryByLotteryId(lotteryId) {
    const {data: data} = await fetchReservationLotteryByLotteryId.apply(this, [lotteryId]);

    return data;
  }

  async fetchReservationLotteryAggregates(fromDate, toDate, bsnCode) {
    const {data: data} = await fetchReservationLotteryAggregates.apply(this, [fromDate, toDate, bsnCode]);

    return data;
  }

  async reservationLotterySave(data) {
    const {data: lotteryId} = await reservationLotterySave.apply(this, [data]);

    return lotteryId;
  }

  async fetchCountByRceptStatusCheck(
      {
        fromDate,
        toDate,
        memberNameNumber,
        bsnCode,
        lotteryRceptStatusList,
        lotteryRceptStatus,
        filters,
      }
  ) {
    const {data: data} = await fetchCountByRceptStatusCheck.apply(
        this,
        [
          {
            fromDate,
            toDate,
            memberNameNumber,
            bsnCode,
            lotteryRceptStatusList,
            lotteryRceptStatus,
            filters,
          }
        ]
    );

    return data;
  }

  async reservationLotteryProvisionalAssignmentByLotteryIdAndTimeId(lotteryId, timeId) {
    const {status: status} = await reservationLotteryProvisionalAssignmentByLotteryIdAndTimeId.apply(this, [lotteryId, timeId]);

    return status;
  }

  async reservationLotteryScoreAggregation(
      {
        fromDate,
        toDate,
        memberNameNumber,
        bsnCode,
        lotteryRceptStatusList,
        filters,
      }
  ) {
    const {status: status} = await reservationLotteryScoreAggregation.apply(
        this,
        [
          {
            fromDate,
            toDate,
            memberNameNumber,
            bsnCode,
            lotteryRceptStatusList,
            filters,
          }
        ]
    );

    return status;
  }

  async reservationLotteryProvisionalAssignment(
      {
        fromDate,
        toDate,
        memberNameNumber,
        bsnCode,
        lotteryRceptStatusList,
        filters,
        orders,
        limit,
        offset,
      }
  ) {
    const {status: status} = await reservationLotteryProvisionalAssignment.apply(
        this,
        [
          {
            fromDate,
            toDate,
            memberNameNumber,
            bsnCode,
            lotteryRceptStatusList,
            filters,
            orders,
            limit,
            offset,
          }
        ]
    );

    return status;
  }

  async reservationLotteryProvisionalAssignmentCancel(
      {
        fromDate,
        toDate,
        memberNameNumber,
        bsnCode,
        lotteryRceptStatusList,
        filters,
        orders,
        limit,
        offset,
      }
  ) {
    const {status: status} = await reservationLotteryProvisionalAssignmentCancel.apply(
        this,
        [
          {
            fromDate,
            toDate,
            memberNameNumber,
            bsnCode,
            lotteryRceptStatusList,
            filters,
            orders,
            limit,
            offset,
          }
        ]
    );

    return status;
  }

  async reservationLotteryProvisionalAssignmentReservationConfirm(
      {
        fromDate,
        toDate,
        memberNameNumber,
        bsnCode,
        lotteryRceptStatusList,
        filters,
        isSmsSend,
      }
  ) {
    const {status: status} = await reservationLotteryProvisionalAssignmentReservationConfirm.apply(
        this,
        [
          {
            fromDate,
            toDate,
            memberNameNumber,
            bsnCode,
            lotteryRceptStatusList,
            filters,
            isSmsSend,
          }
        ]
    );

    return status;
  }

  async fetchAirPortIncheonInterfaceData(args) {
    const {data: data} = await fetchAirPortIncheonInterfaceData.apply(this, [args]);

    return data;
  }

  async fetchLogCountByBsnDateAndLinkDiv(args) {
    const {data: data} = await fetchLogCountByBsnDateAndLinkDiv.apply(this, [args]);

    return data;
  }

  async airportInterfaceLogSave(data) {
    const {status: status} = await airportInterfaceLogSave.apply(this, [data]);

    return status;
  }

  async patchTableActivation(salesId) {
    const {status: status} = await patchTableActivation.apply(this, [salesId]);

    return status;
  }

  async fetchMileageAccrualUsageStatus(args) {
    const {data: data} = await fetchMileageAccrualUsageStatus.apply(this, [args]);

    return data;
  }

  async postAirPortIncheonInterfaceData(requestData) {
    const {data: data} = await postAirPortIncheonInterfaceData.apply(this, [requestData]);

    return data;
  }

  async fetchMileageByMileageId(mileageId) {
    const {data: data} = await fetchMileageByMileageId.apply(this, [mileageId]);

    return data;
  }

  async fetchIncheonAirportInterfaceDataMonthCloseList(args) {
    const {data: data} = await fetchIncheonAirportInterfaceDataMonthCloseList.apply(this, [args]);

    return data;
  }

  async fetchMaxBsnDate(storeCode) {
    const {data: data} = await fetchMaxBsnDate.apply(this, [storeCode]);

    return data;
  }

  async deleteDailyCloseCreatePenlty(bsnDate) {
    const {status: status} = await deleteDailyCloseCreatePenlty.apply(this, [bsnDate]);

    return status;
  }

  async putCloseExecuteByBsnDate(bsnDate) {
    const {data: data} = await putCloseExecuteByBsnDate.apply(this, [bsnDate]);

    return data;
  }

  async fetchReservationReceptionRecords(args) {
    const {data: data} = await fetchReservationReceptionRecords.apply(this, [args]);

    return data;
  }

  async downloadExcelSearchReservationConditions(
      args,
      {interruptTokenId},
  ) {
    await downloadExcelSearchReservationConditions.apply(this, [args, {interruptTokenId}]);
  }

  async fetchReservationFrontDeskWeeklyBusinessReport(bsnDate) {
    const {data: data} = await fetchReservationFrontDeskWeeklyBusinessReport.apply(this, [bsnDate]);

    return data;
  }

  async patchMileage(args) {
    const {data: data} = await patchMileage.apply(this, [args]);

    return data;
  }

  async deleteMileage(args) {
    const {status: status} = await deleteMileage.apply(this, [args]);

    return status;
  }

  async sendSmsByReservationBatch(request) {
    const {status: status} = await sendSmsByReservationBatch.apply(this, [request]);

    return status;
  }

  async tgResveLotteryCmpetrtInsertByAggregates(fromDate, toDate) {
    const {status: status} = await tgResveLotteryCmpetrtInsertByAggregates.apply(this, [fromDate, toDate]);

    return status;
  }

  async fetchGroupHistory(grpNo) {
    const {data: data} = await fetchGroupHistory.apply(this, [grpNo]);

    return data;
  }

  async annualGroupRegistrationStatus(args) {
    const {data: data} = await annualGroupRegistrationStatus.apply(this, [args]);

    return data;
  }

  async getGroupDetailedInquiry(args) {
    const {data: data} = await getGroupDetailedInquiry.apply(this, [args]);

    return data;
  }

  async patchGroupReceptionStatusChange(params, args) {
    const {status: status} = await patchGroupReceptionStatusChange.apply(this, [params, args]);

    return status;
  }

  async deleteGroupRegistration(args) {
    const {status: status} = await deleteGroupRegistration.apply(this, [args]);

    return status;
  }


  async fetchReservationStatusList(bsnDate, courseDiv) {
    const {data: data} = await fetchReservationStatusList.apply(this, [bsnDate, courseDiv]);

    return data;
  }

  async fetchReservationCartCount(args) {
    const {data: data} = await fetchReservationCartCount.apply(this, [args]);

    return data;
  }

  async patchTgPayCreditReturns(request) {
    const {status: status} = await patchTgPayCreditReturns.apply(this, [request]);

    return status;
  }

  async fetchReservationPenaltyHistory(penltyId) {
    const {data: data} = await fetchReservationPenaltyHistory.apply(this, [penltyId]);

    return data;
  }

  async fetchDepositHistory(depositId) {
    const {data: data} = await fetchDepositHistory.apply(this, [depositId]);

    return data;
  }

  async fetchDepositIncomHistory(incomId) {
    const {data: data} = await fetchDepositIncomHistory.apply(this, [incomId]);

    return data;
  }

  async fetchDepositCardHistory(cardId) {
    const {data: data} = await fetchDepositCardHistory.apply(this, [cardId]);

    return data;
  }

  async fetchDepositUseHistory(useId) {
    const {data: data} = await fetchDepositUseHistory.apply(this, [useId]);

    return data;
  }

  async fetchIncomeAmountAggregationTable(bsnDate) {
    const {data: data} = await fetchIncomeAmountAggregationTable.apply(this, [bsnDate]);

    return data;
  }

  async getReportFeeDivision(fromDate,toDate) {
    const {data: data} = await getReportFeeDivision.apply(this, [fromDate,toDate]);

    return data;
  }

  async getReportSalesByPeriod(fromDate,toDate) {
    const {data: data} = await getReportSalesByPeriod.apply(this, [fromDate,toDate]);

    return data;
  }
  async getReportDailyVisitPer(currentYearStart,currentYearEnd,lastYearStart,lastYearEnd) {
    const {data: data} = await getReportDailyVisitPer.apply(this, [currentYearStart,currentYearEnd,lastYearStart,lastYearEnd]);

    return data;
  }
  async fetchMemberVisitStatus(args) {
    const {data: data} = await fetchMemberVisitStatus.apply(this, [args]);

    return data;
  }

  async fetchDepositIncomStatus(args) {
    const {data: data} = await fetchDepositIncomStatus.apply(this, [args]);

    return data;
  }

  async fetchDepositUseStatus(args) {
    const {data: data} = await fetchDepositUseStatus.apply(this, [args]);

    return data;
  }

  async reservationMemoConfirm(request) {
    const {data: data} = await reservationMemoConfirm.apply(this, [request]);

    return data;
  }

  async payDivide(tgPayDivides) {
    const {status: status} = await payDivide.apply(this, [tgPayDivides]);

    return status;
  }
  async patchDutchPayByItem(tgPayDivides) {
    const {status: status} = await patchDutchPayByItem.apply(this, [tgPayDivides]);

    return status;
  }
  async fetchCompanionMember(args) {
    const {data: data} = await fetchCompanionMember.apply(this, [args]);

    return data;
  }

  async patchCompanionMember(args) {
    const {data: data} = await patchCompanionMember.apply(this, [args]);

    return data;
  }

  async fetchReservationInfo(args) {
    const {data: data} = await fetchReservationInfo.apply(this, [args]);

    return data;
  }

  async fetchMonthlySalesAndUnitPrice(args) {
    const {data: data} = await fetchMonthlySalesAndUnitPrice.apply(this, [args]);

    return data;
  }

  async fetchVisitorStatusStatistics(args) {
    const {data: data} = await fetchVisitorStatusStatistics.apply(this, [args]);

    return data;
  }
  async fetchMonthlyVisitorStatus(args) {
    const {data: data} = await fetchMonthlyVisitorStatus.apply(this, [args]);

    return data;
  }
  async fetchMonthlySalesStatusByMemberType(args) {
    const {data: data} = await fetchMonthlySalesStatusByMemberType.apply(this, [args]);

    return data;
  }

  async fetchTotalMonthlySales(args) {
    const {data: data} = await fetchTotalMonthlySales.apply(this, [args]);

    return data;
  }

  async fetchMonthlyYearOverYearSales(args) {
    const {data: data} = await fetchMonthlyYearOverYearSales.apply(this, [args]);

    return data;
  }

  async fetchWeeklyMeetingReport(args) {
    const {data: data} = await fetchWeeklyMeetingReport.apply(this, [args]);

    return data;
  }
  async fetchMonthlySalesIncreasStatus(args){
      const {data: data} = await fetchMonthlySalesIncreasStatus.apply(this, [args]);

      return data;
  }
  async getRoomNumber(args) {
    const {data: data} = await getRoomNumber.apply(this, [args]);

    return data;
  }

  async getRoomDetailNumber(roomId) {
    const {data: data} = await getRoomDetailNumber.apply(this, [roomId]);

    return data;
  }

  async patchRoomDetailNumber(args) {
    const {data: data} = await patchRoomDetailNumber.apply(this, [args]);

    return data;
  }

  async postRoomNumber(args) {
    const {status: status} = await postRoomNumber.apply(this, [args]);

    return status;
  }

  async patchRoomDetailNumberSeveral(args) {
    const {data: data} = await patchRoomDetailNumberSeveral.apply(this, [args]);

    return data;
  }

  async deleteRoomDetailNumber(roomId) {
    const {data: data} = await deleteRoomDetailNumber.apply(this, [roomId]);

    return data;
  }

  async deleteRoomDetailNumberSeveral(args) {
    const {data: data} = await deleteRoomDetailNumberSeveral.apply(this, [args]);

    return data;
  }

  async getAvailableRooms(args) {
    const {data: data} = await getAvailableRooms.apply(this, [args]);

    return data;
  }

  async getAvailableRoomsByRaw(args) {
    const {data: data} = await getAvailableRoomsByRaw.apply(this, [args]);

    return data;
  }

  async createAvailableRooms(data) {
    const {status: status} = await createAvailableRooms.apply(this, [data]);

    return status;
  }

  async patchRoomUsefulSeveral(data) {
    const {status: status} = await patchRoomUsefulSeveral.apply(this, [data]);

    return status;
  }

  async deleteAvailableRooms(args) {
    const {status: status} = await deleteAvailableRooms.apply(this, [args]);

    return status;
  }

  async getRoomCalendarList(args) {
    const {data: data} = await getRoomCalendarList.apply(this, [args]);

    return data;
  }

  async getRoomCalendarByFromDateAndToDate(args) {
    const {data: data} = await getRoomCalendarByFromDateAndToDate.apply(this, [args]);

    return data;
  }

  async patchRoomCalendarList(data) {
    const {status: status} = await patchRoomCalendarList.apply(this, [data]);

    return status;
  }

  async getAddRoomCalendarInfo(args) {
    const {data: data} = await getAddRoomCalendarInfo.apply(this, [args]);

    return data;
  }

  async postAddRoomCalendarInfo(args) {
    const {data: data} = await postAddRoomCalendarInfo.apply(this, [args]);

    return data;
  }

  async deleteRoomCalendar(args) {
    const {data: data} = await deleteRoomCalendar.apply(this, [args]);

    return data;
  }

  async putRoomCalendarRealtimeSetting(args) {
    const {data: data} = await putRoomCalendarRealtimeSetting.apply(this, [args]);

    return data;
  }

  async putRoomCalendarRealtimeSettingInit(args) {
    const {data: data} = await putRoomCalendarRealtimeSettingInit.apply(this, [args]);

    return data;
  }

  async getRoomRateBaseSetting(args) {
    const {data: data} = await getRoomRateBaseSetting.apply(this, [args]);

    return data;
  }

  async patchRoomRateBaseSetting(args) {
    const {data: data} = await patchRoomRateBaseSetting.apply(this, [args]);

    return data;
  }

  async getRoomRatePeriodSetting(args) {
    const {data: data} = await getRoomRatePeriodSetting.apply(this, [args]);

    return data;
  }

  async patchRoomRatePeriodSetting(args) {
    const {data: data} = await patchRoomRatePeriodSetting.apply(this, [args]);

    return data;
  }

  async fetchRoomReservations(args) {
    const {data: data} = await fetchRoomReservations.apply(this, [args]);

    return data;
  }

  async fetchRoomReservationDetail(args) {
    const {data: data} = await fetchRoomReservationDetail.apply(this, [args]);

    return data;
  }

  async fetchRoomResveStayPaymtDetail(stayId) {
    const {data: data} = await fetchRoomResveStayPaymtDetail.apply(this, [stayId]);

    return data;
  }

  async roomReservationDetailSave(data) {
    const {data: rresveNo} = await roomReservationDetailSave.apply(this, [data]);

    return rresveNo;
  }

  async roomReservationDetailCancel(args) {
    const {status: status} = await roomReservationDetailCancel.apply(this, [args]);

    return status;
  }

  async fetchVacantRoom(args) {
    const {data: data} = await fetchVacantRoom.apply(this, [args]);

    return data;
  }

  async roomReservationNamingCancel(stayId) {
    const {status: status} = await roomReservationNamingCancel.apply(this, [stayId]);

    return status;
  }

  async golfPackageLink(rresveNo, resveNos) {
    const {status: status} = await golfPackageLink.apply(this, [rresveNo, resveNos]);

    return status;
  }

  async deleteGolfPackageLink(args) {
    const {data: data} = await deleteGolfPackageLink.apply(this, [args]);

    return data;
  }

  async roomResveStayPaymtsSave(data) {
    const {status: status} = await roomResveStayPaymtsSave.apply(this, [data]);

    return status;
  }

  async batchPromotionRegistration(promtnId, timeIds) {
    const {status: status} = await batchPromotionRegistration.apply(this, [promtnId, timeIds]);

    return status;
  }

  async batchPromotionDelete(timeIds) {
    const {status: status} = await batchPromotionDelete.apply(this, [timeIds]);

    return status;
  }

  async reservationRainCancel(resveId, isRainCancel) {
    const {status: status} = await reservationRainCancel.apply(this, [resveId, isRainCancel]);

    return status;
  }
  async reservationSelfTimeChange(args) {
    const {status: status} = await reservationSelfTimeChange.apply(this, [args]);

    return status;
  }


  async reservationMemoConfirmByStayId(request) {
    const {data: data} = await reservationMemoConfirmByStayId.apply(this, [request]);

    return data;
  }

  async fetchBoardWithPosts(args) {
    const {data: data} = await fetchBoardWithPosts.apply(this, [args]);

    return data;
  }

  async createPost(boardId) {
    const {data: data} = await createPost.apply(this, [boardId]);

    return data;
  }

  async saveImage(boardId, postId, file) {
    const {data: data} = await saveImage.apply(this, [boardId, postId, file]);

    return data;
  }

  async fetchSearchPostsById(boardId, postId) {
    const {data: data} = await fetchSearchPostsById.apply(this, [boardId, postId]);

    return data;
  }

  async savePost(boardId, data) {
    const {status: status} = await savePost.apply(this, [boardId, data]);

    return status;
  }

  async deletePost(boardId, postId) {
    const {status: status} = await deletePost.apply(this, [boardId, postId]);

    return status;
  }

  async fetchGolfPachkageLink(args) {
    const {data: data} = await fetchGolfPachkageLink.apply(this, [args]);

    return data;
  }

  async checkAvailableRooms(args) {
    const {status: status} = await checkAvailableRooms.apply(this, [args]);

    return status;
  }

  async fetchRoomCheckIns(args) {
    const {data: data} = await fetchRoomCheckIns.apply(this, [args]);

    return data;
  }

  async roomCheckInDetailSave(data) {
    const {data: rresveNo} = await roomCheckInDetailSave.apply(this, [data]);

    return rresveNo;
  }

  async checkInRoomCancel(stayId) {
    const {status: status} = await checkInRoomCancel.apply(this, [stayId]);

    return status;
  }

  async fetchRoomCheckInDetail(args) {
    const {data: data} = await fetchRoomCheckInDetail.apply(this, [args]);

    return data;
  }

  async fetchRoomCheckInPosting(stayId) {
    const {data: data} = await fetchRoomCheckInPosting.apply(this, [stayId]);

    return data;
  }

  async roomPostingSave(data) {
    const {status: status} = await roomPostingSave.apply(this, [data]);

    return status;
  }

  async fetchCheckInRooms(rresveNo) {
    const {data: data} = await fetchCheckInRooms.apply(this, [rresveNo]);

    return data;
  }

  async roomPostingDelete(salesId, deleteReason) {
    const {status: status} = await roomPostingDelete.apply(this, [salesId, deleteReason]);

    return status;
  }

  async getVisitThumbnail(chkinId) {
    const {data: data} = await getVisitThumbnail.apply(this, [chkinId]);

    return data;
  }

  async roomCheckout(stayId) {
    const {status: status} = await roomCheckout.apply(this, [stayId]);

    return status;
  }

  async roomCheckoutByAll(rresveNo, bsnDate) {
    const {status: status} = await roomCheckoutByAll.apply(this, [rresveNo, bsnDate]);

    return status;
  }

  async roomTotalSum(rresveNo, stayId) {
    const {status: status} = await roomTotalSum.apply(this, [rresveNo, stayId]);

    return status;
  }

  async roomRestoration(rresveNo) {
    const {status: status} = await roomRestoration.apply(this, [rresveNo]);

    return status;
  }

  async fetchResvePromtnAll() {
    const {data: data} = await fetchResvePromtnAll.apply(this);

    return data;
  }

  async fetchRoomCheckInDetailByStayId(stayId) {
    const {data: data} = await fetchRoomCheckInDetailByStayId.apply(this, [stayId]);

    return data;
  }

  async getLostItemList(args) {
    const {data: data} = await getLostItemList.apply(this, [args]);

    return data;
  }

  async fetchRoomCheckInDetailByOrSearch(args) {
    const {data: data} = await fetchRoomCheckInDetailByOrSearch.apply(this, [args]);

    return data;
  }

  async patchLostItems(records) {
    const {data: data} = await patchLostItems.apply(this, [records]);

    return data;
  }

  async roomExtensionTrans(request) {
    const {status: status} = await roomExtensionTrans.apply(this, [request]);

    return status;
  }

  async postLostItem(lostItem) {
    const {data: data} = await postLostItem.apply(this, [lostItem]);

    return data;
  }

  /**
   * @param lostIds
   * lostIds Type: Array
   */
  async deleteLostItems(lostIds) {
    const {data: data} = await deleteLostItems.apply(this, [lostIds]);

    return data;
  }

  /**
   * @param lostId : Long(Number)
   */
  async getLostItemImages(lostId) {
    const {data: data} = await getLostItemImages.apply(this, [lostId]);

    return data;
  }

  /**
   * @param { lostId: Long(Number), imageId(Number) }
   */
  async saveLostItemImageFileRecord({lostId, imageId}) {
    const {data: data} = await saveLostItemImageFileRecord.apply(this, [lostId, imageId]);

    return data;
  }

  async deleteLostItemImageFileSoft({lostId, imageId}) {
    const {data: data} = await deleteLostItemImageFileSoft.apply(this, [lostId, imageId]);

    return data;
  }

  async fetchRoomReservationStatusList(args) {
    const {data: data} = await fetchRoomReservationStatusList.apply(this, [args]);

    return data;
  }

  async fetchGolfResveMember(resveNo) {
    const {data: data} = await fetchGolfResveMember.apply(this, [resveNo]);

    return data;
  }

  async fetchCaddieTaxSubmissionStatements(args) {
    const {data: data} = await fetchCaddieTaxSubmissionStatements.apply(this, [args]);

    return data;
  }

  async fetchRoomReservationHistory(stayId) {
    const {data: data} = await fetchRoomReservationHistory.apply(this, [stayId]);

    return data;
  }

  async reservationTimeHoldResnModify(timeId, holdResn) {
    const {status: status} = await reservationTimeHoldResnModify.apply(this, [timeId, holdResn]);

    return status;
  }

  async fetchGroupInUse(bsnDateFrom, bsnDateTo, grpNo) {
    const {data: data} = await fetchGroupInUse.apply(this, [bsnDateFrom, bsnDateTo, grpNo]);

    return data;
  }

  async postReservationHistory(args) {
    const {data: data} = await postReservationHistory.apply(this, [
      args,
    ]);
    return data;
  }
  async fetchSettlementStatusByHole(args) {
    const {data: data} = await fetchSettlementStatusByHole.apply(this, [args]);
    return data;
  }

  async getUserLoginHist(args) {
    const {data: data} = await getUserLoginHist.apply(this, [args]);
    return data;
  }

  async getProgramUseHist(args) {
    const {data: data} = await getProgramUseHist.apply(this, [args]);
    return data;
  }

  async createProgramUseHist(args) {
    const {data: data} = await createProgramUseHist.apply(this, [args]);
    return data;
  }

  async getProgramButtonUseHist(args) {
    const {data: data} = await getProgramButtonUseHist.apply(this, [args]);
    return data;
  }

  async createProgramButtonUseHist(data) {
    const {status: status} = await createProgramButtonUseHist.apply(this, [data]);
    return status;
  }

  async getHomePagePasswordChangeHist(args) {
    const {data: data} = await getHomePagePasswordChangeHist.apply(this, [args]);
    return data;
  }

  async getErpPasswordChangeHist(args) {
    const {data: data} = await getErpPasswordChangeHist.apply(this, [args]);
    return data;
  }

  async fetchStoreCoupon(args) {
    const {data: data} = await fetchStoreCoupon.apply(this, [args]);

    return data;
  }

  async getNameChangeApplicationConfirmation(args) {
    const {data: data} = await getNameChangeApplicationConfirmation.apply(this,[args]);
    return data;
  }
  async getNameChangeApplicationConfirmationDetails(renameRequestId) {
    const {data: data} = await getNameChangeApplicationConfirmationDetails.apply(this,[renameRequestId]);
    return data;
  }
  async patchNameChangeApplicationConfirmationDetail(args) {
    const {data: data} = await patchNameChangeApplicationConfirmationDetail.apply(this,[args]);
    return data;
  }
  async deleteNameChangeApplicationConfirmationDetail(args) {
    await deleteNameChangeApplicationConfirmationDetail.apply(this,[args]);
  }
  async confirmNameChangeApplicationConfirmation(args) {
    await confirmNameChangeApplicationConfirmation.apply(this,[args]);
  }
  async cancelNameChangeApplicationConfirmation(args) {
    await cancelNameChangeApplicationConfirmation.apply(this,[args]);
  }

  async getSeizureCheck(args) {
    const {data: data} = await getSeizureCheck.apply(this, [args]);
    return data;
  }
  async getMembershipChangeStatusIndividualCorporate(args){
    const {data: data} = await getMembershipChangeStatusIndividualCorporate.apply(this, [args]);
    return data;
  }

  async getMembershipChangeStatus(args){
    const {data: data} = await getMembershipChangeStatus.apply(this, [args]);
    return data;
  }

  async getMembershipChangeReportList(args){
    const { data } = await getMembershipChangeReportList.apply(this, [args]);
    return data;
  }
  async saveMembershipChangeReportList(args){
    await saveMembershipChangeReportList.apply(this, [args]);
  }
  async getGeneratedReportFiles(args){
    const { data } = await getGeneratedReportFiles.apply(this, [args]);
    return data;
  }
  async getDataForReportGeneration(args){
    const { data } = await getDataForReportGeneration.apply(this, [args]);
    return data;
  }

  async patchReportBasic(args){
    await patchReportBasic.apply(this, [args]);
  }

  async getSeizureList(args){
    const { data } = await getSeizureList.apply(this, [args]);
    return data;
  }

  async patchSeizure(args){
    await patchSeizure.apply(this, [args]);
  }

  async getSeizureDetail(args){
    const { data } = await getSeizureDetail.apply(this, [args]);
    return data;
  }

  async deleteSeizure(args){
    await deleteSeizure.apply(this, [args]);
  }

  async getMembershipHistory(args){
    const { data } = await getMembershipHistory.apply(this, [args]);
    return data;
  }

  async getMiddleMembershipNumber(args){
    const { data } = await getMiddleMembershipNumber.apply(this, [args]);
    return data;
  }

  async fetchDestructionOfPersonalInformation(args) {
    const { data: data } = await fetchDestructionOfPersonalInformation.apply(this, [args]);

    return data;
  }

  async destructionOfPersonalInformationSmsSend(data) {
    const { status: status } = await destructionOfPersonalInformationSmsSend.apply(this, [data]);

    return status;
  }

  async destructionOfPersonalInformation(drmncyIds) {
    const { status: status } = await destructionOfPersonalInformation.apply(this, [drmncyIds]);

    return status;
  }

  async fetchDestructionOfPersonalInformationStatus(args) {
    const { data: data } = await fetchDestructionOfPersonalInformationStatus.apply(this, [ args ]);

    return data;
  }

  async fetchPostSigun() {
    const { data: data } = await fetchPostSigun.apply(this);

    return data;
  }

  async fetchMembershipAnnualFee(args) {
    const { data: data } = await fetchMembershipAnnualFee.apply(this, [args]);

    return data;
  }
  async fetchMembershipAnnualFeeDetail(args) {
    const { data: data } = await fetchMembershipAnnualFeeDetail.apply(this, [args]);

    return data;
  }

  async membershipAnnualFeeSave(args) {
    const { data: data } = await membershipAnnualFeeSave.apply(this, [args]);

    return data;
  }

  async deleteMembershipAnnualFee(args) {
    const { data: data } = await deleteMembershipAnnualFee.apply(this, [args]);

    return data;
  }

  async reservationCopy(args) {
    const { status: status } = await reservationCopy.apply(this, [args]);

    return status;
  }

  async reservationTimeAgencySetting(request) {
    const { status: status } = await reservationTimeAgencySetting.apply(this, [request]);

    return status;
  }

  async reservationTimeAgencyDelete(timeIds) {
    const { status: status } = await reservationTimeAgencyDelete.apply(this, [timeIds]);

    return status;
  }

  async fetchTicketPubInfo(couponStartStr) {
    const { data: data } = await fetchTicketPubInfo.apply(this, [couponStartStr]);

    return data;
  }

  async autoArgmtFlagSettingByVariableLocker(data) {
    const { status: status } = await autoArgmtFlagSettingByVariableLocker.apply(this, [data]);

    return status;
  }

  async initPasswordToUser(id) {
    const { status: status } = await initPasswordToUser.apply(this, [id]);

    return status;
  }

  async fetchMembershipSeizure(membershipId) {
    const { data: data } = await fetchMembershipSeizure.apply(this, [membershipId]);

    return data;
  }


  /*내장객현황 변경이력 조회*/
  async getCheckinHistory(args) {
    const { data: data } = await getCheckinHistory.apply(this, [args]);
    return data;
  }

  /* 예약 채널별 가동율 현황 */
  async fetchStatusOfUtilizationByReservationChannel(args) {
    const { data: data } = await fetchStatusOfUtilizationByReservationChannel.apply(this, [args]);

    return data;
  }

}
export default new GolfErpAPI();
