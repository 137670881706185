import Vue from "vue";
import Vuex from "vuex";
import _uniqBy from "lodash/uniqBy";
import { getTotalInfo } from "@/api/common";
import {
  commonCodesLoadCommonCodesMap,
  commonCodesSetBizMstInfo,
  commonCodesSetCommonCode,
  commonCodesSetStandardInfos,
} from "@/utils/commonCodes";
import memoView from "./modules/memoView";
import fcm from "./modules/fcm";
import modifiedViewState from "./modules/modifiedViewState";
import {
  getLayoutLeftIsShow,
  getLayoutRightContentIsShow,
  getLayoutRightTabIsShow,
  getLayoutRightMemberIsShow,
  getLayoutRightWeatherIsShow,
  getLayoutRightVocIsShow,
} from "@/utils/appInfo";
import epsonPrinter from "./modules/epsonPrinter";
// 즐겨찾기 관련 상수
export const menuUserMarkMenuId = "userMarkFolder";
export const menuUserMarkSortNo = -1;

import pos from "@/store/modules/pos";
import dialog from "@/store/modules/dialog";
import deviceSettingState from "@/store/modules/deviceSetting";
import documents from "@/store/modules/documents";
import {
  getAccessToken,
  saveAccessToken,
} from "@/utils/session/SessionUtil";
import {getCurrentBizCode} from "@/utils/pageKeyUtil";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userId: null,
    username: null,
    // golfErpAccessToken: null,
    authList: [],
    menuAuthList: [],

    isLeftMenuActive: getLayoutLeftIsShow(),
    isRightTabActive: getLayoutRightTabIsShow(),
    isRightContentActive: getLayoutRightContentIsShow(),
    isRightWeatherActive: getLayoutRightWeatherIsShow(),
    isRightMemberActive: getLayoutRightMemberIsShow(),
    isRightVocActive: getLayoutRightVocIsShow(),

    isRightExtendActive: false,
    isRightMemberPopupOpened: false,

    isWaitShortcut: false,

    isHoldERP: false,

    menuList: [],
    menuUserMarkList: [],

    footerMessage: "",
    footerMessageIcon: null,

    loginViewUniqueId: null,

    systemVersionId: null,

    progress: null,
    maxProgress: null,
    isStopProgress: null,

    connectIp: null
  },
  actions: {
    async loadApplication({ commit }) {
      // init session info
      saveAccessToken(getAccessToken());

      /*if (process.env.NODE_ENV === "production") {
        const {versionId} = await GolfERPService.fetchSystemVersion();
        commit("SET_SYSTEM_VERSION_ID", versionId);
      }*/
      const {
        data: {
          commonCodes,
          standardInfos,
          bisMstInfo,
          authList,
          menuAuthList,
          menuList,
          menuUserMarks,
          tsDeviceSet
        },
      } = await getTotalInfo(localStorage.getItem('pcUseCode'));
      this.commit("deviceSettingState/SETTING_DEVICE",tsDeviceSet);
      for (const [key, value] of Object.entries(commonCodes)) {
        Object.assign(
          commonCodes,
          Object.fromEntries(
            new Map(
              [
                [
                  key,
                  value.filter(v => v.bizCode === getCurrentBizCode() || v.bizCode === "%" || !v.bizCode)
                ]
              ]
            )
          )
        );
      }

      // 공통코드 저장
      commonCodesSetCommonCode(commonCodes);
      commonCodesLoadCommonCodesMap();
      // 시스템 환경설정 저장
      commonCodesSetStandardInfos(standardInfos);
      // 해당 유저의 사업장 정보 저장
      commonCodesSetBizMstInfo(bisMstInfo);
      // Frontend 권한, Menu 정보 저장, 즐겨찾기 저장
      commit("SET_AUTH_LIST", authList);
      commit("SET_MENU_AUTH_LIST", menuAuthList);
      commit("SET_MENU_LIST", menuList);
      commit("SET_MENU_USER_MARKS", menuUserMarks);
    },
    setLoaderProgress({ commit }, args) {
      commit("SET_LOADER_PROGRESS", args);
    },
    clearLoaderProgress({ commit }) {
      commit("SET_LOADER_PROGRESS", {
        progress: null,
        maxProgress: null,
      });
      commit("SET_LOADER_STOP_PROGRESS");
      Vue.prototype.$EventBus.$emit('loaderOff');
    },
    toggleLoaderStopProgress({ commit }, isStopProgress = false) {
      commit("SET_LOADER_STOP_PROGRESS", isStopProgress);
    },
    setUserIp({ commit }, userIp) {
      commit("SET_USER_IP", userIp);
    },
  },
  mutations: {
    SET_USERNAME(state, username) {
      state.username = username;
    },
    SET_USER_ID(state, userId) {
      state.userId = userId;
    },
    SET_USER_IP(state, userIp) {
      state.connectIp = userIp;
    },
    /*SET_GOLF_ERP_ACCESS_TOKEN(state, accessToken) {
      state.golfErpAccessToken = accessToken;
    },*/
    SET_SYSTEM_VERSION_ID(state, systemVersionId) {
      state.systemVersionId = systemVersionId;
    },
    SET_AUTH_LIST(state, authList) {
      state.authList = authList;
    },
    SET_MENU_AUTH_LIST(state, menuAuthList) {
      state.menuAuthList = menuAuthList;
    },
    SET_IS_WAIT_SHORTCUT(state, isWaitShortcut) {
      state.isWaitShortcut = isWaitShortcut;
    },
    SET_IS_HOLD_ERP(state, isHoldERP) {
      state.isHoldERP = isHoldERP;
    },
    SET_IS_LEFT_MENU_ACTIVE(state, isLeftMenuActive) {
      state.isLeftMenuActive = isLeftMenuActive;
    },
    SET_IS_RIGHT_TAB_ACTIVE(state, isRightTabActive) {
      state.isRightTabActive = isRightTabActive;
    },
    SET_IS_RIGHT_CONTENT_ACTIVE(state, isRightContentActive) {
      state.isRightContentActive = isRightContentActive;
    },
    SET_IS_RIGHT_WEATHER_ACTIVE(state, isRightWeatherActive) {
      state.isRightWeatherActive = isRightWeatherActive;
    },
    SET_IS_RIGHT_MEMBER_ACTIVE(state, isRightMemberActive) {
      state.isRightMemberActive = isRightMemberActive;
    },
    SET_IS_RIGHT_VOC_ACTIVE(state, isRightVocActive) {
      state.isRightVocActive = isRightVocActive;
    },
    SET_IS_RIGHT_EXTEND_ACTIVE(state, isRightExtendActive) {
      state.isRightExtendActive = isRightExtendActive;
    },
    SET_IS_RIGHT_MEMBER_POPUP_OPENED(state, isRightMemberPopupOpened) {
      state.isRightMemberPopupOpened = isRightMemberPopupOpened;
    },
    SET_MENU_LIST(state, multiDepthMenuList) {
      const menuList = multiDepthMenuList.map((menu) => ({
        menuId: menu.menuId,
        parentId: menu.parentId,
        menuFlag: menu.menuFlag,
        menuName: menu.menuName,
        menuTooltip: menu.menuTooltip,
        chldrnExstFlag: menu.chldrnExstFlag,
        menuIcon: menu.menuIcon,
        routerName: menu.routerName,
        description: menu.description,
        useFlag: menu.useFlag,
        authMenuFlag: menu.authMenuFlag,
      }));

      let parentMenuList = multiDepthMenuList.map((menu) => ({
        menuId: menu.menuIdParent,
        parentId: menu.parentIdParent,
        menuFlag: menu.menuFlagParent,
        menuName: menu.menuNameParent,
        menuTooltip: menu.menuTooltipParent,
        chldrnExstFlag: menu.chldrnExstFlagParent,
        menuIcon: menu.menuIconParent,
        routerName: menu.routerNameParent,
        description: menu.descriptionParent,
        useFlag: menu.useFlagParent,
        sortNo: menu.sortNoParent,
        authMenuFlag: menu.authMenuFlag,
      }));
      parentMenuList = _uniqBy(parentMenuList, "menuId");

      let grandParentMenuList = multiDepthMenuList.map((menu) => ({
        menuId: menu.menuIdGrandParent,
        parentId: menu.parentIdGrandParent,
        menuFlag: menu.menuFlagGrandParent,
        menuName: menu.menuNameGrandParent,
        menuTooltip: menu.menuTooltipGrandParent,
        chldrnExstFlag: menu.chldrnExstFlagGrandParent,
        menuIcon: menu.menuIconGrandParent,
        routerName: menu.routerNameGrandParent,
        description: menu.descriptionGrandParent,
        useFlag: menu.useFlagGrandParent,
        sortNo: menu.sortNoGrandParent,
        authMenuFlag: menu.authMenuFlag,
      }));
      grandParentMenuList = _uniqBy(grandParentMenuList, "menuId");
      state.menuList = menuList
        .concat(parentMenuList)
        .concat(grandParentMenuList);

      // 즐겨찾기 폴더 추가
      state.menuList.unshift({
        chldrnExstFlag: true,
        description: "즐겨찾기",
        menuFlag: false,
        menuIcon: "",
        menuId: menuUserMarkMenuId, // 상수
        menuName: "즐겨찾기",
        menuTooltip: "즐겨찾기",
        parentId: null,
        routerName: null,
        sortNo: menuUserMarkSortNo,
        useFlag: true,
        getAuthFlag: true,
        authMenuFlag: false,
      });

      // 리뉴얼 POS 는 항상 단독 실행한다.
      const posViewMenu = state.menuList.find(
        (menu) => menu.routerName === "PosView"
      );
      if (posViewMenu) {
        posViewMenu.isOnNewTab = true;
      }

      if (process.env.NODE_ENV !== "production") {
        // production 아닌 경우에만 샘플용 메뉴 추가
        // 공통 컴포넌트 메뉴 추가
        state.menuList.push({
          chldrnExstFlag: false,
          description: "공통 컴포넌트",
          menuFlag: true,
          menuIcon: "",
          menuId: 29999999,
          menuName: "공통 컴포넌트",
          menuTooltip: "공통 컴포넌트",
          parentId: null,
          routerName: "CommonComponentsSample",
          sortNo: 2999999,
          useFlag: true,
          authMenuFlag: false,
        });
        // 보고서 샘플 화면 추가
        state.menuList.push({
          chldrnExstFlag: false,
          description: "보고서 샘플",
          menuFlag: true,
          menuIcon: "",
          menuId: 3000000,
          menuName: "보고서 샘플",
          menuTooltip: "보고서 샘플",
          parentId: null,
          routerName: "ReportSampleView",
          sortNo: 3000001,
          useFlag: true,
          authMenuFlag: false,
        });
      }
    },
    SET_MENU_USER_MARKS(state, menuUserMarks) {
      state.menuUserMarkList = menuUserMarks.slice(0);
    },
    SET_FOOTER_MESSAGE(state, footerMessage = '') {
      state.footerMessage = footerMessage;
    },
    SET_FOOTER_MESSAGE_ICON(state, footerMessageIcon = 'warning') {
      state.footerMessageIcon = footerMessageIcon;
    },
    SET_LOGIN_VIEW_UNIQUE_ID(state, loginViewUniqueId) {
      state.loginViewUniqueId = loginViewUniqueId;
    },
    SET_LOADER_PROGRESS(state, args) {
      state.progress = args?.progress;
      state.maxProgress = args?.maxProgress;
    },
    SET_LOADER_STOP_PROGRESS(state, isStopProgress) {
      state.isStopProgress = isStopProgress;
    },
  },
  getters: {
    userId: ({ userId }) => userId,
    userIp: ({ connectIp }) => connectIp,
    username: ({ username }) => username,
    systemVersionId: ({ systemVersionId }) => systemVersionId,
    // golfErpAccessToken: ({ golfErpAccessToken }) => golfErpAccessToken,
    modifiedViewStateByName: (state) => (viewName) => {
      // TODO : 삭제
      const modifiedViewState = state.modifiedViewState.states.filter(
        (v) => v.viewName === viewName
      );
      if (modifiedViewState != null && modifiedViewState.length > 0) {
        return modifiedViewState[0].isModified;
      } else {
        return false;
      }
    },
    authList: ({ authList }) => authList,
    menuAuthList: ({ menuAuthList }) => menuAuthList,
    isWaitShortcut: ({ isWaitShortcut }) => isWaitShortcut,
    isHoldERP: ({ isHoldERP }) => isHoldERP,
    isLeftMenuActive: ({ isLeftMenuActive }) => isLeftMenuActive,
    isRightTabActive: ({ isRightTabActive }) => isRightTabActive,
    isRightContentActive: ({ isRightContentActive }) => isRightContentActive,
    isRightWeatherActive: ({ isRightWeatherActive }) => isRightWeatherActive,
    isRightMemberActive: ({ isRightMemberActive }) => isRightMemberActive,
    isRightVocActive: ({ isRightVocActive }) => isRightVocActive,
    isRightExtendActive: ({ isRightExtendActive }) => isRightExtendActive,
    isRightMemberPopupOpened: ({ isRightMemberPopupOpened }) => isRightMemberPopupOpened,
    menuList: (state, getters) => {
      let returnMenuList = state.menuList.slice(0);

      // 즐겨찾기 폴더 구성 - 즐겨찾기 항목이 menuList에 있는지 & 메뉴 항목인지(메뉴 폴더 아님) 여부를 필터링한 후 구성함(내가 볼 수 있는 메뉴만 즐겨찾기에 출력하기 위함)
      const menuUserMarkElementList = getters.menuUserMarkList;
      returnMenuList = returnMenuList.concat(menuUserMarkElementList);

      return returnMenuList;
    },
    menuListByKeyword: (state) => (keyword) => {
      return state.menuList.filter(
        (menu) =>
          menu.menuName &&
          keyword &&
          menu.menuName.toUpperCase().indexOf(keyword.toUpperCase()) !== -1 &&
          !menu.chldrnExstFlag
      );
    },
    menuByRouterName: (state) => (routerName) => {
      return state.menuList.filter(item => !item.authMenuFlag).find((menu) => menu.routerName === routerName);
    },
    menuByParentId: (state) => (parentId) => {
      return state.menuList.find((menu) => menu.menuId === parentId);
    },
    currentMenu: (state) => {
      return (routerName) =>
        state.menuList.find((menu) => menu.routerName === routerName);
    },
    menuUserMarkList: ({ menuList, menuUserMarkList }) => {
      return menuUserMarkList
        .filter(
          (menuUserMark) =>
            menuList.findIndex(
              (menu) =>
                menu.menuId === menuUserMark.menuId && !menu.chldrnExstFlag
            ) !== -1
        )
        .map((filteredMenuUserMark, filteredMenuUserMarkIdx) => {
          let originalMenuMarkedData = menuList.find(
            (menu) => menu.menuId === filteredMenuUserMark.menuId
          );

          return {
            chldrnExstFlag: false,
            description: originalMenuMarkedData.description,
            menuFlag: originalMenuMarkedData.menuFlag,
            menuIcon: null,
            menuId: menuUserMarkMenuId + "_" + originalMenuMarkedData.menuId,
            menuName: originalMenuMarkedData.menuName,
            menuTooltip: originalMenuMarkedData.menuTooltip,
            parentId: menuUserMarkMenuId,
            routerName: originalMenuMarkedData.routerName,
            sortNo: filteredMenuUserMarkIdx,
            useFlag: originalMenuMarkedData.useFlag,
            isOnNewTab: originalMenuMarkedData.isOnNewTab
              ? originalMenuMarkedData.isOnNewTab
              : false,
            isMenuUserMark: true,
          };
        });
    },
    menuNameByRouterName: (state) => (routerName) => {
      const menu = state.menuList.find(
        (menu) => menu.routerName === routerName
      );
      if (menu) {
        return menu.menuName;
      } else {
        return null;
      }
    },
    footerMessage: ({ footerMessage }) => footerMessage,
    footerMessageIcon: ({ footerMessageIcon }) => footerMessageIcon,
    progress: ({ progress, maxProgress }) => {
      return progress !== null && maxProgress !== null && maxProgress > 0
        ? Math.floor((progress / maxProgress) * 100)
        : null;
    },
    isStopProgress: ({ isStopProgress }) => isStopProgress,
  },
  modules: {
    dialog,
    deviceSettingState,
    fcm,
    modifiedViewState,
    memoView,
    pos,
    documents,
    epsonPrinter,
  },
});

export default store;
