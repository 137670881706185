<template>
  <div class="wrapper" :class="layoutMainClass">
    <!-- 좌우측 메뉴의 클래스 -->
    <appSideLeft/>
    <div class="appBody">
      <appHeader
        @leftMenuIconClicked="onLeftMenuIconClicked"
        @rightTabIconClicked="onRightTabIconClicked"
      />
      <appContent/>
      <appFooter/>
    </div>
    <!-- 우측에 들어갈 컴포넌트(빈 컴포넌트) -->
    <appSideRight
      :isRightContentActive="isRightContentActive"
      :isRightWeatherActive="isRightWeatherActive"
      :isRightMemberActive="isRightMemberActive"
      :isRightVocActive="isRightVocActive"
      @memoButtonClick="onMemoButtonClick"
      @weatherButtonClick="onWeatherButtonClick"
      @memberButtonClick="onMemberButtonClick"
      @vocButtonClick="onVocButtonClick"
    />
  </div>
</template>

<style scoped src="../../assets/css/layout.css"></style>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import resize from 'vue-resize-directive';

import appSideLeft from './components/appSideLeft';
import appHeader from './components/appHeader';
import appContent from './components/appContent';
import appFooter from './components/appFooter';
import appSideRight from './components/appSideRight';

import {getDeviceInfo} from '@/utils/device';
import {setLayoutLeftRightValues} from '@/utils/appInfo';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import {sortBy as _sortBy} from 'lodash';
import ipBillPrinterMixin from "../../mixins/ipBillPrinterMixin";

export default {
  name: 'layout',
  directives: {
    resize,
  },
  mixins: [ipBillPrinterMixin],
  components: {
    appSideLeft,
    appHeader,
    appContent,
    appFooter,
    appSideRight,
  },
  created() {
    this.$EventBus.$on('openRightMenu', () => {
      this.rightContentLayoutOpen();
    });
    this.$EventBus.$on('closeRightMenu', () => {
      this.rightContentClose();
      this.rightContentLayoutReduce();
    });
    this.$EventBus.$on('extendRightMenu', () => {
      this.rightContentLayoutExtend();
    });
    this.$EventBus.$on('reduceRightMenu', () => {
      this.rightContentLayoutReduce();
    });

    if (this.$route.meta.enableMemo) {
      this.setCurrentView(this.$route.meta.currentView);
    } else {
      this.setCurrentView(null);
    }
  },
  beforeDestroy() {
    this.$EventBus.$off('openRightMenu');
    this.$EventBus.$off('closeRightMenu');
    this.$EventBus.$off('extendRightMenu');
    this.$EventBus.$off('reduceRightMenu');
  },
  async mounted() {
    this.rightContentClose();
    const {
      isClientMonitorUse,
      clientMonitorInterval,
      clientMonitorEffect,
      isBillPrinterUse,
      billPrinterCommunication,
      billPrinterDeviceId,
      billPrinterIpAddress,
      billPrinterIpPort,
      isKitchenBillPrinterUse,
      kitchenBillPrinterCommunication,
      kitchenBillPrinterDeviceId,
      kitchenBillPrinterIpAddress,
      kitchenBillPrinterIpPort,
    } = getDeviceInfo();

    if (billPrinterCommunication === 'IP') {
      const isRegisteredBillPrinter = billPrinterDeviceId && billPrinterIpAddress && billPrinterIpPort;
      if (isBillPrinterUse && isRegisteredBillPrinter) {
        this.addEposScriptAndConnect(isBillPrinterUse, isKitchenBillPrinterUse);
      }
    }

    if (kitchenBillPrinterCommunication === 'IP') {
      const isRegisteredKitchenPrinter = kitchenBillPrinterDeviceId && kitchenBillPrinterIpAddress && kitchenBillPrinterIpPort;
      if (isKitchenBillPrinterUse && isRegisteredKitchenPrinter) {
        this.addEposScriptAndConnect(isBillPrinterUse, isKitchenBillPrinterUse);
      }
    }

    if (isClientMonitorUse) {
      await this.fetch();
      this.openWebSocket({
        interval: clientMonitorInterval,
        effect: clientMonitorEffect,
        imageList: this.imageList,
      }, true);
    }
  },
  watch: {
    $route(to) {
      if (!to.meta.enableMemo) {
        this.rightContentClose();
      } else {
        this.setCurrentView(to.meta.currentView);
      }
    },
  },
  data() {
    return {
      imageList: [],
    };
  },
  computed: {
    ...mapGetters({
      _isLeftMenuActive: 'isLeftMenuActive',
      _isRightTabActive: 'isRightTabActive',
      _isRightContentActive: 'isRightContentActive',
      _isRightWeatherActive: 'isRightWeatherActive',
      _isRightMemberActive: 'isRightMemberActive',
      _isRightVocActive: 'isRightVocActive',
      _isRightExtendActive: 'isRightExtendActive',
    }),
    isLeftMenuActive: {
      get() {
        return this._isLeftMenuActive;
      },
      set(isLeftMenuActive) {
        this.SET_IS_LEFT_MENU_ACTIVE(isLeftMenuActive);
      },
    },
    isRightTabActive: {
      get() {
        return this._isRightTabActive;
      },
      set(isRightTabActive) {
        this.SET_IS_RIGHT_TAB_ACTIVE(isRightTabActive);
      },
    },
    isRightContentActive: {
      get() {
        return this._isRightContentActive;
      },
      set(isRightContentActive) {
        this.SET_IS_RIGHT_CONTENT_ACTIVE(isRightContentActive);
      },
    },
    isRightWeatherActive: {
      get() {
        return this._isRightWeatherActive;
      },
      set(isRightWeatherActive) {
        this.SET_IS_RIGHT_WEATHER_ACTIVE(isRightWeatherActive);
      },
    },
    isRightMemberActive: {
      get() {
        return this._isRightMemberActive;
      },
      set(isRightMemberActive) {
        this.SET_IS_RIGHT_MEMBER_ACTIVE(isRightMemberActive);
      },
    },
    isRightVocActive: {
      get() {
        return this._isRightVocActive;
      },
      set(isRightVocActive) {
        this.SET_IS_RIGHT_VOC_ACTIVE(isRightVocActive);
      },
    },
    isRightExtendActive: {
      get() {
        return this._isRightExtendActive;
      },
      set(isRightExtendActive) {
        this.SET_IS_RIGHT_EXTEND_ACTIVE(isRightExtendActive);
      },
    },

    leftNoRightNo() {
      return (
        !this.isLeftMenuActive &&
        !this.isRightTabActive &&
        !this.isRightContentActive &&
        !this.isRightWeatherActive &&
        !this.isRightMemberActive &&
        !this.isRightVocActive
      );
    },
    leftYesRightNo() {
      return (
        this.isLeftMenuActive &&
        !this.isRightTabActive &&
        !this.isRightContentActive &&
        !this.isRightWeatherActive &&
        !this.isRightMemberActive &&
        !this.isRightVocActive
      );
    },
    leftYesRightTabYesRightContentNo() {
      return (
        this.isLeftMenuActive &&
        this.isRightTabActive &&
        !this.isRightContentActive &&
        !this.isRightWeatherActive &&
        !this.isRightMemberActive &&
        !this.isRightVocActive
      );
    },
    leftNoRightTabYesRightContentNo() {
      return (
        !this.isLeftMenuActive &&
        this.isRightTabActive &&
        !this.isRightContentActive &&
        !this.isRightWeatherActive &&
        !this.isRightMemberActive &&
        !this.isRightVocActive
      );
    },
    leftNoRightTabYesRightContentYes() {
      return (
        !this.isLeftMenuActive &&
        this.isRightTabActive &&
        (this.isRightContentActive ||
          this.isRightWeatherActive ||
          this.isRightMemberActive  ||
          this.isRightVocActive
        )
      );
    },

    layoutMainClass() {
      if (this.leftNoRightNo) {
        return this.$t('className.menuLayout.leftNoRightNo');
      } else if (this.leftYesRightNo) {
        return this.$t('className.menuLayout.leftYesRightNo');
      } else if (this.leftYesRightTabYesRightContentNo) {
        return this.$t('className.menuLayout.leftYesRightTabYesRightContentNo');
      } else if (this.leftNoRightTabYesRightContentNo) {
        return this.$t('className.menuLayout.leftNoRightTabYesRightContentNo');
      } else if (this.leftNoRightTabYesRightContentYes) {
        return this.$t('className.menuLayout.leftNoRightTabYesRightContentYes');
      } else {
        return 'invalid-class-name-invalid-invalid';
      }
    },
  },
  methods: {
    ...mapActions('memoView', ['setCurrentView']),
    ...mapMutations([
      'SET_IS_LEFT_MENU_ACTIVE',
      'SET_IS_RIGHT_TAB_ACTIVE',
      'SET_IS_RIGHT_CONTENT_ACTIVE',
      'SET_IS_RIGHT_WEATHER_ACTIVE',
      'SET_IS_RIGHT_MEMBER_ACTIVE',
      'SET_IS_RIGHT_VOC_ACTIVE',
      'SET_IS_RIGHT_EXTEND_ACTIVE',
    ]),
    rightContentLayoutOpen(div) {
      if (!this.isRightContentActive || !this.isRightWeatherActive || !this.isRightMemberActive || !this.isRightVocActive) {
        if (div === 'MEMO') {
          this.isRightContentActive = true;
          this.isRightWeatherActive = false;
          this.isRightMemberActive = false;
          this.isRightVocActive = false;
        } else if (div === 'MEMBER') {
          this.isRightContentActive = false;
          this.isRightWeatherActive = false;
          this.isRightMemberActive = true;
          this.isRightVocActive = false;
        } else if (div === 'WEATHER') {
          this.isRightContentActive = false;
          this.isRightWeatherActive = true;
          this.isRightMemberActive = false;
          this.isRightVocActive = false;
        } else if (div === 'VOC') {
          this.isRightContentActive = false;
          this.isRightWeatherActive = false;
          this.isRightMemberActive = false;
          this.isRightVocActive = true;
        } else {
          this.isRightContentActive = true;
          this.isRightWeatherActive = true;
          this.isRightMemberActive = true;
          this.isRightVocActive = true;
        }
        this.isRightTabActive = true;
        this.isLeftMenuActive = false;

        this.setLayoutLeftRightValues();
      }
    },
    rightContentClose() {
      if (
        this.isRightContentActive ||
        this.isRightMemberActive ||
        this.isRightWeatherActive ||
        this.isRightVocActive
      ) {
        this.isRightContentActive = false;
        this.isRightWeatherActive = false;
        this.isRightMemberActive = false;
        this.isRightVocActive = false;
        this.isRightTabActive = true;
        this.isRightExtendActive = false;

        this.setLayoutLeftRightValues();
      }
    },
    rightContentLayoutExtend() {
      this.isRightExtendActive = true;
    },
    rightContentLayoutReduce() {
      this.isRightExtendActive = false;
    },
    setLayoutLeftRightValues() {
      setLayoutLeftRightValues(
        this.isLeftMenuActive,
        this.isRightTabActive,
        this.isRightContentActive,
        this.isRightWeatherActive,
        this.isRightMemberActive,
        this.isRightVocActive,
      );
    },
    onLeftMenuIconClicked() {
      if (this.isLeftMenuActive) {
        this.isLeftMenuActive = false;
      } else {
        this.isLeftMenuActive = true;
        this.isRightContentActive = false;
        this.isRightWeatherActive = false;
        this.isRightMemberActive = false;
        this.isRightVocActive = false;
        this.isRightExtendActive = false;
      }
      this.setLayoutLeftRightValues();
    },
    onRightTabIconClicked() {
      if (this.isRightTabActive) {
        this.isRightTabActive = false;
        this.isRightContentActive = false;
        this.isRightWeatherActive = false;
        this.isRightMemberActive = false;
        this.isRightVocActive = false;
      } else {
        this.isRightTabActive = true;
      }
      this.setLayoutLeftRightValues();
    },
    onMemoButtonClick(args, isAuto = false) {
      if (!this.isRightContentActive || isAuto) {
        this.rightContentLayoutOpen('MEMO');
      } else {
        this.rightContentClose();
        this.rightContentLayoutReduce();
      }
    },
    onWeatherButtonClick() {
      if (!this.isRightWeatherActive) {
        this.rightContentLayoutOpen('WEATHER');
      } else {
        this.rightContentClose();
      }
    },
    onMemberButtonClick() {
      if (!this.isRightMemberActive) {
        this.rightContentLayoutOpen('MEMBER');
      } else {
        this.rightContentClose();
      }
    },
    onVocButtonClick() {
      if (!this.isRightVocActive) {
        this.rightContentLayoutOpen('VOC');
      } else {
        this.rightContentClose();
      }
    },
    async fetch() {
      const data = await GolfErpAPI.fetchClientMonitorImages(getDeviceInfo().clientMonitorImagePattern);
      this.imageList = _sortBy(data.map(item => {
        return {
          imageId: item.id,
          imageUrl: item.path,
          sortNo: item.sortNo,
        };
      }), ["sortNo"]);
    },
  },
};
</script>
