<template>
  <ul class="button">
    <li
      v-for="(item, index) in items"
      v-bind:key="`button-list-${uid}-item-${index}`"
      :class="item.class"
    >
      <f-button
        ref="buttons"
        v-on="item.buttonOptions.listeners"
        :id="item.id"
        @keydown="onButtonKeydown"
      >
        {{ item.buttonOptions.text }}
      </f-button>
    </li>
  </ul>
</template>

<script>
import { v4 as createUUID } from "uuid";
import FButton from "@/components/button/FButton";

export default {
  name: "FButtonList",
  components: { FButton },
  props: {
    items: {
      type: [Array],
      default: () => [],
    },
  },
  data() {
    return {
      uid: createUUID(),

      focusedButtonIndex: 0,
    };
  },
  methods: {
    onButtonKeydown(event) {
      if (["ArrowLeft", "ArrowRight", "Tab"].includes(event.key)) {
        event.preventDefault();

        let nextFocusButtonIndex = this.focusedButtonIndex;
        switch (event.key) {
          case "ArrowLeft":
            nextFocusButtonIndex =
              (nextFocusButtonIndex + this.items.length - 1) %
              this.items.length;
            break;
          case "ArrowRight":
          case "Tab":
            nextFocusButtonIndex =
              (nextFocusButtonIndex + this.items.length + 1) %
              this.items.length;
            break;
        }

        this.focusButton(nextFocusButtonIndex);
      }
    },

    focusButton(buttonIndex) {
      this.$refs["buttons"][buttonIndex].$el.focus();
      this.focusedButtonIndex = buttonIndex;
    },
  },
};
</script>

<style scoped></style>
