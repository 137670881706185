import { getCurrentBizCode } from "@/utils/pageKeyUtil";

const commonCodesKey = "Common-Codes";
const standardInfosKey = "Standard-Infos";
const bizMstInfoKey = "Biz-Mst-Info";

let commonCodesMap = new Map();

/**
 * 특정 groupCode의 공통코드 값 가져오기
 * JSON.parse를 사용하여 deep copy
 *
 * @param groupCode
 * @returns {any}
 */
function getParsedCommonCodes(groupCode) {
  const commonCodesByGroupCode = commonCodesMap.get(groupCode?.trim());
  if (!commonCodesByGroupCode) {
    console.error(`### No Common Codes with ${groupCode} ###`);
  } else {
    return JSON.parse(JSON.stringify(commonCodesByGroupCode));
  }
}

export function commonCodesSetBizMstInfo(bizMstInfoJson) {
  if (bizMstInfoJson && bizMstInfoJson.tsBizMstInfo) {
    sessionStorage.setItem(
      bizMstInfoKey,
      JSON.stringify(bizMstInfoJson.tsBizMstInfo)
    );
  }
}

export function commonCodesGetBizMstInfo() {
  return JSON.parse(sessionStorage.getItem(bizMstInfoKey));
}

export function commonCodesSetCommonCode(commonCodesJson) {
  sessionStorage.setItem(commonCodesKey, JSON.stringify(commonCodesJson));
}

export function hasCommonCode() {
  return Object.prototype.hasOwnProperty.call(sessionStorage, commonCodesKey);
}

export function commonCodesLoadCommonCodesMap() {
  commonCodesMap.clear();

  let loadedCommonCodesJson = JSON.parse(
    sessionStorage.getItem(commonCodesKey)
  );
  let commonCodesJsonKeys = Object.keys(loadedCommonCodesJson);

  if (commonCodesJsonKeys && commonCodesJsonKeys.length > 0) {
    commonCodesJsonKeys.forEach((commonCodeKey) => {
      commonCodesMap.set(commonCodeKey, loadedCommonCodesJson[commonCodeKey]);
    });
    return hasCommonCode();
  }
  return false;
}

/**
 * groupCode에 해당하는 모든 공통 코드 목록을 가져온다
 * bizCode와 상관 없이 모두 가져옴
 * @param groupCode 가져올 공통 코드의 그룹 코드
 * @param useFlag 가져올 공통 코드의 사용여부. true인 경우에는 useFlag === true 인 값만 가져온다. false인 경우에는 모두 다 가져온다
 * @param isInit
 * @param initValue
 * @returns {any}
 */
export function commonCodesGetCommonCode(groupCode, useFlag = false, isInit = false, initValue = null, initText = "") {
  const commonCodes = getParsedCommonCodes(groupCode);

  if (!commonCodes || !Array.isArray(commonCodes)) {
    return null;
  }
  //사용여부 true인 경우만 필터링하기
  const filteredCommonCodes = useFlag ?  commonCodes.filter(commonCode => commonCode.useFlag === useFlag) : [...commonCodes];
  //초기값 있을경우 초기값 추가하기
  return isInit ? [{ comCode: initValue, comName: initText },...filteredCommonCodes] : filteredCommonCodes;
}
// export function commonCodesGetCommonCode(groupCode, useFlag = false, isInit = false, initValue = null) {
//   let commonCodes = getParsedCommonCodes(groupCode);
//   let retCommonCodes = [];
//
//   if (commonCodes && Array.isArray(commonCodes)) {
//     if (!useFlag) {
//       return commonCodes.slice(0);
//     } else {
//       if (isInit) {
//         retCommonCodes.push({
//           comCode: initValue,
//           comName: "",
//         });
//       }
//       commonCodes.forEach((commonCode) => {
//         if (commonCode.useFlag !== null) {
//           if (commonCode.useFlag === useFlag) {
//             retCommonCodes.push(commonCode);
//           }
//         }
//       });
//       return retCommonCodes;
//     }
//   } else {
//     return null;
//   }
// }

/**
 * groupCode에 해당하는 모든 공통 코드 중 JSON_DATA의 attrb 필드로 필터링한 목록을 가져온다
 * bizCode와 상관 없이 모두 가져옴
 * @param groupCode 가져올 공통 코드의 그룹 코드
 * @param attrb 가져올 공통 코드 중, 필터링할 JSON_DATA의 ATTRB
 * @param useFlag 가져올 공통 코드의 사용여부. true면 사용여부가 true인 것만, false면 전체.
 * @returns {any}
 */
export function commonCodesGetCommonCodeByAttrb(
  groupCode,
  attrb,
  useFlag = false
) {
  let commonCodes = getParsedCommonCodes(groupCode);
  let retCommonCodes = [];
  if (Array.isArray(commonCodes)) {
    commonCodes.forEach((commonCode) => {
      if (commonCode.jsonData != null) {
        let commonCodeJsonDataArray = JSON.parse(commonCode.jsonData);
        if (
          Array.isArray(commonCodeJsonDataArray) &&
          commonCodeJsonDataArray.findIndex(
            (jsonData) => attrb === jsonData.ATTRB
          ) !== -1
        ) {
          if (useFlag) {
            if (commonCode.useFlag) {
              retCommonCodes.push(commonCode);
            }
          } else {
            retCommonCodes.push(commonCode);
          }
        }
      }
    });
  }
  return retCommonCodes;
}

/**
 * groupCode에 해당하는 모든 공통 코드 중 JSON_DATA의 idx 필드로 필터링한 목록을 가져온다
 * bizCode와 상관 없이 모두 가져옴
 * @param groupCode 가져올 공통 코드의 그룹 코드
 * @param idx 가져올 공통 코드 중, 필터링할 JSON_DATA의 IDX
 * @returns {any}
 */
export function commonCodesGetCommonCodeByIdx(groupCode, idx) {
  let commonCodes = getParsedCommonCodes(groupCode);
  let retCommonCodes = [];
  if (Array.isArray(commonCodes)) {
    commonCodes.forEach((commonCode) => {
      commonCode.comCodeAttrbList = commonCode.comCodeAttrbList.filter(attrb => attrb.idx === idx);
      if (commonCode.jsonData != null) {
        let commonCodeJsonDataArray = JSON.parse(commonCode.jsonData);
        if (
          Array.isArray(commonCodeJsonDataArray) &&
          commonCodeJsonDataArray.findIndex(
            (jsonData) => idx === jsonData.IDX
          ) !== -1
        ) {
          retCommonCodes.push(commonCode);
        }
      }
    });
  }
  return retCommonCodes;
}

export function commonCodesGetCommonCodeAttrbByCodeAndIdx(
  groupCode,
  comCode,
  idx,
  numberFlag = true
) {
  let returnAttrbNumber = null;
  let commonCodes = getParsedCommonCodes(groupCode);
  let commonCode = commonCodes.find((code) => code.comCode === comCode);

  if (commonCode?.jsonData) {
    let commonCodeJsonDataArray = JSON.parse(commonCode.jsonData);
    let commonCodeJsonData = commonCodeJsonDataArray.find(
      (jsonData) => jsonData.IDX === idx
    );

    if (commonCodeJsonData !== undefined) {
      if (numberFlag) {
        returnAttrbNumber = Number(commonCodeJsonData.ATTRB);
      } else {
        returnAttrbNumber = commonCodeJsonData.ATTRB;
      }
    }
    if (isNaN(returnAttrbNumber)) {
      returnAttrbNumber = commonCodeJsonData.ATTRB;
    }
  }
  return returnAttrbNumber;
}

export function commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
  groupCode,
  comCode,
  idx
) {
  let returnAttrbNumber = null;
  let commonCodes = getParsedCommonCodes(groupCode);
  let commonCode = commonCodes.find((code) => code.comCode === comCode);
  if (commonCode?.jsonData) {
    let commonCodeJsonDataArray = JSON.parse(commonCode.jsonData);
    let commonCodeJsonData = commonCodeJsonDataArray.find(
      (jsonData) => jsonData.IDX === idx
    );

    if (commonCodeJsonData !== undefined) {
      returnAttrbNumber = Number(commonCodeJsonData.ATTRB_NAME);
    }
    if (isNaN(returnAttrbNumber)) {
      returnAttrbNumber = commonCodeJsonData.ATTRB_NAME;
    }
  }
  return returnAttrbNumber;
}

/**
 * groupCode에 해당하는 모든 공통 코드 중 JSON_DATA의 idx, attrb 필드로 필터링한 목록을 가져온다
 * bizCode와 상관 없이 모두 가져옴
 * @param groupCode 가져올 공통 코드의 그룹 코드
 * @param idx 가져올 공통 코드 중, 필터링할 JSON_DATA의 IDX
 * @param attrb 가져올 공통 코드 중, 필터링할 JSON_DATA의 ATTRB
 * @returns {any}
 */
export function commonCodesGetCommonCodeByIdxAttrb(groupCode, idx, attrb) {
  let commonCodes = getParsedCommonCodes(groupCode);
  let retCommonCodes = [];
  if (Array.isArray(commonCodes)) {
    commonCodes.forEach((commonCode) => {
      commonCode.comCodeAttrbList = commonCode.comCodeAttrbList.filter(comCodeAttrb => comCodeAttrb.idx === idx && comCodeAttrb.attrb === attrb);
      if (commonCode.jsonData != null) {
        let commonCodeJsonDataArray = JSON.parse(commonCode.jsonData);
        if (
          Array.isArray(commonCodeJsonDataArray) &&
          commonCodeJsonDataArray.findIndex(
            (jsonData) => idx === jsonData.IDX && attrb === jsonData.ATTRB
          ) !== -1
        ) {
          retCommonCodes.push(commonCode);
        }
      }
    });
  }
  return retCommonCodes;
}

/**
 * 특정 코드의 colorValue 값을 가져온다
 * @param groupCode 그룹 코드
 * @param comCode 코드값
 * @returns {*}
 */
export function commonCodesGetColorValue(groupCode, comCode) {
  let findedComCode = findElementWithComCode(groupCode, comCode);

  if (findedComCode) {
    return findedComCode.colorValue;
  } else {
    return null;
  }
}

/**
 * 특정 코드의 comName 값을 가져온다
 * @param groupCode 그룹 코드
 * @param comCode 코드값
 * @returns {*}
 */
export function commonCodesGetComName(groupCode, comCode) {
  let findedComCode = findElementWithComCode(groupCode, comCode);

  if (findedComCode) {
    return findedComCode.comName;
  } else {
    return null;
  }
}

/**
 * 특정 코드의 codeDesc 값을 가져온다
 * @param groupCode 그룹 코드
 * @param comCode 코드값
 * @returns {*}
 */
export function commonCodesGetCodeDesc(groupCode, comCode) {
  let findedComCode = findElementWithComCode(groupCode, comCode);

  if (findedComCode) {
    return findedComCode.codeDesc;
  } else {
    return null;
  }
}

/**
 * 특정 코드의 sortNo 값을 가져온다
 * @param groupCode 그룹 코드
 * @param comCode 코드값
 * @returns {*}
 */
export function commonCodesGetSortNo(groupCode, comCode) {
  let findedComCode = findElementWithComCode(groupCode, comCode);

  if (findedComCode) {
    return findedComCode.sortNo;
  } else {
    return null;
  }
}

/**
 * 특정 코드의 codeAbrv 값을 가져온다
 * @param groupCode 그룹 코드
 * @param comCode 코드값
 * @returns {null|*}
 */
export function commonCodesGetCodeAbrv(groupCode, comCode) {
  let findedComCode = findElementWithComCode(groupCode, comCode);

  if (findedComCode) {
    return findedComCode.codeAbrv;
  } else {
    return null;
  }
}

/**
 * 특정 코드의 jsonData 값을 가져온다
 * @param groupCode
 * @param comCode
 */
export function commonCodesGetJsonData(groupCode, comCode) {
  let findedComCode = findElementWithComCode(groupCode, comCode);

  if (findedComCode) {
    return JSON.parse(findedComCode.jsonData);
  } else {
    return null;
  }
}

/**
 * 현재 선택한 사업장 및 특정 groupCode에 해당하는 공통 코드 목록을 가져옴
 * @param groupCode 가져올 공통 코드의 그룹 코드
 * @returns {*}
 */
export function commonCodeGetComCodeByCurrentBizCode(groupCode) {
  return commonCodeGetComCodeByBizCode(groupCode, getCurrentBizCode());
}

/**
 * GroupCode 및 사업장 코드에 해당하는 공통 코드 목록을 가져옴
 * 대부분의 경우, commonCodeGetComCodeByCurrentBizCode를 사용하면 됨
 * @param groupCode 그룹 코드
 * @param bizCode 사업장 코드
 * @returns {*}
 */
export function commonCodeGetComCodeByBizCode(groupCode, bizCode) {
  let comCodes = commonCodesGetCommonCode(groupCode);

  if (Array.isArray(comCodes)) {
    return comCodes.filter(
      (comCode) => comCode.bizCode === bizCode || comCode.bizCode === "%"
    );
  } else {
    return null;
  }
}

/**
 * groupCode에 해당하는 모든 공통 코드 중 default 의 comCode 값을 가져온다
 * @param groupCode
 * @returns {*}
 */
export function commonCodeGetComCodeDefaultValue(groupCode) {
  let commonCodes = commonCodesGetCommonCode(groupCode);
  return getDefaultComCode(commonCodes);
}

/**
 * groupCode에 해당하는 모든 공통 코드 중, 현재 사업장에 해당하는 것 중에 default 의 comCode 값을 가져온다
 * @param groupCode
 * @returns {*}
 */
export function commonCodeGetComCodeDefaultValueByCurrentBizCode(groupCode) {
  let commonCodes = commonCodeGetComCodeByCurrentBizCode(groupCode);
  return getDefaultComCode(commonCodes);
}

function findElementWithComCode(groupCode, comCode) {
  let comCodesOfGroupCode = commonCodesGetCommonCode(groupCode, false);
  let findedComCode = null;

  if (comCodesOfGroupCode && comCodesOfGroupCode.length > 0) {
    findedComCode = comCodesOfGroupCode.find(
      (element) => comCode === element.comCode
    );
  }

  return findedComCode;
}

function getDefaultComCode(commonCodes) {
  let defaultValue;

  if (Array.isArray(commonCodes)) {
    const defaultCommonCode = commonCodes.find(
      (commonCode) => commonCode.defaultFlag === true
    );
    if (defaultCommonCode) {
      defaultValue = defaultCommonCode.comCode;
    }
  }

  return defaultValue;
}

export function commonCodesSetStandardInfos(standardInfosJson) {
  if (standardInfosJson && standardInfosJson.confCom) {
    sessionStorage.setItem(
      standardInfosKey,
      JSON.stringify(standardInfosJson.confCom)
    );
  }
}

/**
 * 시스템 환경설정 조회
 * 시스템 환경설정 저장 후 (모든 창에서) 바로 반영되도록 하기 위하여 sessionStorage 바로 loading 하도록 수정함
 * @param key
 * @returns {*}
 */
export function commonCodesGetStandardInfo(key) {
  const standardInfoObject = JSON.parse(
    sessionStorage.getItem("Standard-Infos")
  );
  return standardInfoObject[key];
}
