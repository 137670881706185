<template>
  <div
    :class="['custom loader', progress !== null && 'overlapped']"
  >
    <div
      class="custom-spinner"
      v-if="progress === null"
      v-show="loading"
    >
      <div
        :style="{
          ...spinnerStyle,
          borderColor: 'rgba(0, 0, 0, .1)',
        }"
      />
      <div
        class="custom-clip"
        :style="{
          ...spinnerStyle,
          borderColor: `${color} ${color} transparent`,
        }"
      />
    </div>
    <div
      class="custom-spinner"
      v-else
      v-show="loading"
    >
      <div
        :style="{
          ...spinnerStyle,
          borderColor: 'rgba(0, 0, 0, .1)',
        }"
      />
      <div
        :style="{
          position: 'absolute',
          top: 0,
          left: 0,
          width: `${size + 16}px`,
          height: `${size + 16}px`,
          borderRadius: '500rem',
          background: `conic-gradient(${color} 0%, ${color} ${progress}%, transparent ${progress + 1}%, transparent 100%)`,
        }"
      />
      <div
        v-if="progress !== null"
        class="custom-progress"
        :style="{
          width: `${size}px`,
          height: `${size}px`,
          color,
        }"
      >
        {{ `${progress} %` }}
      </div>
      <div
        v-if="progress !== null"
        class="stop-progress"
        :style="{
          left: `${(size / 2) - (48 / 2) + 8}px`,
        }"
        @click="onClickStopProgress"
      />
    </div>
  </div>
</template>

<style scoped>
.custom-spinner {
  position: relative;
  text-align: center;
}

.custom-spinner .custom-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  position: absolute;
  top: 0;
  left: 0;
  font-family: NanumSquare-Regular, serif;
  font-size: 13px;
  font-weight: bold;
  border-radius: 500rem;
  background-color: rgba(255, 255, 255, .95);
  backdrop-filter: blur(20px);
}

.custom-spinner .stop-progress {
  width: 48px;
  height: 48px;
  position: absolute;
  left: 0;
  bottom: -60px;
  border-radius: 500rem;
  background: rgba(255, 255, 255, .6) url(../../assets/images/common/close.png) no-repeat center;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, .06);
  cursor: pointer;
}
.custom-spinner .stop-progress:hover {background-color: rgba(255, 255, 255, .9)}
.custom-spinner .stop-progress:active {background-image: url(../../assets/images/common/close_active.png)}

.custom-spinner .custom-clip {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: custom-clipDelay 0.75s 0s infinite linear;
  animation: custom-clipDelay 0.75s 0s infinite linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}

@-webkit-keyframes custom-clipDelay {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes custom-clipDelay {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "CustomLoader",
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "#2e7458",
    },
    size: {
      type: Number,
      default: 50,
    },
    radius: {
      type: String,
      default: "100%",
    },
  },
  async created() {
    window.addEventListener("keydown", this.onWindowKeydown, true);
  },
  async beforeDestroy() {
    window.removeEventListener("keydown", this.onWindowKeydown, true);
  },
  computed: {
    ...mapGetters(['progress']),
    spinnerStyle() {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`,
        borderWidth: "8px",
        borderStyle: "solid",
        borderRadius: this.radius,
        background: "transparent",
      };
    },
  },
  methods: {
    ...mapActions(['toggleLoaderStopProgress']),
    async onWindowKeydown(event) {
      if (this.progress !== null && event.key === 'Escape') {
        await this.onClickStopProgress();
      }
    },
    async onClickStopProgress() {
      await this.toggleLoaderStopProgress(true);
    },
  },
};
</script>
