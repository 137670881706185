import Vue from "vue";
import ClientMonitorMixin from '@/mixins/clientMonitorMixin';
// import * as Sentry from '@sentry/vue';

// Global Vue Instance Event Bus
Vue.prototype.$EventBus = new Vue();

Vue.mixin(ClientMonitorMixin);

// use syncfusion
import("./libs/syncfusion");

// Fonts
import "./assets/fonts/NanumSquare-Light.css";
import "./assets/fonts/NanumSquare-Regular.css";
import "./assets/fonts/NanumSquare-Bold.css";

import App from "./App.vue";

import router from "./router";
import store from "./store";
import i18n from "./i18n";

import BaseError from "@/error/BaseError";
import { GOLF_ERP_STATUS_CODE_MESSAGES } from "@/api/v2/GolfErpAPI";
import {initializeFirebase} from "@/utils/pushNofication";
import {createRegExpForUrl} from "@/utils/string";

initializeFirebase();

// Turn off to display production tip
Vue.config.productionTip = false;
Vue.config.devtools = true;

// default error handler
Vue.config.errorHandler = function (err, vm) {
  if (err instanceof BaseError) {
    const { status } = err.getData();

    if (GOLF_ERP_STATUS_CODE_MESSAGES[status]) {
      vm.$EventBus.$emit("alert", {
        message: GOLF_ERP_STATUS_CODE_MESSAGES[status],
      });
    }
  } else {
    console.error(err);
  }
};

/* sentry 임시주석
if(process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    trackComponents: true,
    // autoSessionTracking: false,
    // release: process.env.npm_package_name + "@" + process.env.npm_package_version,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          createRegExpForUrl(process.env.VUE_APP_HOST_API_BASE_V1),
          createRegExpForUrl(process.env.VUE_APP_HOST_API_BASE_V2),
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, hint) {
      const error = hint.originalException;
      if(error instanceof RangeError) {
        return null;
      }
      return event;
    }
  });
}*/


new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
