import Vue from "vue";
import InputDate from "./InputDate";
import gridTemplateDestroyMixin from "../../../mixins/gridTemplateDestroyMixin";

export default function getDateEditTemplate(field, options = {}) {
  return {
    template: Vue.component("DateEditTemplate", {
      mixins: [gridTemplateDestroyMixin],
      components: { InputDate },
      template: `<input-date v-model="data[field]" :id="field" :name="field" :format="options.format"/>`,
      data() {
        return {
          field: field,
          options,
          data: {},
        };
      },
    }),
  };
}
