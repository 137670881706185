import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

import layout from "@/views/layout/layout";

import samplePageRoute from "./modules/samplePage";
import CodeManagementRoute from "./modules/CodeManagement";
import CheckinRoute from "./modules/Checkin";
import DailyCloseRoute from "./modules/DailyClose";
import GameProgressRoute from "./modules/GameProgress";
import GolfReservationRoute from "./modules/GolfReservation";
import roomReservationManagementRouter from "./modules/RoomReservationManagement";
import logManagementRouter from "./modules/LogManagement";
import roomFrontRouter from "@/router/modules/RoomFront";
import MemberManagementRoute from "./modules/MemberManagement";
import StoreManagementRoute from "./modules/StoreManagement";
import CreditManagement from "./modules/CreditManagement";
import authoritiesManagementRoute from "@/router/modules/AuthorityManagement";
import voiceOfTheCustomerRoute from "@/router/modules/VoiceOfTheCustomer";
import managementRoute from "@/router/modules/Management";
import LostItemManagement from "@/router/modules/LostItemManagement";
import TransferOfNameRoute from "@/router/modules/TransferOfName";
import stockManageRoute from "@/router/modules/StockManagement";

Vue.use(Router);

// CSS
import common from "@/assets/css/common.lazy.css";
import custom from "@/assets/css/custom.lazy.css";
import dev from "@/assets/css/dev.lazy.css";
import pos from "@/assets/css/pos.lazy.css";
import window from "@/assets/css/window.lazy.css";
import themeRed from "@/assets/css/theme-red.lazy.css";
import themeOrange from "@/assets/css/theme-orange.lazy.css";
import themeYellow from "@/assets/css/theme-yellow.lazy.css";
import themeGreen from "@/assets/css/theme-green.lazy.css";
import themeBlue from "@/assets/css/theme-blue.lazy.css";
import themeIndigo from "@/assets/css/theme-indigo.lazy.css";
import themePurple from "@/assets/css/theme-purple.lazy.css";
import themeBlack from "@/assets/css/theme-black.lazy.css";

const erpRouter = new Router({
  base: "/",
  routes: [
    {
      path: "",
      component: layout,
      redirect: "dashboard",
      children: [
        {
          path: "dashboard",
          component: () => import("@/views/dashboard/index"),
          name: "dashboard",
          meta: {
            useUsualStyles: true,
          },
        },
      ],
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/views/login/LoginView"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      name: "SessionExpired",
      path: "/session-expired",
      component: () => import("@/views/login/SessionExpiredView"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "/pos-renew",
      component: () =>
        import(
          "@/views/store-management/sales-registration/store-pos-renew/PosView"
        ),
      name: "PosView",
      meta: {
        useUsualStyles: false,
      },
    },
    {
      path: "/test-report",
      component: () => process.env.NODE_ENV !== "production" ?
          import(
              "@/views/test/TestReport.vue"
              )
          : import("@/views/error/404")
      ,
      name: "PosView",
      meta: {
        useUsualStyles: false,
      },
    },
    {
      name: "error404",
      path: "/404",
      component: () => import("@/views/error/404"),
    },
    CodeManagementRoute,
    GolfReservationRoute,
    MemberManagementRoute,
    CheckinRoute,
    GameProgressRoute,
    DailyCloseRoute,
    StoreManagementRoute,
    roomReservationManagementRouter,
    logManagementRouter,
    roomFrontRouter,
    CreditManagement,
    authoritiesManagementRoute,
    samplePageRoute,
    voiceOfTheCustomerRoute,
    managementRoute,
    LostItemManagement,
    TransferOfNameRoute,
    stockManageRoute,
    {
      path: "*",
      redirect: "/404",
      hidden: true,
    },
  ],
});

erpRouter.afterEach((to) => {
  if (
    to.matched?.reduce(
      (previousValue, route) => previousValue || !!route.meta?.useUsualStyles,
      false
    )
  ) {
    common.use();
    dev.use();
    window.use();
    themeRed.use();
    themeOrange.use();
    themeYellow.use();
    themeGreen.use();
    themeBlue.use();
    themeIndigo.use();
    themePurple.use();
    themeBlack.use();
    custom.use();
  } else {
    common.unuse();
    dev.unuse();
    window.unuse();
    themeRed.unuse();
    themeOrange.unuse();
    themeYellow.unuse();
    themeGreen.unuse();
    themeBlue.unuse();
    themeIndigo.unuse();
    themePurple.unuse();
    themeBlack.unuse();
    custom.unuse();
  }

  if (to.matched?.findIndex((route) => route.name === "PosView") < 0) {
    pos.unuse();
  } else {
    pos.use();
  }
});

erpRouter.onError((error) => {
  if (error.name === "ChunkLoadError") {
    if (store._vm.$router.currentRoute.name === "PosView") {
      store._vm.$EventBus.$emit("pos-confirm", {
        message: "새로운 프로그램이 반영되어, 화면을 새로고침합니다.",
        callback: () => {
          location.reload();
        },
      });
    } else {
      console.log("===> chunk load error !!!");
      alert("새로운 프로그램이 반영되었습니다. 새로고침 해주세요.");
      store._vm.$EventBus.$emit("alert", {
        message: "새로운 프로그램이 반영되어, 화면을 새로고침합니다.",
        closeCallback: () => {
          location.reload();
        },
      });
    }
  } else {
    if (store._vm.$router.currentRoute.name === "PosView") {
      store._vm.$EventBus.$emit("pos-confirm", {
        message: "새 페이지를 로딩하던 중에 오류가 발생하였습니다.",
        callback: () => {},
      });
    } else {
      console.log("===> chunk load error !!!");
      alert("새로운 프로그램이 반영되었습니다. 새로고침 해주세요.");
      store._vm.$EventBus.$emit("alert", {
        message: "새 페이지를 로딩하던 중에 오류가 발생하였습니다.",
      });
    }
  }
});

export default erpRouter;
