import Vue from "vue";
import InputTime from "./InputTime";
import gridTemplateDestroyMixin from "../../../mixins/gridTemplateDestroyMixin";

export default function getTimeEditTemplate(field, options = {}) {
  return {
    template: Vue.component("TimeEditTemplate", {
      mixins: [gridTemplateDestroyMixin],
      components: { InputTime },
      template: `<input-time v-model="data[field]" :id="field" :name="field" :format="options.format" :max="options.max" :min="options.min"/>`,
      data() {
        return {
          field: field,
          options,
          data: {},
        };
      },
    }),
  };
}
