export const PATH_DORMANT_MEMBERS = "/dormant-members";

export function fetchDestructionOfPersonalInformation(args) {
  return this.http.get(`${PATH_DORMANT_MEMBERS}/destruction-of-personal-information`, {
    params: {
      bsnDate: args.bsnDate,
      drmncyProcessDiv: args.drmncyProcessDiv,
      memberName: args.memberName,
    },
  });
}

export function fetchDestructionOfPersonalInformationStatus(args) {
  return this.http.get(`${PATH_DORMANT_MEMBERS}/destruction-of-personal-information/status`, {
    params: {
      expirDateFrom: args.expirDateFrom,
      expirDateTo: args.expirDateTo,
      drmncyProcessDiv: args.drmncyProcessDiv,
      memberName: args.memberName,
    },
  });
}

export function destructionOfPersonalInformationSmsSend(newSMSRequestList) {
  return this.http.post(`${PATH_DORMANT_MEMBERS}/destruction-of-personal-information/sms-send`, newSMSRequestList);
}

export function destructionOfPersonalInformation(drmncyIds) {
  return this.http.post(`${PATH_DORMANT_MEMBERS}/destruction-of-personal-information/dispose`, drmncyIds);
}