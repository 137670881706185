var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['custom loader', _vm.progress !== null && 'overlapped']},[(_vm.progress === null)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"custom-spinner"},[_c('div',{style:(Object.assign({}, _vm.spinnerStyle,
        {borderColor: 'rgba(0, 0, 0, .1)'}))}),_c('div',{staticClass:"custom-clip",style:(Object.assign({}, _vm.spinnerStyle,
        {borderColor: (_vm.color + " " + _vm.color + " transparent")}))})]):_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"custom-spinner"},[_c('div',{style:(Object.assign({}, _vm.spinnerStyle,
        {borderColor: 'rgba(0, 0, 0, .1)'}))}),_c('div',{style:({
        position: 'absolute',
        top: 0,
        left: 0,
        width: ((_vm.size + 16) + "px"),
        height: ((_vm.size + 16) + "px"),
        borderRadius: '500rem',
        background: ("conic-gradient(" + _vm.color + " 0%, " + _vm.color + " " + _vm.progress + "%, transparent " + (_vm.progress + 1) + "%, transparent 100%)"),
      })}),(_vm.progress !== null)?_c('div',{staticClass:"custom-progress",style:({
        width: (_vm.size + "px"),
        height: (_vm.size + "px"),
        color: _vm.color,
      })},[_vm._v(" "+_vm._s((_vm.progress + " %"))+" ")]):_vm._e(),(_vm.progress !== null)?_c('div',{staticClass:"stop-progress",style:({
        left: (((_vm.size / 2) - (48 / 2) + 8) + "px"),
      }),on:{"click":_vm.onClickStopProgress}}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }