export default {
  created() {
    this.$EventBus.$on("gridWrapperDestroyed", async (uid) => {
      if (await this.findGridWrapperUid(this, uid)) {
        this.$destroy();
      }
    });
  },
  destroyed() {
    this.$EventBus.$off("gridWrapperDestroyed");
  },
  methods: {
    async findGridWrapperUid(vueInstance, gridWrapperUid, depth = 0) {
      if (!vueInstance || depth > 10) {
        return false;
      }
      if (vueInstance._uid === gridWrapperUid) {
        return true;
      } else {
        return await this.findGridWrapperUid(vueInstance.$parent, gridWrapperUid, depth + 1);
      }
    }
  }
};
