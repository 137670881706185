export const PATH_RESERVATION_LOTTERY = `/reservation-lottery`;

export function fetchReservationLottery(
  {
    fromDate,
    toDate,
    memberNameNumber,
    bsnCode,
    lotteryRceptStatusList,
    filters,
    orders,
    limit,
    offset,
  }
) {
  return this.http.post(`${PATH_RESERVATION_LOTTERY}`, {
    fromDate,
    toDate,
    memberNameNumber,
    bsnCode,
    lotteryRceptStatusList,
    filters,
    orders,
    limit,
    offset,
  });
}

export function fetchCountByRceptStatusCheck(
  {
    fromDate,
    toDate,
    memberNameNumber,
    bsnCode,
    lotteryRceptStatusList,
    lotteryRceptStatus,
    filters,
  }
) {
  return this.http.post(`${PATH_RESERVATION_LOTTERY}/count/rcept-status-check`, {
    fromDate,
    toDate,
    memberNameNumber,
    bsnCode,
    lotteryRceptStatusList,
    lotteryRceptStatus,
    filters,
  });
}

export function fetchReservationLotteryByLotteryId(lotteryId) {
  return this.http.get(`${PATH_RESERVATION_LOTTERY}/${lotteryId}`);
}

export function fetchReservationLotteryAggregates(fromDate, toDate, bsnCode) {
  return this.http.get(`${PATH_RESERVATION_LOTTERY}/aggregates`, {
    params: {
      fromDate,
      toDate,
      bsnCode,
    },
  });
}

export function reservationLotterySave(data) {
  return this.http.put(`${PATH_RESERVATION_LOTTERY}/save`, data);
}

export function reservationLotteryProvisionalAssignmentByLotteryIdAndTimeId(lotteryId, timeId) {
  return this.http.put(`${PATH_RESERVATION_LOTTERY}/provisional-assignment/${lotteryId}/${timeId}`);
}

export function reservationLotteryScoreAggregation(
  {
    fromDate,
    toDate,
    memberNameNumber,
    bsnCode,
    lotteryRceptStatusList,
    filters,
  }
) {
  return this.http.put(`${PATH_RESERVATION_LOTTERY}/score-aggregation`, {
    fromDate,
    toDate,
    memberNameNumber,
    bsnCode,
    lotteryRceptStatusList,
    filters,
  });
}

export function reservationLotteryProvisionalAssignment(
  {
    fromDate,
    toDate,
    memberNameNumber,
    bsnCode,
    lotteryRceptStatusList,
    filters,
    orders,
    limit,
    offset,
  }
) {
  return this.http.put(`${PATH_RESERVATION_LOTTERY}/provisional-assignment`, {
    fromDate,
    toDate,
    memberNameNumber,
    bsnCode,
    lotteryRceptStatusList,
    filters,
    orders,
    limit,
    offset,
  });
}

export function reservationLotteryProvisionalAssignmentCancel(
  {
    fromDate,
    toDate,
    memberNameNumber,
    bsnCode,
    lotteryRceptStatusList,
    filters,
    orders,
    limit,
    offset,
  }
) {
  return this.http.put(`${PATH_RESERVATION_LOTTERY}/provisional-assignment-cancel`, {
    fromDate,
    toDate,
    memberNameNumber,
    bsnCode,
    lotteryRceptStatusList,
    filters,
    orders,
    limit,
    offset,
  });
}

export function reservationLotteryProvisionalAssignmentReservationConfirm(
  {
    fromDate,
    toDate,
    memberNameNumber,
    bsnCode,
    lotteryRceptStatusList,
    filters,
    isSmsSend,
  }
) {
  return this.http.put(`${PATH_RESERVATION_LOTTERY}/provisional-assignment/reservation-confirm`, {
    fromDate,
    toDate,
    memberNameNumber,
    bsnCode,
    lotteryRceptStatusList,
    filters,
    isSmsSend,
  });
}

export function tgResveLotteryCmpetrtInsertByAggregates(fromDate, toDate) {
  return this.http.put(`${PATH_RESERVATION_LOTTERY}/reservation-lottery-cmpetrt-insert/${fromDate}/${toDate}`);
}