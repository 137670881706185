import Vue from "vue";

import ComponentTelephone from "@/components/ComponentTelephone";
import gridTemplateDestroyMixin from "../../../mixins/gridTemplateDestroyMixin";

export default function gridTelephoneEditTemplate(field, { maxLength = 12 }) {
  return {
    template: Vue.component("gridTelephoneEditTemplate", {
      mixins: [gridTemplateDestroyMixin],
      components: { ComponentTelephone },
      template: `
        <div style="width: calc(100% + 16px); margin: 0 -8px; line-height: 1.4; outline: none;" @click="divClicked">
          <input type="hidden" :value="value" :id="field" :name="field" />
          <component-telephone v-model="data[field]" ref="input-telephone" :max-length="maxLength" @focus="$event.target.select()" style="box-sizing: border-box; padding: 0 8px; border: none; width: 100%; height: 100%; background-color: transparent; font-size: 12px; font-weight: normal; font-family: '돋움', Dotum, Arial, Verdana, sans-serif; color: #666; line-height: inherit;"/>
        </div>`,
      data() {
        return {
          field,
          maxLength,
          data: {
            data: {},
          },
        };
      },
      computed: {
        value() {
          return this.data[field]?.replace(/[^\d*]/gi, "");
        }
      },
      // mounted() {
      //   this.divClicked();
      // },
      methods: {
        divClicked() {
          this.$refs["input-telephone"]?.focus();
          this.$refs["input-telephone"]?.click();
        },
      },
    }),
  };
}
