import request from "@/utils/request";

/**
 * @return Promise
 */
export function getSystemConfigInfo() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/info",
    method: "get",
  });
}

/**
 * @return Promise
 */
export function putSystemConfigInfo(confCom) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/info",
    method: "put",
    data: confCom,
  });
}

/**
 * @return Promise
 */
export function patchSystemConfigInfo(confCom) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/info",
    method: "patch",
    data: confCom,
  });
}

/**
 * @return Promise
 */
export function getCardCompanyInfo(useFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/card-company-info",
    method: "get",
    params: {
      useFlag,
    },
  });
}

/**
 * @return Promise
 */
export function putCardCompanyInfo(tsConfCardRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/card-company-info",
    method: "put",
    data: tsConfCardRequest,
  });
}

/**
 * @return Promise
 */
export function getAccountInfo() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/account-info",
    method: "get",
  });
}

/**
 * @return Promise
 */
export function putAccountInfo(tsConfAccountRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/account-info",
    method: "put",
    data: tsConfAccountRequest,
  });
}

/**
 * @return Promise
 */
export function getCourseHoleInfo(courseClass) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/course-hole-info",
    method: "get",
    params: {
      courseClass,
    },
  });
}

/**
 * @return Promise
 */
export function putCourseHoleInfo(tsConfCourses) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/course-hole-info",
    method: "put",
    data: tsConfCourses,
  });
}

/**
 * @return Promise
 */
export function getBlackListInfo() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/blacklist-management-info",
    method: "get",
  });
}

/**
 * @return Promise
 */
export function putBlackListInfo(tsConfPenltyRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/blacklist-management-info",
    method: "put",
    data: tsConfPenltyRequest,
  });
}

/**
 * @return Promise
 */
export function getCalenderHolidayInfo() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/calendar-holiday-info",
    method: "get",
  });
}

/**
 * @return Promise
 */
export function putCalenderHolidayInfo(calenderConf) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/calendar-holiday-info",
    method: "put",
    data: calenderConf,
  });
}

/**
 * @return Promise
 */
export function getClosingCheckInfo(useFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/daily-closing-check-info",
    method: "get",
    params: {
      useFlag,
    },
  });
}

/**
 * @return Promise
 */
export function putClosingCheckInfo(dailyCloseCheckListRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/daily-closing-check-info",
    method: "put",
    data: dailyCloseCheckListRequest,
  });
}

/**
 * @return Promise
 */
export function getPaymentLineInfo(useFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/payment-line-info",
    method: "get",
    params: {
      useFlag,
    },
  });
}

/**
 * @return Promise
 */
export function putPaymentLineInfo(tsConfSanctnRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/payment-line-info",
    method: "put",
    data: tsConfSanctnRequest,
  });
}

/**
 * @return Promise
 */
export function getGroupReceptConfiguration() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/grp-recept-info",
    method: "get",
  });
}

/**
 * @return Promise
 */
export function putGroupReceptConfiguration(tsConfGrpRceptRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/system-code/grp-recept-info",
    method: "put",
    data: tsConfGrpRceptRequest,
  });
}

/**
 * @return Promise
 */
export function getSmsLastInfo(useFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/sms-close-info",
    method: "get",
    params: {
      useFlag,
    },
  });
}

/**
 * @return Promise
 */
export function putSmsLastInfo(tsConfSmsCloseRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/sms-close-info",
    method: "put",
    data: tsConfSmsCloseRequest,
  });
}

/**
 * @return Promise
 */
export function getSmsWordInfo() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/conf-sms-words",
    method: "get",
  });
}

/**
 * @return Promise
 */
export function putSmsWordInfo(smsWordsRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/conf-sms-words",
    method: "put",
    data: smsWordsRequest,
  });
}

/**
 * @return Promise
 */
export function getKakaoSendKeyInfo() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/kakao-sendkey-info",
    method: "get",
  });
}

/**
 * @return Promise
 */
export function putKakaoSendKeyInfo(tsConfKakaoTemplateList) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/kakao-sendkey-info",
    method: "put",
    data: tsConfKakaoTemplateList,
  });
}