export const PATH_LOG = '/transfer-of-name';

/**
 * 명의개서 신청/현황 - 조회
 */
export function getNameChangeApplicationConfirmation(args) {
    return  this.http.get(`${PATH_LOG}/name-change-application-confirmation`, {
        params: {
            renameDateFrom : args.changedDateFrom,
            renameDateTo :  args.changedDateTo,
            searchMemberNameNo : args.searchName,
            isConfirm : args.status ? args.status === "true"  : "",
            renameDiv: args.renameDiv,
        },
    });
}

export function getNameChangeApplicationConfirmationDetails(renameRequestId) {
    return  this.http.get(`${PATH_LOG}/name-change-application-confirmation/detail`, {
        params: {
            renameRequestId
        },
    });
}

export function patchNameChangeApplicationConfirmationDetail(args) {
    return this.http.patch(`${PATH_LOG}/name-change-application-confirmation/detail`,args);
}
export function deleteNameChangeApplicationConfirmationDetail(args) {
    return this.http.delete(`${PATH_LOG}/name-change-application-confirmation/delete`, {
        data: args,
    });
}

export function confirmNameChangeApplicationConfirmation(args) {
    return this.http.patch(`${PATH_LOG}/name-change-application-confirmation/confirm`,args);
}

export function cancelNameChangeApplicationConfirmation(args) {
    return this.http.patch(`${PATH_LOG}/name-change-application-confirmation/cancel`,args);
}
// 명의개서 회원권 압류여부 체크
export function getSeizureCheck(args) {
    return this.http.get(`${PATH_LOG}/name-change-application-confirmation/seizure`, {
        params: {...args},
    });
}

export function getMembershipChangeStatusIndividualCorporate(args) {
    return  this.http.get(`${PATH_LOG}/membership-change-status/individual-corporate`, {
        params: {...args},
    });
}
export function getMembershipChangeStatus(args) {
    return this.http.get(`${PATH_LOG}/membership-change-status`, {
        params: {...args},
    });
}
export function getMembershipChangeReportList(args) {
    return this.http.get(`${PATH_LOG}/report`, {
        params: {...args},
    });
}
export function saveMembershipChangeReportList(args) {
    return this.http.post(`${PATH_LOG}/report`, args);
}
// 명의변경 전산매체신고 - 팝업 - 조회
export function getGeneratedReportFiles(args) {
    return this.http.get(`${PATH_LOG}/report/basic`, {
        params: {...args},
    });
}
// 명의변경 전산매체신고 - 팝업 - 신고파일 생성을 위한 데이터 조회
export function getDataForReportGeneration(args) {
    return this.http.get(`${PATH_LOG}/report/data`, {
        params: {...args},
    });
}
// 명의변경 전산매체신고 - 팝업 - 기초자료 수정
export function patchReportBasic(args) {
    return this.http.patch(`${PATH_LOG}/report/basic`, args);
}
// 명의개서 회원권 압류 - 조회
export function getSeizureList(args) {
    return this.http.get(`${PATH_LOG}/seizure`, {
        params: {...args},
    });
}
// 명의개서 회원권 압류 - 등록/수정
export function patchSeizure(args) {
    return this.http.patch(`${PATH_LOG}/seizure`, args);
}
// 명의개서 회원권 압류 - 상세
export function getSeizureDetail(args) {
    return this.http.get(`${PATH_LOG}/seizure/detail`, {
        params: {...args},
    });
}
// 회원권 압류 조회(회원권ID)
export function fetchMembershipSeizure(membershipId) {
    return this.http.get(`${PATH_LOG}/seizure/membership-id`, {
        params: {
            membershipId
        },
    });
}
// 명의개서 회원권 압류 - 삭제
export function deleteSeizure(seizrId) {
    return this.http.delete(`${PATH_LOG}/seizure/${seizrId}`);
}
// 명의개서 회원권 현황 - 조회
export function getMembershipHistory(args) {
    return this.http.get(`${PATH_LOG}`, {
        params: {...args},
    });
}
// 명의개서 맥시멈 회원번호 중간 4자리 조회
export function getMiddleMembershipNumber(args) {
    return this.http.get(`${PATH_LOG}/name-change-application-confirmation/next-member-no`, {
        params: {...args},
    });
}
