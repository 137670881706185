import { isEqual } from "lodash";

export function isUndefinedOrNull(value) {
  return value === undefined || value === null;
}

export function deepEquals(value, other) {
  return isEqual(value, other);
}

export function deepDiffs(value, other) {
  const changes = {};

  const originKeys = Object.getOwnPropertyNames(value);
  const otherKeys = Object.getOwnPropertyNames(other);

  for (const key of [...originKeys, ...otherKeys]) {
    if (key === "__ob__") {
      continue;
    }

    if (value[key] && typeof value[key] === "object") {
      if (!Array.isArray(value[key])) {
        const diffs = deepDiffs(value[key] || {}, other[key] || {});
        if (diffs) {
          changes[key] = diffs;
        }
        continue;
      }
    }

    if (!deepEquals(value[key], other[key])) {
      changes[key] = other[key];
    }
  }

  if (0 < Object.getOwnPropertyNames(changes).length) {
    return changes;
  }
}

export function compactObjectValues(
  object,
  isTrimWhitespaces = false,
  allowEmptyString = false
) {
  if (object === undefined || object === null) {
    return undefined;
  }

  if (typeof object === "string") {
    const str = isTrimWhitespaces ? object.trim() : object;
    if (!allowEmptyString && str === "") {
      return undefined;
    } else {
      return str;
    }
  }

  if (Array.isArray(object)) {
    return object.reduce((prev, el) => {
      const compacted = compactObjectValues(
        el,
        isTrimWhitespaces,
        allowEmptyString
      );
      if (compacted !== undefined) {
        prev.push(compacted);
      }

      return prev;
    }, []);
  }

  if (typeof object === "object") {
    return Object.getOwnPropertyNames(object).reduce((prev, key) => {
      if (key !== "__ob__" && Object.prototype.hasOwnProperty.call(object, key)) {
        const compacted = compactObjectValues(
          object[key],
          isTrimWhitespaces,
          allowEmptyString
        );
        if (compacted !== undefined) {
          if (!prev) {
            const returnObject = {};
            returnObject[key] = compacted;

            return returnObject;
          } else {
            prev[key] = compacted;
          }
        }
      }

      return prev;
    }, undefined);
  }

  return object;
}
