import qs from "qs";

export const PATH_MILEAGE = `/mileage`;

export function fetchMileageByMileageId(mileageId) {
  return this.http.get(`${PATH_MILEAGE}/${mileageId}`);
}

export function fetchMileageAccrualUsageStatus(args) {
  return this.http.get(`${PATH_MILEAGE}/accrual-usage-status`, {
    params: args,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    }
  });
}

export function patchMileage(data) {
  return this.http.patch(`${PATH_MILEAGE}`, data);
}

export function deleteMileage(args) {
  return this.http.delete(`${PATH_MILEAGE}`, {
    params: args
  });
}