export const PATH_GROUPS = '/groups';

export function fetchGroupHistory(grpNo) {
  return this.http.get(`${PATH_GROUPS}/history`, {
    params: {
      grpNo
    }
  });
}

export function fetchGroupInUse(bsnDateFrom, bsnDateTo, grpNo) {
  return this.http.get(`${PATH_GROUPS}/in-use`, {
    params: {
      bsnDateFrom,
      bsnDateTo,
      grpNo,
    },
  });
}