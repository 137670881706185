import { getMemos } from "@/api/common";

const memoView = {
  namespaced: true,
  state: {
    memoViews: null,
    resveId: null,
    memoResveId: null,
    stayId: null,
    memoStayId: null,
    currentView: null,
    memoInfo: null,
  },
  mutations: {
    SET_CURRENT_VIEW: (state, view) => {
      state.currentView = view;
    },
    SET_MEMO_VIEW: (state, memo) => {
      state.memoViews = memo;
    },
    SET_MEMO_INFO: (state, info) => {
      if (info.isGrp !== null || info.isGrp instanceof Number) {
        info.isGrp = Boolean(info.isGrp);
      }
      //if(info.isGallery !== null || info.isGallery instanceof Number){
      //  info.isGallery = Boolean(info.isGallery);
      //}
      state.memoInfo = info;
    },
    SET_MEMO_RESVE_ID: (state, resveId) => {
      state.memoResveId = resveId;
      state.resveId = null;
    },
    SET_RESVE_ID: (state, resveId) => {
      state.resveId = resveId;
      if (resveId === null) {
        state.memoResveId = null;
      }
    },
    SET_MEMO_STAY_ID: (state, stayId) => {
      state.memoStayId = stayId;
      state.stayId = null;
    },
    SET_STAY_ID: (state, stayId) => {
      state.stayId = stayId;
      if (stayId === null) {
        state.memoStayId = null;
      }
    },
    CLEAR_MEMO_DATA: (state) => {
      state.memoViews = null;
      state.resveId = null;
      state.memoResveId = null;
      state.memoInfo = null;
    },
  },
  actions: {
    clearMemo({ commit }) {
      commit("CLEAR_MEMO_DATA");
    },
    setCurrentView({ commit }, currentView) {
      commit("SET_CURRENT_VIEW", currentView);
    },
    setStayId({ dispatch, commit }, stayId) {
      return new Promise((resolve, reject) => {
        if (stayId) {
          commit("SET_STAY_ID", stayId);
          dispatch("getMemoListByStayId", stayId)
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        } else {
          commit("SET_STAY_ID", null);
          reject();
        }
      });
    },
    setResveId({ dispatch, commit }, resveId) {
      return new Promise((resolve, reject) => {
        if (resveId) {
          commit("SET_RESVE_ID", resveId);
          dispatch("getMemoList", resveId)
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        } else {
          commit("SET_RESVE_ID", null);
          reject();
        }
      });
    },
    setResveData({ dispatch, commit }, resveId) {
      return new Promise((resolve, reject) => {
        commit("SET_RESVE_ID", resveId);
        dispatch("getMemoList", resveId)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    getMemoList({ commit, state, dispatch }, resveId) {
      return new Promise((resolve, reject) => {
        if (resveId === null) {
          commit("SET_RESVE_ID", null);
          reject(resveId);
        } else {
          getMemos(resveId, state.currentView, null)
            .then((result) => {
              const {
                value: { memoList, memoInfo },
              } = result;
              commit("SET_MEMO_VIEW", memoList);
              commit("SET_MEMO_INFO", memoInfo);
              commit("SET_MEMO_RESVE_ID", resveId);
              dispatch("fcm/setIsNewMessage", false, { root: true });
              /*
              2022-09-27 :
              단순히 memo를 사용하지 않아서 빈 Array가 들어왔을때도 reject 처리를 하여서,
              정상적인 요청과 응답임에도 불구하고 Error로 잡힘. 기존에 있던 조건문 삭제 후 메모기능 테스트 결과, 이상 없음.
               */
              resolve(resveId);
            })
            .catch(() => {
              reject(resveId);
            });
        }
      });
    },
    getMemoListByStayId({ commit, state, dispatch }, stayId) {
      return new Promise((resolve, reject) => {
        if (stayId === null) {
          commit("SET_STAY_ID", null);
          reject(stayId);
        } else {
          getMemos(null, state.currentView, stayId)
            .then((result) => {
              const {
                value: { memoList, memoInfo },
              } = result;
              commit("SET_MEMO_VIEW", memoList);
              commit("SET_MEMO_INFO", memoInfo);
              commit("SET_MEMO_STAY_ID", stayId);
              dispatch("fcm/setIsNewMessage", false, { root: true });
              /*
              2022-09-27 :
              단순히 memo를 사용하지 않아서 빈 Array가 들어왔을때도 reject 처리를 하여서,
              정상적인 요청과 응답임에도 불구하고 Error로 잡힘. 기존에 있던 조건문 삭제 후 메모기능 테스트 결과, 이상 없음.
               */
              resolve(stayId);
            })
            .catch(() => {
              reject(stayId);
            });
        }
      });
    },
    refreshByFcm({ dispatch, state }, data) {
      const resveIdList = JSON.parse(data.resveId);
      if (
        resveIdList.includes(state.memoResveId) &&
        (data.recptnDept === state.currentView ||
          data.sendDept === state.currentView ||
          data.recptnDept === "%")
      ) {
        dispatch("refreshMemoView");
      }
    },
    refreshMemoView({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        if (state.memoResveId) {
          dispatch("getMemoList", state.memoResveId)
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        }
        if (state.memoStayId) {
          dispatch("getMemoListByStayId", state.memoStayId)
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        }
      });
    },
  },
};

export default memoView;
