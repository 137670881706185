import layout from "@/views/layout/layout";

const creditManagementRoute = {
  path: "/credit-management",
  component: layout,
  redirect: "/credit-management/deposit-registration",
  children: [
    {
      path: "credit-return-registration",
      name: "CreditReturnRegistration",
      component: () =>
        import("@/views/credit-management/CreditReturnRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "credit-return-report",
      name: "CreditReturnReport",
      component: () =>
        import("@/views/credit-management/CreditReturnReport"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "deposit-registration",
      name: "DepositRegistration",
      component: () => import("@/views/credit-management/DepositRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "credit-card-reports",
      name: "CreditCardReports",
      component: () => import("@/views/credit-management/CreditCardReports"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "cash-receipts-reports",
      name: "CashReceiptsReports",
      component: () => import("@/views/credit-management/CashReceiptsReports"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "deposit-incom-status",
      name: "DepositIncomStatus",
      component: () => import("@/views/credit-management/DepositIncomStatus"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "deposit-use-status",
      name: "DepositUseStatus",
      component: () => import("@/views/credit-management/DepositUseStatus"),
      meta: {
        useUsualStyles: true,
      },
    },
  ],
};

export default creditManagementRoute;
