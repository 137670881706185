export const PATH_WEATHER = `/weather`;

export function fetchWeatherInfo(args) {
  return this.http.get(`${PATH_WEATHER}/${args.bsnDate}`);
}

export function patchWeather(args) {
  return this.http.patch(`${PATH_WEATHER}/${args.bsnDate}`, args);
}

export function deleteWeather(args) {
  return this.http.delete(`${PATH_WEATHER}/${args.bsnDate}`);
}
