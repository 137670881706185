export const PATH_PRODUCTS = `/products`;

export function fetchProductPriceUnits(productCode, salesDate, storeCode) {
  return this.http.get(`${PATH_PRODUCTS}/${productCode}/price-units`, {
    params: {
      salesDate,
      storeCode,
    },
  });
}
