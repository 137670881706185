<template>
  <div class="sideLeft-search">
    <!-- 활성(Class) : active -->
    <div
      class="search"
      :class="menuSearchListClassObject"
      @focusout="onMenuSearchKeywordFocusOut"
    >
      <div class="value">
        <input
          type="text"
          ref="menuSearchKeyword"
          placeholder="메뉴 검색"
          spellcheck="false"
          v-on:input="menuSearchKeyword = $event.target.value"
          @keyup="onMenuSearchKeywordKeyUp"
          @keydown="onMenuSearchKeywordKeyDown"
        />
        <erp-button
          button-div="CLOSE"
          :use-syncfusion-component-style="false"
        >
          검색
        </erp-button>
      </div>
      <ul
        class="list"
        @mouseenter="onSearchListMouseEnter"
        @mouseleave="onSearchListMouseLeave"
      >
        <li
          v-for="(menu, menuIdx) in menuSearchList"
          :key="menu.menuId"
          @click="onMenuSearchClicked($event, menu.menuId)"
          :class="menuSelectClassObject(menuIdx)"
        >
          {{ menu.menuName }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "menuSearchComboBox",
  components: {
    ErpButton,
  },
  computed: {
    ...mapGetters(["menuListByKeyword"]),
    menuSearchListClassObject() {
      return {
        active: this.menuSearchList && this.menuSearchList.length > 0,
      };
    },
    menuSelectClassObject() {
      return (idx) => ({
        "dev-menu-search-highlight": idx === this.menuSearchSelectIdx,
      });
    },
  },
  data() {
    return {
      menuSearchKeyword: null,
      menuSearchSelectIdx: -1,
      menuSearchList: [],
      menuSearchListMouseEntered: false,
    };
  },
  methods: {
    onMenuSearchKeywordKeyUp() {
      if (this.menuSearchKeyword && this.menuSearchKeyword.trim()) {
        this.menuSearchList = this.menuListByKeyword(this.menuSearchKeyword).filter(item => !item.authMenuFlag);
      } else {
        this.initMenuSearch();
      }
    },
    onMenuSearchKeywordKeyDown(e) {
      const menuSearchListLength = this.menuSearchList.length;
      if (menuSearchListLength === 0) {
        return;
      }
      switch (e.key) {
        case "ArrowDown":
          if (this.menuSearchSelectIdx < menuSearchListLength - 1) {
            this.menuSearchSelectIdx++;
          }
          this.$refs.menuSearchKeyword.value = this.menuSearchList[
            this.menuSearchSelectIdx
          ].menuName;
          break;
        case "ArrowUp":
          if (this.menuSearchSelectIdx > 0) {
            this.menuSearchSelectIdx--;
          }
          this.$refs.menuSearchKeyword.value = this.menuSearchList[
            this.menuSearchSelectIdx
          ].menuName;
          break;
        case "Enter":
          this.$emit(
            "openNewMenu",
            this.menuSearchList[this.menuSearchSelectIdx].menuId.toString()
          );
          // this.openNewMenu(this.menuSearchList[this.menuSearchSelectIdx].menuId.toString());

          // 초기화
          this.initMenuSearch();
          break;
        case "Escape":
          // 초기화
          this.initMenuSearch();
          break;
        default:
          break;
      }
    },
    onMenuSearchKeywordFocusOut() {
      // 초기화
      if (!this.menuSearchListMouseEntered) {
        // 메뉴찾기 - 메뉴를 클릭한 경우를 체크하기 위함
        this.initMenuSearch();
      }
    },
    onMenuSearchClicked(e, id) {
      // this.openNewMenu(id.toString());
      this.$emit("openNewMenu", id.toString());

      // 초기화
      this.initMenuSearch();
    },
    initMenuSearch() {
      this.$refs.menuSearchKeyword.value = null;
      this.menuSearchKeyword = null;
      this.menuSearchList = [];
      this.menuSearchSelectIdx = -1;
    },
    onSearchListMouseEnter() {
      this.menuSearchListMouseEntered = true;
    },
    onSearchListMouseLeave() {
      this.menuSearchListMouseEntered = false;
    },
  },
};
</script>
