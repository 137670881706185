import layout from "@/views/layout/layout";

const logManagementRouter = {
  path: "/log-management",
  component: layout,
  redirect: "/log-management/user-login-hist",
  children: [
    {
      path: "user-login-hist",
      component: () => import("@/views/log-management/UserLoginHist"),
      name: "UserLoginHist",
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "program-use-hist",
      component: () => import("@/views/log-management/ProgramUseHist"),
      name: "ProgramUseHist",
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "program-button-use-hist",
      component: () => import("@/views/log-management/ProgramButtonUseHist"),
      name: "ProgramButtonUseHist",
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "homepage-password-change-hist",
      component: () => import("@/views/log-management/HomePagePasswordChangeHist"),
      name: "HomePagePasswordChangeHist",
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "erp-password-change-hist",
      component: () => import("@/views/log-management/ErpPasswordChangeHist"),
      name: "ErpPasswordChangeHist",
      meta: {
        useUsualStyles: true,
      },
    },
  ],
};

export default logManagementRouter;
