<template>
  <aside class="appSideLeft">
    <h1 class="sideLeft-ci">
      <router-link to="/">
        <img
          src="../../../assets/images/layout/sideLeft-ci-greenit.png"
          @click="menuSelect('dashboard')"
        />
      </router-link>
    </h1>
    <menu-search-combo-box @openNewMenu="onOpenNewMenu" />
    <nav class="sideLeft-navigation">
      <ejs-treeview
        ref="menuTree"
        :fields="menuTreeFields"
        :expandOn="'Click'"
        :nodeTemplate="treeTemplate"
        :selectedNodes="menuTreeSelectedNodes"
        @nodeSelected="treeViewNodeSelected($event)"
        @dataBound="treeViewNodeDataBound"
      >
      </ejs-treeview>
    </nav>
  </aside>
</template>

<script>
import store from "@/store";

import { menuUserMarkMenuId } from "@/store";
import treeTemplateVue from "./appSideLeftMenuTemplate";

import MenuSearchComboBox from "./menuSearchComboBox";
import {mapActions, mapGetters} from "vuex";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getCurrentBizCode} from '@/utils/pageKeyUtil';
import commonMixin from "@/views/layout/mixin/commonMixin";

export default {
  name: "appSideLeft",
  components: {
    MenuSearchComboBox,
  },
  mixins: [commonMixin],
  created() {
    this.$EventBus.$on("leftMenuReload", this.leftMenuReload);
    this.$EventBus.$on("menuTreeMenuClicked", this.onMenuTreeClicked); // appSideLeftMenuTemplate 에서 메뉴 클릭 시 발생
  },
  mounted() {
    this.leftMenuReload();
  },
  beforeDestroy() {
    this.$EventBus.$off("leftMenuReload");
    this.$EventBus.$off("menuTreeMenuClicked");
  },
  watch: {
    $route(to) {
      this.menuSelect(to.name, to.query.menuId);
    },
  },
  computed: {
    menuTreeSelectedNodes() {
      return this.menuTreeSelectedNodesData;
    },
    ...mapGetters(["userIp"]),
    ...mapGetters(["userId"])
  },
  data() {
    return {
      menuTreeSelectedNodesData: [],
      item_data: "",
      previous_data: null,
      // ejs-treeview의 Field를 설정.
      menuTreeFields: {},
      // ejs-treeview를 Template으로 표현하기 위한 데이터.
      treeTemplate() {
        return {
          template: treeTemplateVue,
        };
      },
    };
  },
  methods: {
    ...mapActions("memoView", ["clearMemo"]),
    ...mapActions(["clearLoaderProgress"]),
    expandRecursive(parentId) {
      const menuList = store.getters.menuList.filter(item => !item.authMenuFlag);
      const parentMenu = menuList.find((menu) => menu.menuId === parentId);

      if (parentMenu.parentId) {
        this.expandRecursive(parentMenu.parentId);
      }

      if (parentId) {
        this.$refs.menuTree.expandAll([parentId.toString()]);
      }
    },
    menuSelect(routerName, menuId) {
      const menuList = store.getters.menuList.filter(item => !item.authMenuFlag);
      const selectedMenu = menuList.find(
        (menu) =>
          menu.routerName === routerName &&
          (menuId ? menu.menuId.toString() === menuId : true)
      ); // menuId 값이 있으면 체크

      // 즐겨찾기 추가하여 menuSelect 하는 경우에는 즐겨찾기 펼침
      if (this.menuTreeFields.isAdded) {
        this.$refs.menuTree.expandAll([menuUserMarkMenuId]);
      }

      // 선택한 node expand
      if (selectedMenu && selectedMenu.parentId) {
        // expand test --> 재귀 호출로
        this.expandRecursive(selectedMenu.parentId);
        this.menuTreeSelectedNodesData = [selectedMenu.menuId];
      } else if (selectedMenu) {
        this.menuTreeSelectedNodesData = [selectedMenu.menuId];
      } else {
        this.menuTreeSelectedNodesData = [];
      }
    },
    treeViewNodeSelected() {
      /*if (event.isInteracted) { // 마우스로 선택하여 이동한 경우에만 아래와 같이 처리
        const id = event.nodeData.id;
        this.openNewMenu(id);
      }*/
    },
    onOpenNewMenu(id) {
      this.openNewMenu(id);
    },
    openNewMenu(id) {
      const menuItem = store.getters.menuList.filter(item => !item.authMenuFlag).find(
        ({ menuId }) => `${menuId}` === `${id}`
      );
      if (menuItem) {
        const { isOnNewTab, routerName } = menuItem;
        if (isOnNewTab) {
          if (this.$router.mode === "hash") {
            window.open(
              `${location.origin}/#${
                this.$router.options.routes.find(
                  ({ name }) => name === routerName
                ).path
              }`,
              "_blank"
            );
          } else {
            window.open(
              this.$router.options.routes.find(
                ({ name }) => name === routerName
              ).path,
              "_blank"
            );
          }
        } else {
          // 메뉴 ID가 페이지 이동 URL과 동일할 경우 적용 방법.
          const data = this.$refs.menuTree.getTreeData(id);

          // 페이지 이동 유무를 별도의 Data로 가지고 있어야 함.
          const isSamePage = data[0].routerName === this.$route.name;
          if (data[0].menuFlag && !isSamePage) {
            this.movePageTo(data[0].routerName, id);
          }
        }

      }
      // 프로그램 사용 이력 데이터 생성
        let programId = '';
        let find = this.$router.options.routes;
        find.forEach(o => {
            if (o.children){
                o.children.forEach(c => {
                    if (c.name === menuItem.routerName){
                        programId = o.path + "/" + c.path + "?menuId=" + id;
                    }
                });
            }
        });
        this.createProgramHist(menuItem, programId);

    },
    async createProgramHist(menuItem, programId) {
      const menuId = this.commonMixinGetMenuId();
      let params = {
          bizCode : getCurrentBizCode(),
          userId : this.userId,
          pgId : programId,
          menuId : menuId,
          menuName : menuItem.menuName,
          description : menuItem.description,
          insertId : this.userId
      };
      await GolfErpAPI.createProgramUseHist(params);
    } ,
    treeViewNodeDataBound() {
      this.menuSelect(this.$route.name, this.$route.query.menuId);
    },
    async movePageTo(viewName, menuId) {
      await this.clearLoaderProgress();
      await this.$router.push({
        name: viewName,
        query: {
          menuId: menuId,
        },
      });
    },
    leftMenuReload(isAdded) {
      const menuList = store.getters.menuList.filter(item => !item.authMenuFlag);

      this.menuTreeFields = {
        dataSource: menuList,
        id: "menuId",
        parentID: "parentId",
        text: "menuName",
        hasChildren: "chldrnExstFlag",
        iconCss: "menuIcon",
        htmlAttributes: "htmlAttributes",
        isAdded: isAdded, // 즐겨찾기 추가되면 true로 설정
      };
    },
    onMenuTreeClicked(menuId) {
      this.clearMemo();
      this.openNewMenu(menuId);
    },
  },
};
</script>
