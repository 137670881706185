export const PATH_AUTH = "/auth";
export const PATH_AUTH_ISSUE = `${PATH_AUTH}/issue`;
export const PATH_AUTH_WITHDRAW = `${PATH_AUTH}/withdraw`;
export const PATH_AUTH_CHANGE_PASSWORD = `${PATH_AUTH}/password`;

export function issueAuthorization(email, password) {
  return this.http.post(PATH_AUTH_ISSUE, { email, password });
}

export function removeAuthorization() {
  return this.http.delete(PATH_AUTH_WITHDRAW);
}

export function updatePassword(oldPassword, newPassword) {
  return this.http.put(PATH_AUTH_CHANGE_PASSWORD, { oldPassword, newPassword });
}

export function fetchAuthorities() {
  return this.http.get(PATH_AUTH);
}
export function putAuthGroups(args) {
  return this.http.put(`${PATH_AUTH}/group`,args);
}

export function fetchAuthorityGroups(q) {
  return this.http.get(`${PATH_AUTH}/groups`, {
    params: {
      q,
    },
  });
}
export function getGroupAuth(groupId) {
  return this.http.get(`${PATH_AUTH}/group/${groupId}`);
}
export function fetchAuthGroupsBySearchContents(searchContents) {
  return this.http.get(`${PATH_AUTH}/groups/searchContents`, {
    params: {
      searchContents: searchContents,
    },
  });
}

export function fetchAuthorityGroupAuthorities(groupId) {
  return this.http.get(`${PATH_AUTH}/groups/${groupId}/authorizations`);
}

export function fetchAuthorityGroupUsers(groupId) {
  return this.http.get(`${PATH_AUTH}/groups/${groupId}/users`);
}

export function createAuthorityGroup(id, name, description, order) {
  return this.http.post(`${PATH_AUTH}/groups`, {
    id,
    name,
    description,
    order,
  });
}

export function updateAuthorityGroup(groupId, name, description, order) {
  return this.http.patch(`${PATH_AUTH}/groups/${groupId}`, {
    name,
    description,
    order,
  });
}

export function updateAuthorityGroupAuthorities(groupId, authorizations) {
  return this.http.put(
    `${PATH_AUTH}/groups/${groupId}/authorizations`,
    authorizations
  );
}

export function updateAuthorityGroupUsers(groupId, users) {
  return this.http.put(`${PATH_AUTH}/groups/${groupId}/users`, users);
}

export function deleteAuthorityGroup(groupId) {
  return this.http.delete(`${PATH_AUTH}/groups/${groupId}`);
}

export function getMenuList(highMenuId, middleMenuId){
  return this.http.get(`${PATH_AUTH}/menu`,{
   params: {
    highMenuId : highMenuId,
    middleMenuId : middleMenuId,
   } 
  });
}

export function getMenu(menuId){
  return this.http.get(`${PATH_AUTH}/menu/${menuId}`);
}

export function saveMenu(args){
  return this.http.post(`${PATH_AUTH}/menu`, args);
}

export function patchMenu(menuId , args){
  return this.http.patch(`${PATH_AUTH}/menu/${menuId}`, args);

}
export function deleteMenu(menuId){
  return this.http.delete(`${PATH_AUTH}/menu/${menuId}`);
}

export function moveMenu(firstMenuId, lastMenuId){
  return this.http.patch(`${PATH_AUTH}/menu`,{
    firstMenuId,
    lastMenuId,
  });
}
