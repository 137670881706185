<template>
  <div>
    <ejs-dialog
        ref="dialog"
        header="지정 락카"
        width="900"
        height="auto"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        :close="onClickClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <div class="article-section" style="height: auto;">
                  <div class="section-body" style="border: none;">
                    <div class="article-right" style="width: 100%;">
                      <div class="body-grid">
                        <ejs-grid-wrapper
                            ref="grid"
                            v-bind ="initGrid"
                            @onGridDialogDoubleClickedOrEnterKeyed="onDoubleClickedOrEnterKeyed"
                            @queryCellInfo="onQueryCellInfo"
                            @headerCellInfo="onHeaderCellInfo"
                            @actionComplete="gridComplete"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="onClickConfirm">확인</erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="onClickClose">닫기</erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import {gridUtilGetTelColumnAccess} from "@/utils/gridUtil";
import {getFormattedDate} from "@/utils/date";

export default {
  name: "appnLockerListPopup",
  mixins: [confirmDialogMixin],
  components: {
    ejsGridWrapper,
    ErpButton,
  },

  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
    // 콤보 갖고오기....
  },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      popupData: {},
      gridField: {
        title: "지정 락카",
        count : 0,
        dataSource: [],
      },
      //드롭다운 코드
      commonCodeOptions: {
        fields: { text: "comName", value: "comCode" },
      },
    };
  },

  async mounted() {
  },

  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    //그리드 세팅
    initGrid(){
      return{
        columns:  [
          {field: 'bsnDate',        textAlign: 'center',    width: 90,   visible: true,   allowEditing : false,  type: 'string',   headerText: '일자', valueAccessor: (field,data) => getFormattedDate(data[field])  },
          {field: 'lockerNo',       textAlign: 'center',    width: 70,   visible: true,   allowEditing : false,  type: 'string',   headerText: '락카번호'  },
          {field: 'lockerPrtNo',    textAlign: 'center',    width: 70,   visible: true,   allowEditing : false,  type: 'string',   headerText: '락카출력번호' },
          {field: 'lockerDiv',      textAlign: 'center',    width: 50,   visible: true,   allowEditing : false,  type: 'string',   headerText: '성별', isCommonCodeField: true, groupCode: "SEX_CODE"  },
          {field: 'sector',         textAlign: 'center',    width: 50,   visible: true,   allowEditing : false,  type: 'string',   headerText: '섹터' },
          {field: 'appnLocker',     textAlign: 'center',    width: 100,  visible: true,   allowEditing : false,  type: 'string',   headerText: '지정자'},
          {field: 'appnMemberId',   textAlign: 'center',    width: 100,  visible: true,   allowEditing : false,  type: 'string',   headerText: '지정회원ID'  },
          {field: 'appnHpNo',       textAlign: 'center',    width: 100,  visible: true,   allowEditing : false,  type: 'string',   headerText: '연락처',  valueAccessor: (field, data) => gridUtilGetTelColumnAccess(field, data),  },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: false,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        isInPopup:true,
        pageSettings: { pageSize: 10 },
      };
    },
  },

  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //region ******************************* 버튼 이벤트 *******************************
    //팝업 초기값 세팅
    async showPopup(popupData  = {}, appnList= []){
      this.popupData = {...popupData};
      this.gridField.dataSource = appnList;
    },
    //팝업 닫기
    onClickClose(){
      this.$emit('popupClosed', null);
    },
    //데이터 확정
    onClickConfirm(){
      const selRowData = this.$refs.grid.getSelectedRecords();
      if(selRowData?.length === 0){
        this.errorToast("선택된 지정락카가 없습니다.");
      }else{
        this.popupData.appnLocker = selRowData[0].appnLocker;
        this.popupData.sexCode = selRowData[0].lockerDiv;
        this.popupData.lockerNo = selRowData[0].lockerNo;
        this.popupData.lockerPrtNo = selRowData[0].lockerPrtNo;
        this.$emit("popupConfirmed",  this.popupData);
      }
    },
    //endregion

    //region ******************************* 그리드 이벤트 *******************************
    //그리드 헤더 이미지적용
    onHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      if(this.$refs.grid.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    //그리드 셀 이미지 적용
    onQueryCellInfo(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
      if(this.gridField.count > 0){
        this.$refs.grid.selectRow(0);
      }
    },
    //더블클릭 및 엔터
    onDoubleClickedOrEnterKeyed(){
      this.onClickConfirm();
    },
    //endregion
  },
};
</script>
<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>

</style>
