import layout from "@/views/layout/layout";

const roomReservationManagementRouter = {
    path: "/room-reservation-management",
    component: layout,
    redirect: "room-reservation-management/standard-information/room-calendar-registration",
    children: [
        {
            path: "standard-information/room-calendar-registration",
            name: "RoomCalendarRegistration",
            component: () =>
                import("@/views/room-reservation-management/standard-information/RoomCalendarRegistration"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "standard-information/create-available-room",
            name: "CreateAvailableRoom",
            component: () =>
                import("@/views/room-reservation-management/standard-information/CreateAvailableRoom"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "standard-information/room-rate-registration",
            name: "RoomRateRegistration",
            component: () =>
                import("@/views/room-reservation-management/standard-information/RoomRateRegistration"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "room-reservation/room-reservation-registration",
            name: "RoomReservationRegistration",
            component: () =>
              import("@/views/room-reservation-management/room-reservation/RoomReservationRegistration"),
            meta: {
                enableMemo: true,
                currentView: 'ROOM',
                useUsualStyles: true,
            },
        },
    ],
};
export default roomReservationManagementRouter ;
