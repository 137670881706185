import qs from "qs";

export const PATH_MEMBER_SEARCH = `/member-search`;

export function fetchMemberReservationVisitSearch(args) {
  return this.http.get(`${PATH_MEMBER_SEARCH}/reservation-and-visit`, {
    params: {
      // bsnDate는 필수
      // membershipId와 memberName 둘 중 하나는 필수.
      membershipId: args.membershipId,
      bsnDate: args.bsnDate,
      courseCode: args.courseCode,
      bsnCode: args.bsnCode,
      partDiv: args.partDiv,
      dwCode: args.dwCode,
      memberDiv: args.memberDiv,
      grpName: args.grpName,
      contactTelIdx: args.contactTelIdx,
      resveRemarks: args.resveRemarks,
      memberName: args.memberName,
      contactTel: args.contactTel,
      isLinkMember: args.isLinkMember,
      bsnDateTo: args.bsnDateTo,
      visitTimeFrom: args.visitTimeFrom,
      visitTimeTo: args.visitTimeTo,
      searchDiv: args.searchDiv,
      isLimit: args.isLimit,
      isMemberInfoView: args.isMemberInfoView,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}
