import { stringify as qs_stringify } from "qs";

export const PATH_MEMBER_GRADE_MANAGEMENT = `/member-grade-management`;

export function fetchMemberGradeManagements(args) {
  return this.http.get(`${PATH_MEMBER_GRADE_MANAGEMENT}/members`, {
    params: {
      bsnDateFrom: args.bsnDateFrom,
      bsnDateTo: args.bsnDateTo,
      visitCnt: args.visitCnt,
      memberGrades: args.memberGrades,
      membershipId: args.membershipId,
      memberName: args.memberName,
      memberGradeChanges: args.memberGradeChanges,
      limit: args.limit,
      offset: args.offset,
      filters: args.filters,
      orders: args.orders,
    },
    paramsSerializer: (params) => {
      if (!params.memberGrades) {
        delete params.memberGrades;
      }
      if (!params.membershipId) {
        delete params.membershipId;
      }
      if (!params.memberName) {
        delete params.memberName;
      }

      return qs_stringify(params, { arrayFormat: "indices", allowDots: true });
    },
  });
}

export function fetchTgLogMemberGrade(membershipId) {
  return this.http.get(`${PATH_MEMBER_GRADE_MANAGEMENT}/logs`, {
    params: {
      membershipId,
    },
  });
}

export function putMemberGradeChange(args) {
  return this.http.put(
    `${PATH_MEMBER_GRADE_MANAGEMENT}/member-grade-change`,
    args,
    {
      timeout: 6000000, // 1시간
    }
  );
}
