<template>
  <div class="text">
    <!-- <component :is="dynamicComponent"></component> -->
    <!-- 바로가기 버튼 활성(Class) : activeButton -->
    <span :title="data.chldrnExstFlag==false?data.menuName:''" >
    <div
        class="item"
        :class="menuActiveButtonClassObject"
        :ref="data.ref === undefined ? '' : data.ref"
        @click="onMenuClicked"
    >
        {{ data.menuName }}
      <div class="button" @click.capture="onNewWindowClicked">바로가기</div>
    </div>
    </span>
  </div>
</template>

<script>
import { openNewWindow } from "@/utils/appInfo";
import {getCurrentBizCode} from "@/utils/pageKeyUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import store from "@/store";
import {mapGetters} from "vuex";
import commonMixin from "@/views/layout/mixin/commonMixin";

export default {
  name: "appSideLeftMenuTemplate",
  data() {
    return {
      data: {},
      dynamicComponent: `testComponent`,
    };
  },
  computed: {
    menuActiveButtonClassObject() {
      return {
        activeButton: !this.data.chldrnExstFlag,
      };
    },
    ...mapGetters(["userIp"]),
    ...mapGetters(["userId"])
  },
  mixins: [commonMixin],
  methods: {
    onMenuClicked() {
      if (!this.data.chldrnExstFlag) {
        this.$EventBus.$emit(
          "menuTreeMenuClicked",
          this.data.menuId.toString()
        );
      }
    },
    onNewWindowClicked(e) {
      let routeData = this.$router.resolve({ name: this.data.routerName });
      openNewWindow(routeData);
      const menuItem = store.getters.menuList.find(o => o.routerName === routeData.location.name);
      this.createProgramHist(menuItem, routeData.route.fullPath);

      e.stopPropagation();
      e.preventDefault();
    },
    async createProgramHist(menuItem, programId) {
      const menuId = this.commonMixinGetMenuId();
      let params = {
          bizCode : getCurrentBizCode(),
          userId : this.userId,
          pgId : programId,
          menuId : menuId,
          menuName : menuItem.menuName,
          description : menuItem.description,
          insertId : this.userId
      };
      await GolfErpAPI.createProgramUseHist(params);
    } ,
  },
};
</script>
