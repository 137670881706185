<template>
  <div class="f-dialog">
    <ejs-dialog
      ref="dialog"
      v-bind="dialogProps"
      @open="$_dialog_listeners_open"
      @close="$_dialog_listeners_close"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <span v-html="message" />
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <f-button-list ref="button-list" :items="buttonListItems" />
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import FButtonList from "@/components/button/FButtonList";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "FDialog",
  components: { FButtonList },
  props: {
    title: {
      type: String,
      default: () => "",
    },
    message: {
      type: String,
      default: () => "",
    },
    buttonListItems: {
      type: [Array],
      default: () => [],
    },
  },
  destroyed() {
    this.SET_ACTIVE_DIALOG(false);
  },
  computed: {
    ...mapGetters("dialog", ["activeDialog"]),
    dialogProps() {
      return {
        allowDragging: true,
        animationSettings: { effect: "None" },
        enableResize: false,
        header: this.title,
        isModal: true,
        position: { x: "center", y: "center" },
        showCloseIcon: false,
        visible: false,
      };
    },
  },
  methods: {
    ...mapMutations("dialog", ["SET_ACTIVE_DIALOG"]),
    show() {
      this.SET_ACTIVE_DIALOG(true);
      this.$nextTick(() => {
        this.$refs["dialog"].show();
      });
    },
    hide() {
      this.$refs["dialog"].hide();
    },

    $_dialog_listeners_open() {
      this.$refs["button-list"].focusButton(0);
    },
    $_dialog_listeners_close() {
      this.SET_ACTIVE_DIALOG(false);
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
