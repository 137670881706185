import Vue from "vue";
import gridTemplateDestroyMixin from "../../../mixins/gridTemplateDestroyMixin";

export default function gridStringEditTemplate(
  field,
  options = { maxLength: 50 }
) {
  return {
    template: Vue.component("gridStringEditTemplate", {
      mixins: [gridTemplateDestroyMixin],
      template: `<div style="width: calc(100% + 16px); margin: 0 -8px; line-height: 1.4; outline: none;" @click="divClicked"><input v-model="data[field]" ref="text" type="text" spellcheck="false" :maxlength="options.maxLength" :id="field" :name="field" autocomplete="off" @focus="$event.target.select()" style="box-sizing: border-box; padding: 0 8px; border: none; width: 100%; height: 100%; background-color: transparent; font-size: 12px; font-weight: normal; font-family: '돋움', Dotum, Arial, Verdana, sans-serif; color: #666; line-height: inherit;"></div>`,
      data() {
        return {
          field: field,
          options: options,
          data: {
            data: {},
          },
        };
      },
      // mounted() {
      //   this.divClicked();
      // },
      methods: {
        divClicked() {
          this.$refs.text.focus();
          this.$refs.text.click();
        },
      },
    }),
  };
}
