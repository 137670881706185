import { saveFcm } from "@/api/common";

const fcm = {
  namespaced: true,
  state: {
    fcm_token: null,
    fcm_message: null,
    is_new_message: false,
  },
  mutations: {
    SET_FCM_TOKEN: (state, fcm_token) => {
      state.fcm_token = fcm_token;
    },
    SET_FCM_MESSAGE: (state, fcm_message) => {
      state.fcm_message = fcm_message;
    },
    SET_NEW_MESSAGE: (state, isNew) => {
      state.is_new_message = isNew;
    },
  },
  actions: {
    setIsNewMessage({ commit }, isNew) {
      commit("SET_NEW_MESSAGE", isNew);
    },
    setFcmToken({ commit, dispatch }, messaging) {
      messaging.onMessage((payload) => {
        console.log("Fcm On Message", payload);
        commit("SET_FCM_MESSAGE", payload.data);
        commit("SET_NEW_MESSAGE", true);
        dispatch("memoView/refreshByFcm", payload.data, { root: true });
      });
      return new Promise((resolve, reject) => {
        messaging
          .getToken()
          .then((currentToken) => {
            console.log("GET CURRENT TOKEN");
            saveFcm({ token: currentToken })
              .then(() => {
                resolve(currentToken);
              })
              .catch((err) => {
                reject(err);
              });
            commit("SET_FCM_TOKEN", currentToken);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default fcm;
