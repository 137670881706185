import qs from "qs";

export const PATH_MANAGEMENT = `/management`;

export function fetchReservationInfo(args) {
  return this.http.get(`${PATH_MANAGEMENT}/information/reservation-info`, {
    params: {
      fromDate: args.fromDate,
      toDate: args.toDate,
    }
  });
}

export function fetchMonthlySalesAndUnitPrice(args) {
  return this.http.get(`${PATH_MANAGEMENT}/information/monthly-sales-and-unit-price`, {
    params: {
      fromDate: args.fromDate,
      toDate: args.toDate,
    }
  });
}

export function fetchMonthlySalesStatusByMemberType(args) {
  return this.http.get(`${PATH_MANAGEMENT}/information/monthly-sales-status-by-member-type`, {
    params: {
      fromDate: args.fromDate,
      toDate: args.toDate,
    }
  });
}

export function fetchTotalMonthlySales(args) {
  return this.http.get(`${PATH_MANAGEMENT}/information/total-monthly-sales`, {
    params: {
      fromDate: args.fromDate,
      toDate: args.toDate,
    }
  });
}

export function fetchMonthlyYearOverYearSales(args) {
  return this.http.get(`${PATH_MANAGEMENT}/information/monthly-year-over-year-sales`, {
    params: {
      fromDate: args.fromDate,
      toDate: args.toDate,
    }
  });
}
export function fetchWeeklyMeetingReport(args) {
  return this.http.get(`${PATH_MANAGEMENT}/information/weekly-meeting-report`, {
    params: {
      fromDate: args.fromDate,
      toDate: args.toDate,
    }
  });
}

export function fetchVisitorStatusStatistics(args) {
  return this.http.get(`${PATH_MANAGEMENT}/report/visitor-status-statistics`, {
    params: {
      year: args.year,
      month: args.month,
    }
  });
}
export function fetchMonthlyVisitorStatus(args) {
  return this.http.get(`${PATH_MANAGEMENT}/report/monthly-visitor-status`, {
    params: {
      yyyyMM: args.yyyyMM,
      courseList: args.courseList,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}
export function fetchMonthlySalesIncreasStatus(args) {
    return this.http.get(`${PATH_MANAGEMENT}/information/fetch_monthly_sales_increas_status`, {
      params: {
        yearMonth: args.yearMonth,
        attrbIdx: args.attrbIdx
      }
    });
}


