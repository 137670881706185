// import qs from "qs";

export const PATH_FRONT = `/front`;

export function payDivide(tgPayDivides) {
    return this.http.post(
        `${PATH_FRONT}/pay-divide`,
        tgPayDivides
    );
}

export function getVisitThumbnail(chkinId) {
    return this.http.get(`${PATH_FRONT}/visit/thumbnail`, {
        params: {
            chkinId: chkinId,
        },
    });
}
// 품목별 1/N 분할
export function patchDutchPayByItem(tgPayDivides) {
    return this.http.patch(
        `${PATH_FRONT}/pay-divide/by-item`,
        tgPayDivides
    );
}
//동반자 가져오기 현재 안씀...
export function fetchCompanionMember(resveId) {
    return this.http.get(`${PATH_FRONT}/visit/companion-member`, {
        params: {
            resveId: resveId,
        },
    });
}
//동반자 조회하여 저장까지...
export function patchCompanionMember(args) {
    return this.http.patch(`${PATH_FRONT}/visit/companion-member` , {
        resveId : args.resveId,
        posNo   : args.posNo
    });
}