import layout from "@/views/layout/layout";

const golfReservationRoute = {
  path: "/golf-reservation",
  component: layout,
  redirect: "/golf-reservation/calendar-registration",
  children: [
    {
      path: "calendar-registration",
      name: "calendarRegistration",
      component: () => import("@/views/golf-reservation/CalendarRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "reservation-receipt-registration",
      name: "reservationReceiptRegistration",
      component: () =>
        import("@/views/golf-reservation/ReservationReceiptRegistration"),
      meta: {
        enableMemo: true,
        currentView: "RESVE",
        useUsualStyles: true,
      },
    },
    {
      path: "time-table-create",
      name: "timeTableCreate",
      component: () => import("@/views/golf-reservation/TimeTableCreate"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "promotion-registration",
      name: "promotionRegistration",
      component: () => import("@/views/golf-reservation/PromotionRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "waiting-reservation-registration",
      name: "waitingReservationRegistration",
      component: () =>
        import("@/views/golf-reservation/WaitingReservationRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "group-management-registration",
      name: "groupManagementRegistration",
      component: () =>
        import("@/views/golf-reservation/GroupManagementRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "group-receipt-status",
      name: "GroupReceiptStatus",
      component: () =>
          import("@/views/golf-reservation/GroupReceiptStatus"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "blacklist/blacklist-registration",
      name: "blacklistRegistration",
      component: () => import("@/views/golf-reservation/BlacklistRegistration"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "history",
      name: "reservationHistory",
      component: () => import("@/views/golf-reservation/ReservationHistory"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "reservation-guest-book-print",
      name: "ReservationGuestBookPrint",
      component: () =>
        import("@/views/golf-reservation/ReservationGuestBookPrint"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "reservation-status",
      name: "ReservationStatus",
      component: () => import("@/views/golf-reservation/ReservationStatus"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "reservation-batch-sms-send",
      name: "ReservationBatchSmsSend",
      component: () =>
        import("@/views/golf-reservation/ReservationBatchSmsSend"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "sms-send-status",
      name: "SmsSendStatus",
      component: () => import("@/views/golf-reservation/SmsSendStatus"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "group-reservation-status",
      name: "GroupReservationStatus",
      component: () =>
        import("@/views/golf-reservation/GroupReservationStatus"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "one-or-more-reservations",
      name: "OneOrMoreReservations",
      component: () =>
        import("@/views/golf-reservation/OneOrMoreReservations"),
      meta: {
        useUsualStyles: true
      }
    },
    {
      path: "lottery-reservation-registration",
      name: "LotteryReservationRegistration",
      component: () =>
        import("@/views/golf-reservation/LotteryReservationRegistration"),
      meta: {
        useUsualStyles: true
      }
    },
    {
      path: "search-reservation-conditions",
      name: "SearchReservationConditions",
      component: () =>
        import("@/views/golf-reservation/SearchReservationConditions"),
      meta: {
        useUsualStyles: true
      }
    },
    {
      path: "reservation-status-by-game-progress",
      name: "ReservationStatusByGameProgress",
      component: () => import("@/views/golf-reservation/ReservationStatusByGameProgress"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "golf-monthly-reservation-status",
      name: "GolfMonthlyReservationStatus",
      component: () => import("@/views/golf-reservation/GolfMonthlyReservationStatus"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "sms-send-for-user",
      name: "SmsSendForUser",
      component: () => import("@/views/golf-reservation/SmsSendForUser"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "status-of-utilization-by-reservation-channel",
      name: "StatusOfUtilizationByReservationChannel",
      component: () => import("@/views/golf-reservation/StatusOfUtilizationByReservationChannel"),
      meta: {
        useUsualStyles: true,
      },
    },
  ],
};

export default golfReservationRoute;
