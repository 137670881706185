import store from "@/store";
import jwt_decode from "jwt-decode";
import {getBizCodesObject, getCurrentBizCode, setCurrentBizCode} from "@/utils/pageKeyUtil";
import { JWT_DEFAULT_SCHEME_PREFIX } from "@/api/v2/GolfErpAPI";

export const LOCALSTORAGE_KEY_LOGIN_EMAIL = "___les___";
export const LOCALSTORAGE_KEY_ACCESS_TOKEN = "___act___";

export function saveLoginEmail(email) {
  localStorage.setItem(LOCALSTORAGE_KEY_LOGIN_EMAIL, email);
}

export function getLoginEmail() {
  return localStorage.getItem(LOCALSTORAGE_KEY_LOGIN_EMAIL);
}

export function clearLoginEmail() {
  localStorage.removeItem(LOCALSTORAGE_KEY_LOGIN_EMAIL);
}

export function saveAccessToken(
  accessToken
) {
  const { aud } = jwt_decode(accessToken);
  const bizCode =
    getCurrentBizCode() ||
    aud
      .find((s) => s.startsWith(JWT_DEFAULT_SCHEME_PREFIX))
      .substring(JWT_DEFAULT_SCHEME_PREFIX.length);
  // const email = aud[0];
  const username = aud[1];
  const userId = aud[3];

  const bizCodesObject = getBizCodesObject();
  if (bizCodesObject.findIndex(({bizCode: _bizCode}) => _bizCode === bizCode) < 0) {
    setCurrentBizCode(bizCodesObject[0]?.bizCode);
  } else {
    setCurrentBizCode(bizCode);
  }

  store.commit("SET_USERNAME", username);
  store.commit("SET_USER_ID", userId);

  localStorage.setItem(LOCALSTORAGE_KEY_ACCESS_TOKEN, accessToken);
}

export function getAccessToken() {
  return localStorage.getItem(LOCALSTORAGE_KEY_ACCESS_TOKEN);
}

export function removeAccessToken() {
  localStorage.removeItem(LOCALSTORAGE_KEY_ACCESS_TOKEN);
}
