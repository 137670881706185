const CONFIG_PATH = "/config";

export function getPlacesOfCardPurchase(vanKind) {
  return this.http.get(`${CONFIG_PATH}/placesOfCardPurchase`, {
    params: { vanKind: vanKind },
  });
}

export function fetchAccountCardCompanies() {
  return this.http.get(`${CONFIG_PATH}/account-card-companies`);
}

export function fetchConfigMemberGrade() {
  return this.http.get(`${CONFIG_PATH}/member-grade`);
}

export function putConfigMemberGrade(saveData) {
  return this.http.put(`${CONFIG_PATH}/member-grade`, saveData);
}
