<template>
  <f-dialog
    v-if="visibleDialog"
    ref="dialog"
    v-bind="dialogProps"
    @close="onDialogClose"
  />
</template>

<script>
import FDialog from "@/components/dialog/FDialog";

export default {
  name: "AlertDialog",
  components: { FDialog },
  data() {
    return {
      visibleDialog: false,
      title: "",
      message: "",
      callback: () => {},
      closeCallback: () => {},
    };
  },
  computed: {
    dialogProps() {
      return {
        title: this.title,
        message: this.message,
        buttonListItems: [
          {
            class: "close",
            buttonOptions: {
              text: this.$t("main.popupButton.baseLeftBtnText"),
              listeners: {
                click: this.onCloseClick,
              },
            },
          },
        ],
      };
    },
  },
  methods: {
    show({ title, message, closeCallback }) {
      this.title = title;
      this.message = message;
      this.closeCallback = closeCallback;

      this.visibleDialog = true;
      this.$nextTick(() => {
        this.$refs["dialog"].show();
      });
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.closeCallback();
      this.visibleDialog = false;
    },
    onCloseClick() {
      this.hide();
    },
  },
};
</script>

<style scoped></style>
