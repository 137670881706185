import { stringify as qs_stringify } from "qs";
import axios from "axios";

export const PATH_REPORTS = `/reports`;
export const PATH_VISITOR_REPORT = `/visitor-report`;
export const PATH_REPORTS_MEMBER_REGISTRATION = `${PATH_REPORTS}/member-registration`;
export const PATH_REPORTS_WEB_MEMBER_REGISTRATION = `${PATH_REPORTS}/web-member-registration`;
export const PATH_REPORTS_MEMBERS = `${PATH_REPORTS}/members`;
export const PATH_REPORTS_MEMBER_GRADE_MANAGEMENT = `${PATH_REPORTS}/member-grade-management`;
export const PATH_REPORTS_MEMBER_GRADE_CHANGES = `${PATH_REPORTS}/member-grade-changes`;
export const PATH_REPORTS_SALES = `${PATH_REPORTS}/sales`;
export const PATH_REPORTS_RESERVATION = `${PATH_REPORTS}/reservations`;

export function fetchProductSalesSummaryTableByPeriod(args) {
  return this.http.get(`${PATH_REPORTS_SALES}/product-sales-summary-table-by-period`, {
    params: {
      bsnDateFrom: args.bsnDateFrom,
      bsnDateTo: args.bsnDateTo
    }
  });
}

/**
 * 카드 매출 현황
 * @param payerName
 * @param dateDivision
 * @param dateFrom
 * @param dateTo
 * @param filterOptions
 */
export function fetchCreditCardReports(
  payerName,
  dateDivision,
  dateFrom,
  dateTo,
  {
    cardCompanies = [],
    salesDivisions = [],
    approvalDivisions = [],
    storeCode,
    businessCode,
    approvalNumber,
    cardNumber,
    cancelViewFlag,
    trnscDiv,
  } = {}
) {
  return this.http.get(`${PATH_REPORTS}/card`, {
    params: {
      payerName,
      dateDivision,
      dateFrom,
      dateTo,
      cardCompanies: cardCompanies.join(",") || undefined,
      salesDivisions: salesDivisions.join(",") || undefined,
      approvalDivisions: approvalDivisions.join(",") || undefined,
      storeCode: storeCode || undefined,
      businessCode: businessCode || undefined,
      approvalNumber: approvalNumber || undefined,
      cardNumber: cardNumber || undefined,
      cancelViewFlag: cancelViewFlag,
      trnscDiv,
    },
  });
}

/**
 * 현금영수증 발행 현황
 * @param payerName
 * @param dateDivision
 * @param dateFrom
 * @param dateTo
 * @param filterOptions
 */
export function fetchCashReceiptsReports(
  payerName,
  dateDivision,
  dateFrom,
  dateTo,
  {
    salesDivisions = [],
    proofTypes = [],
    approvalDivisions = [],
    storeCode,
    businessCode,
    approvalNumber,
    proofNumber,
    cancelViewFlag,
    trnscDiv,
  } = {}
) {
  return this.http.get(`${PATH_REPORTS}/cash`, {
    params: {
      payerName,
      dateDivision,
      dateFrom,
      dateTo,
      salesDivisions: salesDivisions.join(",") || undefined,
      proofTypes: proofTypes.join(",") || undefined,
      approvalDivisions: approvalDivisions.join(",") || undefined,
      storeCode: storeCode || undefined,
      businessCode: businessCode || undefined,
      approvalNumber: approvalNumber || undefined,
      proofNumber: proofNumber || undefined,
      cancelViewFlag: cancelViewFlag,
      trnscDiv,
    },
  });
}

/**
 * 내장객 명세서 조회
 */
export function fetchVisitorStatement(args) {
  return this.http.get(`${PATH_VISITOR_REPORT}/visitor-statements`, {
    params: {
      visitDateFrom: args.resveDateStart,
      visitDateTo: args.resveDateEnd,
      partDiv: args.partDiv,
      resveRemarks: args.resveRemarks,
      name:args.name,
      grpName: args.grpName,
      sexCode: args.sexCode,
    },
    paramsSerializer: (params) => {
      return qs_stringify(params, { arrayFormat: "repeat" });
    },
  });
}

/**
 * 요금구분별 내장현황 조회
 */
export function fetchPaymtCodeByVisitStatements(args) {
  return this.http.get(`${PATH_VISITOR_REPORT}/paymt-code-by-visit-statements`, {
    params: {
      visitDateFrom: args.visitDateFrom,
      visitDateTo: args.visitDateTo,
      memberDivs: args.memberDivs,
      paymtCodes: args.paymtCodes,
      isAutoCheckedIn: args.isAutoCheckedIn,
      isNot: args.isNot,
      isNoSaleSlip: args.isNoSaleSlip,
      visitName: args.visitName,
      gcDiv: args.gcDiv,
      grpName: args.grpName,
    },
    paramsSerializer: (params) => {
      return qs_stringify(params, { arrayFormat: "repeat" });
    }
  });
}

export function fetchRecordsAndCountMemberRegistration({
  q,
  corporationDivision,
  memberDivision,
  memberGrade,
  corporationName,
  endOfHpNumber,
  webMemberId,
  groupCompanyCode,
  affltsCompanyCode,
  smsReceiptDivision,
  membershipStatus,
  acquireDateFrom,
  acquireDateTo,
  nearbyCode,
  expireDateFrom,
  expireDateTo,
  birthday,
  gender,
  isOut,
  limit,
  offset,
  filters,
  orders,
}) {
  return this.http.post(
    `${PATH_REPORTS_MEMBER_REGISTRATION}`,
    {
      q,
      corporationDivision,
      memberDivision,
      memberGrade,
      corporationName,
      endOfHpNumber,
      webMemberId,
      groupCompanyCode,
      affltsCompanyCode,
      smsReceiptDivision,
      membershipStatus,
      acquireDateFrom,
      acquireDateTo,
      nearbyCode,
      expireDateFrom,
      expireDateTo,
      birthday,
      gender,
      isOut,
      limit,
      offset,
      filters,
      orders,
    },
    {
      timeout: 1000 * 60,
    }
  );
}
export function fetchRecordsAndCountWebMemberRegistration({
  q,
  endOfHpNumber,
  webMemberId,
  acquireDateFrom,
  acquireDateTo,
  gender,
  filters,
  orders,
  limit,
  offset,
}) {
  return this.http.post(`${PATH_REPORTS_WEB_MEMBER_REGISTRATION}`, {
    q,
    endOfHpNumber,
    webMemberId,
    acquireDateFrom,
    acquireDateTo,
    gender,
    filters,
    orders,
    limit,
    offset,
  });
}

export function downloadExcelMemberRegistration(
  {
    q,
    corporationDivision,
    memberDivision,
    memberGrade,
    corporationName,
    endOfHpNumber,
    webMemberId,
    groupCompanyCode,
    affltsCompanyCode,
    smsReceiptDivision,
    membershipStatus,
    acquireDateFrom,
    acquireDateTo,
    expireDateFrom,
    expireDateTo,
    birthday,
    gender,
    isOut,
    filters,
    orders,
    columns,
  },
  { interruptTokenId }
) {
  let cancelToken;

  if (interruptTokenId) {
    const cancelTokenSource = axios.CancelToken.source();
    cancelToken = cancelTokenSource.token;
    this.interruptTokeSourceMap[interruptTokenId] = cancelTokenSource;
  }

  return this.http.post(
    `${PATH_REPORTS_MEMBER_REGISTRATION}`,
    {
      q,
      corporationDivision,
      memberDivision,
      memberGrade,
      corporationName,
      endOfHpNumber,
      webMemberId,
      groupCompanyCode,
      affltsCompanyCode,
      smsReceiptDivision,
      membershipStatus,
      acquireDateFrom,
      acquireDateTo,
      expireDateFrom,
      expireDateTo,
      birthday,
      gender,
      isOut,
      filters,
      orders,
      columns,
    },
    {
      responseType: "blob",
      headers: {
        Accept: "application/vnd.ms-excel",
      },
      cancelToken,
      timeout: 1000 * 60 * 60,
      isBackground: true,
    }
  );
}
export function downloadExcelWebMemberRegistration(
  {
    q,
    endOfHpNumber,
    webMemberId,
    acquireDateFrom,
    acquireDateTo,
    gender,
    filters,
    orders,
    columns,
  },
  { interruptTokenId }
) {
  let cancelToken;

  if (interruptTokenId) {
    const cancelTokenSource = axios.CancelToken.source();
    cancelToken = cancelTokenSource.token;
    this.interruptTokeSourceMap[interruptTokenId] = cancelTokenSource;
  }

  return this.http.post(
    `${PATH_REPORTS_WEB_MEMBER_REGISTRATION}`,
    {
      q,
      endOfHpNumber,
      webMemberId,
      acquireDateFrom,
      acquireDateTo,
      gender,
      filters,
      orders,
      columns,
    },
    {
      responseType: "blob",
      headers: {
        Accept: "application/vnd.ms-excel",
      },
      cancelToken,
      timeout: 1000 * 60 * 60,
      isBackground: true,
    }
  );
}

export function getMaxMemberNo() {
    return this.http.get(`${PATH_REPORTS_MEMBERS}/max-member-no`);
}

export function fetchRecordsAndCountMembers(
  memberDivisions,
  memberGrades,
  memberName,
  memberNumberFrom,
  memberNumberTo,
  gender,
  birthYear,
  birthMonth,
  birthDay,
  ageCodes,
  endOfHpNumber,
  allowSmsReceiptDivision,
  allowReceiptEmail,
  corporationDivision,
  corporationName,
  workPlace,
  acquireDateFrom,
  acquireDateTo,
  expireDateFrom,
  expireDateTo,
  loginCount,
  isOut,
  membershipStatus,
  entryOrders,
  nearbyCodes,
  areaCodes,

  reservationDateFrom,
  reservationDateTo,
  reservationTimeFrom,
  reservationTimeTo,
  reservationBsnCode,
  reservationDayOfWeeks,
  reservationCount,
  reservationChannels,
  excludeExistReservationDateFrom,
  excludeExistReservationDateTo,
  excludePenaltyReservationDateFrom,
  excludePenaltyReservationDateTo,
  reservationKinds,

  visitDateFrom,
  visitDateTo,
  visitTimeFrom,
  visitTimeTo,
  visitBsnCode,
  visitDayOfWeeks,
  visitCount,
  paymentCodes,

  salesDateFrom,
  salesDateTo,
  salesBsnCode,
  storeCodes,
  fitOrTeamSalesDivision,
  salesAmount,
  unitPrice,

  excludeMembersByMembershipId,

  limit,
  offset,
  filters,
  orders
) {
  return this.http.post(`${PATH_REPORTS_MEMBERS}`, {
    memberDivisions,
    memberGrades,
    memberName,
    memberNumberFrom,
    memberNumberTo,
    gender,
    birthYear,
    birthMonth,
    birthDay,
    ageCodes,
    endOfHpNumber,
    allowSmsReceiptDivision,
    allowReceiptEmail,
    corporationDivision,
    corporationName,
    workPlace,
    acquireDateFrom,
    acquireDateTo,
    expireDateFrom,
    expireDateTo,
    loginCount,
    isOut,
    membershipStatus,
    entryOrders,
    nearbyCodes,
    areaCodes,

    reservationDateFrom,
    reservationDateTo,
    reservationTimeFrom,
    reservationTimeTo,
    reservationBsnCode,
    reservationDayOfWeeks,
    reservationCount,
    reservationChannels,
    excludeExistReservationDateFrom,
    excludeExistReservationDateTo,
    excludePenaltyReservationDateFrom,
    excludePenaltyReservationDateTo,
    reservationKinds,

    visitDateFrom,
    visitDateTo,
    visitTimeFrom,
    visitTimeTo,
    visitBsnCode,
    visitDayOfWeeks,
    visitCount,
    paymentCodes,

    salesDateFrom,
    salesDateTo,
    salesBsnCode,
    storeCodes,
    fitOrTeamSalesDivision,
    salesAmount,
    unitPrice,

    excludeMembersByMembershipId,

    limit,
    offset,
    filters,
    orders,
  });
}

export function downloadExcelMembers(
  {
    memberDivisions,
    memberGrades,
    memberName,
    memberNumberFrom,
    memberNumberTo,
    gender,
    birthYear,
    birthMonth,
    birthDay,
    ageCodes,
    endOfHpNumber,
    allowSmsReceiptDivision,
    allowReceiptEmail,
    corporationDivision,
    corporationName,
    workPlace,
    acquireDateFrom,
    acquireDateTo,
    loginCount,
    expireDate,
    isOut,
    membershipStatus,
    entryOrders,
    nearbyCodes,
    areaCodes,

    reservationDateFrom,
    reservationDateTo,
    reservationTimeFrom,
    reservationTimeTo,
    reservationBsnCode,
    reservationDayOfWeeks,
    reservationCount,
    reservationChannels,
    excludeExistReservationDateFrom,
    excludeExistReservationDateTo,
    excludePenaltyReservationDateFrom,
    excludePenaltyReservationDateTo,
    reservationKinds,

    visitDateFrom,
    visitDateTo,
    visitTimeFrom,
    visitTimeTo,
    visitBsnCode,
    visitDayOfWeeks,
    visitCount,
    paymentCodes,

    salesDateFrom,
    salesDateTo,
    salesBsnCode,
    storeCodes,
    fitOrTeamSalesDivision,
    salesAmount,
    unitPrice,

    excludeMembersByMembershipId,

    filters,
    orders,
    columns,
  },
  { interruptTokenId }
) {
  let cancelToken;

  if (interruptTokenId) {
    const cancelTokenSource = axios.CancelToken.source();
    cancelToken = cancelTokenSource.token;
    this.interruptTokeSourceMap[interruptTokenId] = cancelTokenSource;
  }

  return this.http.post(
    `${PATH_REPORTS_MEMBERS}`,
    {
      memberDivisions,
      memberGrades,
      memberName,
      memberNumberFrom,
      memberNumberTo,
      gender,
      birthYear,
      birthMonth,
      birthDay,
      ageCodes,
      endOfHpNumber,
      allowSmsReceiptDivision,
      allowReceiptEmail,
      corporationDivision,
      corporationName,
      workPlace,
      acquireDateFrom,
      acquireDateTo,
      loginCount,
      expireDate,
      isOut,
      membershipStatus,
      entryOrders,
      nearbyCodes,
      areaCodes,

      reservationDateFrom,
      reservationDateTo,
      reservationTimeFrom,
      reservationTimeTo,
      reservationBsnCode,
      reservationDayOfWeeks,
      reservationCount,
      reservationChannels,
      excludeExistReservationDateFrom,
      excludeExistReservationDateTo,
      excludePenaltyReservationDateFrom,
      excludePenaltyReservationDateTo,
      reservationKinds,

      visitDateFrom,
      visitDateTo,
      visitTimeFrom,
      visitTimeTo,
      visitBsnCode,
      visitDayOfWeeks,
      visitCount,
      paymentCodes,

      salesDateFrom,
      salesDateTo,
      salesBsnCode,
      storeCodes,
      fitOrTeamSalesDivision,
      salesAmount,
      unitPrice,

      excludeMembersByMembershipId,

      filters,
      orders,
      columns,
    },
    {
      responseType: "blob",
      headers: {
        Accept: "application/vnd.ms-excel",
      },
      cancelToken,
      timeout: 1000 * 60 * 60,
      isBackground: true,
    }
  );
}

export function fetchRecordsAndCountMemberGradeManagement(
  bsnDateFrom,
  bsnDateTo,
  visitCnt,
  memberGrades,
  membershipId,
  memberName,
  memberGradeChanges,
  limit,
  offset,
  filters,
  orders
) {
  return this.http.post(`${PATH_REPORTS_MEMBER_GRADE_MANAGEMENT}`, {
    bsnDateFrom,
    bsnDateTo,
    visitCnt,
    memberGrades,
    membershipId,
    memberName,
    memberGradeChanges,
    limit,
    offset,
    filters,
    orders,
  });
}

export function downloadExcelMemberGradeManagement(
  {
    bsnDateFrom,
    bsnDateTo,
    visitCnt,
    memberGrades,
    membershipId,
    memberName,
    memberGradeChanges,
    filters,
    orders,
    columns,
  },
  { interruptTokenId }
) {
  let cancelToken;

  if (interruptTokenId) {
    const cancelTokenSource = axios.CancelToken.source();
    cancelToken = cancelTokenSource.token;
    this.interruptTokeSourceMap[interruptTokenId] = cancelTokenSource;
  }

  return this.http.post(
    `${PATH_REPORTS_MEMBER_GRADE_MANAGEMENT}`,
    {
      bsnDateFrom,
      bsnDateTo,
      visitCnt,
      memberGrades,
      membershipId,
      memberName,
      memberGradeChanges,
      filters,
      orders,
      columns,
    },
    {
      responseType: "blob",
      headers: {
        Accept: "application/vnd.ms-excel",
      },
      cancelToken,
      timeout: 1000 * 60 * 60,
      isBackground: true,
    }
  );
}

export function fetchRecordsAndCountMemberGradeChanges(
  changeDateFrom,
  changeDateTo,
  memberGrades,
  changeMemberGradeDivisions,
  memberName,
  membershipId,
  isCouponPub,
  filters,
  orders,
  limit,
  offset
) {
  return this.http.post(`${PATH_REPORTS_MEMBER_GRADE_CHANGES}`, {
    changeDateFrom,
    changeDateTo,
    memberGrades,
    changeMemberGradeDivisions,
    memberName,
    membershipId,
    isCouponPub,
    filters,
    orders,
    limit,
    offset,
  });
}

export function downloadExcelMemberGradeChanges(
  {
    changeDateFrom,
    changeDateTo,
    memberGrades,
    changeMemberGradeDivisions,
    memberName,
    membershipId,
    isCouponPub,
    filters,
    orders,
    columns,
  },
  { interruptTokenId }
) {
  let cancelToken;

  if (interruptTokenId) {
    const cancelTokenSource = axios.CancelToken.source();
    cancelToken = cancelTokenSource.token;
    this.interruptTokeSourceMap[interruptTokenId] = cancelTokenSource;
  }

  return this.http.post(
    `${PATH_REPORTS_MEMBER_GRADE_CHANGES}`,
    {
      changeDateFrom,
      changeDateTo,
      memberGrades,
      changeMemberGradeDivisions,
      memberName,
      membershipId,
      isCouponPub,
      filters,
      orders,
      columns,
    },
    {
      responseType: "blob",
      headers: {
        Accept: "application/vnd.ms-excel",
      },
      cancelToken,
      timeout: 1000 * 60 * 60,
      isBackground: true,
    }
  );
}

export function fetchReservationReceptionRecords(
  {
    dateDiv,
    resveDateFrom,
    resveDateTo,
    resveTimeFrom,
    resveTimeTo,
    resveCourseList,
    bsnCode,
    dwCodeList,
    grpName,
    grpKindList,
    resveName,
    memberNo,
    memberGradeList,
    memberDivList,
    resveChannelList,
    resveKindList,
    contactName,
    contactTelIdx,
    resveRemarks,
    smsSendFlag,
    areaCodeList,
    sexCode,
    recomendr,
    vipFlag,
    excludeExistReservationDateFrom,
    excludeExistReservationDateTo,
    isAditTime,
    filters,
    orders,
    limit,
    offset,
  }
) {
  return this.http.post(`${PATH_REPORTS_RESERVATION}`, {
    dateDiv,
    resveDateFrom,
    resveDateTo,
    resveTimeFrom,
    resveTimeTo,
    resveCourseList,
    bsnCode,
    dwCodeList,
    grpName,
    grpKindList,
    resveName,
    memberNo,
    memberGradeList,
    memberDivList,
    resveChannelList,
    resveKindList,
    contactName,
    contactTelIdx,
    resveRemarks,
    smsSendFlag,
    areaCodeList,
    sexCode,
    recomendr,
    vipFlag,
    excludeExistReservationDateFrom,
    excludeExistReservationDateTo,
    isAditTime,
    filters,
    orders,
    limit,
    offset,
  });
}

export function downloadExcelSearchReservationConditions(
  {
    dateDiv,
    resveDateFrom,
    resveDateTo,
    resveTimeFrom,
    resveTimeTo,
    resveCourseList,
    bsnCode,
    dwCodeList,
    grpName,
    grpKindList,
    resveName,
    memberNo,
    memberGradeList,
    memberDivList,
    resveChannelList,
    resveKindList,
    contactName,
    contactTelIdx,
    resveRemarks,
    smsSendFlag,
    areaCodeList,
    sexCode,
    recomendr,
    vipFlag,
    excludeExistReservationDateFrom,
    excludeExistReservationDateTo,
    isAditTime,
    filters,
    orders,
    columns,
  },
  { interruptTokenId }
) {
  let cancelToken;

  if (interruptTokenId) {
    const cancelTokenSource = axios.CancelToken.source();
    cancelToken = cancelTokenSource.token;
    this.interruptTokeSourceMap[interruptTokenId] = cancelTokenSource;
  }

  return this.http.post(
    `${PATH_REPORTS_RESERVATION}`,
    {
      dateDiv,
      resveDateFrom,
      resveDateTo,
      resveTimeFrom,
      resveTimeTo,
      resveCourseList,
      bsnCode,
      dwCodeList,
      grpName,
      grpKindList,
      resveName,
      memberNo,
      memberGradeList,
      memberDivList,
      resveChannelList,
      resveKindList,
      contactName,
      contactTelIdx,
      resveRemarks,
      smsSendFlag,
      areaCodeList,
      sexCode,
      recomendr,
      vipFlag,
      excludeExistReservationDateFrom,
      excludeExistReservationDateTo,
      isAditTime,
      filters,
      orders,
      columns,
    },
    {
      responseType: "blob",
      headers: {
        Accept: "application/vnd.ms-excel",
      },
      cancelToken,
      timeout: 1000 * 60 * 60,
      isBackground: true,
    }
  );
}

export function postReservationHistory(args) {
  return this.http.post(`${PATH_REPORTS}/reservation-history`, args);
}

export function fetchSettlementStatusByHole(args) {
    return this.http.get(`${PATH_REPORTS}/settlement-status-by-hole`,{
        params: {
            bsnDateFrom: args.from,
            bsnDateTo: args.to,
        }
    });
}

export function fetchStatusOfUtilizationByReservationChannel(args) {
    return this.http.get(`${PATH_REPORTS_RESERVATION}/status-of-utilization-by-reservation-channel`, {
        params: {
            fromDate: args.fromDate,
            toDate: args.toDate,
            isBlankTimeInclude: args.isBlankTimeInclude,
        }
    });
}
