import Vue from "vue";
import gridTemplateDestroyMixin from "../../../mixins/gridTemplateDestroyMixin";

const formatToReplace = "${value}";

export default function GridAggregationTotalCountTemplate(templateStyle) {
  return {
    template: Vue.component("gridAggregationTotalCountTemplate", {
      mixins: [gridTemplateDestroyMixin],
      template: `<span style="line-height:24px; vertical-align:middle;" :style="{ color : templateStyle && templateStyle.textColor ? templateStyle.textColor : null }"><b>{{getTotalCount()}}</b></span>`, // 폰트굵기 bold css지정 필요
      data() {
        return {
          data: {},
          templateStyle: templateStyle,
        };
      },
      methods: {
        getTotalCount() {
          const totalCount = this.data.Count.toLocaleString();

          if (!this.templateStyle.stringFormat) {
            return totalCount;
          } else {
            return this.templateStyle.stringFormat.replace(
              formatToReplace,
              totalCount
            );
          }
        },
      },
    }),
  };
}
