// TODO : 삭제 예정
const modifiedViewState = {
  state: {
    states: [],
  },
  mutations: {
    SET_MODIFIED_VIEW_STATE: (state, { viewName, isModified }) => {
      let viewState = state.states.filter((v) => v.viewName === viewName);
      if (viewState != null && viewState.length > 0) {
        viewState[0].isModified = isModified;
      } else {
        state.states.push(
          Object.assign(
            {},
            {
              viewName: viewName || "no-name",
              isModified: isModified,
            }
          )
        );
      }
    },
    DEL_MODIFIED_VIEW_STATE: (state, viewName) => {
      for (const [i, v] of state.states.entries()) {
        if (v.viewName === viewName) {
          state.states.splice(i, 1);
          break;
        }
      }
    },
  },
  actions: {
    setModofiedViewState({ commit }, { viewName, isModified }) {
      commit("SET_MODIFIED_VIEW_STATE", { viewName, isModified });
    },
    delModifiedViewState({ commit }, viewName) {
      commit("DEL_MODIFIED_VIEW_STATE", viewName);
    },
  },
};

export default modifiedViewState;
