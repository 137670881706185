export const PATH_CLOSE = `/close`;

export function fetchCloseCheck(args) {
  return this.http.get(`${PATH_CLOSE}/check`, {
    params: {
      bsnDateFrom: args.bsnDateFrom,
      bsnDateTo: args.bsnDateTo,
      storeCode: args.storeCode,
    },
  });
}

export function getCloseDiv(args) {
  return this.http.get(`${PATH_CLOSE}/check/close_div`, {
    params: {
      bsnDate: args.bsnDate,
      storeCode: args.storeCode,
    },
  });
}

export function fetchDailyStoreReport(bsnDate, storeCode) {
  return this.http.get(`${PATH_CLOSE}/daily-store-report`, {
    params: {
      bsnDate,
      storeCode,
    },
  });
}

export function fetchSalesStatusByPartDiv(args) {
  return this.http.get(`${PATH_CLOSE}/sales-status-by-part-div`, {
    params: {
      bsnDateFrom: args.bsnDateFrom,
      bsnDateTo: args.bsnDateTo,
      salesSumDiv: args.salesSumDiv,
      aditFlag: args.aditFlag,
      bsnCode: args.bsnCode
    }
  });
}

export function putStoredProcedureExecute(request) {
  return this.http.put(`${PATH_CLOSE}/stored-procedure-execute`, request);
}

export function fetchMaxBsnDate(storeCode) {
  return this.http.get(`${PATH_CLOSE}/max-bsn-date`, {
    params: {
      storeCode
    }
  });
}

export function deleteDailyCloseCreatePenlty(bsnDate) {
  return this.http.delete(`${PATH_CLOSE}/daily-close-create-penlty-cancel/${bsnDate}`);
}

export function putCloseExecuteByBsnDate(bsnDate) {
  return this.http.put(`${PATH_CLOSE}/execute/${bsnDate}`);
}

export function fetchReservationFrontDeskWeeklyBusinessReport(bsnDate) {
  return this.http.get(`${PATH_CLOSE}/reservation-front-desk-weekly-business-report`, {
    params: {
      bsnDate
    }
  });
}

export function fetchIncomeAmountAggregationTable(bsnDate) {
  return this.http.get(`${PATH_CLOSE}/income-amount-aggregation-table`, {
    params: {
      bsnDate
    }
  });
}


export function getReportFeeDivision(bsnDateFrom,bsnDateTo) {
  return this.http.get(`${PATH_CLOSE}/report/daily-report-fee-division`, {
    params: {
      bsnDateFrom,
      bsnDateTo
    }
  });
}
export function getReportSalesByPeriod(bsnDateFrom,bsnDateTo) {
    return this.http.get(`${PATH_CLOSE}/report/close-report-sales-period`, {
      params: {
        bsnDateFrom,
        bsnDateTo
      }
    });
}
export function getReportDailyVisitPer(currentYearStart,currentYearEnd,lastYearStart,lastYearEnd) {
  return this.http.get(`${PATH_CLOSE}/report/daily-visit-per`, {
    params: {
      currentYearStart,
      currentYearEnd,
      lastYearStart,
      lastYearEnd
    }
  });
}

