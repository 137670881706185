export const PATH_ROOM_CHECKIN = `/room/check-in`;

export function fetchRoomCheckIns(args) {
  return this.http.get(`${PATH_ROOM_CHECKIN}`, {
    params: args,
  });
}

export function fetchRoomCheckInDetail(args) {
  return this.http.get(`${PATH_ROOM_CHECKIN}/detail`, {
    params: args,
  });
}

export function fetchRoomCheckInDetailByStayId(stayId) {
  return this.http.get(`${PATH_ROOM_CHECKIN}/detail/${stayId}`);
}

export function fetchRoomCheckInDetailByOrSearch(args) {
  return this.http.get(`${PATH_ROOM_CHECKIN}/detail/or-search`, {
    params: {
      bsnDate: args.bsnDate,
      searchValue: args.searchValue,
    },
  });
}

export function fetchRoomCheckInPosting(stayId) {
  return this.http.get(`${PATH_ROOM_CHECKIN}/posting`, {
    params: {
      stayId: stayId,
    },
  });
}

export function fetchCheckInRooms(rresveNo) {
  return this.http.get(`${PATH_ROOM_CHECKIN}/rooms`, {
    params: {
      rresveNo: rresveNo,
    },
  });
}

export function roomCheckInDetailSave(data) {
  return this.http.patch(`${PATH_ROOM_CHECKIN}/detail`, data);
}

export function checkInRoomCancel(stayId) {
  return this.http.patch(`${PATH_ROOM_CHECKIN}/room-cancel/${stayId}`);
}

export function roomPostingSave(data) {
  return this.http.post(`${PATH_ROOM_CHECKIN}/posting`, data);
}

export function roomPostingDelete(salesId, deleteReason) {
  return this.http.delete(`${PATH_ROOM_CHECKIN}/posting`, {
    params: {
      salesId: salesId,
      deleteReason: deleteReason,
    },
  });
}

export function roomCheckout(stayId) {
  return this.http.patch(`${PATH_ROOM_CHECKIN}/check-out/${stayId}`);
}

export function roomCheckoutByAll(rresveNo, bsnDate) {
  return this.http.patch(`${PATH_ROOM_CHECKIN}/check-out/all/${rresveNo}/${bsnDate}`);
}

export function roomTotalSum(rresveNo, stayId) {
  return this.http.patch(`${PATH_ROOM_CHECKIN}/total-sum/${rresveNo}/${stayId}`);
}

export function roomRestoration(rresveNo) {
  return this.http.patch(`${PATH_ROOM_CHECKIN}/restoration/${rresveNo}`);
}

export function roomExtensionTrans(request) {
  return this.http.patch(`${PATH_ROOM_CHECKIN}/ext-tr`, request);
}