import layout from "@/views/layout/layout";

const voiceOfTheCustomerRoute = {
  path: "/voice-of-the-customer",
  component: layout,
  redirect: "/voice-of-the-customer/",
  children: [
    {
      path: "voc-status",
      name: "VocStatus",
      component: () => import("@/views/voice-of-the-customer/voc/VocStatus"),
      meta: {
        useUsualStyles: true,
      },
    },
  ],
};

export default voiceOfTheCustomerRoute;
