export const PATH_MEMBER_VISIT_STATUS = `/member-visit-status`;

export function fetchMemberVisitStatus(args) {
  return this.http.get(`${PATH_MEMBER_VISIT_STATUS}`, {
    params: {
      membershipId: args.membershipId,
      bsnYear: args.bsnYear,
      isLinkMember: args.isLinkMember,
      dateFrom: args.bsnDate.from,
      dateTo: args.bsnDate.to,
    }
  });
}