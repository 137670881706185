import Vue from 'vue';
import i18n from '@/i18n';

import {encodeBase64} from '@/utils/ImageUtil';
import GolfErpAPI from '@/api/v2/GolfErpAPI';

export const ValidType = Object.freeze({
  HP: Symbol("hp"),
  BIZ: Symbol("biz"),
  CPR: Symbol("cpr"),
  MAIL: Symbol("mail"),
  NON_KO: Symbol("non_korean"),
  NUMBER: Symbol("number"),
});

export const hpRegExp = /^((01[016789])([0-9]{3,4})([0-9]{4})|)$/;

export const inputType = Object.freeze({
  dateTimeRange: Symbol("dateTimeRange"),
});

/**
 * Form validator의 CustomPlace 초기화
 * @param field
 * @param data
 */
export function formCustomPlacement(inputElement, errorElement) {
  inputElement
    .closest(".form-group")
    .querySelector(".validation")
    .querySelector(".e-tip-content")
    .appendChild(errorElement); // errorElement 넣기
}

/**
 * Form validator 결과에 따라 validation 부분을 보였다 감추었다 하는 역할
 * @param args
 */
export function formValidationComplete(args) {
  if (args.status === "failure") {
    args.element
      .closest(".form-group")
      .querySelector(".validation").style.display = "block";
  } else {
    args.element
      .closest(".form-group")
      .querySelector(".validation").style.display = "none";
  }
}

/**
 * Form validation 내의 input element에서 엔터키를 치는 경우 form submit을 막아주는 역할
 * @param event
 */
export function formPreventEnterEvent(event) {
  if (event.key === "Enter") {
    event.preventDefault();
  }
}

/**
 * fieldValue 값이 'required' validation을 통과하는지 여부 체크. 통과하지 못하면 true 를 return
 * @param fieldValue 허용되는 타입 : number, bigint, boolean, object, string. 그 외의 타입이 들어오면 true 를 return
 * @returns {boolean}
 */
export function formNotPassedRequiredCheck(fieldValue) {
  const fieldType = typeof fieldValue;
  if (fieldType === "number" || fieldType === "bigint") {
    return isNaN(fieldValue);
  } else if (fieldType === "boolean") {
    return false;
  } else if (fieldType === "object") {
    if (Array.isArray(fieldValue)) {
      // array인 경우에는 length === 0 이면 통과하지 못한다
      return fieldValue.length === 0;
    } else {
      return fieldValue === null;
    }
  } else if (fieldType === "string") {
    return !fieldValue || !fieldValue.trim();
  } else {
    return true;
  }
}

/**
 * fieldValue 값이 'maxLength' validation을 통과하는지 여부 체크. 통과하지 못하면 true를 return
 * @param fieldValue 허용되는 타입 : number, bigint, string, undefined, object 중 null
 * @param maxLength
 * @returns {boolean}
 */
export function formNotPassedMaxLengthCheck(fieldValue, maxLength) {
  const fieldType = typeof fieldValue;
  if (fieldType === "number" || fieldType === "bigint") {
    return !isNaN(fieldValue) && fieldValue.toString().length > maxLength;
  } else if (fieldType === "string") {
    return fieldValue.length > maxLength;
  } else if (fieldValue === null || fieldType === "undefined") {
    // null, undefined는 무조건 통과
    return false;
  } else {
    throw new Error(
      `invalid data type - form length check, fieldValue:${fieldValue}, maxLength:${maxLength}`
    );
  }
}

export function formNotPassedMinLengthCheck(fieldValue, minLength) {
  const fieldType = typeof fieldValue;
  if (fieldType === "number" || fieldType === "bigint") {
    return !isNaN(fieldValue) && fieldValue.toString().length > minLength;
  } else if (fieldType === "string") {
    return fieldValue.length < minLength;
  } else if (fieldValue === null || fieldType === "undefined") {
    // null, undefined는 무조건 통과
    return false;
  } else {
    throw new Error(
      `invalid data type - form length check, fieldValue:${fieldValue}, minLength:${minLength}`
    );
  }
}

/**
 * fieldValue 값이 'regEx' validation을 통과하는지 여부 체크. 통과하지 못하면 true를 return
 * @param fieldValue 허용되는 타입 : string, undefined, object 중 null
 * @param regExp
 */
export function formNotPassedRegularExpressionCheck(fieldValue, ruleRegExp) {
  const fieldType = typeof fieldValue;
  if (fieldType === "string") {
    return !ruleRegExp.test(fieldValue);
  } else if (fieldValue === null || fieldType === "undefined") {
    // null, undefined는 무조건 통과 못함
    return true;
  } else {
    throw new Error(
      `invalid data type - form regEx check, fieldValue:${fieldValue}`
    );
  }
}

/**
 * fieldValue 값 min/max check
 * @param fieldValue 허용되는 타입 : number, bigint, undefined, object 중 null
 * @param compareValue 비교할 값
 * @param isMin true이면 min 체크
 */
export function formNotPassedRangeCheck(fieldValue, compareValue, isMin) {
  const fieldType = typeof fieldValue;
  if (fieldType === "number" || fieldType === "bigint") {
    if (isMin) {
      return fieldValue < compareValue;
    } else {
      return fieldValue > compareValue;
    }
  } else if (fieldValue === null || fieldType === "undefined") {
    // null 또는 undefined는 무조건 통과 못함
    return true;
  } else {
    throw new Error(
      `invalid data type - form min check. fieldValue:${fieldValue}, compareValue: ${compareValue}, isMin: ${isMin}`
    );
  }
}

const validFunction = {
  min: (header, compare, value) => {
    if (isNaN(compare) || isNaN(value)) {
      return null;
    }
    if (formNotPassedRangeCheck(value, compare, true)) {
      return vueMessageInstance.getMessage(
        "main.validationMessage.minMessage",
        [header, compare]
      );
    }
    return null;
  },
  max: (header, compare, value) => {
    if (isNaN(compare) || isNaN(value)) {
      return null;
    }
    if (formNotPassedRangeCheck(value, compare, false)) {
      return vueMessageInstance.getMessage(
        "main.validationMessage.maxMessage",
        [header, compare]
      );
    }
    return null;
  },
  range: (header, compare, value) => {
    if (isNaN(compare[0]) || isNaN(compare[1]) || isNaN(value)) {
      return null;
    }
    if (
      formNotPassedRangeCheck(value, compare[0], true) ||
      formNotPassedRangeCheck(value, compare[1], false)
    ) {
      return vueMessageInstance.getMessage(
        "main.validationMessage.rangeMessage",
        [header, compare[0], compare[1]]
      );
    }
    return null;
  },
  maxLength: (header, compare, value) => {
    if (formNotPassedMaxLengthCheck(value, compare)) {
      return vueMessageInstance.getMessage(
        "main.validationMessage.maxLengthMessage",
        [header, compare]
      );
    }
    return null;
  },
  minLength: (header, compare, value) => {
    if (formNotPassedMinLengthCheck(value, compare)) {
      return vueMessageInstance.getMessage(
        "main.validationMessage.minLengthMessage",
        [header, compare]
      );
    }
    return null;
  },
  required: (header, compare, value) => {
    if (formNotPassedRequiredCheck(value) && compare) {
      if (header) {
        return vueMessageInstance.getMessage(
          "main.validationMessage.requiredMessage",
          [header]
        );
      } else {
        return vueMessageInstance.getMessage(
          "main.validationMessage.requiredMessageNoParam"
        );
      }
    }
    return null;
  },
  type: (header, validType, value) => {
    if (value === null || value === undefined || value === "") {
      // null, undefined, empty string은 type 에서는 체크하지 않는다
      return null;
    }
    switch (validType) {
      case ValidType.HP:
        // eslint-disable-next-line no-case-declarations
        const hpRuleRegExp = hpRegExp; // empty string 허용
        if (formNotPassedRegularExpressionCheck(value, hpRuleRegExp)) {
          return vueMessageInstance.getMessage(
            "main.validationMessage.regexMessage",
            [header]
          );
        }
        break;
      case ValidType.BIZ:
        // eslint-disable-next-line no-case-declarations
        const bizLength = 10;
        if (value.length !== bizLength) {
          return vueMessageInstance.getMessage(
            "main.validationMessage.regexMessage",
            [header]
          );
        }
        break;
      case ValidType.CPR:
        // eslint-disable-next-line no-case-declarations
        const cprLength = 13;
        if (value.length !== cprLength) {
          return vueMessageInstance.getMessage(
            "main.validationMessage.regexMessage",
            [header]
          );
        }
        break;
      case ValidType.MAIL:
        // eslint-disable-next-line no-case-declarations
        const mailRuleRegExp = /^[A-Za-z0-9_.-]+@[A-Za-z0-9-]+\.[A-Za-z0-9-]+/;
        if (formNotPassedRegularExpressionCheck(value, mailRuleRegExp)) {
          return vueMessageInstance.getMessage(
            "main.validationMessage.regexMessage",
            [header]
          );
        }
        break;
      case ValidType.NON_KO:
        // eslint-disable-next-line no-case-declarations
        const nonKoreanRuleRegExp = /^[A-Za-z0-9_]*$/;
        if (formNotPassedRegularExpressionCheck(value, nonKoreanRuleRegExp)) {
          return vueMessageInstance.getMessage(
            "main.validationMessage.nonKoreanMessage",
            [header]
          );
        }
        break;
      case ValidType.NUMBER:
        // eslint-disable-next-line no-case-declarations
        const numberRuleRegExp = /^[+-]?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;
        if (
          formNotPassedRegularExpressionCheck(value, numberRuleRegExp) === true
        ) {
          return vueMessageInstance.getMessage(
            "main.validationMessage.nonNumberMessage",
            [header]
          );
        }
        break;
      default:
        return null;
    }

    return null;
  },
  custom: (header, { method, message, messageParams = [] }, value) => {
    if (!method(value)) {
      return vueMessageInstance.getMessage(message, [header, ...messageParams]);
    }
    return null;
  },
};

const vueMessageInstance = new Vue({
  i18n,
  methods: {
    getMessage: function (key, param) {
      return this.$t(key, param);
    },
  },
});

function getDivContentElement(refObj) {
  let divCntEle = null;

  if (refObj instanceof Vue) {
    divCntEle = refObj.$el.closest("li > div.content");
    // TODO : validation을 위한 임시 방편.
    if (!divCntEle) {
      divCntEle = refObj.$el.closest("li > ul.content > li.item");

      if (!divCntEle) {
        divCntEle = refObj.$el.closest("div.item > div.content > div.item");
      }
    }
  } else {
    divCntEle = refObj.closest("li > div.content");
    // TODO : validation을 위한 임시 방편.
    if (!divCntEle) {
      divCntEle = refObj.closest("li > ul.content > li.item");
    }
  }

  return divCntEle;
}
function getFormHeader(refObj) {
  let divCntEle = getDivContentElement(refObj);
  let header = null;
  let htmlCollection = divCntEle.parentElement.getElementsByClassName("title");
  // TODO : validation을 위한 임시 방편.
  if (!!htmlCollection && !(htmlCollection.length > 0)) {
    htmlCollection = divCntEle.parentElement.parentElement.getElementsByClassName(
      "title"
    );
  }
  if (htmlCollection.length > 0) {
    header = htmlCollection[0].innerHTML.trim();
  }
  return header;
}

function validationData(refObj, validObj, value) {
  const header = getFormHeader(refObj);
  let message = null;
  if (
    Object.prototype.hasOwnProperty.call(validObj, "required") &&
    !validObj.required &&
    (!value || value === "")
  ) {
    return message;
  }
  for (let i = 0, key; (key = Object.keys(validObj)[i]); i++) {
    if (Object.prototype.hasOwnProperty.call(validFunction, key)) {
      message = validFunction[key](header, validObj[key], value);
      if (message !== null) {
        break;
      }
    } else {
      message = vueMessageInstance.getMessage(
        "main.validationMessage.invalidRule"
      );
      break;
    }
  }
  return message;
}
export function validateFormRefs(validateRefList) {
  return validateFormRefsService(validateRefList, this);
}

export function validateFormRefsGetValidatedElement(validateRefList) {
  return validateFormRefsService(validateRefList, this, true);
}

/**
 * Validation 표시 clear
 * @param validateRefList
 */
export function validateFormRefsClear(validateRefList) {
  for (let i = 0, obj; (obj = Object.keys(validateRefList)[i]); i++) {
    const refObj = this.$refs[obj];
    if (refObj) {
      const warnDiv = getDivContentElement(refObj);
      if (warnDiv) {
        warnDiv.style.boxShadow = "none";
      }
    }
  }
}

const validateFormRefsService = (
  validateRefList,
  self,
  isGetElement = false
) => {
  let validatedObject;

  try {
    for (let i = 0, obj; (obj = Object.keys(validateRefList)[i]); i++) {
      const refObj = self.$refs[obj];
      if (refObj) {
        const validObj = validateRefList[obj];
        const warnDiv = getDivContentElement(refObj);
        let value = null;
        if (refObj instanceof Vue) {
          if (refObj.type && (refObj.type === inputType.dateTimeRange || refObj.inputType === inputType.dateTimeRange)) {
            const invalidArr = Object.keys(refObj.value).filter((data) => {
              const tmp = refObj.value[data];
              return tmp === null ||
                  tmp === undefined ||
                  tmp.toString().trim() === "";

            });
            value = invalidArr.length > 0 ? null : refObj.value;
          } else {
            value = refObj.ej2Instances ? refObj.ej2Instances.value : refObj.value;
          }
        } else {
          if (refObj.type.toLowerCase() === "radio") {
            const radioName = refObj.name ? refObj.name : null;
            const radioId = refObj.id ? refObj.id : null;
            const inputs = Array.from(
              document.getElementsByTagName("input")
            ).filter((data) => {
              return data.type === "radio" &&
                  (data.name === radioName || data.id === radioId);
            });
            const checkedRadio = inputs.filter((data) => data.checked);
            value = checkedRadio.length > 0 ? true : null;
          } else {
            value = refObj.value;
          }
        }
        const validMessage = validationData(refObj, validObj, value);
        if (validMessage) {
          if (refObj instanceof Vue) {
            if (isGetElement) {
              validatedObject = refObj.$el;
            } else {
              refObj.$el.focus();
            }
          } else {
            if (isGetElement) {
              validatedObject = refObj;
            } else {
              refObj.focus();
            }
          }

          if (warnDiv) {
            warnDiv.style.boxShadow = "0 0 0 1px #f00 inset";
          }
          if (!isGetElement) {
            self.$EventBus.$emit("errToast", validMessage);
          }
          throw new Error();
        } else {
          if (warnDiv) {
            warnDiv.style.boxShadow = "none";
          }
        }
      }
    }
    if (isGetElement) {
      return null;
    } else {
      return true;
    }
  } catch (e) {
    if (isGetElement) {
      return validatedObject;
    } else {
      return false;
    }
  }
};

function getFileErrorMessage(filesData) {
  if (filesData.length === 1) {
    if (filesData[0].status === "File size is too large") {
      // 파일 크기가 너무 큰 경우. status 문구로만 식별 가능
      return "파일 크기가 너무 큽니다";
    } else {
      return "파일을 다시 업로드해 주십시오";
    }
  } else {
    return "파일 갯수가 잘못되었습니다";
  }
}

export async function uploadImage(imageId, uploadDiv, filesData, file, sortNo = 0) {
  if (filesData.length === 1 && filesData[0].statusCode === "1") {
    // 파일 상태 체크는 statusCode로(값이 1이어야 upload. 그 외에는 alert 처리)
    try {
      const imageUploadData = {
        imageFile: {
          path: await encodeBase64(file),
          name: file.name,
        },
        uploadDiv,
        imageId,
        sortNo,
      };
      return await GolfErpAPI.putImageUpload(imageUploadData);
    } catch (e) {
      return null;
    }
  } else {
    throw new Error(getFileErrorMessage(filesData));
  }
}

export async function updateImageSortNo(imageId, sortNo) {
  try {
    return await GolfErpAPI.patchImageUploadSortNo({
      imageId,
      sortNo,
    });
  } catch (e) {
    return null;
  }
}
